import React from "react";
import _ from "lodash";
import classnames from "classnames";
import { Button, Avatar, Dropdown } from "antd";
import IconArrow from "../assets/images/ic_arrow_down.svg";
import IconDelete from "../assets/images/ic_clear.svg";
import IconAdd from "../assets/images/ic_add_green.svg";
import "./component_employee_gridview.less";
import { SchoolRole } from "../utils/enum";

interface Props {
  data: any[];
  onAddMore: Function;
  onDeleteEmployee: Function;
  onAuthChange: Function;
}
interface ItemProps {
  data: any;
  index: number;
  onDeleteEmployee: Function;
  onAuthChange: Function;
}

export const getRoleName = (authority: any, forBusiness = false) => {
  switch (authority) {
    case SchoolRole.ADMIN:
      return "Admin";
    case SchoolRole.TEACHER:
      return "Teacher";
    case SchoolRole.TEACHER_ASSISTANT:
      return "Teacher Assistant";
    case SchoolRole.OTHER:
    default:
      return forBusiness? 'Non-admin' : "Other";
  }
};

const EmployeeItem: React.FC<ItemProps> = (props) => {
  const { data, index, onDeleteEmployee, onAuthChange } = props;

  const menuAuth = (
    <div className="menu-auth">
      <div
        className="auth-item"
        onClick={() => onAuthChange({id: data.id, email: data.email}, SchoolRole.ADMIN)}
      >
        Admin
      </div>
      <div
        className="auth-item"
        onClick={() => onAuthChange({id: data.id, email: data.email}, SchoolRole.TEACHER)}
      >
        Teacher
      </div>
      <div
        className="auth-item"
        onClick={() => onAuthChange({id: data.id, email: data.email}, SchoolRole.TEACHER_ASSISTANT)}
      >
        Teacher Assistant
      </div>
      <div
        className="auth-item"
        onClick={() => onAuthChange({id: data.id, email: data.email}, SchoolRole.OTHER)}
      >
        Other
      </div>
    </div>
  );

  return (
    <div
      className={classnames("employee-item", {
        isCenter: (index - 1) % 3 === 0,
      })}
    >
      {data.avatar ? (
        <Avatar className="avatar" />
      ) : (
        <span className="empty-avatar">
          {data.name ? data.name[0] : data.email ? data.email[0] : ""}
        </span>
      )}

      <div className="name-sec">
        <div className="name">
          {data.firstName || data.lastName
            ? `${data.firstName} ${data.lastName}`
            : data.email || ""}
        </div>
        <Dropdown overlay={menuAuth} trigger={["click"]}>
          <div className="operate">
            {getRoleName(data.authority)}
            <img src={IconArrow} alt="" />
          </div>
        </Dropdown>
      </div>
      <img
        src={IconDelete}
        alt=""
        className="icon-delete"
        onClick={() => onDeleteEmployee(data.id)}
      />
    </div>
  );
};

export const EmployeeGridView: React.FC<Props> = (props) => {
  const { onAddMore, data, onDeleteEmployee, onAuthChange } = props;

  return (
    <div className="employee-grid-root">
      {_.map(data, (item, index) => {
        return (
          <EmployeeItem
            key={index}
            data={item}
            index={index}
            onDeleteEmployee={(id: number) => onDeleteEmployee(id)}
            onAuthChange={onAuthChange}
          />
        );
      })}
      <Button
        className={classnames("btn-secondary", "btn-add-employee", {
          isLineLast:
            data && ((data.length + 1) % 3 === 0 || data.length % 3 === 0),
        })}
        onClick={() => onAddMore()}
      >
        <img alt="" src={IconAdd} />
        Add more staff
      </Button>
    </div>
  );
};
