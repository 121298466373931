import React from "react";
import { Layout, Button } from "antd";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import CRSwitch from "../../components/component_cr_switch";
import queryString from "query-string";
// import { EmployeeGridView } from "../../components/component_employee_gridview";
import AddressTextField from "../../components/component_address_text_field";
import AddEmployeeModal from "../create_business_canvas/add_employee_modal";
import FieldRequireModal from "../../components/component_field_require_modal";
import { mapProp, doAction, doFunction } from "module-reaction";
import { searchSchool } from "../../api/Options";
import { CanvasType, ClassType, SchoolRole } from "../../utils/enum";
import IconSchool from "../../assets/images/ic_school.svg";
import IconAdd from "../../assets/images/ic_add_green.svg";
import IconDelete from "../../assets/images/ic_delete.svg";
// import IconEmployee from "../../assets/images/ic_employee.svg";
import IconLocation from "../../assets/images/ic_locate.svg";
import {
  ModuleSchoolInfo,
  module_school_info,
  DefaultLocationEditAction,
  AddExtraLocationAction,
  EditExtraLocationAction,
  DelExtraLocationAction,
  DelEmployeeAction,
  SubmitAction,
  UpdatePageInfoAction,
  InitDataAction,
  MODULE_SCHOOL_INFO,
} from "../../models/model_school_info";
import ContactNumberMask from "../../components/component_contact_number_mask";
import CRTextArea from "../../components/component_cr_textarea";
import { payStatus } from "../../api/Canvas";

const { Content } = Layout;
declare var google: any;

interface LocationDetailProps {
  index?: number;
  location: any;
  programOptions: any[];
  onAddEmployee: Function;
  onUpdate: Function;
  onDeleteEmployee: Function;
  onAuthChange: Function;
  autoCompleteService?: any;
  onAddressSelect: Function;
}

const LocationDetail: React.FC<LocationDetailProps> = (props) => {
  const {
    index = 0,
    location,
    programOptions,
    onUpdate,
    // onAddEmployee,
    // onDeleteEmployee,
    // onAuthChange,
    autoCompleteService,
    onAddressSelect,
  } = props;

  const classTypes = [
    {
      type: ClassType.DAY,
      value: "My school offers day classes",
    },
    {
      type: ClassType.NIGHT,
      value: "My school offers night classes",
    },
    {
      type: ClassType.ALL,
      value: "My school offers both day & night classes",
    },
  ];

  return (
    <div className="location-detail">
      <div className="location-divider" />
      <span className="location-title">
        <img alt="" src={IconLocation} />
        {`Location ${index ? `${index} ` : ""}Information`}
      </span>
      <AddressTextField
        label="Address"
        className="input"
        value={location.address || ""}
        onInput={(value: string) => onUpdate({ address: value })}
        onAddressSelect={onAddressSelect}
        autoCompleteService={autoCompleteService}
      />
      {/* <div className="accredited-sec">
        <span className="accredited-text">Are you an accredited school?</span>
        <CRSwitch
          enable={location.isAccredited || false}
          onChange={(enable: boolean) => {
            onUpdate({ isAccredited: enable });
          }}
        />
      </div>
      <CRTextArea
        type="number"
        className="textField-container"
        key="employee"
        label="Number of employees"
        value={location.employeeNumber || ""}
        onInput={(e: number) =>
          onUpdate({
            employeeNumber: Number(e) < 0 ? 0 : e,
          })
        }
        onKeyDown={(e: any) =>
          (e.keyCode === 69 ||
            e.keyCode === 187 ||
            e.keyCode === 189 ||
            e.keyCode === 190) &&
          e.preventDefault()
        }
      />
      <TextField
        select
        key="class avaibility"
        label="Day & Night class availability"
        variant="filled"
        value={
          (location.classType &&
            _.find(classTypes, { type: location.classType })?.value) ||
          ""
        }
        onChange={(e) => {
          const item = _.find(classTypes, { value: e.target.value });
          if (item) {
            onUpdate({ classType: item.type });
          }
        }}
        className="material-input input"
      >
        {_.map(classTypes, (item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.value}
            </MenuItem>
          );
        })}
      </TextField>
      <div className="chais-sec">
        <CRTextArea
          type="number"
          key="day-student"
          label="Number of Day students"
          className="chairs-input"
          value={location.dayStudents || ""}
          onInput={(e: any) => {
            let value = null;
            if (e !== "") {
              value = Number(e) < 0 ? 0 : e;
            }
            onUpdate({
              dayStudents: value,
            });
          }}
          onKeyDown={(e: any) =>
            (e.keyCode === 69 ||
              e.keyCode === 187 ||
              e.keyCode === 189 ||
              e.keyCode === 190) &&
            e.preventDefault()
          }
        />
        <CRTextArea
          key="night-student"
          label="Number of Night students"
          className="chairs-input"
          type="number"
          value={location.nightStudents || ""}
          onInput={(e: any) => {
            let value = null;
            if (e !== "") {
              value = Number(e) < 0 ? 0 : e;
            }
            onUpdate({
              nightStudents: value,
            });
          }}
          onKeyDown={(e: any) =>
            (e.keyCode === 69 ||
              e.keyCode === 187 ||
              e.keyCode === 189 ||
              e.keyCode === 190) &&
            e.preventDefault()
          }
        />
      </div>
      <Autocomplete
        multiple
        className="tag-input program-input"
        options={programOptions}
        freeSolo
        value={location.supportedProgram || []}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
        getOptionLabel={(option) => {
          return option.name || option;
        }}
        onChange={(event: object, value: any, reason: string) => {
          const newItems = _.map(value, (item) => {
            if (typeof item === "string") {
              if (value.find((_: any) => _.name === item)) {
                return undefined;
              }
              return programOptions.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
            }
            return item;
          }).filter(Boolean);
          onUpdate({ supportedProgram: _.uniqBy(newItems, 'name') });
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Supported program"
            variant="filled"
            className="material-input"
          />
        )}
      /> */}
      {/* {_.isEmpty(location.employee) ? (
        <div className="empty-employee-sec">
          <div className="left-sec">
            <img alt="" src={IconEmployee} className="icon-employee" />
            <div className="left-center">
              <span className="employee">Staff</span>
              <span className="later">You can leave it for later</span>
            </div>
          </div>
          <Button
            className="btn-secondary btn-add-employee"
            onClick={() => onAddEmployee()}
          >
            <img alt="" src={IconAdd} />
            Add staff
          </Button>
        </div>
      ) : (
        <div className="employee-sec">
          <div className="employee-header">
            <img alt="" src={IconEmployee} className="icon-employee" />
            Staff
          </div>
          <EmployeeGridView
            data={location.employee}
            onAddMore={() => onAddEmployee()}
            onDeleteEmployee={(id: number) => {
              onDeleteEmployee(id);
            }}
            onAuthChange={onAuthChange}
          />
        </div>
      )} */}
    </div>
  );
};

@mapProp(module_school_info)
export default class SchoolInfo extends React.Component<ModuleSchoolInfo, {}> {
  state = {
    showAddEmployee: false,
    curEditLocation: {} as any,
    autoCompleteService: {} as any,
    requiredFields: [] as string[],
    schoolOptions: [] as any[],
    schoolLoading: false,
  };

  autoCompleteService: any;
  placeService: any;

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  componentDidMount() {
    const { slug } = queryString.parse(window.location.search);
    if (slug) {
      doAction(InitDataAction, slug);
    }
    if (google) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
      const map = new google.maps.Map(document.createElement("div"));
      this.placeService = new google.maps.places.PlacesService(map);
    }
  }

  componentWillUnmount() {
    doAction(MODULE_SCHOOL_INFO, module_school_info);
  }

  private searchSchoolByName = async (keywords: string) => {
    if (!keywords) {
      this.updateState({ schoolOptions: [], schoolLoading: false });
      return;
    }
    this.updateState({ schoolLoading: true });
    const result = await searchSchool(keywords, [2, 20]);
    if (result && !_.isEmpty(result.schools)) {
      this.updateState({ schoolOptions: result.schools, schoolLoading: false });
    }
  };

  private onAddressSelected = (address: any, uuid: string) => {
    if (!address.place_id || !uuid) return;
    this.placeService.getDetails(
      { placeId: address.place_id },
      (place: any, status: any) => {
        if (status === "OK") {
          let country = "";
          let countryShort = "";
          let state = "";
          let stateShort = "";
          let city = "";
          let coordinates = [] as number[];
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          if (lat && lng) {
            coordinates = [lng, lat];
          }
          _.forEach(place.address_components, (item) => {
            if (item.types.indexOf("country") > -1) {
              country = item.long_name;
              countryShort = item.short_name;
            } else if (item.types.indexOf("administrative_area_level_1") > -1) {
              state = item.long_name;
              stateShort = item.short_name;
            } else if (item.types.indexOf("locality") > -1) {
              city = item.long_name;
            }
          });
          if (uuid === this.props.defaultLocation.uuid) {
            doAction(DefaultLocationEditAction, {
              ...this.props.defaultLocation,
              country: country,
              countryShort: countryShort,
              state: state,
              stateShort: stateShort,
              city: city,
              coordinates: coordinates,
            });
          } else {
            const extraLoc = [...this.props.extraLocation];
            const loc = _.find(extraLoc, { uuid: uuid });
            if (loc) {
              loc.country = country;
              loc.countryShort = countryShort;
              loc.state = state;
              loc.stateShort = stateShort;
              loc.city = city;
              loc.coordinates = coordinates;
            }
            doAction(UpdatePageInfoAction, {
              extraLocation: extraLoc,
            });
          }
        }
      }
    );
  };

  public submitSchoolInfo = () => {
    return this.onSubmit();
  }

  private onSubmit = (isSkip = false) => {
    //check fields
    const emptyField = [];
    if (!this.props.school?.name) {
      emptyField.push("School name");
    }
    if (!this.props.title) {
      emptyField.push("Your title");
    }
    // if (!this.props.contactNumber) {
    //   emptyField.push("Primary contact number");
    // }
    let extraHasEmptyAddress = false;
    _.forEach(this.props.extraLocation, (extra) => {
      if (!extra.address) {
        extraHasEmptyAddress = true;
      }
    });
    if (!this.props.defaultLocation.address || extraHasEmptyAddress) {
      emptyField.push("Location");
    }
    if (_.isEmpty(emptyField)) {
      doAction(SubmitAction, { history: this.props.history, isSkip: isSkip, isDirect: !this.props.isInline });
      return true;
    } else {
      this.updateState({
        requiredFields: emptyField,
      });
      return false;
    }
  };

  private handleScInput = (value: string) => {  
    doAction(UpdatePageInfoAction, {
      scSalonId: value,
    });
  };

  render() {
    const { isInline } = this.props;
    return (
      <Layout>
        {!isInline &&
          <CreateBusinessCanvasHeader
            type={CanvasType.SCHOOL}
            step={0}
            jumpToStep={(step: number) => {
              if (step === 1 && this.props.slug) {
                this.props.history.push(`/school-canvas/${this.props.slug}`);
              }
            }}
          />
        }
        <Content style={{ marginTop: !isInline ? '104px' : undefined }}>
          <div className="container">
            <div className="center-wrap">
              <span className="subtitle">
                <img alt="" src={IconSchool} />
                School Details
              </span>
              <Autocomplete
                key="school"
                freeSolo
                className="material-input company-input"
                options={this.state.schoolOptions}
                value={(this.props.school && this.props.school.name) || ""}
                loading={this.state.schoolLoading}
                getOptionLabel={(option) => {
                  return option.name || option;
                }}
                onChange={(event: object, value: any, reason: string) => {
                  let newItem = value;
                  if (typeof newItem === "string") {
                    newItem = { name: value };
                  }
                  doAction(UpdatePageInfoAction, {
                    school: newItem,
                  });
                }}
                onInputChange={(
                  event: object,
                  value: string,
                  reason: string
                ) => {
                  this.searchSchoolByName(value);
                  if (this.props.school && this.props.school.name !== value) {
                    doAction(UpdatePageInfoAction, {
                      school: { name: value },
                    });
                  }
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School name"
                    variant="filled"
                    className="material-input"
                    onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
                  />
                )}
              />

              <CRTextArea
                type="text"
                className="textField-container"
                key="title"
                label="Your title"
                placeholder="Your title"
                value={this.props.title}
                onInput={(value: string) => {
                  doAction(UpdatePageInfoAction, {
                    title: value,
                  });
                }}
              />

              <CRTextArea
                key="number"
                label="Primary contact number"
                type="text"
                className="textField-container"
                placeholder="Primary contact number"
                value={this.props.contactNumber || ""}
                onInput={(value: string) => {
                  doAction(UpdatePageInfoAction, {
                    contactNumber: value,
                  });
                }}
                onKeyDown={(e: any) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 187 ||
                    e.keyCode === 189 ||
                    e.keyCode === 190) &&
                  e.preventDefault()
                }
                InputProps={{ inputComponent: ContactNumberMask }}
              />

              <CRTextArea
                type="text"
                className="textField-container"
                key="scSalonId"
                label="SalonCentric School Id (Optional)"
                placeholder="SalonCentric School Id"
                value={this.props.scSalonId !== null ? this.props.scSalonId.toString() : ""}
                onInput={this.handleScInput}
                onKeyDown={(e: any) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 187 ||
                    e.keyCode === 189 ||
                    e.keyCode === 190) &&
                  e.preventDefault()
                }
              />

              <AddressTextField
                key="location"
                label={`Location${this.props.extraLocation?.length > 0 ? "1" : ""
                  }`}
                className="input"
                value={this.props.defaultLocation.address || ""}
                placeholder={`Location${this.props.extraLocation?.length > 0 ? "1" : ""
                  }`}
                onInput={(value: string) => {
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    address: value,
                  });
                }}
                onAddressSelect={(address: any) => {
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    address: address.description,
                  });
                  doFunction(async () => {
                    this.onAddressSelected(
                      address,
                      this.props.defaultLocation.uuid
                    );
                  });
                }}
                autoCompleteService={this.autoCompleteService}
              />
              {_.map(this.props.extraLocation, (location: any, idx: number) => {
                return (
                  <div className="extra-location" key={idx}>
                    <AddressTextField
                      key={`location${idx + 2}`}
                      label={`Location${idx + 2}`}
                      className="input"
                      value={location.address || ""}
                      placeholder={`Location${idx + 2}`}
                      onInput={(value: string) => {
                        doAction(EditExtraLocationAction, {
                          ...location,
                          address: value,
                        });
                      }}
                      onAddressSelect={(address: any) => {
                        doAction(EditExtraLocationAction, {
                          ...location,
                          address: address.description,
                        });
                        doFunction(async () => {
                          this.onAddressSelected(address, location.uuid);
                        });
                      }}
                      autoCompleteService={this.autoCompleteService}
                    />
                    <img
                      src={IconDelete}
                      alt=""
                      className="icon-delete"
                      onClick={() =>
                        doAction(DelExtraLocationAction, location.uuid)
                      }
                    />
                  </div>
                );
              })}
              <Button
                className="btn-secondary btn-add"
                onClick={() => {
                  doAction(AddExtraLocationAction);
                }}
              >
                <img alt="" src={IconAdd} />
                Add another location
              </Button>
              <LocationDetail
                index={_.isEmpty(this.props.extraLocation) ? 0 : 1}
                location={this.props.defaultLocation}
                programOptions={this.props.programOptions}
                onUpdate={(options: any) =>
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    ...options,
                  })
                }
                onAddEmployee={() =>
                  this.updateState({
                    curEditLocation: this.props.defaultLocation,
                    showAddEmployee: true,
                  })
                }
                onDeleteEmployee={(id: number) => {
                  doAction(DelEmployeeAction, {
                    locationId: this.props.defaultLocation.uuid,
                    employeeId: id,
                  });
                }}
                onAuthChange={(
                  item: { id: number; email: string },
                  auth: number
                ) => {
                  const employeeList = [...this.props.defaultLocation.employee];
                  const editItem = _.find(
                    employeeList,
                    (o) => o.id === item.id || o.email === item.email
                  );
                  if (editItem) {
                    editItem.authority = auth;
                    doAction(DefaultLocationEditAction, {
                      ...this.props.defaultLocation,
                      employee: employeeList,
                    });
                  }
                }}
                autoCompleteService={this.autoCompleteService}
                onAddressSelect={(address: any) => {
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    address: address.description,
                  });
                  doFunction(async () => {
                    this.onAddressSelected(
                      address,
                      this.props.defaultLocation.uuid
                    );
                  });
                }}
              />
              {!_.isEmpty(this.props.extraLocation) &&
                _.map(this.props.extraLocation, (location, idx) => {
                  return (
                    <LocationDetail
                      index={idx + 2}
                      key={idx}
                      location={location}
                      programOptions={this.props.programOptions}
                      onUpdate={(options: any) =>
                        doAction(EditExtraLocationAction, {
                          ...location,
                          ...options,
                        })
                      }
                      onAddEmployee={() =>
                        this.updateState({
                          curEditLocation: location,
                          showAddEmployee: true,
                        })
                      }
                      onDeleteEmployee={(id: number) => {
                        doAction(DelEmployeeAction, {
                          locationId: location.uuid,
                          employeeId: id,
                        });
                      }}
                      onAuthChange={(
                        item: { id: number; email: string },
                        auth: number
                      ) => {
                        const employeeList = [...location.employee];
                        const editItem = _.find(
                          employeeList,
                          (o) => o.id === item.id || o.email === item.email
                        );
                        if (editItem) {
                          editItem.authority = auth;
                          doAction(EditExtraLocationAction, {
                            ...location,
                            employee: employeeList,
                          });
                        }
                      }}
                      autoCompleteService={this.autoCompleteService}
                      onAddressSelect={(address: any) => {
                        doAction(EditExtraLocationAction, {
                          ...location,
                          address: address.description,
                        });
                        doFunction(async () => {
                          this.onAddressSelected(address, location.uuid);
                        });
                      }}
                    />
                  );
                })}
              <div className="bottom-sec">
              {this.props.payOrderStatus !== 'succeeded' && (
                <div className="next-sec">
                  <Button
                    className="btn-primary btn-next"
                    onClick={() => this.onSubmit(true)}
                  >
                    Skip to Purchase School Dashboard
                  </Button>
                  <span className="tip">
                    You can skip to purchase an School Dashboard right away.
                  </span>
                </div>
              )}
              <div className="skip-sec">
                <Button
                  className="btn-secondary btn-skip"
                  onClick={() => this.onSubmit()}
                >
                  {this.props.slug ? (<>Save</>) : (<>Next to Create</>)}&nbsp;School Canvas
                </Button>
                <span className="tip">
                  We’d recommend creating your school canvas first so that
                  your Student can view your school profile.
                </span>
              </div>
            </div>              

            </div>
          </div>
          <div id="map" hidden />
          {this.state.showAddEmployee && (
            <AddEmployeeModal
              visible
              onClose={() => this.updateState({ showAddEmployee: false })}
              onSend={(selected: any[]) => {
                this.updateState({ showAddEmployee: false });
                const addAthor = _.map(selected, (item) => {
                  return { ...item, authority: SchoolRole.OTHER };
                });
                const oldEmployeeList =
                  this.state.curEditLocation.employee || [];
                const newEmployeeList = _.uniqBy(
                  [...oldEmployeeList, ...addAthor],
                  "id"
                );
                doAction(
                  this.state.curEditLocation.uuid ===
                    this.props.defaultLocation.uuid
                    ? DefaultLocationEditAction
                    : EditExtraLocationAction,
                  {
                    ...this.state.curEditLocation,
                    employee: newEmployeeList,
                  }
                );
              }}
            />
          )}
          <FieldRequireModal
            onClose={() => this.updateState({ requiredFields: [] })}
            visible={!_.isEmpty(this.state.requiredFields)}
            data={this.state.requiredFields}
          />
        </Content>
      </Layout>
    );
  }
}
