import React, { ReactNode } from "react";
import classnames from "classnames";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import IconArrow from "../assets/images/ic_arrow_down.svg";
import "./component_expansion_panel.less";

interface Props {
  className?: string;
  title?: string;
  children?: ReactNode;
  onExpanded?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
}

const CRExpansionPanel: React.FC<Props> = (props) => {
  const { className = "", title, children, onExpanded } = props;

  return (
    <ExpansionPanel
      className={classnames(
        className,
        "material-expand",
        "expansion-panel-root"
      )}
      onChange={onExpanded}
    >
      <ExpansionPanelSummary
        key={title || "ExpansionPanelSummary"}
        expandIcon={<img alt="" src={IconArrow} />}
      >
        {title || ""}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CRExpansionPanel;
