import { ModuleStore, ModuleAction } from "module-reaction";
import { getNotiCount } from "../api/Notifications";

export const MODULE_NOTIFICATIONS = "MODULE_NOTIFICATIONS";

export interface ModelNotifications extends ModuleStore {
  notificationCount: number;
}

export const module_notifications: ModelNotifications = {
  module: MODULE_NOTIFICATIONS,
  notificationCount: 0,
};

export const GetNotificationCount: ModuleAction<any, ModelNotifications> = {
  module: MODULE_NOTIFICATIONS,
  process: async (x: any, module: ModelNotifications) => {
    const data = await getNotiCount();
    return {
      notificationCount: data.counter,
    };
  },
};
