import { ModuleStore, ModuleAction, KV, plusAction } from "module-reaction";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { getCustomized } from "../api/Options";
import { createCanvas, getCanvas, editCanvas } from "../api/Canvas";
import { getUserInfo } from "../api/Auth";
import { CanvasType } from "../utils/enum";
export const MODULE_SCHOOL_INFO = "module_school_info";

export interface SchoolLocationInfo {
  uuid: string;
  address: string;
  employee: any[];
  employeeNumber: number;
  country?: string;
  countryShort?: string;
  state?: string;
  stateShort?: string;
  city?: string;
  coordinates?: number[];
  isAccredited?: boolean;
  classType?: string;
  dayStudents?: number;
  nightStudents?: number;
  supportedProgram?: any[];
}

export interface ModuleSchoolInfo extends ModuleStore {
  id: number | null;
  payOrderStatus: string;
  programOptions: any[];
  slug: string;
  school: any;
  title: string;
  contactNumber: string;
  scSalonId: number | null;
  defaultLocation: SchoolLocationInfo;
  extraLocation: SchoolLocationInfo[];
  isInline: boolean;
}

export const module_school_info: ModuleSchoolInfo = {
  module: MODULE_SCHOOL_INFO,
  //options
  programOptions: [],
  //
  id: null,
  payOrderStatus: "none",
  slug: "",
  school: {},
  title: "",
  contactNumber: "",
  scSalonId: null,
  defaultLocation: {
    uuid: uuidv4(),
    employee: [] as any[],
  } as SchoolLocationInfo,
  extraLocation: [],
  isInline: false
};

export const InitDataAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (slug: string) => {
    if (slug) {
      const canvasInfo = await getCanvas(slug);
      if (canvasInfo) {
        let pageData = {
          id: canvasInfo.id,
          payOrderStatus: canvasInfo.payorder.status,
          slug: slug,
          school: {
            id: canvasInfo.schoolOption.optionId,
            name: canvasInfo.schoolOption.name,
          },
          title:
            (canvasInfo.introduction && canvasInfo.introduction.title) || "",
          contactNumber:
            (canvasInfo.detail && canvasInfo.detail.primaryContactNumber) || "",
          scSalonId:
            (canvasInfo.detail && canvasInfo.detail.scSalonId) || "",
        } as any;

        if (canvasInfo.detail && !_.isEmpty(canvasInfo.detail.locations)) {
          const mapped = _.map(canvasInfo.detail.locations, (item) => {
            return {
              uuid: item.uuid,
              address: item.address,
              city: item.city,
              coordinates: item.coordinates,
              country: item.country,
              state: item.state,
              stateShort: item.stateShort,
              countryShort: item.countryShort,
              employeeNumber: item.employeeAmount,
              classType: item.classType,
              dayStudents: item.dayStudents,
              nightStudents: item.nightStudents,
              supportedProgram: _.map(item.supportedProgram, (program) => {
                return {
                  id: program.optionId,
                  name: program.name,
                };
              }),
            };
          });
          const defaultLoc = mapped[0];
          const extraLoc = _.slice(mapped, 1);
          pageData = {
            ...pageData,
            defaultLocation: defaultLoc,
            extraLocation: extraLoc || [],
          };
        }
        plusAction(GetSelectionsAction);
        return pageData;
      }
      plusAction(GetSelectionsAction);
      return {
        slug: slug,
      };
    }
    plusAction(GetSelectionsAction, true);
    return {};
  },
};

export const GetSelectionsAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (prefill: boolean = false) => {
    const res = await getCustomized(true, ["program"]);
    if (res) {
      let params = {
        programOptions: res.program,
      } as any;
      if (prefill) {
        const userRes = await getUserInfo();
        if (
          userRes &&
          userRes.user &&
          userRes.user.profile &&
          !_.isEmpty(userRes.user.profile.jobTitles)
        ) {
          const jobTitle = _.first(userRes.user.profile.jobTitles) as any;
          if (jobTitle && jobTitle.name) {
            params = { ...params, title: jobTitle.name };
          }
        }
      }
      return params;
    }
    return {};
  },
};

export const DefaultLocationEditAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (location: SchoolLocationInfo, module: ModuleSchoolInfo) => {
    return { defaultLocation: location };
  },
};

export const UpdatePageInfoAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (options: KV, module: ModuleSchoolInfo) => {
    return { ...options };
  },
};

export const AddExtraLocationAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (key: any, module: ModuleSchoolInfo) => {
    return {
      extraLocation: [...module.extraLocation, { uuid: uuidv4() }],
    };
  },
};

export const EditExtraLocationAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (location: SchoolLocationInfo, module: ModuleSchoolInfo) => {
    const oldList = [...module.extraLocation];
    const curLocation = _.find(oldList, { uuid: location.uuid });
    if (curLocation) {
      curLocation.uuid = location.uuid;
      curLocation.address = location.address;
      curLocation.employee = location.employee;
      curLocation.employeeNumber = location.employeeNumber;
      curLocation.isAccredited = location.isAccredited;
      curLocation.classType = location.classType;
      curLocation.dayStudents = location.dayStudents;
      curLocation.nightStudents = location.nightStudents;
      curLocation.supportedProgram = location.supportedProgram;
      return { extraLocation: oldList };
    }
    return {};
  },
};

export const DelExtraLocationAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (uuid: string, module: ModuleSchoolInfo) => {
    const newList = _.filter(module.extraLocation, (item) => {
      return item.uuid !== uuid;
    });
    return {
      extraLocation: newList,
    };
  },
};

export const DelEmployeeAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (params: KV, module: ModuleSchoolInfo) => {
    const { locationId, employeeId } = params;
    if (locationId === module.defaultLocation.uuid) {
      const newList = _.filter(module.defaultLocation.employee, (item) => {
        return item.id !== employeeId;
      });

      return {
        defaultLocation: { ...module.defaultLocation, employee: newList },
      };
    } else {
      const locations = [...module.extraLocation];
      const cur = _.find(locations, { uuid: locationId });
      if (cur) {
        const newList = _.filter(cur.employee, (item) => {
          return item.id !== employeeId;
        });
        cur.employee = newList;
        return { extraLocation: locations };
      } else {
        return {};
      }
    }
  },
};

export const SubmitAction: ModuleAction<any, ModuleSchoolInfo> = {
  module: MODULE_SCHOOL_INFO,
  process: async (params: KV, module: ModuleSchoolInfo) => {
    const { history, isSkip } = params;
    const locationList = [module.defaultLocation, ...module.extraLocation];
    const newLocationList = _.map(locationList, (item) => {
      let location = {
        uuid: uuidv4(), //item.uuid,
        employeeAmount: item.employeeNumber,
        address: item.address,
        isAccredited: item.isAccredited,
        classType: item.classType,
        dayStudents: item.dayStudents,
        nightStudents: item.nightStudents,
        supportedProgram: _.map(item.supportedProgram, (item) => {
          let newItem = { name: item.name } as any;
          if (item.id) {
            newItem = {
              ...newItem,
              optionId: item.id,
            };
          }
          return newItem;
        }),
      } as any;
      if (!_.isEmpty(item.country)) {
        location = {
          ...location,
          country: item.country,
        };
      }
      if (!_.isEmpty(item.countryShort)) {
        location = {
          ...location,
          countryShort: item.countryShort,
        };
      }
      if (!_.isEmpty(item.state)) {
        location = {
          ...location,
          state: item.state,
        };
      }
      if (!_.isEmpty(item.stateShort)) {
        location = {
          ...location,
          stateShort: item.stateShort,
        };
      }
      if (!_.isEmpty(item.city)) {
        location = {
          ...location,
          city: item.city,
        };
      }
      if (!_.isEmpty(item.coordinates)) {
        location = {
          ...location,
          coordinates: item.coordinates,
        };
      }
      const newEmployee = _.map(item.employee, (emp) => {
        let newEmp = {
          uuid: uuidv4(),
          permission: {
            permission: emp.authority > 1 ? "viewOnly" : "canEdit",
          },
          role: emp.authority,
          type: "employee",
        } as any;
        if (emp.id) {
          newEmp = {
            ...newEmp,
            memberUserId: emp.id,
          };
        } else {
          newEmp = {
            ...newEmp,
            email: emp.email,
          };
        }

        return newEmp;
      });
      location = {
        ...location,
        members: newEmployee,
      };
      return location;
    });
    let schoolOpt = { name: module.school.name } as any;
    if (module.school.id) {
      schoolOpt = { ...schoolOpt, optionId: module.school.id };
    }
    const requestParams = {
      introduction: {
        title: module.title,
      },
      detail: {
        primaryContactNumber: module.contactNumber || null,
        scSalonId: module.scSalonId || null,
        locations: newLocationList,
      },
      schoolOption: schoolOpt,
    };
    let createRes;
    if (module.slug) {
      createRes = await editCanvas(module.id, requestParams);
    } else {
      createRes = await createCanvas(CanvasType.SCHOOL, requestParams);
    }

    if (createRes && createRes.slug) {
      if (params.isDirect) {
        if (isSkip) {
          history.push(`/school-payment/${createRes.slug}`);
        } else {
          history.push(`/school-canvas/${createRes.slug}`);
        }
      }
      return { slug: createRes.slug };
    }
    
    return { };
  },
};
