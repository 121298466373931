import React from "react";
import { Button, Avatar, Checkbox, message, Icon } from "antd";
import { TextField, InputAdornment } from "@material-ui/core";
import { getUserInfo, editUserInfo, fetchVerifyCode, verifyPhoneCode } from "../../api/Auth";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { modle_user, LoginWithTokenAction } from "../../models/model_user";
import FileUploader from "../../components/component_file_uploader";
import { AWS_URL } from "../../utils/constants";
import IconUpload from "../../assets/images/ic_upload_green.svg";
import IconAlert from "../../assets/images/ic_alert.svg";
import IconProfessionalInfo from "../../assets/images/ic_ professional_info.svg";
import IconDel from "../../assets/images/ic_delete.svg";
import IconProfileBorder from "../../assets/images/ic_profile_border.svg";
import "./setting.less";
import { mapProp, doAction } from "module-reaction";
import InputMask from 'react-input-mask';
import store from "../../utils/store";

@mapProp(modle_user)
export class Setting extends React.Component<{ history: any }, {}> {
  state = {
    profile: {} as any,
    email: "",
    loading: false,
    platformType: "",
    verify: false,
    code: null,
  };

  componentDidMount() {
    this.getUserInfo();
  }

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  private getUserInfo = async () => {
    const result = await getUserInfo();
    if (result && result.user) {
      const notification = result.user.profile?.settings?.notification ? result.user.profile.settings.notification : {
        daily: true,
        news: true,
        note: true,
      };
      this.updateState({
        profile: {
          ...result.user.profile,
          ...{
            settings: {
              notification
            },
          },
        },
        email: result.user.email,
        platformType: result.user.platformType
      });
    }
  };

  private uploadAvatar = async (file: any) => {
    this.updateState({ loading: true });
    const remotePath = "avatar/";
    const customPrefix = {
      public: remotePath,
    };
    const result: any = await Storage.put(uuidv4() + ".png", file, {
      customPrefix,
      progressCallback(progress: any) {
        const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
        store.dispatch({
          type: 'SET_UPLOAD_PROGRESS',
          payload: uploadProgress
        });
      }
    }).catch((error) => {
      message.error(error.message);
    });
    if (result && result.key) {
      const imageKey = result.key;
      const imgUrl = AWS_URL + remotePath + imageKey;
      this.updateState({
        profile: { ...this.state.profile, avatar: imgUrl },
        loading: false,
      });
    } else {
      this.updateState({ loading: false });
    }
  };
  private save = async () => {
    const profile = this.state.profile;
    let newProfile = {
      avatar: profile.avatar,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: this.state.email,
      zipCode: profile.zipCode,
    } as any;
    if (profile.phoneNumber) {
      newProfile = {
        ...newProfile,
        phoneNumber: profile.phoneNumber,
      };
    }
    if (profile.settings) {
      newProfile = {
        ...newProfile,
        settings: profile.settings,
      };
    }
    const result = await editUserInfo(newProfile);
    if (result && result.user) {
      if(result.success){
        message.success(result.success)
      }
      this.updateState({
        profile: result.user.profile,
        email: result.user.email,
      });
      doAction(LoginWithTokenAction);

    }
  };

  private verifyNumber = async (e: { preventDefault: () => void; }) => {
    e.preventDefault()
    const res = await fetchVerifyCode(this.state.profile.phoneNumber)
    if (res?.success) {
      this.updateState({verify: true})
      message.info('The verification code is sent!')
    }
  };

  private verifyCode = async (e: { preventDefault: () => void; }) => {
    e.preventDefault()
    const res = await verifyPhoneCode(this.state.profile.phoneNumber, this.state.code)
    if (res?.success) {
      message.success('Phone Number has been verified!');
      this.updateState({verify: false, profile: {...this.state.profile, verify: true}})
    }
  }

  render() {
    return (
      <div className="setting-root">
        <div className="title">Account Settings</div>
        <span className="subtitle">
          <img alt="" src={IconProfileBorder} />
          Profile Picture
        </span>
        <div className="avatar-sec">
          {this.state.profile.avatar ? (
            <Avatar
              className="avatar"
              src={this.state.profile.avatar || ""}
              icon="user"
              size={120}
            />
          ) : (
            <span className="default-avatar">
              {(this.state.profile &&
                this.state.profile.firstName &&
                this.state.profile.firstName[0]) ||
                ""}
            </span>
          )}

          <div className="avatar-btn-sec">
            <FileUploader onPost={(file: any) => this.uploadAvatar(file)}>
              <Button className="btn-secondary btn-upload">
                {this.state.loading ? (
                  <Icon
                    type="loading"
                    className="loading"
                    style={{ color: "#83b23a" }}
                  />
                ) : (
                  <img alt="" src={IconUpload} />
                )}
                Upload new picture
              </Button>
            </FileUploader>
            {/* <Button
              className="btn-secondary btn-delete"
              onClick={() => {
                this.updateState({
                  profile: { ...this.state.profile, avatar: "" },
                });
              }}
            >
              <img alt="" src={IconDel} />
              Delete
            </Button> */}
          </div>
        </div>
        <div className="info-sec">
          <span className="subtitle">
            <img alt="" src={IconProfessionalInfo} />
            Personal Information
          </span>
          <div className="name-sec">
            <TextField
              className="material-input name-input"
              label="first name"
              variant="filled"
              value={this.state.profile.firstName || ""}
              onChange={(e) => {
                this.updateState({
                  profile: { ...this.state.profile, firstName: e.target.value },
                });
              }}
              onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
            />
            <span className="tip tip-mobile">
              We’re big on real names around here, so people know who’s who.
            </span>
            <TextField
              className="material-input name-input"
              label="last name"
              variant="filled"
              value={this.state.profile.lastName || ""}
              onChange={(e) => {
                this.updateState({
                  profile: { ...this.state.profile, lastName: e.target.value },
                });
              }}
              onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
            />
          </div>
          <span className="tip">
            We’re big on real names around here, so people know who’s who.
          </span>
          <TextField
            className="material-input input"
            label="email"
            variant="filled"
            value={this.state.email || ""}
            disabled ={this.state.platformType !== 'internal' ? true : false}
            onChange={(e) => {
              this.updateState({
                email: e.target.value,
              });
            }}
          />
          <InputMask
            mask="999-999-9999"
            value={this.state.profile.phoneNumber || ""}
            onChange={(e) =>
                this.updateState({
                    profile: { ...this.state.profile, phoneNumber: e.target.value.replace(/\D/g,'') }
                })
            }
          >
            {() => (
                <TextField
                    className="material-input input"
                    label="Phone no."
                    variant="filled"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><span className="phone-pre">+1</span></InputAdornment>,
                    }}
                />
            )}
          </InputMask>
          {this.state.profile.verify ? (
            <>
              This number has been txt verified
            </>
          ):(
            <>
              This number needs to be txt verified
              <div className="btn-section">
                <Button className="btn-primary btn-save" onClick={(e) => this.verifyNumber(e)}>
                  Txt Me Code
                </Button>
              </div>
              {this.state.verify && (
                <>
                  Enter Verification code:<br />
                  <TextField
                    className="material-input name-input"
                    label="Code"
                    variant="filled"
                    value={this.state.code || ""}
                    onChange={(e) => {
                      this.updateState({
                        code: e.target.value
                      });
                    }}
                    onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
                  />
                  <div className="btn-section">
                    <Button className="btn-primary btn-save" onClick={(e) => this.verifyCode(e)}>
                    Verify Code
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          <br />
          <TextField
            key="zip-code"
            label="Zip Code"
            variant="filled"
            value={this.state.profile.zipCode || ""}
            onChange={(e) => {
                const input = e.target.value;
                if (/^[a-zA-Z\d]{0,20}$/.test(input)) {
                    this.setState({  profile: { ...this.state.profile, zipCode: e.target.value }, });
                }
            }}
            className="material-input input-title"
          />
        </div>
        <span className="title">Email & SMS Notifications</span>
        <span className="subtitle">
          <img alt="" src={IconAlert} />
          Alert & Notifications
        </span>
        <div className="select-sec">
          <div className="select-item">
            <Checkbox
              className="check-box"
              checked={
                this.state.profile.settings &&
                this.state.profile.settings.notification &&
                this.state.profile.settings.notification.news
              }
              onChange={(e) => {
                const settings =
                  this.state.profile.settings &&
                  this.state.profile.settings.notification
                    ? {
                        ...this.state.profile.settings,
                        notification: {
                          ...this.state.profile.settings.notification,
                          news: e.target.checked,
                        },
                      }
                    : { notification: { news: e.target.checked } };
                this.updateState({
                  profile: {
                    ...this.state.profile,
                    settings: settings,
                  },
                });
              }}
            />
            <span>Get news, announcements, and updates</span>
          </div>
          <div className="select-item">
            <Checkbox
              className="check-box"
              checked={
                this.state.profile.settings &&
                this.state.profile.settings.notification &&
                this.state.profile.settings.notification.note
              }
              onChange={(e) => {
                const settings =
                  this.state.profile.settings &&
                  this.state.profile.settings.notification
                    ? {
                        ...this.state.profile.settings,
                        notification: {
                          ...this.state.profile.settings.notification,
                          note: e.target.checked,
                        },
                      }
                    : { notification: { news: e.target.checked } };
                this.updateState({
                  profile: {
                    ...this.state.profile,
                    settings: settings,
                  },
                });
              }}
            />
            <span>
              Get important notifications about you or activity you've missed
            </span>
          </div>
          <div className="select-item">
            <Checkbox
              className="check-box"
              checked={
                this.state.profile.settings &&
                this.state.profile.settings.notification &&
                this.state.profile.settings.notification.daily
              }
              onChange={(e) => {
                const settings =
                  this.state.profile.settings &&
                  this.state.profile.settings.notification
                    ? {
                        ...this.state.profile.settings,
                        notification: {
                          ...this.state.profile.settings.notification,
                          daily: e.target.checked,
                        },
                      }
                    : { notification: { news: e.target.checked } };
                this.updateState({
                  profile: {
                    ...this.state.profile,
                    settings: settings,
                  },
                });
              }}
            />
            <span>
              Get notified when opportunities are posted in your area
            </span>
          </div>
        </div>
        <div className="btn-sec">
          <Button className="btn-primary btn-save" onClick={() => this.save()}>
            Save Profile
          </Button>
          <Button
            className="btn-secondary btn-pwd"
            onClick={() => this.props.history.push("/settings/change-password")}
          >
            Change password
          </Button>
        </div>
      </div>
    );
  }
}
