import { ModuleStore, ModuleAction, KV, plusAction } from "module-reaction";
import { getCustomized } from "../api/Options";
import { createCanvas, getCanvas, editCanvas } from "../api/Canvas";
import { getInviteSchool } from "../api/School";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";
import { localStorageGet } from "../utils/utils";
import { CanvasType } from "../utils/enum";
export const MODULE_EDUCATION_INFO = "module_education_info";

export interface ModuleEducationInfo extends ModuleStore {
  //options
  programOptions: any[];
  //page data
  slug: string;
  educationList: IEducationItem[];
  deanList: IAwardItem[];
  honoredAndAwards: IAwardItem[];
  caper: IAwardItem[];
  beacon: IAwardItem[];
  nahaSubmission: IAwardItem[];
  scholarshipRecipient: IAwardItem[];
  club: IAwardItem[];
  category: any;
  oldAwards: IAwardItem[];
}

export interface IEducationItem {
  uuid?: string;
  program: any;
  school: any;
  graduationDate: any;
  gpa: string;
  attendance: string;
  workPermit: string;
}

export const educationItem: IEducationItem = {
  uuid: "",
  program: {},
  school: {},
  graduationDate: null,
  gpa: "",
  attendance: "",
  workPermit: "",
};

export interface IAwardItem {
  title: string;
  issuer?: string;
  year: any;
}

export const awardItem: IAwardItem = {
  title: "",
  issuer: "",
  year: null,
};

export const module_education_info: ModuleEducationInfo = {
  module: MODULE_EDUCATION_INFO,
  //options
  programOptions: [],
  //pagedata
  slug: "",
  educationList: [educationItem],
  deanList: [],
  honoredAndAwards: [],
  caper: [],
  beacon: [],
  nahaSubmission: [],
  scholarshipRecipient: [],
  club: [],
  category: {},
  oldAwards: [],
};

export const awardRenderList = [
  {
    title: "Dean’s List",
    intro: "Have you ever made the Dean’s List?",
    name: "deanList",
    defaultTitle: "Dean’s List",
    isDean: true,
  },
  {
    title: "Achievements and Awards",
    intro:
      "Are you proud of something you have accomplished? Share it with the world!",
    name: "honoredAndAwards",
  },
  {
    title: "Caper",
    intro: "Have you attended the Paul Mitchell Caper event?",
    name: "caper",
    defaultIssuer: "Paul Mitchell Caper ",
  },
  {
    title: "PBA Beacon",
    intro: "Have you ever been selected to attend the PBA Beacon event?",
    name: "beacon",
    defaultIssuer: "PBA Beacon",
  },
  {
    title: "NAHA",
    intro: "Have you ever won a NAHA award?",
    name: "nahaSubmission",
    defaultIssuer: "NAHA",
  },
  {
    title: "Scholarship Recipient",
    intro: "Have you received any scholarships?",
    name: "scholarshipRecipient",
  },
  {
    title: "Club/ Teams",
    intro: "Active in any club or team?",
    name: "club",
    isClub: true,
  },
];

export const UpdateBaseInfoAction: ModuleAction<KV, ModuleEducationInfo> = {
  module: MODULE_EDUCATION_INFO,
  process: async (info: KV) => {
    return { ...info };
  },
};

export const InitDataActions: ModuleAction<any, ModuleEducationInfo> = {
  module: MODULE_EDUCATION_INFO,
  process: async (slug: string) => {
    if (slug) {
      const canvasInfo = await getCanvas(slug);
      if (canvasInfo) {
        let pageData = {
          id: canvasInfo.id,
          slug: slug,
          educationList: _.map(canvasInfo.educations, (item) => {
            let newItem = {
              workPermit: item.workPermitHour || "",
              attendance: item.attendance || "",
              gpa: item.gpa || "",
              graduationDate: item.graduationDate,
            } as any;
            if (item.program && item.program.name && item.program.optionId) {
              newItem = {
                ...newItem,
                program: {
                  id: item.program.optionId,
                  name: item.program.name,
                },
              };
            }
            if ((item.schoolId || item.schoolOptionId) && item.schoolName) {
              newItem = {
                ...newItem,
                school: {
                  id: item.schoolId || item.schoolOptionId,
                  name: item.schoolName,
                },
              };
            }
            return newItem;
          }),
          oldAwards: canvasInfo.achievements || [],
        } as any;

        plusAction(GetOptionsActions);
        return pageData;
      }
      plusAction(GetOptionsActions);
      return {
        slug: slug,
      };
    }
    plusAction(GetOptionsActions);
    return {};
  },
};

export const GetOptionsActions: ModuleAction<KV, ModuleEducationInfo> = {
  module: MODULE_EDUCATION_INFO,
  process: async () => {
    const res = await getCustomized(true, ["program", "category"]);
    const invitationCode = localStorageGet("invitationCode");
    if (invitationCode) {
      const inviteSchool = await getInviteSchool(invitationCode);
      console.log("inviteSchool", inviteSchool);
    }
    if (res) {
      return {
        programOptions: res.program,
        category: _.map(res.category.student, (item) => {
          return {
            optionId: item.id,
            name: item.name,
          };
        }),
      };
    }
    return {};
  },
};

export const SubmitAction: ModuleAction<any, ModuleEducationInfo> = {
  module: MODULE_EDUCATION_INFO,
  process: async (history: any, module: ModuleEducationInfo) => {
    const request = convertToRequestData(module);
    let createRes;
    if (module.slug) {
      createRes = await editCanvas(module.id, request);
    } else {
      createRes = await createCanvas(CanvasType.STUDENT, request);
    }
    if (createRes && createRes.slug) {
      history.push(`/student-canvas/${createRes.slug}`);
    }
    return {};
  },
};

const convertToRequestData = (module: ModuleEducationInfo) => {
  const educations = _.map(module.educationList, (item) => {
    let newProgram = {
      name: item.program.name,
    } as any;
    if (item.program.id) {
      newProgram = {
        ...newProgram,
        optionId: item.program.id,
      };
    }
    let newEdu = {
      uuid: item.uuid || uuidv4(),
      schoolName: item.school.name || "",
      program: newProgram,
      graduationDate: item.graduationDate
        ? moment(item.graduationDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    } as any;
    if (item.school && item.school.id) {
      newEdu = {
        ...newEdu,
        schoolId: item.school.id,
      };
    }
    if (item.gpa) {
      newEdu = {
        ...newEdu,
        gpa: Number(item.gpa),
      };
    }
    if (item.attendance) {
      newEdu = {
        ...newEdu,
        attendance: Number(item.attendance),
      };
    }
    if (item.workPermit) {
      newEdu = {
        ...newEdu,
        workPermitHour: Number(item.workPermit),
      };
    }
    return newEdu;
  });

  const awards = [
    ...module.deanList,
    ...module.honoredAndAwards,
    ...module.caper,
    ...module.beacon,
    ...module.nahaSubmission,
    ...module.scholarshipRecipient,
    ...module.club,
  ];
  const mappedAwards = _.map(awards, (item) => {
    return {
      uuid: uuidv4(),
      title: item.title,
      issuer: item.issuer,
      issueYear: moment(item.year).get("year").toString(),
    };
  });

  const request = {
    educations: educations,
    detail: { categories: module.category },
    achievements: _.isEmpty(mappedAwards) ? module.oldAwards : mappedAwards,
  };

  return request;
};
