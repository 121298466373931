import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';

// Define interfaces for each part of the state
interface LandingPageState {
  visitedLandingPage: {
    source: string | null;
    rep: string | null;
  };
  getStartedButtonClicked: boolean;
}

interface AuthState {
  showReloginModal: boolean;
}

interface UploadState {
  progress: number;
}

// Define the root state interface including the upload state
export interface RootState {
  landingPage: LandingPageState;
  auth: AuthState;
  upload: UploadState;
}

// Initial state for landing page
const initialLandingPageState: LandingPageState = {
  visitedLandingPage: {
    source: null,
    rep: null,
  },
  getStartedButtonClicked: false,
};

// Initial state for authentication
const initialAuthState: AuthState = {
  showReloginModal: false,
};

// Initial state for upload progress
const initialUploadState: UploadState = {
  progress: 0,
};

// Action types
const MARK_LANDING_PAGE_VISITED = 'MARK_LANDING_PAGE_VISITED';
const SET_GET_STARTED_BUTTON_CLICKED_TRUE = 'SET_GET_STARTED_BUTTON_CLICKED_TRUE';
const SET_GET_STARTED_BUTTON_CLICKED_FALSE = 'SET_GET_STARTED_BUTTON_CLICKED_FALSE';
const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';

// Action creators
export const setGetStartedButtonClickedTrue = () => ({
  type: SET_GET_STARTED_BUTTON_CLICKED_TRUE,
});

export const setGetStartedButtonClickedFalse = () => ({
  type: SET_GET_STARTED_BUTTON_CLICKED_FALSE,
});

export const markLandingPageVisited = (source: string | null, rep: string | null) => ({
  type: MARK_LANDING_PAGE_VISITED,
  payload: { source, rep },
});

export const setUploadProgress = (progress: number) => ({
  type: SET_UPLOAD_PROGRESS,
  payload: progress,
});

// Reducers
const landingPageReducer = (state = initialLandingPageState, action: any) => {
  switch (action.type) {
    case MARK_LANDING_PAGE_VISITED:
      return {
        ...state,
        visitedLandingPage: action.payload,
      };
    case SET_GET_STARTED_BUTTON_CLICKED_TRUE:
      return {
        ...state,
        getStartedButtonClicked: true,
      };
    case SET_GET_STARTED_BUTTON_CLICKED_FALSE:
      return {
        ...state,
        getStartedButtonClicked: false,
      };
    default:
      return state;
  }
};

const authReducer = (state = initialAuthState, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_RELOGIN_MODAL':
      return { ...state, showReloginModal: !state.showReloginModal };
    case 'SHOW_RELOGIN_MODAL':
      return { ...state, showReloginModal: true };
    case 'HIDE_RELOGIN_MODAL':
      return { ...state, showReloginModal: false };
    default:
      return state;
  }
};

const uploadReducer = (state = initialUploadState, action: any) => {
  switch (action.type) {
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    default:
      return state;
  }
};

// Combining reducers
const rootReducer = combineReducers({
  landingPage: landingPageReducer,
  auth: authReducer,
  upload: uploadReducer,
});

// Creating the store
const store = configureStore({
  reducer: rootReducer,
});

export default store;
