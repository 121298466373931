import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { TextField, MenuItem, Button } from "@material-ui/core";
import HorizontalScrollView from "../../../components/component_horizontal_scroll_view";
import _ from "lodash";
import queryString from "query-string";
import BusinessDashboardStats from "./business_dashboard_stats";
import { MyAccountPostedOpp } from "../dashboard_opportunity/my_account_posted_opportunities";
import { MyOpportunities } from "../dashboard_opportunity/my_opportunities";
import cloneDeep from "lodash/cloneDeep";
import { modle_user, ModleUser } from "../../../models/model_user";
import classnames from "classnames";
import { mapProp, doAction } from "module-reaction";
import { freshDashboardAction, MODULE_DASHBOARD_INDEX } from "../../../models/model_dashboard_index";
import { SchoolRole, router_business_payment_with_id } from "../../../utils/enum";
import { SchoolDashboardTeam } from "../school_dashboard_index/school_dashboard_team";
import { getRoleName } from "../../../components/component_employee_gridview";
import iconNote from '../../../assets/images/ic_big_note.svg'
import './business_dashboard_index.less'

interface BusinessDashboardProps extends ModleUser, RouteComponentProps {
  viewCanvas?: number
}
@mapProp(modle_user)
export class BusinessDashboardIndex extends React.Component<
  BusinessDashboardProps
> {

  tabType: any = {
    posted_opportunity: "Posted Opportunities",
  };

  selectedCanvas = () => this.props?.curCanvas || {}

  get dashboardRouters() {
    const curCanvas: any = this.selectedCanvas();

    return [
      {
        name: "My Team",
        component: () => (
          <SchoolDashboardTeam
            business={true}
            school={this.selectedCanvas()}
            changeTab={this.changeTab}
            history={this.props.history}
            curUser={this.props.curUser}
          />
        ),
        hidden:
          curCanvas &&
          curCanvas.memberRole &&
          curCanvas.memberRole !== SchoolRole.ADMIN,
      },
      {
        name: "My Opportunities",
        component: () => <MyOpportunities canvas={curCanvas}></MyOpportunities>,
        exact: true,
        showMenu: true,
      },
      {
        name: "Posted Opportunities",
        component: () => (
          <MyAccountPostedOpp
            canvasInfo={this.selectedCanvas()}
            history={this.props.history}
          ></MyAccountPostedOpp>
        ),
        hidden:
        curCanvas &&
        curCanvas.memberRole &&
        curCanvas.memberRole !== SchoolRole.ADMIN,
      },
      {
        name: "Stats",
        component: () => (
          <BusinessDashboardStats canvas={this.selectedCanvas()} />
        ),
      },
    ];
  }
  
  state = {
    curTab: _.last(this.dashboardRouters),
  };


  componentDidMount() {
    const searchObj: any = queryString.parse(window.location.search);
    if (searchObj && searchObj.tabType && this.tabType[searchObj.tabType]) {
      const router = _.find(this.dashboardRouters, {
        name: this.tabType[searchObj.tabType],
      });
      router && this.setState({ curTab: router });
    }
  }

  componentDidUpdate() {
    this.initCurTab();
  }

  componentWillReceiveProps(props: any) {
    if (props.curCanvas?.id !== this.props.curCanvas?.id) {
      if (this.state.curTab?.name) {
        // refresh curTab component
        const curTabName = this.state.curTab.name
        this.setState({ curTab: undefined}, () => {
          this.changeTab(curTabName)
        })
      }
    }
  }

  changeTab = (name: string) => {
    this.setState({
      curTab: _.find(this.dashboardRouters, { name }),
    });
  };

  private initCurTab = () => {
    if (!this.state.curTab?.name) {
      this.setState({ curTab: _.last(this.dashboardRouters) });
    }
  };

  render() {
    const { curCanvas, canvasList, curCanvasPaid } = this.props;
    let canvasSelect = 0;
    if (curCanvas) {
      canvasSelect = canvasList.findIndex((s: any) => s.id === curCanvas.id);
    }
    const tabs = cloneDeep(this.dashboardRouters)
      .reverse()
      .map((item: any, index: number) => item.hidden? null : (
        <span
          className={classnames("tab-btn tab-item", {
            active: this.state.curTab && item.name === this.state.curTab.name,
          })}
          key={index}
          onClick={() => {
            this.setState({curTab: item });
          }}
        >
          {item.name}
        </span>
      ));

    return (
      <div className="school-dashboard-wrap">
        <div className="school-dashboard-tab-wrap">
          <div className="tab-list">
            {curCanvasPaid && (
              <HorizontalScrollView className="scroll">
                {tabs}
              </HorizontalScrollView>
            )}
          </div>
          <TextField
            label="Dashboard"
            variant="filled"
            select
            className="material-input input"
            value={"" + canvasSelect || ""}
            onChange={(e) => {
              const curCanvas = this.props.canvasList[e.target.value];
              doAction(freshDashboardAction, curCanvas);
            }}
          >
            {this.props.canvasList.map((item: any, index: number) => (
              <MenuItem key={index} value={"" + index}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          {curCanvasPaid && (
            <>
              {curCanvas?.memberRole && (
                <div className="member-role">
                  View as {getRoleName(curCanvas.memberRole, true)}
                </div>
              )}
            </>
          )}
        </div>
        {curCanvasPaid && (
          <>
            {this.state.curTab && this.state.curTab.component()}
          </>
        )}

        {!curCanvasPaid && (
          <div className="business-pay-tip">
            <div className="tip-content">
              <img src={iconNote} alt="note" />
              <div className="tip-title">Subscribe to a Business plan to use the dashboard</div>
              <div className="tip-des">This canvas profile is not subscribed to a paid plan. Please process your payment to see your dashboard.</div>
              <Button 
                className="btn-primary btn-pay"
                onClick={e => this.props.history.push(router_business_payment_with_id.replace(':id', curCanvas.id))}
              >Subscribe</Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}
