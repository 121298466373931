import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import { TextField, IconButton } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import noop from "lodash/noop";
import cloneDeep from "lodash/cloneDeep";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import _ from "lodash";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import { mapProp, doAction } from "module-reaction";
import { CanvasType } from "../../utils/enum";
import { searchSchool } from "../../api/Options";
import FieldRequireModal from "../../components/component_field_require_modal";
import {
  MODULE_EDUCATION_INFO,
  module_education_info,
  educationItem,
  awardRenderList,
  ModuleEducationInfo,
  UpdateBaseInfoAction,
  SubmitAction,
  IAwardItem,
  InitDataActions,
} from "../../models/model_education_info";
import { modle_user, LoginWithTokenAction } from "../../models/model_user";
import classnames from "classnames";
import IconEducationInfo from "../../assets/images/ic_education_info.svg";
import IconDelete from "../../assets/images/ic_delete.svg";
import IconEdit from "../../assets/images/ic_edit.svg";
import IconAward from "../../assets/images/ic_award.svg";
import IconClub from "../../assets/images/ic_club.svg";
import IconAwardAdd from "../../assets/images/ic_award_add.svg";
import "./education_info.less";
const { Content } = Layout;

export const YseOrNo = (props: any) => {
  const { title, afterSelect = noop, enable = false } = props;
  const [selectYes, handleSelectYes] = useState(false);

  useEffect(() => {
    handleSelectYes(enable);
  }, [enable]);

  return (
    <div className="yes-or-no-wrap">
      <div className="title">{title}</div>
      <div className="select-wrap">
        <span
          className={classnames({ active: selectYes })}
          onClick={() => {
            handleSelectYes(true);
            afterSelect(true);
          }}
        >
          Yes
        </span>
        <span
          className={classnames({ active: !selectYes })}
          onClick={() => {
            handleSelectYes(false);
            afterSelect(false);
          }}
        >
          No
        </span>
      </div>
    </div>
  );
};

export const AwardsList = (props: any) => {
  const {
    title,
    intro,
    isClub = false,
    isDean = false,
    listData = [],
    setListData,
    isMobile,
    defaultTitle,
    defaultIssuer,
  } = props;
  const defaultItem = {
    title: defaultTitle || "",
    issuer: defaultIssuer || "",
    year: null,
  } as IAwardItem;
  const [showForm, handleShowForm] = useState(false);
  const [formIndex, handleFormIndex] = useState(-1);
  const [formData, handleFormData] = useState(defaultItem);
  const [schoolOptions, setSchoolOptions] = useState([] as any[]);
  const [schoolOptionsLoading, setSchoolOptionsLoading] = useState(false);
  const [editIndexList, setEditIndexList] = useState([] as number[]);

  const searchSchoolByName = async (keywords: string) => {
    if (!keywords) {
      setSchoolOptions([]);
      setSchoolOptionsLoading(false);
      return;
    }
    setSchoolOptionsLoading(true);
    const result = await searchSchool(keywords, [2, 20]);
    if (result && !_.isEmpty(result.schools)) {
      setSchoolOptions(result.schools);
      setSchoolOptionsLoading(false);
    }
  };

  return (
    <div className="awards-list-wrap">
      <div className="title-with-icon">
        <img alt="" src={isClub ? IconClub : IconAward} />
        {title}
      </div>
      <YseOrNo
        title={intro}
        afterSelect={(value: boolean) => {
          handleShowForm(value);
          if (!value) {
            setListData([]);
          }
        }}
      />
      <div className="award-list">
        {listData.map((item: any, index: number) => (
          <div className="award-item-wrap" key={index}>
            <div className="award-item">
              <div className="item-icon">
                <img alt="" src={isClub ? IconClub : IconAward} />
              </div>
              <div className="item-content">
                <h2>{item.title}</h2>
                <div>
                  {moment(item.year).format("YYYY")}
                  {isClub ? "" : " - " + item.issuer}
                </div>
              </div>
              <div className="handle-item">
                <IconButton
                  onClick={() => {
                    handleFormIndex(index);
                    handleFormData(item);
                    handleShowForm(true);
                    setEditIndexList([...editIndexList, index]);
                  }}
                  disabled={showForm}
                >
                  <img src={IconEdit} alt="" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    const newListData = [...listData];
                    newListData.splice(index, 1);
                    setListData(newListData);
                    if (!newListData.length) {
                      handleFormIndex(-1);
                      handleFormData(defaultItem);
                      handleShowForm(true);
                    }
                  }}
                >
                  <img src={IconDelete} alt="" />
                </IconButton>
              </div>
            </div>
            {index === listData.length - 1 ? (
              <Button
                className="btn-secondary"
                onClick={() => {
                  handleFormIndex(-1);
                  handleFormData(defaultItem);
                  handleShowForm(true);
                }}
              >
                <img src={IconAwardAdd} alt="" />
                Add more
              </Button>
            ) : null}
          </div>
        ))}
      </div>
      {showForm ? (
        <div className="award-form">
          <Row gutter={24}>
            {isClub ? (
              <>
                <Col span={isMobile ? 24 : 12}>
                  <TextField
                    label="Club/team name"
                    variant="filled"
                    className="material-input input"
                    placeholder="Issuer"
                    value={formData.title}
                    onChange={(e) =>
                      handleFormData({ ...formData, title: e.target.value })
                    }
                  />
                </Col>
                <Col span={isMobile ? 24 : 12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disableFuture
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="YYYY"
                      label="Participated since"
                      className="material-date-picker"
                      views={["year"]}
                      value={formData.year}
                      onChange={(value: any) =>
                        handleFormData({ ...formData, year: value })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <TextField
                    label="Title"
                    variant="filled"
                    className="material-input input"
                    placeholder="Title"
                    value={formData.title}
                    onChange={(e) =>
                      handleFormData({ ...formData, title: e.target.value })
                    }
                  />
                </Col>

                <Col span={isMobile ? 24 : 12}>
                  {isDean ? (
                    <Autocomplete
                      key="school"
                      freeSolo
                      className="school-issuer"
                      loading={schoolOptionsLoading}
                      options={_.map(schoolOptions, (item) => {
                        return item.name;
                      })}
                      value={formData.issuer || ""}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                      onChange={(event: object, value: any, reason: string) => {
                        handleFormData({ ...formData, issuer: value });
                      }}
                      onInputChange={(
                        event: object,
                        value: string,
                        reason: string
                      ) => {
                        searchSchoolByName(value);
                        handleFormData({ ...formData, issuer: value });
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Issuer"
                          variant="filled"
                          className="material-input"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      label="Issuer"
                      variant="filled"
                      className="material-input input"
                      placeholder="Issuer"
                      value={formData.issuer}
                      onChange={(e) =>
                        handleFormData({ ...formData, issuer: e.target.value })
                      }
                    />
                  )}
                </Col>
                <Col span={isMobile ? 24 : 12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disableFuture
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="YYYY"
                      label="Year"
                      className="material-date-picker"
                      views={["year"]}
                      value={formData.year}
                      onChange={(value: any) =>
                        handleFormData({ ...formData, year: value })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Col>
              </>
            )}
          </Row>
          <div className="add-btn">
            <Button
              className="btn-primary"
              disabled={
                !formData.title ||
                !formData.year ||
                (isClub ? false : !formData.issuer)
              }
              onClick={() => {
                if (formIndex === -1) {
                  setListData(listData.concat(formData));
                } else {
                  listData[formIndex] = formData;
                  setListData(listData);
                }
                handleShowForm(false);
                const newIndexList = _.without(editIndexList, formIndex);
                setEditIndexList(newIndexList);
              }}
            >
              {editIndexList.indexOf(formIndex) > -1 ? "Save" : "Add"}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const EducationItem = (props: any) => {
  const {
    eduIndex,
    programList,
    isMobile,
    educationList,
    setListData,
    delEducation,
    user,
    preFill = false,
  } = props;
  const [schoolOptions, setSchoolOptions] = useState([] as any[]);
  const [schoolOptionsLoading, setSchoolOptionsLoading] = useState(false);
  const [showPermit, handleShowPermit] = useState(false);
  const formData = educationList[eduIndex];
  const setItemData = (value: any, name: string) => {
    const newList = [...educationList];
    let newItem = value;
    if (typeof value === "string" && name === "program") {
      if (newList[eduIndex][name] && newList[eduIndex][name].name === value) {
        return;
      }
      newItem = { name: value };
    }
    newList[eduIndex][name] = newItem;
    setListData(newList);
  };

  useEffect(() => {
    if (formData && formData.workPermit > 0 && !showPermit) {
      handleShowPermit(true);
    }
  }, [formData, showPermit]);

  useEffect(() => {
    if (
      user &&
      user.profile &&
      user.profile.program &&
      user.profile.program.name &&
      preFill
    ) {
      setItemData(user.profile.program, "program");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const searchSchoolByName = async (keywords: string) => {
    if (!keywords) {
      setSchoolOptions([]);
      setSchoolOptionsLoading(false);
      return;
    }
    setSchoolOptionsLoading(true);
    const result = await searchSchool(keywords, [2, 20]);
    if (result && !_.isEmpty(result.schools)) {
      setSchoolOptions(result.schools);
      setSchoolOptionsLoading(false);
    }
  };

  return (
    <div className="about-wrap">
      <div className="title-with-icon">
        <img alt="" src={IconEducationInfo} />
        <span>Education Background {eduIndex + 1}</span>
        {educationList.length > 1 ? (
          <IconButton
            className="del-edu"
            onClick={() => {
              delEducation(eduIndex);
            }}
          >
            <img src={IconDelete} alt="" />
          </IconButton>
        ) : null}
      </div>
      <Autocomplete
        key="program"
        freeSolo
        className="tag-input input"
        options={programList}
        value={(formData.program && formData.program.name) || ""}
        getOptionLabel={(option) => {
          return option.name || option;
        }}
        onChange={(event: object, value: any, reason: string) => {
          setItemData(value, "program");
        }}
        onInputChange={(event: object, value: string, reason: string) => {
          setItemData(value, "program");
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select your program"
            variant="filled"
            className="material-input"
          />
        )}
      />
      <Autocomplete
        key="school"
        freeSolo
        className="material-input school-name-input"
        loading={schoolOptionsLoading}
        options={schoolOptions}
        value={(formData.school && formData.school.name) || ""}
        getOptionLabel={(option) => {
          return option.name || option;
        }}
        onChange={(event: object, value: any, reason: string) => {
          let newItem = value;
          if (typeof newItem === "string") {
            newItem = { name: value };
          }

          setItemData(newItem, "school");
        }}
        onInputChange={(event: object, value: string, reason: string) => {
          searchSchoolByName(value);
          if (formData.school?.name !== value) {
            setItemData({ name: value }, "school");
          }
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select your school"
            variant="filled"
            className="material-input"
          />
        )}
      />
      <Row gutter={24}>
        <Col span={isMobile ? 24 : 12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              disableToolbar
              autoOk
              variant="inline"
              openTo="year"
              format="MM/DD/YY"
              label="Graduation date (MM/DD/YY)"
              className="material-date-picker"
              views={["year", "month", "date"]}
              value={formData.graduationDate}
              onChange={(value) => setItemData(value, "graduationDate")}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col span={isMobile ? 24 : 12}>
          <TextField
            key="gpa"
            label="GPA"
            variant="filled"
            className="material-input input"
            placeholder="GPA"
            type="number"
            value={formData.gpa}
            onChange={(e) => {
              const value = e.target.value;
              if (Number(value) < 0) return;
              setItemData(Number(value) > 100 ? 100 : value, "gpa");
            }}
            onKeyDown={(e) =>
              (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) &&
              e.preventDefault()
            }
          />
        </Col>
        <Col span={isMobile ? 24 : 12}>
          <TextField
            key="attendance"
            label="Attendance (%)"
            variant="filled"
            className="material-input input"
            placeholder="Attendance (%)"
            value={formData.attendance}
            type="number"
            onChange={(e) => {
              const value = e.target.value;
              if (Number(value) < 0) return;
              setItemData(Number(value) > 100 ? 100 : value, "attendance");
            }}
            onKeyDown={(e) =>
              (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) &&
              e.preventDefault()
            }
          />
        </Col>
        <Col span={isMobile ? 24 : 12}>
          {showPermit ? (
            <div className="permit-wrap">
              <TextField
                key="workPermit"
                label="Work permit number"
                variant="filled"
                className="material-input input"
                value={formData.workPermit}
                type="number"
                onChange={(e) => setItemData(e.target.value, "workPermit")}
                onKeyDown={(e) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 187 ||
                    e.keyCode === 189) &&
                  e.preventDefault()
                }
              />
              <div className="del-icon">
                <img
                  src={IconDelete}
                  alt=""
                  className="icon-delete"
                  onClick={() => {
                    handleShowPermit(false);
                    setItemData("", "workPermit");
                  }}
                />
              </div>
            </div>
          ) : (
            <YseOrNo
              title="Work permit"
              afterSelect={(value: boolean) => handleShowPermit(value)}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

@mapProp(module_education_info)
@mapProp(modle_user)
export default class EducationInfo extends React.Component<ModuleEducationInfo, {}> {
  state = {
    isMobile: window.innerWidth <= 1024,
    requiredFields: [] as string[],
    editMode: queryString.parse(window.location.search).slug ? true : false,
  };

  componentDidMount() {
    window.onresize = () => {
      this.setState({ isMobile: window.innerWidth < 1024 });
    };
    const { slug } = queryString.parse(window.location.search);
    doAction(InitDataActions, slug);
    if (!this.props.curUser.profile) {
      doAction(LoginWithTokenAction);
    }
  }
  componentWillUnmount() {
    window.onresize = () => {};
    doAction(MODULE_EDUCATION_INFO, module_education_info);
  }

  changeField = (value: any, name: string) => {
    doAction(UpdateBaseInfoAction, {
      [name]: value,
    });
  };

  delEducation = (index: number) => {
    const newEduList = [...this.props.educationList];
    newEduList.splice(index, 1);
    this.changeField(newEduList, "educationList");
  };

  submit = () => {
    //check fields
    const emptyField = [] as any[];
    _.forEach(this.props.educationList, (item) => {
      if (!item.program || !item.program.name) {
        emptyField.push("Program");
      }
      if (!item.school || !item.school.name) {
        emptyField.push("School");
      }
    });
    if (isEmpty(emptyField)) {
      doAction(SubmitAction, this.props.history);
    } else {
      this.setState({
        requiredFields: emptyField,
      });
    }
  };

  render() {
    const { isMobile } = this.state;
    const { programOptions, educationList } = this.props;
    return (
      <Layout>
        <CreateBusinessCanvasHeader
          type={CanvasType.STUDENT}
          step={0}
          jumpToStep={(step: number) => {
            if (step === 1 && this.props.slug) {
              this.props.history.push(`/student-canvas/${this.props.slug}`);
            }
          }}
        />
        <Content>
          <div className="info-container">
            <div className="common-title">About Your Education</div>
            {educationList.map((item: any, index: number) => (
              <EducationItem
                key={index}
                eduIndex={index}
                programList={programOptions}
                isMobile={isMobile}
                educationList={educationList}
                setListData={(value: any) => {
                  this.changeField(value, "educationList");
                }}
                delEducation={this.delEducation}
                user={this.props.curUser}
                preFill={!this.state.editMode}
              />
            ))}
            <Button
              className="btn-secondary"
              onClick={() => {
                const newList = this.props.educationList.concat(
                  cloneDeep(educationItem)
                );
                this.changeField(newList, "educationList");
              }}
            >
              <img src={IconAwardAdd} alt="" />
              Add more education
            </Button>
            {!this.state.editMode &&
              awardRenderList.map((item: any, index: number) => (
                <AwardsList
                  key={"AwardsList" + index}
                  isMobile={isMobile}
                  {...item}
                  listData={this.props[item.name]}
                  setListData={(value: any) => {
                    this.changeField(value, item.name);
                  }}
                />
              ))}
            <Button className="btn-primary" onClick={this.submit}>
              Next to Student Canvas
            </Button>
          </div>
          <FieldRequireModal
            onClose={() => this.setState({ requiredFields: [] })}
            visible={!isEmpty(this.state.requiredFields)}
            data={this.state.requiredFields}
          />
        </Content>
      </Layout>
    );
  }
}
