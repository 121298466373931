import { ModuleStore, ModuleAction, KV } from "module-reaction";
import _ from "lodash";
import { getCanvas } from "../api/Canvas";
import { IMediaItem } from "../components/component_media_item";
import { MediaType, CanvasType } from "../utils/enum";
import { v4 as uuidv4 } from "uuid";
import { IAward } from "../components/component_award_item";
import { ISocialMedia } from "../components/component_social_media_item";
import { IEmployment } from "../components/component_employment_item";
export const MODULE_CANVAS_DETAIL = "module_canvas_detail";

const tempSocial = [
  {
    type: 0,
    label: "instagram",
  },
  {
    type: 1,
    label: "youtube",
  },
  {
    type: 1,
    label: "facebook",
  },
  {
    type: 0,
    label: "tiktok",
  },
];

export interface ModuleCanvasDetail extends ModuleStore {
  id: number;
  type: string;
  slug: string;
  owner: number;
  //business & shcool
  bookmarkTag: boolean;
  awardList: IAward[];
  socialMediaList: ISocialMedia[];
  companyName: string;
  headline: string;
  desc: string;
  whyJoin: string;
  defaultLocation: any;
  contactNumber: string;
  category: any[];
  additional: any;
  extraLocations: any[];
  logo: IMediaItem; // type, link
  featuredPhoto: IMediaItem;
  teamPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  extraVideo: IMediaItem[];
  emptyFields: string[];

  //student & pro
  title: string;
  name: string;
  experience: number;
  location: any;
  willingToRelocate: boolean;
  opportunityStatus: boolean;
  showOpportunityStatus: boolean;
  relocateList: any[];
  aboutMe: string;
  why: string;
  portraitPhoto: IMediaItem;
  portfolioPhotos: IMediaItem[];
  preferredBrands: any[];
  specialities: any[];
  softwares: any[];
  kpi: any;
  employment: IEmployment[];
  payment: any;
  educations: any[];
  categories: any[];
  bookingUrl: string;
  pronouns: string;
}

export const module_canvas_detail: ModuleCanvasDetail = {
  module: MODULE_CANVAS_DETAIL,
  id: 0,
  slug: "",
  owner: 0,
  type: CanvasType.BUSINESS,
  bookmarkTag: false,
  awardList: [],
  socialMediaList: tempSocial,
  companyName: "",
  headline: "",
  desc: "",
  whyJoin: "",
  defaultLocation: {},
  contactNumber: "",
  category: [],
  additional: {
    retailBrands: [],
    softwares: [],
    backbarBrands: [],
    specialties: [],
    companyBenefits: [],
    paymentMode: {},
  },
  extraLocations: [],
  logo: {
    image: "",
    title: "Logo",
    dimensions: "100x60",
    fileSize: 1,
    url: "",
    type: MediaType.LOGO,
  },
  featuredPhoto: {
    image: "",
    title: "Featured Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.FEATURED_PHOTO,
  },
  teamPhoto: {
    image: "",
    title: "Team Photo",
    dimensions: "1240x800",
    fileSize: 5,
    url: "",
    type: MediaType.TEAM,
  },
  featuredVideo: {
    image: "",
    title: "Featured Video",
    dimensions: "1240x800",
    fileSize: 10,
    url: "",
    type: MediaType.FEATURED_VIDEO,
  },
  extraVideo: [] as IMediaItem[],
  emptyFields: [] as string[],
  //pagedata
  title: "",
  name: "",
  experience: -1,
  location: {
    uuid: uuidv4(),
  },
  willingToRelocate: false,
  opportunityStatus: false,
  showOpportunityStatus: false,
  relocateList: [],
  aboutMe: "",
  why: "",
  portraitPhoto: {
    image: "",
    title: "Bio Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.PORTRAIT_PHOTO,
  },

  portfolioPhotos: [],
  preferredBrands: [],
  specialities: [],
  softwares: [],
  kpi: {},
  employment: [],
  payment: {},
  educations: [] as any[],
  categories: [] as any[],
  bookingUrl: null,
  pronouns: null
};

export const GetPageDataAction: ModuleAction<KV, ModuleCanvasDetail> = {
  module: MODULE_CANVAS_DETAIL,
  process: async (params: KV, module: ModuleCanvasDetail) => {
    const { slug, history } = params;
    if (!slug) {
      return {};
    }
    const res = await getCanvas(slug);
    if (res) {
      if (res.type === CanvasType.BUSINESS || res.type === CanvasType.SCHOOL) {
        const mappedLocations = _.map(res.detail.locations, (item) => {
          return {
            uuid: item.uuid,
            location: item.address,
            area: item.area,
            areaUnit: item.areaUnit,
            chairs: item.chairAmount,
            city: item.city,
            coordinates: item.coordinates,
            country: item.country,
            countryShort: item.countryShort,
            employeeNumber: item.employeeAmount,
            state: item.state,
            stateShort: item.stateShort,
            members: _.map(item.members, (item) => {
              const newItem = { ...item };
              delete newItem.status;
              return newItem;
            }),
            isAccredited: item.isAccredited,
            nightStudents: item.nightStudents,
            supportedProgram: _.map(item.supportedProgram, (item) => {
              return {
                id: item.optionId,
                name: item.name,
              };
            }),
            classType: item.classType,
            dayStudents: item.dayStudents,
          };
        });

        const defaultLoc = _.first(mappedLocations) || {};
        const extraLoc =
          _.slice(mappedLocations, 1, mappedLocations.length) || [];

        let social = [...tempSocial];
        social = _.map(social, (socialItem) => {
          if (_.get(res.socialMedia, socialItem.label)) {
            return {
              ...socialItem,
              value: _.get(res.socialMedia, socialItem.label),
            };
          } else {
            return socialItem;
          }
        });
        let pageData = {
          id: res.id,
          slug: res.slug,
          owner: res.owner || 0,
          type: res.type,
          bookmarkTag: res.bookmarkTag,
          awardList: res.achievements,
          additional: {
            backbarBrands: _.map(
              res.additionalInformation.backbarBrands,
              (item) => {
                return { id: item.optionId, name: item.name };
              }
            ),
            companyBenefits: _.map(
              res.additionalInformation.companyBenefits,
              (item) => {
                return { id: item.optionId, name: item.name };
              }
            ),
            paymentMode: res.additionalInformation.paymentMode,
            retailBrands: _.map(
              res.additionalInformation.retailBrands,
              (item) => {
                return { id: item.optionId, name: item.name };
              }
            ),
            softwares: _.map(res.additionalInformation.softwares, (item) => {
              return { id: item.optionId, name: item.name };
            }),
            specialties: _.map(
              res.additionalInformation.specialities,
              (item) => {
                return { id: item.optionId, name: item.name };
              }
            ),
          },
          contactNumber: res.detail.primaryContactNumber || "",
          category: res.detail.categories,
          defaultLocation: defaultLoc,
          extraLocations: extraLoc,
          companyName: res.introduction.name,
          headline: res.introduction.headline,
          desc: res.introduction.description,
          whyJoin: res.introduction.reason,
          logo: (res.media && res.media.logo) || {
            image: "",
            title: "Company Logo",
            dimensions: "100x60",
            fileSize: 1,
            url: "",
            type: MediaType.LOGO,
          },
          featuredPhoto: (res.media && res.media.featuredPhoto) || {
            image: "",
            title: "Featured Photo",
            dimensions: "1440x758",
            fileSize: 5,
            url: "",
            type: MediaType.FEATURED_PHOTO,
          },
          teamPhoto: (res.media && res.media.teamPhoto) || {
            image: "",
            title: "Team Photo",
            dimensions: "1240x800",
            fileSize: 5,
            url: "",
            type: MediaType.TEAM,
          },
          featuredVideo: (res.media && res.media.featuredVideo) || {
            image: "",
            title: "Featured Video",
            dimensions: "1240x800",
            fileSize: 10,
            url: "",
            type: MediaType.FEATURED_VIDEO,
          },
          extraVideo: (res.media && res.media.additionalVideo) || [],
          socialMediaList: social,
        };
        return pageData;
      } else {
        let social = [...tempSocial];
        social = _.map(social, (socialItem) => {
          if (_.get(res.socialMedia, socialItem.label)) {
            return {
              ...socialItem,
              value: _.get(res.socialMedia, socialItem.label),
            };
          } else {
            return socialItem;
          }
        });
        const loca = res.detail.locations[0];
        let cates = [];
        if (res.type === CanvasType.STUDENT) {
          cates = _.map(res.detail.categories, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          });
        } else {
          cates = _.map(res.detail.categories, (item) => {
            let newItem = {
              id: item.optionId,
              name: item.name,
              licenseNumber: item.licenseNumber,
              stateId: (item.state && item.state.id) || 0,
              stateName: (item.state && item.state.name) || "",
              otherName: "",
            } as any;
            return newItem;
          });
        }

        const pageData = {
          id: res.id,
          slug: res.slug,
          owner: res.owner || 0,
          type: res.type,
          title: res.introduction.title,
          bookmarkTag: res.bookmarkTag,
          name: res.introduction.name || "",
          experience: res.introduction.yearOfExperience || -1,
          location: loca
            ? {
                uuid: loca.uuid,
                location: loca.address,
                city: loca.city,
                coordinates: loca.coordinates,
                country: loca.country,
                countryShort: loca.countryShort,
                state: loca.state,
                stateShort: loca.stateShort,
              }
            : {
                uuid: uuidv4(),
              },
          willingToRelocate: res.introduction.isRelocated || false,
          bookingUrl: res.bookingUrl,
          pronouns: res.pronouns,
          opportunityStatus: res.introduction.opportunityStatus || false,
          showOpportunityStatus: res.introduction.showOpportunityStatus || false,
          relocateList: res.introduction.relocateTo || [],
          aboutMe: res.introduction.description || "",
          why: res.introduction.reason || "",
          logo: (res.media && res.media.logo) || {
            image: "",
            title: "Logo",
            dimensions: "100x60",
            fileSize: 1,
            url: "",
            type: MediaType.LOGO,
          },
          featuredPhoto: (res.media && res.media.featuredPhoto) || {
            image: "",
            title: "Featured Photo",
            dimensions: "1440x758",
            fileSize: 5,
            url: "",
            type: MediaType.FEATURED_PHOTO,
          },
          portraitPhoto: (res.media && res.media.portraitPhoto) || {
            image: "",
            title: "Bio Photo",
            dimensions: "1440x758",
            fileSize: 5,
            url: "",
            type: MediaType.PORTRAIT_PHOTO,
          },
          featuredVideo: (res.media && res.media.featuredVideo) || {
            image: "",
            title: "Featured Video",
            dimensions: "1240x800",
            fileSize: 10,
            url: "",
            type: MediaType.FEATURED_VIDEO,
          },
          extraVideo: (res.media && res.media.additionalVideo) || [],
          portfolioPhotos: (res.media && res.media.portfolioPhoto) || [],
          awardList: res.achievements || [],
          preferredBrands:
            _.map(res.additionalInformation.preferredBrands, (item) => {
              return {
                id: item.optionId,
                name: item.name,
              };
            }) || [],
          specialities:
            _.map(res.additionalInformation.specialities, (item) => {
              return {
                id: item.optionId,
                name: item.name,
              };
            }) || [],
          softwares:
            _.map(res.additionalInformation.softwares, (item) => {
              return {
                id: item.optionId,
                name: item.name,
              };
            }) || [],
          kpi: res.additionalInformation.KPI || {},
          employment:
            _.map(res.employments, (item) => {
              let position = [] as any[];
              if (item.position) {
                position = _.map(item.position.split(","), (item) => {
                  return {
                    name: item,
                  };
                });
              }
              let jobCategory = [] as any[];
              if (item.jobCategory) {
                jobCategory = _.map(item.jobCategory.split(","), (item) => {
                  return {
                    name: item,
                  };
                });
              }
              return {
                uuid: item.uuid,
                title: item.title || "",
                position: position,
                jobCategory: jobCategory,
                isCurrentWork: item.isCurrentWork,
                company: item.company,
                startDate: item.startDate,
                endDate: item.endDate,
                description: item.description || "",
              };
            }) || [],
          payment: res.additionalInformation.paymentMode || {},
          socialMediaList: social,
          educations: res.educations || [],
          categories: cates,
        } as any;
        return pageData;
      }
    } else {
      history && history.replace("/");
    }
    return {};
  },
};
