import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import classnames from "classnames";
import useDebounce from "../hooks/useDebounce";
import "./component_address_text_field.less";

interface Props {
  className?: string;
  label: string;
  value?: string;
  placeholder?: string;
  onInput: Function;
  onAddressSelect: Function;
  autoCompleteService?: any;
  onPressEnter?: Function;
}
const keypressCfg: any = {
  ele: undefined,
  cb: undefined,
};

const AddressTextField: React.FC<Props> = (props) => {
  const {
    className = "",
    label = "",
    onInput,
    placeholder = "",
    value,
    autoCompleteService,
    onAddressSelect,
    onPressEnter,
  } = props;

  const [keywords, setKeywords] = useState<string>();
  const [addressOpts, setAddressOpts] = useState<any[]>([]);

  const [val, setVal] = useState<any>('');

  useDebounce(
    () => {
      if (!keywords || !autoCompleteService) {
        setAddressOpts([]);
      } else {
        autoCompleteService.getPlacePredictions(
          { input: keywords },
          (results: any, status: any) => {
            if (results && !_.isEmpty(results)) {
              setAddressOpts(results);
            }
          }
        );
      }
    },
    500,
    [keywords, autoCompleteService]
  );

  useEffect(()=> {
    setVal(value)
  }, [value])

  return (
    <div className={classnames(className, "address-text-field")}>
      <TextField
        label={label}
        variant="filled"
        className="material-input text-field"
        // value={value || keywords || ""}
        value={ val || value || keywords || ''}
        placeholder={placeholder}
        onBlur={() => {
          keypressCfg.ele = null;
          keypressCfg.cb = null;
          document.removeEventListener("keypress", keypressHandler);
          setAddressOpts([]);
        }}
        onChange={(e) => {
          setVal(e.target.value);
          onInput(e.target.value);
          setKeywords(e.target.value);
        }}
        onFocus={(e) => onPressEnter && addKeyboardListener(e, onPressEnter)}
      />
      {!_.isEmpty(addressOpts) && (
        <div className="dropdown-address">
          {_.map(addressOpts, (address, index) => {
            return (
              <span
                key={index}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  onAddressSelect(address);
                  setAddressOpts([]);
                }}
              >
                {address.description || ""}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

function addKeyboardListener(
  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  cb: Function
) {
  keypressCfg.ele = e.target;
  keypressCfg.cb = cb;
  document.addEventListener("keypress", keypressHandler);
}

function keypressHandler(evt: KeyboardEvent) {
  if (evt.keyCode === 13 && keypressCfg.ele && keypressCfg.cb) {
    keypressCfg.cb();
  }
}
export default AddressTextField;
