import React, { useState, useEffect } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { mapProp, doAction, doFunction } from "module-reaction";
import { Button, Checkbox, Modal } from "antd";
import { TextField } from "@material-ui/core";
import ToolTip from '@material-ui/core/Tooltip';
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import _ from "lodash";
import queryString from "query-string";
import CRTextArea from "../../../components/component_cr_textarea";
import CRHeader from "../../../components/component_cr_header";

import { IMediaItem } from "../../../components/component_media_item";
import { EditorComponent } from "../../../components/component_editor";
import MediaItem from "../../../components/component_media_item";
import LoadingModal from "../../../components/component_loading_modal";
import EditPhotoModal from "../../../components/component_edit_photo_modal";
import FieldRequireModal from "../../../components/component_field_require_modal";
import {
  module_post_job_opp,
  UpdateBaseInfoAction,
  UpdateOppInfoAction,
  AdditionalInfoAction,
  UploadFileAction,
  UpdateMediaInfoAction,
  DeleteMediaAction,
  getOptionsActions,
  postOpportunityAction,
  getCanvasDetailAction,
  getOppDtlsAction,
  ModulePostJobOpp,
  general_location_address,
} from "../../../models/model_post_job_opp";
import { User, LogOutAction } from "../../../models/model_user";
import { getUserInfo } from "../../../api/Auth";
import IconCompany from "../../../assets/images/ic_company.svg";
import IconOppInfo from "../../../assets/images/ic_opp_info.svg";
import IconAlert from "../../../assets/images/ic_opp_alert.svg";
import IconFeatureEmpty from "../../../assets/images/ic_feature_photo_empty.svg";
import classnames from "classnames";
import "./post_job_opp.less";
import { Layout } from "antd";
import { getToken } from "../../../utils/authStorage";
import {
  MediaType,
  router_my_dashboard,
  router_opportunity_detail,
  router_post_job_opp_simple,
} from "../../../utils/enum";
import { checkOverlay, isAdminEditing } from "../../../utils/utils";
import { getMediaInfo } from "../../../utils/mediaHelper";
import GetStartedOverlay from "../../onboarding/get_start_overlay";
const { Content } = Layout;

interface PostJobOppProps extends ModulePostJobOpp, RouteComponentProps {
  // canvasDetails: any;
  // website: string;
  // tagLine: string;
  // opportunityInfo: any;
  // additionalInfo: any;
  // mediaInfo: any;
  // opportunityId: number;
  // emptyFields: string[];
}

interface OpportunityInfoProps {
  opportunityInfo: any;
  curUser: any;
  onUpdata: Function;
}

const general_location = {
  address: general_location_address,
  uuid: null
}
const OpportunityInfo: React.FC<OpportunityInfoProps> = (props) => {
  const { opportunityInfo, curUser, onUpdata } = props;
  const [jobTitle, setJobTitle] = useState("");
  const [jobOtherName, setJobOtherName] = useState("");
  const [locationList, setLocationList] = useState<any[]>([])

  const getLocationList = () => {
    if (isAdminEditing(curUser)) {
      return [...opportunityInfo.locationList, general_location]
    }
    if (opportunityInfo.validForAllState) {
      return [general_location]
    }
    return opportunityInfo.locationList
  }

  useEffect(() => {
    const options = opportunityInfo.jobTitleList;
    const jobName = opportunityInfo.jobTitle.name;
    if (!jobName) {
      setJobOtherName("");
      return;
    }
    const item = _.find(options, { name: jobName });
    if (item) {
      setJobTitle(jobName);
      setJobOtherName("");
    } else {
      setJobTitle("Other");
      setJobOtherName(jobName);
    }
    if (curUser.id) {
      setLocationList(getLocationList())
    }
  }, [opportunityInfo, curUser]);

  useEffect(() => {
    setLocationList(getLocationList())
  }, [opportunityInfo.locationList]);

  return (
    <div className="opportunity-info-contianer">
      <div className="info-divider" />
      <span className="info-title">
        <img alt="" src={IconOppInfo} />
        Opportunity Information
      </span>
      <div className="job-title-sec">
        {isAdminEditing(curUser) && (
          <>
            <TextField
              select
              key="competition"
              label="Competition"
              variant="filled"
              value={opportunityInfo.competition || 0}
              onChange={(e) => {
                onUpdata({ competition: e.target.value });
              }}
              className="material-input input job-select"
            >
              <MenuItem key={0} value={0}>
                False
              </MenuItem>
              <MenuItem key={1} value={1}>
                True
              </MenuItem>
            </TextField>
          </>
        )}
        <TextField
          select
          key="title"
          label="Job title"
          variant="filled"
          value={jobTitle || ""}
          onChange={(e) => {
            const item = _.find(opportunityInfo.jobTitleList, {
              name: e.target.value,
            });
            if (item) {
              onUpdata({ jobTitle: item });
            }
          }}
          className="material-input input job-select"
        >
          {_.map(opportunityInfo.jobTitleList, (item, index) => {
            return (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            );
          })}
        </TextField>
        {jobTitle === "Other" && (
          <CRTextArea
            className="job-input"
            key="your-title"
            label="Your job title"
            type="text"
            value={jobOtherName || ""}
            onInput={(e: string) => {
              onUpdata({ jobTitle: { name: e } });
            }}
          />
        )}
      </div>

      <TextField
        select
        key="category"
        label="Job category"
        variant="filled"
        value={opportunityInfo.jobCategory.name || ""}
        onChange={(e) => {
          const item = _.find(opportunityInfo.jobCatagoryList, {
            name: e.target.value,
          });
          if (item) {
            onUpdata({ jobCategory: item });
          }
        }}
        className="material-input input"
      >
        {_.map(opportunityInfo.jobCatagoryList, (item, index) => {
          return (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          );
        })}
      </TextField>

      <TextField
        select
        key="type"
        label="Job type"
        variant="filled"
        value={opportunityInfo.jobType.name || ""}
        onChange={(e) => {
          const item = _.find(opportunityInfo.jobTypeList, {
            name: e.target.value,
          });
          if (item) {
            onUpdata({ jobType: item });
          }
        }}
        className="material-input input"
      >
        {_.map(opportunityInfo.jobTypeList, (item, index) => {
          return (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          );
        })}
      </TextField>

      {/* TODO: add new option here https://trello.com/c/LsbHA7tu
      <TextField
        select
        key="category"
        label="Job category"
        variant="filled"
        value={opportunityInfo.jobCategory.name || ""}
        onChange={(e) => {
          const item = _.find(opportunityInfo.jobCatagoryList, {
            name: e.target.value,
          });
          if (item) {
            onUpdata({ jobCategory: item });
          }
        }}
        className="material-input input"
      >
        {_.map(opportunityInfo.jobCatagoryList, (item, index) => {
          return (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          );
        })}
      </TextField> */}

      <TextField
        select
        key="location"
        label="Select location"
        variant="filled"
        value={opportunityInfo.validForAllState ? general_location_address : opportunityInfo.selectLocation.address || ""}
        disabled={opportunityInfo.validForAllState && !isAdminEditing(curUser)}
        onChange={(e) => {
          const item = _.find(locationList, {
            address: e.target.value,
          });
          if (item) {
            if (item.address === general_location_address) {
              onUpdata({ validForAllState: true })
            } else {
              onUpdata({ selectLocation: item, validForAllState: false });
            }
          }
        }}
        className="material-input input"
      >
        {_.map(locationList, (item, index) => {
          return (
            <MenuItem key={index} value={item.address}>
              {item.address}
            </MenuItem>
          );
        })}
      </TextField>

      <CRTextArea
        type="text"
        key="email"
        label="Email to receive application"
        value={opportunityInfo.email || ""}
        onInput={(e: number) => onUpdata({ email: e })}
      />
      {/* <TextField
        select
        label="Does this opportunity expire?"
        variant="filled"
        value={ opportunityInfo.expirationBool || 0 }
        onChange={(e: any) =>
          onUpdata({ expirationBool: e.target.value })
        }
        className="material-input input"
      >
        <MenuItem value={0}>No</MenuItem>
        <MenuItem value={1}>Yes</MenuItem>
      </TextField>

      You can expire your opportunity if it is an event based opportunity.
      Expiring your opportunity means your opportunity will automatically switch to inactive on the expiration date.
      Your account will remain active but this opportunity will disappear from opportunity search!

      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          autoOk
          label="Expiration date"
          variant="dialog"
          format="MM/DD/YYYY"
          className="material-date-picker input"
          style={{display: (opportunityInfo.expirationBool) ? 'inline-flex' : 'none'}}
          value={opportunityInfo.expirationDate || ''}
          onChange={(e: any) => onUpdata({ expirationDate: e })}
          onBlur={(e: any) => onUpdata({ expirationDate: e.target.value })}
        />
      </MuiPickersUtilsProvider> */}

      <div className="editor-wrap input">
        <div className="cm-standalone-label">
          Opportunity Description
        </div>
        {opportunityInfo.isShowEditor && (
          <EditorComponent
            content={opportunityInfo.editor}
            getEditorConent={(e: any) => onUpdata({ editor: e })}
            placeholder="Opportunity Description"
          ></EditorComponent>
        )}
      </div>
      <CRTextArea
        type="text"
        key="apply-url"
        label="External opportunity post URL"
        value={opportunityInfo.applyUrl || ""}
        onInput={(e: string) => onUpdata({ applyUrl: e })}
      />
    </div>
  );
};

interface AdditionalInfoProps {
  additionalInfo: any;
  onUpdata: Function;
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = (props) => {
  const { additionalInfo, onUpdata } = props;
  return (
    <div className="additional-info-container">
      <div className="info-divider" />
      <span className="info-title">
        <img alt="" src={IconAlert} />
        Additional information
      </span>

      <Autocomplete
        multiple
        className="tag-input auto-complete"
        options={additionalInfo.brandList}
        freeSolo
        value={additionalInfo.retailBrands}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
        onChange={(event: object, value: any, reason: string) => {
          console.log("brand change", value);
          const newItems = _.map(value, (item) => {
            if (typeof item === "string") {
              if (value.find((_: any) => _.name === item)) {
                return undefined;
              }
              return additionalInfo.brandList.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
            }
            return item;
          }).filter(Boolean);
          onUpdata({ retailBrands: _.uniqBy(newItems, 'name') });
        }}
        getOptionSelected={(option: any, value: any) => {
          return option.name === value.name;
        }}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Brands you use"
            variant="filled"
            className="material-input input"
            placeholder="Type in retail brand"
          />
        )}
      />

      <Autocomplete
        multiple
        className="tag-input auto-complete"
        options={additionalInfo.softwareList}
        freeSolo
        value={additionalInfo.softwares}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
        onChange={(event: object, value: any, reason: string) => {
          const newItems = _.map(value, (item) => {
            if (typeof item === "string") {
              if (value.find((_: any) => _.name === item)) {
                return undefined;
              }
              return additionalInfo.softwareList.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
            }
            return item;
          }).filter(Boolean);
          onUpdata({ softwares: _.uniqBy(newItems, 'name') });
        }}
        getOptionSelected={(option: any, value: any) => {
          return option.name === value.name;
        }}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Software you use"
            variant="filled"
            className="material-input input"
            placeholder="Software you use"
          />
        )}
      />

      <TextField
        select
        key="paymeny"
        label="Payment structure"
        variant="filled"
        value={additionalInfo.paymentStructure.name || ""}
        onChange={(e) => {
          const item = _.find(additionalInfo.paymentList, {
            name: e.target.value,
          });
          if (item) {
            onUpdata({ paymentStructure: item });
          }
        }}
        className="material-input input"
      >
        {_.map(additionalInfo.paymentList, (item, index) => {
          return (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
};

interface MediaInfoProps {
  mediaInfo: any;
  getMediaUploading: Function;
  showLoading: Function;
  canvasName?: string;
}
const Media: React.FC<MediaInfoProps> = (props) => {
  const { mediaInfo, getMediaUploading, showLoading, canvasName = "" } = props;
  const [showEditPhoto, setShowEditPhoto] = useState(false);
  const [showEditReposition, setShowEditReposition] = useState(false);
  const [curEditMedia, setCurEditMedia] = useState({} as IMediaItem);
  const [curLoadingMedia, setCurLoadingMedia] = useState(0);
  const setMediaLoading = (type: number) => {
    getMediaUploading && getMediaUploading(type > 0);
    setCurLoadingMedia(type);
  };
  return (
    <div className="media-info-container">
      <div className="info-divider" />
      <span className="info-title">
        <img alt="" src={IconFeatureEmpty} />
        Media
      </span>
      <div className="media-wrap">
        <MediaItem
          data={mediaInfo.thumbnailPhoto}
          isShowUploadBtn={true}
          className="item-media"
          onUpload={(mediaInfo: any) => {
            setMediaLoading(1);
            showLoading(true);
            doAction(UploadFileAction, {
              mediaInfo: mediaInfo,
              type: MediaType.THUMBNAIL_PHOTO,
            });
            doFunction(async () => {
              setMediaLoading(0);
              showLoading(false);
            });
          }}
          showLoading={curLoadingMedia === 1}
          onDelete={() =>
            doAction(DeleteMediaAction, { type: MediaType.THUMBNAIL_PHOTO })
          }
          onEdit={(media: IMediaItem) => {
            setShowEditPhoto(true);
            setCurEditMedia(media);
            setShowEditReposition(false)
          }}
        />
        <MediaItem
          data={mediaInfo.featuredPhoto}
          isShowUploadBtn={true}
          className="item-media"
          onUpload={(mediaInfo: any) => {
            setMediaLoading(2);
            showLoading(true);
            doAction(UploadFileAction, {
              mediaInfo: mediaInfo,
              type: MediaType.FEATURED_PHOTO,
            });
            doFunction(async () => {
              setMediaLoading(0);
              showLoading(false);
            });
          }}
          showLoading={curLoadingMedia === 2}
          onDelete={() =>
            doAction(DeleteMediaAction, { type: MediaType.FEATURED_PHOTO })
          }
          onEdit={(media: IMediaItem) => {
            setShowEditPhoto(true);
            setCurEditMedia(media);
            setShowEditReposition(true)

          }}
        />
        <MediaItem
          data={mediaInfo.companyPhoto}
          isShowUploadBtn={true}
          className="item-media"
          onUpload={(mediaInfo: any) => {
            setMediaLoading(3);
            showLoading(true);
            doAction(UploadFileAction, {
              mediaInfo: mediaInfo,
              type: MediaType.COMPANY_PHOTO,
            });
            doFunction(async () => {
              setMediaLoading(0);
              showLoading(false);
            });
          }}
          showLoading={curLoadingMedia === 3}
          onDelete={() =>
            doAction(DeleteMediaAction, { type: MediaType.COMPANY_PHOTO })
          }
          onEdit={(media: IMediaItem) => {
            setShowEditPhoto(true);
            setCurEditMedia(media);
            setShowEditReposition(false)

          }}
        />
        <MediaItem
          data={mediaInfo.featuredVideo}
          isShowUploadBtn={true}
          className="item-media"
          onUpload={(file: any) => {
            setMediaLoading(4);
            showLoading(true, true);
            getMediaInfo(file).then(mediaInfo => {
              doAction(UploadFileAction, {
                mediaInfo: mediaInfo,
                type: MediaType.FEATURED_VIDEO,
              });
              doFunction(async () => {
                setMediaLoading(0);
                showLoading(false, false);
              });
            })
          }}
          showLoading={curLoadingMedia === 4}
          onUrlInput={(value: string) => {
            const query = value.split("?");
            let queryStr = "";
            if (query.length > 0) {
              queryStr = query[1];
            }
            const { v } = queryString.parse(queryStr);
            let image = "";
            if (v) {
              image = `https://img.youtube.com/vi/${v}/0.jpg`;
            }
            doAction(UpdateMediaInfoAction, {
              featuredVideo: {
                ...mediaInfo.featuredVideo,
                url: value,
                image: image,
              },
            });
          }}
          onDelete={() =>
            doAction(DeleteMediaAction, {
              type: MediaType.FEATURED_VIDEO,
            })
          }
          onEdit={(media: IMediaItem) => {
            setShowEditPhoto(true);
            setCurEditMedia(media);
            setShowEditReposition(false)
          }}
          char={!_.isEmpty(canvasName) ? canvasName[0] : ""}
        />

        {showEditPhoto && (
          <EditPhotoModal
            visible
            showReposition={showEditReposition}
            data={curEditMedia}
            onClose={() => {
              setShowEditPhoto(false);
            }}
            onApply={(mediaInfo: any) => {
              setShowEditPhoto(false);
              doAction(UploadFileAction, {
                mediaInfo,
                type: curEditMedia.type,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

@mapProp(module_post_job_opp)
export class PostJobOpportunitySimple extends React.Component<PostJobOppProps, {}> {
  state = {
    // autoSaveId: 0,
    showSaved: false,
    isInMyAccount: false,
    user: {} as User,
    postBtnDisabled: false,
    showLoading: false,
    isVideoLoading: false,
    getStartedOverlay: false,
    simpleForm: false,
  };

  componentDidMount() {
    const {
      isInMyAccount = "0",
      id,
      opportunityId,
      is_edit = "0",
    } = queryString.parse(this.props.history.location.search);
    if (!Number(is_edit)) {
      if (opportunityId) {
        doAction(getOppDtlsAction, Number(opportunityId));
      } else {
        doAction(UpdateOppInfoAction, {
          ...this.props.opportunityInfo,
          isShowEditor: true,
        });
      }
    }
    this.getUserInfo();
    doAction(getCanvasDetailAction, id);
    this.updateState({
      isInMyAccount: isInMyAccount === "1",
    });

    doAction(getOptionsActions);

    if (checkOverlay()) {
      this.setState({ getStartedOverlay: true });
    }

    const urlPath = window.location.pathname;
    console.log(urlPath);
    if (urlPath === router_post_job_opp_simple) {
      this.setState({ simpleForm: true });
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams)
      const code = urlParams.get('code');
    }
  }

  componentWillUnmount() { }

  private getUserInfo = async () => {
    if (getToken()) {
      const res = await getUserInfo();
      if (res && res.user) {
        this.setState({ ...this.state, user: res.user });
      }
    }
  };

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  private breadcrumbsList = [
    {
      path: router_my_dashboard + "?tabType=posted_opportunity",
      name: "Posted opportunity",
    },
    {
      name: "Post New Opportunity",
    },
  ];

  private previewDetails = () => {
    const { history, opportunityId } = this.props;
    const isInMyAccount = this.state.isInMyAccount;
    history.push({
      pathname: router_opportunity_detail.replace(":slug", opportunityId + ""),
      search: `?is_preview=1${isInMyAccount ? "&from_dashboard=1" : ""}`,
    });
  };

  private jumpToLoginPage = () => {
    this.props.history.push({
      pathname: "/sign-in",
      search: `?from=${window.location.pathname}`,
    });
  };

  private postJob = (isPublish: boolean = true) => {
    if (this.state.postBtnDisabled) {
      return;
    }
    this.setState({ postBtnDisabled: true }, () => {
      doAction(postOpportunityAction, {
        history: this.props.history,
        isPublish,
      });
      doFunction(async () => {
        this.setState({ postBtnDisabled: false });
      });
    });
  };

  private setPostBtnDisabled = (isUploading: boolean) => {
    this.setState({ postBtnDisabled: isUploading });
  };

  render() {
    const { canvasDetails: canvas, opportunityInfo, about } = this.props;
    const { getStartedOverlay } = this.state;
    return (
      <div
        className={classnames("post-job-opp-container", {
          isInMyAccount: this.state.isInMyAccount,
        })}
      >
        <Content>
          <div
            className={classnames("container", {
              hasHeader: !this.state.isInMyAccount,
            })}
          >
            <div className="center-wrap">
              {this.state.isInMyAccount && (
                <div className="breadcrumbs-wrap">
                  {this.breadcrumbsList.map((breadcrumb: any, id: number) => (
                    <div className="breadcrumb-item" key={id}>
                      {breadcrumb.path ? (
                        <Link to={breadcrumb.path}>
                          <span className="breadcrumb-name">
                            {breadcrumb.name}
                          </span>
                        </Link>
                      ) : (
                        <span className="breadcrumb-name">
                          {breadcrumb.name}
                        </span>
                      )}
                      <span className="break-icon">/</span>
                    </div>
                  ))}
                </div>
              )}
              <span className="title">Post Job Opportunity</span>
              <span className="subtitle">
                <img alt="" src={IconCompany} />
                Company Information
              </span>
              {canvas && (
                <>
                  <TextField
                    key="name"
                    label="Company Name"
                    variant="filled"
                    className="material-input input"
                    value={canvas.introduction.name}
                  />
                  <CRTextArea
                    key="website"
                    label="Website"
                    type="text"
                    value={this.props.website}
                    onInput={(e: string) => {
                      doAction(UpdateBaseInfoAction, {
                        website: e,
                      });
                    }}
                  />
                  <CRTextArea
                    key="tagline"
                    label="Mission/ Vision Statement"
                    type="text"
                    value={this.props.tagLine}
                    onInput={(e: string) => {
                      doAction(UpdateBaseInfoAction, {
                        tagLine: e,
                      });
                    }}
                  />

                  <div className="editor-wrap input">
                    <div className="cm-standalone-label">
                      About Us
                    </div>
                    {(
                      <EditorComponent
                        key="aboutUs"
                        content={about || canvas.introduction.description || ""}
                        getEditorConent={(e: any) => {
                          doAction(UpdateBaseInfoAction, {
                            about: e,
                          });
                        }}
                        placeholder="About Us"
                      ></EditorComponent>
                    )}
                  </div>
                </>
              )}
              <OpportunityInfo
                opportunityInfo={opportunityInfo}
                curUser={this.state.user}
                onUpdata={(options: any) =>
                  doAction(UpdateOppInfoAction, {
                    ...opportunityInfo,
                    ...options,
                  })
                }
              ></OpportunityInfo>

              <AdditionalInfo
                additionalInfo={this.props.additionalInfo}
                onUpdata={(options: any) =>
                  doAction(AdditionalInfoAction, {
                    ...this.props.additionalInfo,
                    ...options,
                  })
                }
              ></AdditionalInfo>
              <Media
                mediaInfo={this.props.mediaInfo}
                getMediaUploading={(isUploading: boolean) => {
                  this.setPostBtnDisabled(isUploading);
                }}
                showLoading={(show: boolean, isVideo: boolean = false) => {
                  this.updateState({
                    showLoading: show,
                    isVideoLoading: isVideo,
                  });
                }}
                canvasName={
                  canvas && canvas.introduction && canvas.introduction.name
                }
              ></Media>
              <div className="btn-wrap">
                <div className="left-sec">
                  <Button
                    className="btn-primary"
                    disabled={this.state.postBtnDisabled}
                    onClick={() => {
                      this.postJob(true);
                    }}
                  >
                    Post Job
                  </Button>
                  <Button
                    className="btn-secondary preview-btn"
                    onClick={() => this.previewDetails()}
                  >
                    Preview
                  </Button>
                  <Button
                    className="btn-secondary preview-btn"
                    disabled={this.state.postBtnDisabled}
                    onClick={() => this.postJob(false)}
                  >
                    Save and close
                  </Button>
                </div>

                <Button
                  type="link"
                  hidden={this.state.isInMyAccount}
                  onClick={() =>
                    this.props.history.push({
                      pathname: router_my_dashboard,
                      search: `?tabType=posted_opportunity&viewCanvas=${canvas.id}`,
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>

          <FieldRequireModal
            onClose={() => doAction(UpdateBaseInfoAction, { emptyFields: [] })}
            visible={!_.isEmpty(this.props.emptyFields)}
            data={this.props.emptyFields}
          />
          <LoadingModal
            visible={this.state.showLoading}
          />

          <Modal visible={getStartedOverlay}
            closable={true}
            onCancel={() => this.setState({ getStartedOverlay: false })}
            maskClosable={true}
            footer={null}
            centered={true}>
            <GetStartedOverlay type={'business'} close={() => this.setState({ getStartedOverlay: false })}></GetStartedOverlay>
          </Modal>

        </Content>
      </div>
    );
  }
}
