import { ModuleStore, KV, ModuleAction } from "module-reaction";
import { payStatus, searchMyCanvases } from "../api/Canvas";

export const MODULE_DASHBOARD_INDEX = "module_dashboard_index";

export interface ModleDashBoardIndex extends ModuleStore {
  canvasList: KV[];
  curCanvas: KV;
  curCanvasPaid: boolean;
  postOppCanvasInfo: any;
  noCanvas: boolean;
}

export const model_dashboad_index: ModleDashBoardIndex = {
  module: MODULE_DASHBOARD_INDEX,
  canvasList: [],
  curCanvas: null as any,
  curCanvasPaid: false,
  postOppCanvasInfo: null,
  noCanvas: false,
};

export const freshDashboardAction: ModuleAction<KV, ModleDashBoardIndex> = {
  module: MODULE_DASHBOARD_INDEX,
  process: async (curCanvas: KV, mStore: ModleDashBoardIndex) => {
    let canvasList = [];
    const res = await searchMyCanvases("", "all");
    if (res && res.list) {
      canvasList = res.list;
    }
    if (curCanvas) {
      curCanvas = canvasList.find((_: any) => _.id === curCanvas.id);
    }
    curCanvas = curCanvas ||
      mStore.curCanvas ||
      (canvasList.length ? canvasList[0] : null)
    // fetch payment status
    if (curCanvas) {
      const paySt = await payStatus(curCanvas.id)
      const curCanvasPaid = paySt === 'succeeded'
      return {
        canvasList,
        curCanvas,
        curCanvasPaid,
        noCanvas: canvasList.length === 0,
      };
    }
    return { noCanvas: true }
  },
};
