import Http from "./Http";

/**
 * @param {
    "msg": string,
    "template": string,
    "users": [number],
    "extra"?: {
      "canvas_id": number,
      "address": string
    }
  }
 * templete:
 * msg_to_student, notify_update_canvas, msg_to_meber, msg_to_poster
 */
export const sendMsgEmail = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/user/msg-email", params)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};
