import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { Button, Input, Avatar, Modal, message } from "antd";
import _ from "lodash";
import {
  ListTable,
  IHeadItem,
  IBodyItem,
} from "../../../components/component_list_table";
import ConfirmModal from "../../../components/component_confirm_modal";
import AddCoworkerModal from "../../../components/component_add_co_worker_modal";
import { addEmployee, editStaffRole } from "../../../api/Canvas";
import { searchSchoolEmployee, employeeChangeHide } from "../../../api/School";
import { sendMsgEmail } from "../../../api/Common";
import IconAdd from "../../../assets/images/ic_add_green.svg";
import IconDel from "../../../assets/images/ic_delete_orange.svg";
import IconMessage from "../../../assets/images/ic_message_green.svg";
import IconNoStudent from "../../../assets/images/ic_no_student.svg";
import IconMessageBlack from "../../../assets/images/ic_message_black.svg";
import IconPrint from "../../../assets/images/ic_print.svg";
import IconLink from "../../../assets/images/ic_link.svg";
import IconSuccess from "../../../assets/images/ic_check.svg";
import IconView from "../../../assets/images/ic_view.svg";
import { removeStaffFromSchool } from "../../../api/Canvas";
import "./school_dashboard_students.less";
import { SchoolRole } from "../../../utils/enum";
import EditCoworkerModal from "../../../components/component_edit_co_worker_modal";
import { getModuleProp, doAction } from "module-reaction";
import { MODULE_USER } from "../../../models/model_user";
import { router_message } from "../../../utils/enum";
import { createChat, chatClient } from "../../../utils/chatHelper";
import {
  MODULE_DASHBOARD_INDEX,
  model_dashboad_index,
  freshDashboardAction,
} from "../../../models/model_dashboard_index";
const { Search } = Input;

interface TeamProps {
  school: any;
  changeTab: any;
  history: any;
  curUser: any;
  business?: boolean //reuse for business
}

const msgTypeList: any = {
  msg: "msg_to_staff",
  notify: "notify_update_canvas",
};

const msgText: any = {
  msg: {
    title: "Send Message",
    intro1: "You are sending your staff(s) a message via email. ",
    intro2: (
      <>
        Clicking on <strong>Send My Message</strong> will initiate a email to
        the user(s)
      </>
    ),
  },
  notify: {
    title: "Gentle reminder to update your canvas",
    intro1: "You are sending this notification to <username> via email. ",
    intro2: (
      <>
        Clicking on <strong>Send My Message</strong> will initiate an email to
        this user.
      </>
    ),
  },
};

export const SchoolDashboardTeam: React.FC<TeamProps> = (props) => {
  const { school, business, history = _.noop, curUser } = props;
  const isMobile = window.innerWidth < 1024;

  const size = 8;
  const [location, setLocation] = useState(0);
  const [role, setRole] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selectList, setSelectList] = useState([] as any);
  const [curPage, setCurPage] = useState(1);
  const [list, setList] = useState([] as any[]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visibleMsgModal, setVisibleMsgModal] = useState(false);
  const [visibleEditRole, setVisibleEditRole] = useState(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [msgType, setMsgType] = useState("msg");
  const [msgList, setMsgList] = useState([]);
  const [msgContent, setMsgContent] = useState("");
  const [showDelConfirm, setShowDelConfirm] = useState(false);
  const [showResendConfirm, setShowResendConfirm] = useState(false);
  const [curResendUser, setCurResendUser] = useState({} as any);
  const [curDelStaff, setCurDelStaff] = useState({} as any);
  const [curEditStaff, setCurEditStaff] = useState({} as any);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddStaffConfirm, setshowAddStaffConfirm] = useState(false);
  const [addingStaff, setAddingStaff] = useState({} as any);

  const roleList = () =>{
    return [
      { type: -1, name: "All" },
      { type: SchoolRole.ADMIN, name: "Admin" },
      ...(business? [] : [
        { type: SchoolRole.TEACHER, name: "Teacher(non-admin)" },
        {
          type: SchoolRole.TEACHER_ASSISTANT,
          name: "Teacher Assistant(non-admin)",
        },
      ]),
      { type: SchoolRole.OTHER, name: business ? "Non-admin" : "Other(non-admin)" },
    ];
  } 

  useEffect(() => {
    if (school && school.id) {
      !_.isEmpty(school.locations) && setLocation(school.locations[0].id);
    }
  }, [school]);

  const getList = (page = 1) => {
    if (!school.id || loading || !location) return;
    setLoading(true);
    const params: any = {
      canvas_id: school.id,
      page,
      size,
      business
    };
    const filter = {
      keyword,
      location: +location,
      role,
      sortBy: orderBy,
    };
    _.map(filter, (value: any, key) => {
      if (value !== "" && value !== 0) params[key] = value;
    });

    searchSchoolEmployee(params)
      .then((res: any) => {
        setCurPage(page);
        const dataRole =
          res.list.map((_: any) => ({
            ..._,
            roleName: roleList().find((r) => r.type === _.role)?.name,
          })) || [];
        const dataPending = _.map(dataRole, (item) => {
          if (item.pending) {
            return {
              ...item,
              phoneNumber: "",
              location: null,
              joinDate: "",
              roleName: "",
            };
          } else {
            return item;
          }
        });
        const resultList = _.filter(dataPending, (item) => {
          return !item.hided;
        });
        setList(resultList);
        setTotal(res.count || 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setTimeout(() => {
      getList();
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, role, keyword, orderBy, school.id]);

  const handleChangePage = (event: unknown, newPage: number) => {
    getList(newPage);
  };

  const sendMessage = (userId?: any) => {
    setMsgList(userId ? [userId] : selectList);
    setVisibleMsgModal(true);
  };

  const sendMsg = () => {
    if (!school.id) return;
    if (!msgContent) {
      message.info("please input your message");
      return;
    }
    const params = {
      msg: msgContent,
      template: msgTypeList[msgType],
      users: msgList,
      extra: {
        canvas_id: school.id,
        // address: string,
      },
    };
    sendMsgEmail(params).then(() => {
      onMsgClose();
      setVisibleSuccessModal(true);
    });
  };

  const startChat = async (userId: any) => {
    if (!userId || !curUser || !curUser.id || !chatClient) return;
    if (userId === curUser.id) {
      message.warning('target user is yourself! skipped!')
      return
    }
    const channelId = await createChat([curUser.id, userId]);
    history.push(router_message, { channelId: channelId });
  };

  const onMsgClose = () => {
    setVisibleMsgModal(false);
    setMsgContent("");
    setMsgList([]);
  };

  const removeStaff = async () => {
    if (!school || !school.id || !curDelStaff.userId) return;
    const res = await removeStaffFromSchool(
      school.id,
      curDelStaff.memberLocationId,
      curDelStaff.userId,
      business
    );
    if (res) {
      setCurDelStaff({});
      getList();
    }
  };

  const getMsgIntro = () => {
    if (msgType === "notify") {
      const currentUser = _.find(list, { userId: msgList[0] });
      const username =
        _.get(currentUser, "firstName") +
        " " +
        _.get(currentUser, "lastName") || "";
      return msgText[msgType].intro1.replace("<username>", username);
    } else {
      return msgText[msgType].intro1;
    }
  };

  const hidePendingItem = async (memberId: number) => {
    if (!school || !school.id || !memberId) return;
    setLoading(true);
    const hideResult = await employeeChangeHide(school.id, memberId, business);
    if (hideResult) {
      getList();
    } else {
      setLoading(false);
    }
  };

  const tHeadRender: IHeadItem[] = isMobile
    ? [{ name: "Coworker name", isSort: true, key: "name" }]
    : [
      { name: "Coworker name", isSort: true, key: "name" },
      { name: "Phone No." },
      { name: "Location", isSort: true, key: "location" },
      { name: "Start from", isSort: true, key: "joinDate" },
      { name: "Role"},
      { name: "Actions"},
    ];

  const tBodyRender: IBodyItem[] = [
    {
      type: "avatar",
      component: (row: any) => (
        <>
          <Avatar
            icon="user"
            src={row && row.avatar}
            className="avatar"
            style={{ marginRight: "8px" }}
          />
          {`${row.firstName} ${row.lastName}${row.pending ? " (pending)" : ""}`}
        </>
      ),
      className: "avatar-name",
    },
    { name: "Phone No.", key: "phoneNumber" },
    { name: "Location", key: "location.city" },
    { name: "Start from", key: "joinDate", type: "date" },
    { name: "Role", key: "roleName" },
    {
      type: "dropdown-actions",
      dropdownList: [
        {
          icon: IconMessageBlack,
          name: "Message",
          onClick: (item: any, index: number) => {
            startChat(item.userId);
          },
        },
        {
          icon: IconPrint,
          name: "Download pdf",
          onClick: (item: any, index: number) => {
            const apiHost = process.env.REACT_APP_BASE_URL;
            window.open(
              `${apiHost?.endsWith("/") ? apiHost : apiHost + "/"
              }canvas/canvas-pdf/${item.canvasId}`
            );
          },
        },
        {
          icon: IconLink,
          name: "Edit role",
          onClick: (item: any, index: number) => {
            setCurEditStaff(item);
            setVisibleEditRole(true);
          },
        },
        {
          icon: IconView,
          name: "View",
          onClick: (item: any, index: number) => {
            history.push(
              `/canvas/${item.canvasSlug || item.canvasId}?tabType=team`
            );
          },
        },
        {
          icon: IconDel,
          name: "Remove",
          orangeText: true,
          onClick: (item: any, index: number) => {
            setShowDelConfirm(true);
            setCurDelStaff(item);
          },
        },
      ],
      pendingComponent: (row: any, index: number) => (
        <div className="pending-action">
          <span onClick={() => hidePendingItem(row.memberId)}>Hide</span>
          <span
            className="resend"
            onClick={() => {
              setCurResendUser(row);
              setShowResendConfirm(true);
            }}
          >
            Resend
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="school-dashboard-students">
      <div className="title-wrap">
        <div className="common-title bold-title">My Team</div>
        <div className="space" />
        <Button className="btn-secondary" onClick={() => setShowAdd(true)}>
          <img src={IconAdd} alt="" />
          Add coworker
        </Button>
      </div>
      <div className="filter-wrap">
        {/* <TextField
          label="location"
          variant="filled"
          select
          className="material-input input"
          value={"" + location}
          onChange={(e) => setLocation(+e.target.value)}
        >
          {locationList.map((item: any, index: number) => (
            <MenuItem key={index} value={"" + item.id}>
              {item.address}
            </MenuItem>
          ))}
        </TextField> */}
        <TextField
          label="Role"
          variant="filled"
          select
          className="material-input input"
          value={"" + role}
          onChange={(e) => setRole(+e.target.value)}
        >
          {roleList().map((item: any, index: number) => (
            <MenuItem key={index} value={item.type}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <Search
          className="keyword-input"
          placeholder="Search coworker by name..."
          onSearch={(value) => setKeyword(value)}
        />
        <Button
          className="btn-secondary"
          disabled={!selectList.length}
          onClick={() => {
            setMsgType("msg");
            sendMessage();
          }}
        >
          <img src={IconMessage} alt="" />
          Message
        </Button>
      </div>
      {list.length || loading ? (
        <ListTable
          tHeadRender={tHeadRender}
          tBodyRender={tBodyRender}
          loading={loading}
          hasSelect={true}
          idKey="userId"
          dataList={list}
          selectList={selectList}
          selectOnChange={setSelectList}
          total={total}
          size={8}
          page={curPage}
          pageOnChange={handleChangePage}
          orderBy={orderBy}
          orderByOnChange={(value: string) => setOrderBy(value)}
          isMobile={isMobile}
        />
      ) : (
          <div className="table-empty-wrap">
            <img src={IconNoStudent} alt="" />
            {/* <div className="common-title">There’s no students added</div> */}
            <p>Start adding staff to your dashboard!</p>
            <Button
              className="btn-secondary"
              // onClick={() => changeTab("Add Students")}
              onClick={() => setShowAdd(true)}
            >
              <img src={IconAdd} alt="" />
            Add Coworker
          </Button>
          </div>
        )}
      <Modal
        footer={null}
        closable={false}
        onCancel={onMsgClose}
        visible={visibleMsgModal}
        centered={true}
        width="80%"
      >
        <div className="send-msg-modal">
          <div className="common-title">{msgText[msgType].title}</div>
          <div className="modal-text">{getMsgIntro()}</div>
          <TextField
            label="Message goes here"
            placeholder="Message goes here"
            multiline
            rows={4}
            variant="filled"
            className="material-input"
            value={msgContent}
            onChange={(event) => setMsgContent(event.target.value)}
          />
          <div className="modal-text">{msgText[msgType].intro2}</div>
          <div className="btn-wrap">
            <Button type="primary" onClick={sendMsg}>
              Send Message
            </Button>
            <Button onClick={onMsgClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setVisibleSuccessModal(false)}
        visible={visibleSuccessModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Message sent!</div>
          <div className="modal-text">
            Woohoo! Your message was sent successfully.
          </div>
          <img src={IconSuccess} alt="success" />
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={() => setVisibleSuccessModal(false)}
            >
              Back to My Students
            </Button>
          </div>
        </div>
      </Modal>
      {showDelConfirm && (
        <ConfirmModal
          visible
          title="Confirmation"
          confirmText="Remove"
          description={`You are removing staff(s) in ${school.name}. This user will no longer have access to your ${business ? 'business' : 'school'}.`}
          onClose={() => {
            setShowDelConfirm(false);
            setCurDelStaff({});
          }}
          onConfirm={() => {
            removeStaff();
            setShowDelConfirm(false);
          }}
        />
      )}
      {showResendConfirm && (
        <ConfirmModal
          visible
          title="Confirmation"
          confirmText="Confirm"
          description={`You are resending invitation to <${curResendUser.email}>. Do you want to continue?`}
          onClose={() => {
            setShowResendConfirm(false);
            setCurResendUser({});
          }}
          onConfirm={async () => {
            setLoading(true);
            const res = await addEmployee(school.id, {
              locationId: curResendUser.memberLocationId,
              members: [
                {
                  id: curResendUser.userId,
                  email: curResendUser.email,
                  role: curResendUser.role,
                },
              ],
              resendEmail: true,
              business
            });
            if (res?.success) {
              message.success("Invitation resent!");
            }
            setShowResendConfirm(false);
            setCurResendUser({});
            getList();
          }}
        />
      )}
      {showAddStaffConfirm && (
        <ConfirmModal
          visible
          title="Confirmation"
          confirmText="Add staff"
          description={`You are adding <${addingStaff.members
            .map((_: any) => _.email)
            .join(",")}> as staff to <${school.name
            }>, Do you want to continue?`}
          onClose={() => {
            setshowAddStaffConfirm(false);
            setAddingStaff({});
          }}
          onConfirm={async () => {
            const res = await addEmployee(school.id, {
              locationId: location,
              members: addingStaff.members,
              business
            });
            if (res?.success) {
              message.success("Invitation sent!");
            }
            setshowAddStaffConfirm(false);
            setAddingStaff({});
            // refresh
            getList();
          }}
        />
      )}
      <AddCoworkerModal
        visible={showAdd}
        business={business}
        onAdd={async (d: { members: any[] }) => {
          setAddingStaff(d);
          setshowAddStaffConfirm(true);
        }}
        onClose={() => setShowAdd(false)}
      />
      <EditCoworkerModal
        visible={visibleEditRole}
        business={business}
        initRole={curEditStaff.role}
        onEdit={async (d: { role: number }) => {
          await editStaffRole(school.id, location, curEditStaff.userId, d.role, business);
          const curUser = getModuleProp(MODULE_USER, "curUser");
          if (curUser?.id === curEditStaff.userId) {
            // fresh dashboard index
            doAction(MODULE_DASHBOARD_INDEX, model_dashboad_index);
            doAction(freshDashboardAction);
          }
          setCurEditStaff({});
          setVisibleEditRole(false);
          getList();
        }}
        onClose={() => setVisibleEditRole(false)}
      />
    </div>
  );
};
