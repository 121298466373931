import React, { useEffect, useState } from "react";
import { getOppViewDetail } from "../api/Opportunity";
import ChartComponent from "./component_generate_chart";
import { Button } from "antd";
import './component_opp_view_count_detail.less'

interface Props {
  opportunityId: any;
  slug: any;
}

enum View {
  Days,
  Weeks,
  Months,
}

export const OpportunityCountDetail: React.FC<Props> = (props) => {
  const { slug, opportunityId } = props;
  const [value, setValue] = useState(null);
  const [view, setView] = useState(View.Days);
  const Days = () => {
    return view === View.Days ? true : false
  }

  const Weeks = () => {
    return view === View.Weeks ? true : false
  }

  const Months = () => {
    return view === View.Months ? true : false
  }

  useEffect(() => {
    if (opportunityId) {
      retrieveDetails();
    }
  }, [opportunityId]);

  async function retrieveDetails() {
    setValue(await getOppViewDetail(opportunityId));
  }

  return (
    <div className="view-opp-count-modal">
      <span>Views Per </span>
      <Button className={Days() ? "selected" : ""} onClick={() => setView(View.Days)}>Day</Button>
      <Button className={Weeks() ? "selected" : ""} onClick={() => setView(View.Weeks)}>Week</Button>
      <Button className={Months() ? "selected" : ""} onClick={() => setView(View.Months)}>Month</Button>
      {value && (
        <>
          {Days() && (
            <ChartComponent
              data={value.viewsPerDay}
              header={`Views Per Day on ${slug} opportunity`}
            />
          )}
          {Weeks() && (
            <ChartComponent
              data={value.viewsPerWeek}
              header={`Views Per Week on ${slug} opportunity`}
            />
          )}
          {Months() && (
            <ChartComponent
              data={value.viewsPerMonth}
              header={`Views Per Month on ${slug} opportunity`}
            />
          )}
          <p className="slug">{slug}</p>
        </>
      )}
    </div>
  );
};
