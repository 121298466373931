import Http from "./Http";

const customizedCache = []

export const getCustomized = (official: boolean, types: string[]) => {
  const cacheKey = `${official}${types.map(type => type)}`
  const result = customizedCache[cacheKey]
  if (result) {
    return Promise.resolve(result)
  }
  return Http.get("/option/customized", {
    params: {
      kind: official ? "official" : "unofficial",
      types: JSON.stringify(types),
    },
  })
    .then((resp) => {
      if (resp.status === 200) {
        customizedCache[cacheKey] = resp.data
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((error) => console.error(error));
};

export const search = (
  type: string,
  official: boolean,
  tag: string,
  name: string
) => {
  return Http.get("/option/search", {
    params: {
      type: type,
      kind: official ? "official" : "unofficial",
      tag: tag,
      name: name,
    },
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((error) => console.error(error));
};

export const searchSchool = (keywords: string, tactics: number[]) => {
  return Http.put("/option/school/search", {
    keyword: keywords,
    tactics: tactics,
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};
