import {
  ModuleStore,
  ModuleAction,
  doAction,
  plusAction,
} from "module-reaction";
import { getUserInfo, login } from "../api/Auth";
import { getToken, clearUser } from "../utils/authStorage";
import { MODULE_USER, User } from "./model_user";
import { changeUser, chatClient } from "../utils/chatHelper";
import { TargetType, router_get_started } from "../utils/enum";
import { MODULE_GET_STARTED } from "./model_get_started";
import { global_router } from "../routers";
import TagManager from 'react-gtm-module';
import { localStorageSet } from "../utils/utils";
export const MODULE_HOME = "module_home";

export interface ModuleHome extends ModuleStore {
  user: User;
  logined: boolean;
  showLogin: boolean;
  resetPwdSuccess: boolean;
}

export const module_home: ModuleHome = {
  module: MODULE_HOME,
  user: {} as User,
  logined: getToken() ? true : false,
  showLogin: false,
  resetPwdSuccess: false,
};

export const LoginWithTokenAction: ModuleAction<any, ModuleHome> = {
  module: MODULE_HOME,
  process: async () => {
    if (getToken()) {
      const res = await getUserInfo();
      if (res && res.user) {

        if (res.user?.role?.value === "administrator") {
          res.user.profile.target = "admin";
        } else {
          plusAction(CheckOnboardingStepAction);
        }
        plusAction(MODULE_USER, { logined: true, curUser: res.user })

        localStorageSet('user_id', res.user.id)

        try {
          TagManager.dataLayer({
            dataLayer: {
              event: 'login',
              user_id: res.user.id
            }
          });
        } catch (e) {
          console.log('Did not update GTM User Id', e)
        }

        return {
          logined: true,
          user: res.user,
        };
      }
      return {};
    }
    return {};
  },
};

export const CheckOnboardingStepAction: ModuleAction<any, ModuleHome> = {
  module: MODULE_HOME,
  process: async (payload: any, model: ModuleHome) => {
    const user = model.user;
    if (
      !(
        user.profile.career ||
        user.categories.find((_: any) => _.id && _.id > 0)
      )
    ) {
      if (!user.profile.source) {
        plusAction(MODULE_GET_STARTED, {
          pageName: "source",
        });
        global_router.history.replace(router_get_started);
      } else {
        // didn't finish the onboarding category step
        plusAction(MODULE_GET_STARTED, {
          pageName: "info",
        });
        global_router.history.replace(router_get_started);
      }
    } else if (!user.profile.firstName) {
      // didn't input source
      if (!user.profile.source) {
        plusAction(MODULE_GET_STARTED, {
          pageName: "source",
        });
        global_router.history.replace(router_get_started);
      } else {
        // didn't finish onboarding profile step
        plusAction(MODULE_GET_STARTED, {
          pageName: "profile",
        });
        global_router.history.replace(router_get_started);
      }
    }
    // else if (!user.profile.phoneNumber) {
    //   // didn't finish onboarding profile step
    //   plusAction(MODULE_GET_STARTED, {
    //     pageName: "verifyCode",
    //   });
    //   global_router.history.replace(router_get_started);
    // }

    return {};
  },
};

export const LoginWithEmailAction: ModuleAction<any, ModuleHome> = {
  module: MODULE_HOME,
  process: async (params: any, module: ModuleHome) => {
    // if (module.logined) {
    //   return { showLogin: false };
    // }
    let field = {};
    if (params.type === "email") {
      field = {
        platformType: "internal",
        email: params.email,
        password: params.password,
      };
    } else {
      field = { platformType: params.type, platformUserId: params.userId };
    }
    const res = await login(field);
    if (res && res.access_token && res.user) {
      if (res.user?.role?.value === "administrator") {
        res.user.profile.target = "admin";
      }
      if (chatClient) {
        await changeUser(res.user);
      }
      try {
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            user_id: res.user.id
          }
        });
      } catch (e) {
        console.log('Did not update GTM User Id', e)
      }
      doAction(setUserLoginInfoAction, { res, ...params });
    }
    return {};
  },
};

export const setUserLoginInfoAction: ModuleAction<any, ModuleHome> = {
  module: MODULE_HOME,
  process: async (params: any, module: ModuleHome) => {
    const { res, history, fromPage } = params;
    if (res && res.access_token && res.user) {
      let target = res.user.profile && res.user.profile.target;
      let redirect = "/my-canvases";
      switch (target) {
        case TargetType.ADMIN:
          redirect = "/admin-user";
          break;
        case TargetType.SCHOOL:
        case TargetType.CAREER:
        case TargetType.BUSINESS:
          redirect = "/my-dashboard";
          break;
      }
      let _fromPage = /^\/canvas\/[\w-]+$/.test(fromPage)
        ? fromPage + "?page=/sign-in"
        : fromPage;
      plusAction({
        module: MODULE_HOME,
        process: (async) =>
          history.replace(
            _fromPage === "/" || !_fromPage ? redirect : _fromPage
          ),
      });
      if (target !== "admin") {
        plusAction(CheckOnboardingStepAction);
      }

      localStorageSet('user_id', res.user.id)

      try {
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            user_id: res.user.id
          }
        });
      } catch (e) {
        console.log('Did not update GTM User Id', e)
      }

      return {
        logined: true,
        user: res.user,
        showLogin: false,
      };
    }
    return {};
  },
};

export const ShowHideChangePwdSuccessAction: ModuleAction<any, ModuleHome> = {
  module: MODULE_HOME,
  process: async (show: boolean) => {
    return { resetPwdSuccess: show };
  },
};

export const ShowHideLoginAction: ModuleAction<any, ModuleHome> = {
  module: MODULE_HOME,
  process: async (show: boolean) => {
    return { showLogin: show };
  },
};

export const LogOutAction: ModuleAction<any, ModuleHome> = {
  module: MODULE_HOME,
  process: async () => {
    clearUser();
    return { logined: false };
  },
};
