import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { Button, DatePicker } from "antd";
import { Button as MaButton } from "@material-ui/core";
import { getSchoolStatistics } from "../../../api/Canvas";
import { schoolRecentJobs } from "../../../api/School";
import _ from "lodash";
import HorizontalScrollView from "../../../components/component_horizontal_scroll_view";
import classnames from "classnames";
import IconNoStatistic from "../../../assets/images/ic_no_statistic.svg";
import IconMore from "../../../assets/images/ic_add_green.svg";
import IconUpdate from "../../../assets/images/ic_update.svg";
import "./school_dashboard_stats.less";
import moment from "moment";
import { CanvasSearchItem } from "../../../components/component_canvas_search_item";
import {
  getItemTags,
  getItemAdditions,
} from "../../homepage/homepage_opportunity";
import {
  router_homepage_opportunity,
  router_opportunity_detail,
} from "../../../utils/enum";
const { RangePicker } = DatePicker;

interface StatsProps {
  school: any;
  history: any;
}

export const SchoolDashboardStats: React.FC<StatsProps> = (props) => {
  const { school, history } = props;

  const chartTypeList = [
    { name: "Today", value: 1 },
    { name: "Week", value: 2 },
    { name: "Month", value: 3 },
    { name: "Year", value: 4 },
    { name: "Custom date", value: 5 },
  ];

  const chartTabList = [
    { name: "No. Graduated Students", value: 0 },
    { name: "Views on all portfolios", value: 1 },
    { name: "Time from Graduation to Hire", value: 2, unit: 'days' },
    { name: "No. Hired students", value: 3 },
  ];

  const [chartType, setChartType] = useState(1);
  const [chartTab, setChartTab] = useState(0);
  const [date, setDate] = useState(moment());
  const [location, setLocation] = useState(0);
  const [chartData, setChartData] = useState();
  const [dataX, setDataX] = useState([] as any[]);
  const [dataY, setDataY] = useState([] as any[]);
  const [graduateTotal, setGraduateTotal] = useState(0);
  const [portfoliosTotal, setPortfoliosTotal] = useState(0);
  const [meanTimeforGraduation2Hire, setMeanTimeforGraduation2Hire] = useState(
    0
  );
  const [hiredTotal, setHiredTotal] = useState(0);
  const [durationText, setDurationText] = useState("");
  const [duration, setDuration] = useState<string[]>([]);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [customDateRange, setCustomDateRange] = useState([] as any[]);
  const [jobList, setJobList] = useState<any[]>([]);
  const [oppoCondi, setOppoCondi] = useState<any>("");

  useEffect(() => {
    if (!school.id) return;
    if (!_.isEmpty(school.locations)) {
      const first: any = _.first(school.locations);
      first && setLocation(first.id);
    }
  }, [school]);

  const getChartData = useCallback(
    async (id: number, duration: string[], location: number) => {
      const res = await getSchoolStatistics(id, duration, location);
      if (res) {
        setChartData(res);
        let list = [];
        switch (chartTab) {
          case 0:
            list = res.graduate;
            break;
          case 1:
            list = res.portfoliosAllView;
            break;
          default:
            list = res.hired || [];
        }
        const dateList = _.map(list, (item) => {
          const day = item.date ? moment(item.date).format('MMM-DD') : '';
          return day;
        });
        const valueList = _.map(list, (item) => {
          return item.count;
        });
        // show monthly when duration is year
        if (moment(duration[1]).diff(moment(duration[0]), 'year') > 0.5 && list) {
          const start = moment(duration[0])
          dateList.length = 0
          valueList.length = 0
          const values = list.reduce((obj: any, item: any) => {
            const date = moment(item.date).format('MMM YYYY')
            obj[date] = obj[date] || 0
            obj[date] += item.count
            return obj
          }, {})

          for (let i = 0; i < 12; i++) {
            const key = start.add(1, 'month').format('MMM YYYY')
            dateList.push(key)
            valueList.push(values[key])
          }

        }
        setDataX(dateList);
        setDataY(valueList);
        let graTotal = 0;
        let portTotal = 0;
        let hireTotal = 0;
        _.forEach(res.graduate, (item) => {
          graTotal += Number(item.count);
        });
        _.forEach(res.portfoliosAllView, (item) => {
          portTotal += Number(item.count);
        });
        _.forEach(res.hired, (item) => {
          hireTotal += Number(item.count);
        });
        setGraduateTotal(graTotal);
        setPortfoliosTotal(portTotal);
        setHiredTotal(hireTotal);
        setMeanTimeforGraduation2Hire(res.meanTimeforGraduation2Hire || 0);
      }
    },
    [chartTab]
  );

  useEffect(() => {
    if (chartData) {
      const list =
        chartTab === 0
          ? (chartData as any)!.graduate
          : (chartData as any)!.portfoliosAllView;
      const dateList = _.map(list, (item) => {
        const day = item.date ? moment(item.date).format('MMM-DD') : '';
        return day;
      });
      const valueList = _.map(list, (item) => {
        return item.count;
      });
      // show monthly when duration is year
      if (duration?.length > 1 && moment(duration[1]).diff(moment(duration[0]), 'year') > 0.5 && list) {
        const start = moment(duration[0])
        dateList.length = 0
        valueList.length = 0
        const values = list.reduce((obj: any, item: any) => {
          const date = moment(item.date).format('MMM YYYY')
          obj[date] = obj[date] || 0
          obj[date] += item.count
          return obj
        }, {})

        for (let i = 0; i < 12; i++) {
          const key = start.add(1, 'month').format('MMM YYYY')
          dateList.push(key)
          valueList.push(values[key])
        }

      }
      setDataX(dateList);
      setDataY(valueList);
    }
  }, [chartTab, chartData]);

  useEffect(() => {
    if (!school.id) return;
    let duration = [];
    let duraText = "";
    switch (chartType) {
      case 1:
        const start = moment(date).format("YYYY-MM-DD");
        duration = [start, start];
        duraText = moment(date).format("MMM DD YYYY");
        break;
      case 2:
        const weekStart = moment().subtract(6, "days").format("YYYY-MM-DD");
        const weekEnd = moment().format("YYYY-MM-DD");
        duration = [weekStart, weekEnd];
        duraText = `${moment()
          .subtract(6, "days")
          .format("MMM DD YYYY")} - ${moment().format("MMM DD YYYY")}`;
        break;
      case 3:
        const monthStart = moment().subtract(29, "days").format("YYYY-MM-DD");
        const monthEnd = moment().format("YYYY-MM-DD");
        duration = [monthStart, monthEnd];
        duraText = `${moment()
          .subtract(29, "days")
          .format("MMM DD YYYY")} - ${moment().format("MMM DD YYYY")}`;
        break;
      case 4:
        const yearStart = moment().subtract(1, "years").format("YYYY-MM-DD");
        const yearEnd = moment().format("YYYY-MM-DD");
        duration = [yearStart, yearEnd];
        duraText = `${moment()
          .subtract(1, "years")
          .format("MMM DD YYYY")} - ${moment().format("MMM DD YYYY")}`;
        break;
      default:
        if (customDateRange.length !== 2) {
          return;
        }
        const cusStart = moment(customDateRange[0]).format("YYYY-MM-DD");
        const cusEnd = moment(customDateRange[1]).format("YYYY-MM-DD");
        duration = [cusStart, cusEnd];
        duraText = `${moment(customDateRange[0]).format(
          "MMM DD YYYY"
        )} - ${moment(customDateRange[1]).format("MMM DD YYYY")}`;
        break;
    }
    setDurationText(duraText);
    setDuration(duration)
    getChartData(school.id, duration, location);
  }, [school, location, chartType, date, getChartData, customDateRange]);

  const getCardTotal = (index: number) => {
    switch (index) {
      case 0:
        return graduateTotal;
      case 1:
        return portfoliosTotal;
      case 2:
        return meanTimeforGraduation2Hire;
      case 3:
        return hiredTotal;
      default:
        return 0;
    }
  };

  const emptyData = () => {
    if (!chartData) {
      return true;
    }
    const data = chartData as any;
    if (data?.graduate?.length) {
      return false;
    }
    if (data?.portfoliosAllView?.length) {
      return false;
    }
    if (data?.meanTimeforGraduation2Hire) {
      return false;
    }
    if (data?.totalHiredStudent) {
      return false;
    }
    return true;
  };

  const handleLink = () => {
    history.push(
      router_homepage_opportunity +
      `?location=${JSON.stringify(oppoCondi)}&radius=radius-50`
    );
  };

  const getJobList = () => {
    if (!school.id) return;
    let _data = {
      canvas_id: school.id,
      location_id: location || null,
    };
    schoolRecentJobs(_data).then((data: any) => {
      const _temp =
        data?.opportunities?.map((item: any) => {
          const videoUrl =
            item.media?.featuredVideo?.url || item.media?.featuredVideo?.video;
          return {
            id: item.id,
            type: item.type,
            link: `${router_opportunity_detail.replace(":slug", item.slug)}`,
            media: videoUrl || item.media?.thumbPhoto?.image,
            is_video: !!videoUrl,
            videoCover: item.media?.featuredVideo?.image,
            is_unread: false, // lack
            title: item.jobTitle,
            timestamp: item.updatedAt,
            category: item.jobCategory || "unknown",
            biz_ico: item.media?.logo?.image,
            biz_name: item.canvasName || "unknown",
            location: item.location?.address || "",
          };
        }) || [];

      setJobList(_temp);
      setOppoCondi(data.location);
    });
  };

  useEffect(() => {
    getJobList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, school]);

  return (
    <div className="school-dashboard-stats">
      <div className="title-sec">
        <span className="common-title bold-title">School Dashboard</span>
        {/* <TextField
          label="location"
          variant="filled"
          select
          className="material-input input"
          value={location}
          onChange={(e) => setLocation(Number(e.target.value))}
        >
          {locationList.map((item: any, index: number) => (
            <MenuItem key={index} value={item.id}>
              {item.address}
            </MenuItem>
          ))}
        </TextField> */}
        <div className="space" />
        <Button
          className="btn-secondary btn-add"
          onClick={() => history.push("/school-info")}
        >
          <img alt="" src={IconMore} />
          Add a new school dashboard
        </Button>
      </div>

      <div className="common-title overall-title">Overall Statistics</div>
      <div className="chart-options">
        <div className="tab-list">
          <HorizontalScrollView className="scroll">
            {chartTypeList.map((item: any, index: number) => (
              <span
                className={classnames("tab-btn", {
                  active: chartType === item.value,
                })}
                onClick={() => {
                  setChartType(item.value);
                  setShowRangePicker(item.value === 5);
                }}
                key={index}
              >
                {item.name}
              </span>
            ))}
          </HorizontalScrollView>
        </div>
        {showRangePicker && (
          <RangePicker
            defaultPickerValue={[moment().subtract(1, "month"), moment()]}
            className="range-picker"
            value={customDateRange}
            onChange={(date, dateString) => {
              setCustomDateRange(date);
            }}
          />
        )}
      </div>

      <div className="chart-wrap">
        {emptyData() ? (
          <div className="table-empty-wrap">
            <img src={IconNoStatistic} alt="no statistic" />
            <div className="common-title">There's no statistics yet</div>
            <p>
              Start adding students to your school to see valuable insights.
            </p>
          </div>
        ) : (
            <>
              <div className="data-type">
                <div className="tab-card-wrap">
                  {chartTabList.map((item: any, index: number) => (
                    <div
                      key={index}
                      className={classnames("tab-card", {
                        active: chartTab === item.value,
                      })}
                      onClick={() => setChartTab(item.value)}
                    >
                      <p>{item.name}</p>
                      <div>{getCardTotal(item.value)}&nbsp;{item.unit || ''}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="current-data" hidden={chartTab === 2}>
                <span className="chart-name">{chartTabList[chartTab].name}</span>
                <span>{durationText}</span>
                <span className="space" />
                <div className="update">
                  <div>
                    Updated <span>10 minutes ago</span>
                  </div>
                  <img
                    src={IconUpdate}
                    alt=""
                    onClick={() => setDate(moment())}
                  />
                </div>
              </div>
              <div style={{ width: "100%", height: "300px" }}>
                {chartTab !== 2 && (
                  <Bar
                    data={{
                      labels: dataX,
                      datasets: [
                        {
                          backgroundColor: "#CACBCE",
                          data: dataY,
                          barThickness: 30,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        display: false,
                      },
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 15,
                              precision: 0
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </>
          )}
      </div>

      {(jobList.length && (
        <>
          <div className="common-title dashboard-job-title">
            Jobs Showcase
            <span>
              Jobs are available within 50 miles
              <MaButton onClick={handleLink} variant="outlined">
                View all
              </MaButton>
            </span>
          </div>
          <div className="dashboard-job-wrapper">
            {jobList.map((item: any) => {
              return (
                <CanvasSearchItem
                  key={`opportunity-card-item-${item.id}`}
                  className="opportunity-card"
                  media={item.media}
                  videoCover={item.videoCover}
                  link={item.link}
                  history={history}
                  is_video={item.is_video}
                  tags={getItemTags(item)}
                  timestamp={item.timestamp}
                  title={item.title}
                  addtions={getItemAdditions(item)}
                />
              );
            })}
          </div>
        </>
      )) ||
        null}
    </div>
  );
};
