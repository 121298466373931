import { ModuleStore, ModuleAction, KV } from "module-reaction";

import { rightsData } from "../api/Notifications";

export const MODULE_RIGHTS_DATA = "module_rights_data";

export interface ModuleRightsData extends ModuleStore {
  option: string;
  first_name: string;
  last_name: string;
  address: string;
  email: string;
  phone_number: string;
  canvas_url: string;
  state_residence: string;
  success: boolean;
}

export const module_rights_data: ModuleRightsData = {
  module: MODULE_RIGHTS_DATA,
  option: "access_my_personal_info",
  first_name: "",
  last_name: "",
  address: "",
  email: "",
  phone_number: "",
  canvas_url: "",
  state_residence: "",
  success: false,
};

export const UpdateInfoAction: ModuleAction<KV, ModuleRightsData> = {
  module: MODULE_RIGHTS_DATA,
  process: async (info: KV) => {
    return { ...info };
  },
};

export const SendToEmail: ModuleAction<any, ModuleRightsData> = {
  module: MODULE_RIGHTS_DATA,
  process: async (_data: any, module: ModuleRightsData) => {
    const data = await rightsData(_data);
    return {
      success: data,
    };
  },
};
