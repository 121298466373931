import React from "react";
import { Layout } from "antd";
import _ from "lodash";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import { mapProp, doAction } from "module-reaction";
import { CanvasType } from "../../utils/enum";
import { PreviewMode } from "./canvas_preview";
import queryString from "query-string";
import { IMediaItem } from "../../components/component_media_item";
import {
  GetPageDataAction,
  SubmitToPublishAction,
  UpdatePageInfoAction,
  module_business_canvas_preview,
  MODULE_BUSINESS_CANVAS_PREVIEW,
} from "../../models/model_business_canvas_preview";
import FieldRequireModal from "../../components/component_field_require_modal";
import IconMobile from "../../assets/images/ic_mobile.svg";
import IconPC from "../../assets/images/ic_desktop.svg";
import CanvasPreview from "./canvas_preview";
import "./preview.less";
const { Content } = Layout;

interface Props {
  id: number;
  match: any;
  history: any;
  owner: number;
  type: string;
  awardList: any[];
  socialMediaList: [];
  companyName: string;
  headline: string;
  desc: string;
  whyJoin: string;
  defaultLocation: any;
  contactNumber: string;
  category: any;
  additional: any;
  extraLocations: any[];
  logo: IMediaItem; // type, link
  featuredPhoto: IMediaItem;
  teamPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  extraVideo: IMediaItem[];
  emptyFields: string[];
}

@mapProp(module_business_canvas_preview)
export default class Preview extends React.Component<Props, {}> {
  state = {
    id: this.props.match.params.id,
    isMobile: window.innerWidth < 1024,
    fromPost: false,
  };

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  componentDidMount() {
    doAction(GetPageDataAction, this.state.id);
    const { fromPost } = queryString.parse(window.location.search);
    if (fromPost) {
      this.setState({ fromPost: true });
    }
  }

  componentWillUnmount() {
    doAction(MODULE_BUSINESS_CANVAS_PREVIEW, module_business_canvas_preview);
  }

  render() {
    return (
      <Layout>
        <CreateBusinessCanvasHeader
          type={CanvasType.BUSINESS}
          step={2}
          onEditClick={() => {
            this.props.history.replace(
              `/${
                this.props.type === CanvasType.BUSINESS
                  ? "business-canvas"
                  : "school-canvas"
              }/${this.state.id}${this.state.fromPost ? "?fromPost=true" : ""}`
            );
          }}
          onPublishClick={() => {
            doAction(SubmitToPublishAction, {
              history: this.props.history,
              fromPost: this.state.fromPost,
            });
          }}
          onSaveDraftClick={() => {
            this.props.history.push("/my-canvases");
          }}
        />
        <Content>
          <div className="preview-container">
            <div className="top-sec">
              <span className="desktop">
                <img alt="" src={IconPC} />
                Desktop view
              </span>
              <img
                className="icon-mobile"
                alt=""
                src={IconMobile}
                onClick={() =>
                  window.open(
                    `/${
                      this.props.type === CanvasType.BUSINESS
                        ? "business-canvas"
                        : "school-canvas"
                    }/preview/${this.state.id}`,
                    "",
                    "height=812, width=375"
                  )
                }
              />
              <span className="space" />
              <span className="note">
                Note: Payment method will be required when publish
              </span>
            </div>
            <div className="preview-wrap">
              <CanvasPreview
                mode={PreviewMode.VIEW}
                type={this.props.type}
                isMobile={this.state.isMobile}
                pageData={{
                  id: this.props.id,
                  owner: this.props.owner,
                  logo: this.props.logo.image,
                  companyName: this.props.companyName,
                  category: this.props.category,
                  headline: this.props.headline,
                  desc: this.props.desc,
                  whyJoin: this.props.whyJoin,
                  contactNumber: this.props.contactNumber,
                  teamPhoto: this.props.teamPhoto,
                  awardList: this.props.awardList,
                  additional: this.props.additional,
                  locations: _.filter(
                    [this.props.defaultLocation, ...this.props.extraLocations],
                    (item) => {
                      return !_.isEmpty(item.location);
                    }
                  ),
                  socialMedias: _.filter(
                    this.props.socialMediaList,
                    (item: any) => {
                      return !_.isEmpty(item.value);
                    }
                  ),
                  featuredImage: this.props.featuredPhoto,
                  videos: _.filter(
                    [this.props.featuredVideo, ...this.props.extraVideo],
                    (item) => {
                      return item.image !== "";
                    }
                  ),
                }}
              />
            </div>
          </div>
          <FieldRequireModal
            onClose={() => doAction(UpdatePageInfoAction, { emptyFields: [] })}
            visible={!_.isEmpty(this.props.emptyFields)}
            data={this.props.emptyFields}
            onEdit={() =>
              this.props.history.replace(
                `/${
                  this.props.type === CanvasType.BUSINESS
                    ? "business-canvas"
                    : "school-canvas"
                }/${this.state.id}`
              )
            }
          />
        </Content>
      </Layout>
    );
  }
}
