import Http from "./Http";

export const getNotiCount = () => {
  return Http.get("/user/notification-counter")
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};



export const notiRead = (data: { id: number }) => {
    return Http.post(`/user/notification_readed?id=${data.id}`)
        .then((resp) => {
            if (resp.status === 200) {
                return Promise.resolve(resp.data);
            } else {
                return Promise.reject(resp);
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};



export const deleteNotification = (data:any) => {
    return Http.delete(`/user/delete-notifications`,{
        data
    })
        .then((resp) => {
            if (resp.status === 200) {
                return Promise.resolve(resp.data);
            } else {
                return Promise.reject(resp);
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};


export const getNotiList = (params: { page: number; size: number }) => {
  return Http.get("/user/notifications", {
    params,
  })
    .then((resp) => {
      console.log("RESP:", resp);
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};



export const rightsData = (data:any) => {
    return Http.post('/user/requests', {
        ...data,
    })
        .then((resp) => {
            console.log("RESP:", resp);
            if (resp.status === 200) {
                return Promise.resolve(true);
            } else {
                return Promise.reject(false);
            }
        })
        .catch((err) => {
            return Promise.reject(false);
        });
};
