import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CRSwitch from "./component_cr_switch";
import _ from "lodash";
import classnames from "classnames";
import "./component_school_location_detail.less";
import { ClassType } from "../utils/enum";

interface Props {
  className?: string;
  location: any;
  programOptions: any[];
  index?: number;
  onValueChange: Function;
}

const classTypes = [
  {
    type: ClassType.DAY,
    value: "My school offers day classes",
  },
  {
    type: ClassType.NIGHT,
    value: "My school offers night classes",
  },
  {
    type: ClassType.ALL,
    value: "My school offers both day & night classes",
  },
];

const SchoolLocationDetail: React.FC<Props> = (props) => {
  const {
    className = "",
    location,
    programOptions,
    index = 0,
    onValueChange,
  } = props;

  return (
    <div className={classnames(className, "school-location-detail-root")}>
      <div className="divider" />
      <span className="location-title">{`Location ${
        index > 0 ? index : ""
      } Information`}</span>

      <div className="accredit-sec">
        <span className="accredit-text">Are you an accredited school?</span>
        <CRSwitch
          enable={location.isAccredited}
          onChange={(enable: boolean) => {
            const newLocation = { ...location, isAccredited: enable };
            onValueChange(newLocation);
          }}
        />
      </div>
      <TextField
        key="employee"
        label="Number of employees"
        variant="filled"
        className="material-input input"
        type="number"
        value={location.employeeNumber || ""}
        onChange={(e) => {
          const value = e.target.value;
          if (Number(value) < 0) return;
          const newLocation = { ...location, employeeNumber: e.target.value };
          onValueChange(newLocation);
        }}
        onKeyDown={(e) =>
          (e.keyCode === 69 ||
            e.keyCode === 187 ||
            e.keyCode === 189 ||
            e.keyCode === 190) &&
          e.preventDefault()
        }
      />
      <TextField
        select
        key="class avaibility"
        label="Day & Night class availability"
        variant="filled"
        value={
          (location.classType &&
            _.find(classTypes, { type: location.classType })?.value) ||
          ""
        }
        onChange={(e) => {
          const item = _.find(classTypes, { value: e.target.value });
          if (item) {
            const newLocation = { ...location, classType: item.type };
            onValueChange(newLocation);
          }
        }}
        className="material-input input"
      >
        {_.map(classTypes, (item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.value}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        key="day-student"
        label="Number of Day students"
        variant="filled"
        className="material-input input"
        type="number"
        value={location.dayStudents || ""}
        onChange={(e) => {
          const value = e.target.value;
          if (Number(value) < 0) return;
          onValueChange({ ...location, dayStudents: e.target.value });
        }}
        onKeyDown={(e) =>
          (e.keyCode === 69 ||
            e.keyCode === 187 ||
            e.keyCode === 189 ||
            e.keyCode === 190) &&
          e.preventDefault()
        }
      />
      <TextField
        key="night-student"
        label="Number of Night students"
        variant="filled"
        className="material-input input"
        type="number"
        value={location.nightStudents || ""}
        onChange={(e) => {
          const value = e.target.value;
          if (Number(value) < 0) return;
          onValueChange({ ...location, nightStudents: e.target.value });
        }}
        onKeyDown={(e) =>
          (e.keyCode === 69 ||
            e.keyCode === 187 ||
            e.keyCode === 189 ||
            e.keyCode === 190) &&
          e.preventDefault()
        }
      />
      <Autocomplete
        multiple
        className="tag-input program-input"
        options={programOptions}
        freeSolo
        value={location.supportedProgram || []}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
        getOptionLabel={(option) => {
          return option.name || option;
        }}
        onChange={(event: object, value: any, reason: string) => {
          const newItems = _.map(value, (item) => {
            if (typeof item === "string") {
              if (value.find((_: any) => _.name === item)) {
                return undefined;
              }
                return programOptions.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
            }
            return item;
          }).filter(Boolean);
          onValueChange({ ...location, supportedProgram: _.uniqBy(newItems, 'name') });
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Supported program"
            variant="filled"
            className="material-input"
          />
        )}
      />
    </div>
  );
};

export default SchoolLocationDetail;
