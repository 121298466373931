import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  ModelHomepageOpportunity,
  OpportunityItem,
  FetchOpportunitiesAction,
  module_homepage_opportunity,
  MODULE_HOMEPAGE_OPPORTUNITY,
  browse_opportunity_filter_options,
} from "../../models/model_homepage_opportunity";
import { Select, Button, Tag, List, Pagination, Icon, Modal, Spin } from "antd";
import ic_filter from "../../assets/images/ic_filter.svg";
import ic_close from "../../assets/images/ic_close_black.svg";
import ic_location from "../../assets/images/ic_item_location.svg";
import { CanvasSearchItem } from "../../components/component_canvas_search_item";
import { doAction, mapProp, MODULE_COMMON } from "module-reaction";
import { RadioChangeEvent } from "antd/lib/radio";

import "./homepage_opportunity.styl";
import { GoogleUtil } from "../../utils/utils";
import SearchComponent from "./SearchComponent";
import SearchIcon from "@material-ui/icons/Search";
import { Button as MatButton } from "@material-ui/core";

const { Option } = Select;
const { CheckableTag } = Tag;

interface HomepageOpportunityProp
  extends RouteComponentProps,
  ModelHomepageOpportunity { }

@mapProp(module_homepage_opportunity)
@mapProp(MODULE_COMMON)
export class HomepageOpportunity extends React.Component<
HomepageOpportunityProp,
{}
> {
  state = { showMoreFilter: false, openMbMoreFilter: false };
  private mb_keyword_input: any;
  render() {
    if (!browse_opportunity_filter_options.category) {
      return <Spin className="loading-spin" />;
    }

    const {
      page_idx,
      total,
      num_per_page,
      filters,
      list,
      loadingTag,
    } = this.props;

    const { showMoreFilter } = this.state;

    const { radius, category, locationInfo, jobtype, keyword } = filters;

    const {
      category: categoryOpts,
      radius: radiusOpts,
      jobtype: jobTypeOpts,
    } = browse_opportunity_filter_options;

    return (
      <>
        <div className="homepage-opportunity">
          <div className="search-item">
            <div className="item-title">Browse Opportunities</div>
            <SearchComponent
              value={{
                location: locationInfo.location,
                keywords: keyword,
              }}
              disabled={loadingTag === "ok"}
              autoCompleteService={GoogleUtil.googleAutoCompleteService}
              onChange={(val: any) => {
                if (Object.keys(val)[0] === "keywords") {
                  this.updateFilter(val["keywords"], "keyword");
                } else {
                  doAction(MODULE_HOMEPAGE_OPPORTUNITY, {
                    filters: {
                      ...filters,
                      locationInfo: {
                        ...locationInfo,
                        location: val.location,
                      },
                    },
                  });

                  doAction(
                    FetchOpportunitiesAction,
                    {
                      filters: {
                        ...filters,
                        locationInfo: {
                          ...locationInfo,
                          location: val.location,
                          ...val.addressInfo,
                        },
                      },
                    },
                    "ok"
                  );
                }
              }}
            />
          </div>
          {/*<div className="title">Browse Opportunities</div>*/}
          <div className="filter-con">
            {/*{use_location ? (*/}
            {/*<AddressTextField*/}
            {/*className="item keyword-input"*/}
            {/*label={`Location`}*/}
            {/*value={locationInfo.location}*/}
            {/*onInput={(value: string) => {*/}
            {/*doAction(MODULE_HOMEPAGE_OPPORTUNITY, {*/}
            {/*filters: {*/}
            {/*...filters,*/}
            {/*locationInfo: {*/}
            {/*...locationInfo,*/}
            {/*location: value,*/}
            {/*},*/}
            {/*},*/}
            {/*});*/}
            {/*}}*/}
            {/*onAddressSelect={async (address: any) => {*/}
            {/*const addressInfo = await GoogleUtil.onGoogleAddressSelected(*/}
            {/*address*/}
            {/*);*/}
            {/*if (addressInfo) {*/}
            {/*doAction(FetchOpportunitiesAction, {*/}
            {/*filters: {*/}
            {/*...filters,*/}
            {/*locationInfo: Object.assign(*/}
            {/*{},*/}
            {/*locationInfo,*/}
            {/*addressInfo*/}
            {/*),*/}
            {/*},*/}
            {/*});*/}
            {/*}*/}
            {/*}}*/}
            {/*autoCompleteService={GoogleUtil.googleAutoCompleteService}*/}
            {/*onPressEnter={this.pressEnterToSearchLocation}*/}
            {/*/>*/}
            {/*) : (*/}
            {/*<Search*/}
            {/*className="item keyword-input"*/}
            {/*placeholder="Business name or job title..."*/}
            {/*onSearch={this.onSearch}*/}
            {/*ref={(e: any) => {*/}
            {/*if (e) {*/}
            {/*e.input.setState({ value: keyword });*/}
            {/*}*/}
            {/*}}*/}
            {/*/>*/}
            {/*)}*/}
            {/*<RadioGroup*/}
            {/*className={`lato-bold keyword-location-switch ${*/}
            {/*use_location ? "" : "without-location"*/}
            {/*}`}*/}
            {/*onChange={this.switchKeywordLocation}*/}
            {/*>*/}
            {/*<Radio.Button checked={!use_location} value="keyword">*/}
            {/*Keyword*/}
            {/*</Radio.Button>*/}
            {/*<Radio.Button checked={use_location} value="location">*/}
            {/*Location*/}
            {/*</Radio.Button>*/}
            {/*</RadioGroup>*/}

            <div className="item location-radius">
              <Select
                defaultActiveFirstOption
                value={radius}
                onChange={(val: string) => this.updateFilter(val, "radius")}
              >
                {radiusOpts.map((o) => (
                  <Option key={`radius-o-${o.key}`} value={o.key}>
                    {o.name}
                  </Option>
                ))}
              </Select>
              <span className="item-tag">RADIUS</span>
            </div>

            <div className="item categorys">
              <Select
                defaultActiveFirstOption
                value={category}
                onChange={(val: string) => this.updateFilter(val, "category")}
              >
                {categoryOpts.map((o) => (
                  <Option key={`category-o-${o.key}`} value={o.key}>
                    {o.name}
                  </Option>
                ))}
              </Select>
              <span className="item-tag">CATEGORY</span>
            </div>
            <div className="item select-btns">
              <Button
                className="item filter-btn"
                onClick={this.toggleMoreFilter}
              >
                <img src={ic_filter} alt="filter" />
                {showMoreFilter ? "LESS" : "MORE"} FILTERS
              </Button>
              <Button
                className="item filter-btn clear-btn"
                onClick={this.resetData}
              >
                <img src={ic_close} alt="filter" />
                CLEAR SEARCH
              </Button>
            </div>

            <MatButton
              className="item-search-btn"
              startIcon={<SearchIcon />}
              variant="outlined"
              onClick={this.showMbMoreFilter}
            >
              SEARCH
            </MatButton>
          </div>
          {this.genQuickJobtypes(jobTypeOpts, jobtype!)}
          <Button
            icon="right"
            className="mb-to-right"
            onClick={this.quickJobtypesToRight}
          ></Button>

          {showMoreFilter && this.genFourMoreFilters("more-filters")}
          {
            <Spin
              // className="loading-spin"
              spinning={this.props.loadingTag === "ok"}
            >
              <div className="list-con">
                <List
                  className="card-list"
                  dataSource={list}
                  grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 3 }}
                  renderItem={(item: OpportunityItem, index: number) => {
                    return (
                      <List.Item>
                        <CanvasSearchItem
                          key={`opportunity-card-item-${index}`}
                          className="opportunity-card"
                          media={item.media}
                          videoCover={item.videoCover}
                          link={item.link}
                          history={this.props.history}
                          is_video={item.is_video}
                          tags={getItemTags(item)}
                          timestamp={item.timestamp}
                          title={item.title}
                          addtions={getItemAdditions(item)}
                        />
                      </List.Item>
                    );
                  }}
                />
                <Pagination
                  className="bottom-pagination"
                  size="small"
                  pageSize={num_per_page}
                  current={page_idx}
                  total={total}
                  showLessItems
                  // showQuickJumper={true}
                  onChange={this.onPageChange}
                />
                {num_per_page * page_idx < total && (
                  <Button
                    className="lato-bold mb-load-more"
                    onClick={this.loadNextPage}
                  >
                    LOAD MORE
                  </Button>
                )}
              </div>
            </Spin>
          }
        </div>
        {this.genMbMoreFilterPanel()}
      </>
    );
  }

  private genQuickJobtypes(
    jobTypeOpts: { key: string; name: string }[],
    jobtype: string
  ) {
    return (
      <div className="quick-jobtypes">
        {jobTypeOpts.map((t) => (
          <CheckableTag
            className="lato-bold job-type"
            key={`job-type-o-${t.key}`}
            onChange={(e) => this.toggleJobTypes(t.key)}
            checked={jobtype === t.key}
          >
            {t.name + (t.name && t.name === "Network" ? "ing" : "")}
          </CheckableTag>
        ))}
      </div>
    );
  }

  private genMbMoreFilterPanel() {
    const { filters, loadingTag } = this.props;
    const { radius, category, locationInfo, jobtype, keyword } = filters;
    const { showMoreFilter, openMbMoreFilter } = this.state;

    const {
      category: categoryOpts,
      radius: radiusOpts,
      jobtype: jobTypeOpts,
    } = browse_opportunity_filter_options;

    return (
      <Modal
        visible={openMbMoreFilter}
        wrapClassName="mb-more-filter-panel"
        maskClosable={false}
        closable={false}
        footer={null}
        // style={{ top: 0, width: '100vw' }}
        bodyStyle={{ padding: 0 }}
      >
        <Icon
          type="close"
          className="close-btn"
          onClick={this.hideMbMoreFilter}
        />
        <div className="title">Filters</div>
        {/*<RadioGroup*/}
        {/*className="lato-bold keyword-location-switch"*/}
        {/*onChange={this.switchKeywordLocation}*/}
        {/*>*/}
        {/*<Radio.Button checked={!use_location} value="keyword">*/}
        {/*Keyword*/}
        {/*</Radio.Button>*/}
        {/*<Radio.Button checked={use_location} value="location">*/}
        {/*Location*/}
        {/*</Radio.Button>*/}
        {/*</RadioGroup>*/}
        {/*{use_location ? (*/}
        {/*<AddressTextField*/}
        {/*className="item keyword-input"*/}
        {/*label={`Location`}*/}
        {/*value={locationInfo.location}*/}
        {/*onInput={(value: string) => {*/}
        {/*doAction(MODULE_HOMEPAGE_OPPORTUNITY, {*/}
        {/*filters: {*/}
        {/*...filters,*/}
        {/*locationInfo: {*/}
        {/*...locationInfo,*/}
        {/*location: value,*/}
        {/*},*/}
        {/*},*/}
        {/*});*/}
        {/*}}*/}
        {/*onAddressSelect={async (address: any) => {*/}
        {/*const addressInfo = await GoogleUtil.onGoogleAddressSelected(*/}
        {/*address*/}
        {/*);*/}
        {/*if (addressInfo) {*/}
        {/*doAction(FetchOpportunitiesAction, {*/}
        {/*filters: {*/}
        {/*...filters,*/}
        {/*locationInfo: Object.assign({}, locationInfo, addressInfo),*/}
        {/*},*/}
        {/*});*/}
        {/*}*/}
        {/*}}*/}
        {/*autoCompleteService={GoogleUtil.googleAutoCompleteService}*/}
        {/*onPressEnter={this.pressEnterToSearchLocation}*/}
        {/*/>*/}
        {/*) : (*/}
        {/*<Search*/}
        {/*className="item keyword-input"*/}
        {/*placeholder="Business name or job title..."*/}
        {/*onSearch={this.onSearch}*/}
        {/*ref={(e) => {*/}
        {/*this.mb_keyword_input = e;*/}
        {/*if (this.mb_keyword_input) {*/}
        {/*this.mb_keyword_input.input.setState({ value: keyword });*/}
        {/*}*/}
        {/*}}*/}
        {/*/>*/}
        {/*)}*/}
        <SearchComponent
          value={{
            location: locationInfo.location,
            keywords: keyword,
          }}
          disabled={loadingTag === "ok"}
          autoCompleteService={GoogleUtil.googleAutoCompleteService}
          textChange={(keyword: string) => {
            doAction(MODULE_HOMEPAGE_OPPORTUNITY, {
              filters: {
                ...filters,
                keyword,
              },
            });
          }}
          onChange={(val: any) => {
            if (Object.keys(val)[0] === "keywords") {
              this.updateFilter(val["keywords"], "keywords");
            } else {
              doAction(MODULE_HOMEPAGE_OPPORTUNITY, {
                filters: {
                  ...filters,
                  locationInfo: {
                    ...locationInfo,
                    location: val.location,
                  },
                },
              });

              doAction(
                FetchOpportunitiesAction,
                {
                  filters: {
                    ...filters,
                    locationInfo: {
                      ...locationInfo,
                      location: val.location,
                      ...val.addressInfo,
                    },
                  },
                },
                "ok"
              );
            }
          }}
        />

        <div className="item location-radius">
          <Select
            defaultActiveFirstOption
            value={radius}
            onChange={(val: string) => this.updateFilter(val, "radius")}
          >
            {radiusOpts.map((o) => (
              <Option key={`radius-o-${o.key}`} value={o.key}>
                {o.name}
              </Option>
            ))}
          </Select>
          <span className="item-tag">RADIUS</span>
        </div>

        <div className="item categorys">
          <Select
            defaultActiveFirstOption
            value={category}
            onChange={(val: string) => this.updateFilter(val, "category")}
          >
            {categoryOpts.map((o) => (
              <Option key={`category-o-${o.key}`} value={o.key}>
                {o.name}
              </Option>
            ))}
          </Select>
          <span className="item-tag">CATEGORY</span>
        </div>
        {this.genQuickJobtypes(jobTypeOpts, jobtype!)}
        <Button
          className="lato-bold filter-btn"
          onClick={this.toggleMoreFilter}
        >
          <img src={ic_filter} alt="filter" />
          {showMoreFilter ? "LESS" : "MORE"} FILTERS
        </Button>
        <Button
          className="lato-bold filter-btn clear-btn"
          onClick={this.resetData}
        >
          <img src={ic_close} alt="filter" />
          CLEAR
        </Button>
        {showMoreFilter && this.genFourMoreFilters("mb-more-filters")}
        <Button className="lato-bold action-btn" onClick={this.appyFilter}>
          APPLY FILTER
        </Button>
      </Modal>
    );
  }

  private appyFilter = () => {
    this.setState({ openMbMoreFilter: false });
    if (this.props.filters.keyword) {
      this.onSearch(this.props.filters.keyword);
    }
  };

  private pressEnterToSearchLocation = () => {
    const {
      filters: { locationInfo },
    } = this.props;
    if (!locationInfo.location) {
      //when press enter, allow search when location cleared
      doAction(FetchOpportunitiesAction);
    }
  };

  private genFourMoreFilters(className: string) {
    const {
      filters: { paymentMode, software, brand },
    } = this.props;
    const {
      paymentMode: paymentOpts,
      software: softwareOpts,
      brand: brandOpts,
    } = browse_opportunity_filter_options;
    const renderList = [
      {
        val: paymentMode,
        key: "paymentMode",
        opt: paymentOpts,
        lbl: "PAYMENT PREFERENCE",
      },
      {
        val: software,
        key: "software",
        opt: softwareOpts,
        lbl: "SOFTWARE USED",
      },
      {
        val: brand,
        key: "brand",
        opt: brandOpts,
        lbl: "BRANDS USED",
      },
    ];
    return (
      <div className={className}>
        {renderList.map((r, idx) => {
          return (
            <div className="item" key={`more-filter-item-${idx}`}>
              <Select
                defaultActiveFirstOption
                value={r.val}
                onChange={(val: string) => this.updateFilter(val, r.key)}
              >
                {r.opt.map((o) => (
                  <Option key={`filter-dropdown-o-${o.key}`} value={o.key}>
                    {o.name}
                  </Option>
                ))}
              </Select>
              <span className="item-tag">{r.lbl}</span>
            </div>
          );
        })}
      </div>
    );
  }

  componentDidMount() {
    let { coordinates, name } = JSON.parse(getQueryString("location")!) || {
      coordinates: [],
      name: "",
    };
    let radius = getQueryString("radius") || "radius-50";
    // console.log('radius',radius,coordinates,name)
    // if(name){
    //   console.log(111)
    //   doAction(MODULE_HOMEPAGE_OPPORTUNITY, {
    //     filters: {
    //       ...this.props.filters,
    //       locationInfo: {
    //         ...this.props.filters.locationInfo,
    //         location:name,
    //         coordinates
    //       },
    //       radius:getQueryString('radius')
    //     },
    //   },'ok');
    // }
    doAction(
      FetchOpportunitiesAction,
      {
        filters: {
          ...this.props.filters,
          locationInfo: {
            ...this.props.filters.locationInfo,
            location: name,
            coordinates,
          },
          radius,
        },
      },
      "ok"
    );
    this.props.history.replace("/homepage-opportunity");
    // if (!this.props.list.length) {
    //   doAction(FetchOpportunitiesAction);
    // }
  }

  private resetData = () => {
    doAction(FetchOpportunitiesAction, module_homepage_opportunity, "ok");
  };

  private updateFilter(val: any, key: string) {
    const { filters } = this.props;
    doAction(
      FetchOpportunitiesAction,
      {
        filters: { ...filters, [key]: val },
        page_idx: 1,
      },
      "ok"
    );
  }

  private onSearch = (value: string) => {
    this.updateFilter(value, "keyword");
  };

  private quickJobtypesToRight = (e: any) => {
    const quickJobtypesCon = document.querySelector(".quick-jobtypes");
    if (quickJobtypesCon) {
      let left = quickJobtypesCon.scrollLeft;
      left += 50;
      quickJobtypesCon.scroll({ left });
    }
  };
  private switchKeywordLocation = (e: RadioChangeEvent) => {
    doAction(MODULE_HOMEPAGE_OPPORTUNITY, {
      use_location: e.target.value === "location",
    });
  };

  private toggleJobTypes = (jobtype: string) => {
    this.updateFilter(jobtype, "jobtype");
  };

  private toggleMoreFilter = (e: any) => {
    this.setState({ showMoreFilter: !this.state.showMoreFilter });
  };

  // show mobile more filter pannel
  private showMbMoreFilter = (e: any) => {
    this.setState({ openMbMoreFilter: true });
  };
  private hideMbMoreFilter = (e: any) => {
    this.setState({ openMbMoreFilter: false });
  };

  private onPageChange = (page_idx?: number) => {
    doAction(FetchOpportunitiesAction, { page_idx }, "ok");
  };

  private loadNextPage = () => {
    const { page_idx, total, num_per_page } = this.props;
    if (page_idx * num_per_page < total) {
      doAction(
        FetchOpportunitiesAction,
        {
          page_idx: page_idx + 1,
          isAppend: true,
        },
        "ok"
      );
    }
  };
}

export function getItemTags(item: OpportunityItem) {
  const tags: { label: string; style: React.CSSProperties }[] = [];
  tags.push({
    label: item.category.toUpperCase(),
    style: {
      backgroundColor: "#F4F5F5",
      color: "#2D2E3D",
    },
  });
  if (item.is_unread) {
    tags.push({
      label: "NEW",
      style: {
        backgroundColor: "transparent",
        color: "#5A8E09",
        border: "1px solid #e0ecce",
        borderRadius: "2px",
      },
    });
  }

  return tags;
}

export function getItemAdditions(item: OpportunityItem) {
  const adds = [
    {
      ico: item.biz_ico ? (
        <img src={item.biz_ico} alt="biz-ico" />
      ) : (
          <span className="name-ico">{item.biz_name.charAt(0)}</span>
        ),
      txt: item.biz_name,
    },
    {
      ico: <img src={ic_location} alt="loc-ico" />,
      txt: item.location,
    },
  ];

  return adds;
}

export function getQueryString(name: string) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);

  if (r != null) {
    console.log(decodeURI(r[2]));
    return decodeURI(r[2]);
  }
  return null;
}
