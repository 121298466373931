import React, { useState, useEffect } from "react";
import { Modal, Button, Tooltip, message } from "antd";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useDebounce from "../../../hooks/useDebounce";
import { searchSchoolStudent } from "../../../api/School";
import _ from "lodash";
import IconSearch from "../../../assets/images/ic_search.svg";
import Close from "../../..//assets/images/ic_close.svg";
import "./add_member_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  title?: string;
  onClose: Function;
  onSend: Function;
  channel?: any;
}

const AddMemberModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onSend, title, channel } = props;
  const [searchUserList, setSearchUserList] = useState([] as any[]);
  const [selectedUsers, setSelectedUsers] = useState();
  const [members, setMembers] = useState([] as any[]);
  const [keyword, setKeywords] = useState("");
  const [canvasId, setCanvasId] = useState(0);

  useEffect(() => {
    if (channel && channel.state && channel.state.members) {
      const users = _.map(channel.state.members.asMutable(), (item) => {
        return item.user && item.user.id;
      });
      setMembers(users);
    } else {
      setMembers([]);
    }
    if (channel && channel.data && channel.data.canvasId) {
      setCanvasId(channel.data.canvasId);
    }
  }, [channel]);

  useDebounce(
    () => {
      if (keyword) {
        searchUserByWords(keyword);
      } else {
        setSearchUserList([]);
      }
    },
    500,
    [keyword]
  );

  const searchUserByWords = async (keyword: string) => {
    if (!canvasId) return;
    const params = {
      canvas_id: canvasId,
      keyword: keyword,
      page: 1,
      size: 10,
    };
    searchSchoolStudent(params).then((res: any) => {
      if (res && !_.isEmpty(res.list)) {
        setSearchUserList(res.list);
      } else {
        setSearchUserList([]);
      }
    });
  };

  const checkExistAndAdd = () => {
    const exist = [] as any[];
    _.forEach(selectedUsers, (selected) => {
      const has = _.find(members, (item) => {
        return item === `${(selected as any).userId}`;
      });
      if (has) {
        exist.push(selected);
      }
    });
    if (_.isEmpty(exist)) {
      onSend(selectedUsers);
    } else {
      message.error(
        `${_.map(exist, (item) => {
          return `${item.firstName} ${item.lastName}`;
        }).toString()} ${exist.length > 1 ? "are" : "is"} already in the group`
      );
    }
  };

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="add-member-modal-style"
    >
      <div className="add-member-modal">
        <div className="title-wrap">
          <img className="close" src={Close} alt="" onClick={() => onClose()} />
          <Tooltip title={title || "Channel"}>
            <span className="title">Add to {title || "Channel"}</span>
          </Tooltip>
        </div>
        <div className="input-wrap">
          <img alt="" src={IconSearch} />
          <Autocomplete
            multiple
            className="tag-input input"
            options={searchUserList}
            value={selectedUsers}
            open
            classes={{
              option: "option-item-wrap",
            }}
            filterOptions={(options: any[], state: any) => {
              const filtered = _.filter(options, (item) => {
                return item.firstName && item.lastName;
              });
              return filtered;
            }}
            renderOption={(option) => {
              let char = option.firstName[0].toUpperCase();
              const has = members.indexOf(`${option.userId}`) > -1;
              return (
                <div className="option-item">
                  <span className="avatar">{char}</span>
                  <div className="name-wrap">
                    <span className="name">{`${option.firstName} ${option.lastName}`}</span>
                    <span className="exist" hidden={!has}>
                      Already in this group
                    </span>
                  </div>
                </div>
              );
            }}
            getOptionSelected={(option, value) => option.userId === value.id}
            getOptionLabel={(option) => {
              return `${option.firstName || ""} ${option.lastName || ""}`;
            }}
            onChange={(event: object, value: any, reason: string) => {
              setSelectedUsers(value);
            }}
            onInputChange={(event: object, value: string, reason: string) => {
              setKeywords(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search member by name..."
                variant="filled"
                className="material-input"
              />
            )}
          />
        </div>
        <Button
          className="btn-primary btn-continue"
          onClick={() => checkExistAndAdd()}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};
export default AddMemberModal;
