import React, { FC, useRef, useEffect } from 'react';
import Chart from 'chart.js';

interface Props {
  data: any;
  labels?: string;
  header?: string;
  chartColors?: {
    borderColor: string;
    backgroundColor: string;
    pointBackgroundColor: string;
    pointBorderColor: string;
    pointHoverBackgroundColor: string;
    pointHoverBorderColor: string;
  };
}

const ChartComponent: FC<Props> = (props) => {
  const { data, header, chartColors } = props;

  const labels = data ? data.map((item) => item.x): null;
  const counts = data ? data.map((item) => item.y): null;

  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: header,
                data: counts,
                borderColor: chartColors?.borderColor || 'rgba(75, 192, 192, 1)',
                backgroundColor: chartColors?.backgroundColor || 'rgba(75, 192, 192, 0.2)',
                pointBackgroundColor: chartColors?.pointBackgroundColor || 'rgba(75, 192, 192, 1)',
                pointBorderColor: chartColors?.pointBorderColor || '#fff',
                pointHoverBackgroundColor: chartColors?.pointHoverBackgroundColor || '#fff',
                pointHoverBorderColor: chartColors?.pointHoverBorderColor || 'rgba(75, 192, 192, 1)',
              },
            ],
          },
          options: {
            scales: {
              //@ts-ignore
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  displayFormats: {
                    day: 'MM-DD',
                  },
                },
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Count',
                },
              },
            },
          },
        });
      }
    }
  }, [data, labels, chartColors]);

  if (!data) {
    return null;
  }

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default ChartComponent;
