import React, { useEffect, useState } from "react";
import * as FeaturedApi from "../../../api/Featured";
import "./featured.less";

interface Props {
  data: {
    id: string;
    name: string;
    location: string;
    media?: any;
    type: any;
    url: string;
    image?: string;
  };
  visualization: string;
  buttons: boolean;
}

const ListItem: React.FC<Props> = (props) => {
  const { name, location, type, url, image } = props.data;
  const { visualization } = props;
  const { buttons } = props;

  console.log('vis:',visualization)

  const openPortfolio = () => {
    window.location.href = url;
  };
  const displayName = visualization === "port" ? name : name.toUpperCase();
  const actionLabel =
    visualization === "port" ? "Launch Portfolio" : "View Opportunity";

  function editFeatured(e) {
    e.preventDefault();
    console.log("This Feature Does Not Exist Yet");
  }

  async function deleteFeatured(e) {
    e.preventDefault();
    await FeaturedApi.deleteFeatured(props.data.id);
  }

  return (
    <>
      <div
        className="cm-sp-canvas-tile cm-flex-column"
        onClick={() => openPortfolio()}
      >
        <div className="cm-flex-1">
          <div
            className="cm-sp-canvas-tile-img"
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>
        <div className="cm-sp-canvas-tile__footer">
          <h4>{displayName}</h4>
          <h5>{location}</h5>
        </div>
        <div className="cm-sp-canvas-tile-overlay">{actionLabel}</div>
        {visualization === "port" && (
          <div className="cm-sp-canvas-tile-type">{type}</div>
        )}
        {visualization === "opportunity" && (
          <div className="cm-sp-canvas-tile-type--opportunity">{type}</div>
        )}
        {buttons && (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                editFeatured(e);
              }}
            >
              Edit
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                deleteFeatured(e);
              }}
            >
              Delete
            </button>
          </>
        )}
      </div>
    </>
  );
};

const AdminFeatured: React.FC = () => {
  const [Type, setType] = useState("opp");
  const [Slug, setSlug] = useState("");
  const [Info, setInfo] = useState(null);
  const [Featured, setFeatured] = useState(null);
  const [Ports, setPorts] = useState(null);
  const [Opps, setOpps] = useState(null);
  const [Msg, setMsg] = useState("");
  const [Updated, setUpdated] = useState(1);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await FeaturedApi.getFeatured();
        setFeatured(response);
      } catch (error) {
        console.error(error);
      }
    }
    if (Updated === 1) {
      fetchData();
      setUpdated(0);
    }
  }, [Updated]);

  useEffect(() => {
    console.log("featured:", Featured);
    if (Featured) {
      const updatedPorts = Featured.filter(
        (featured: any) => featured.canvasId !== null
      );
      const updatedOpps = Featured.filter(
        (featured: any) => featured.oppId !== null
      );
      setPorts(updatedPorts);
      setOpps(updatedOpps);
    }
  }, [Featured]);

  async function handlePreviewSubmit(e: { preventDefault: () => void }) {
    e.preventDefault();
    console.log("Submitted:", Slug, Type);
    const info = await FeaturedApi.info(Type, Slug);
    console.log(info);
    if (info[0].id) {
      setInfo(info[0]);
      setMsg("");
    } else {
      setMsg("Not Found, Search again...");
    }
  }

  const PreviewItem = () => {
    const [SelectedImage, setSelectedImage] = useState(null);
    const media = JSON.parse(Info.media);
    const type = Array.isArray(Info.type) ? Info.type.join(" ") : Info.type;
    const data = {
      id: Info.id,
      location: Info.location,
      name: Info.name,
      url: Info.url,
      type,
      image: SelectedImage,
    };

    console.log("data:", data);

    const dropdownOptions = Object.keys(media).map((key) => (
      <option key={key} value={key}>
        {key}
      </option>
    ));

    const handleDropdownChange = (event: { target: { value: any } }) => {
      const selectedKey = event.target.value;
      setSelectedImage(media[selectedKey].image);
    };

    async function addFeatured(e: { preventDefault: () => void }) {
      e.preventDefault();
      const id = Type === "port" ? { canvasId: Info.id } : { oppId: Info.id };
      console.log("Submit Data:", { ...data, ...id });
      const { id: removeId, ...dataWithoutId } = data;
      const response = await FeaturedApi.createFeatured({
        ...dataWithoutId,
        ...id,
      }).then(() => {
        setUpdated(1);
        setInfo(null);
        setSlug("");
      });
      return response;
    }

    return (
      <div className="cm-preview-item">
        <div>
          <ListItem visualization={Type} data={data} buttons={false} />
        </div>
        <div>
          <select onChange={handleDropdownChange}>
            <option value="">Select an image</option>
            {dropdownOptions}
          </select>
          <div>
            <button className="cm-add-featured-btn" onClick={addFeatured}>
              Add Featured {Type === "port" ? "Portfolio" : "Opportunity"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="admin-featured">
      <div className="get-info">
        <h2>Lookup Info and Add New Featured Item</h2>
        {Msg && <h3>{Msg}</h3>}
        <form onSubmit={handlePreviewSubmit}>
          <label>
            Type:
            <select value={Type} onChange={(e) => setType(e.target.value)}>
              <option value="opp">Opportunity</option>
              <option value="port">Portfolio</option>
            </select>
          </label>
          <br />
          <label>
            Slug:
            <input
              type="text"
              value={Slug}
              onChange={(e) => setSlug(e.target.value)}
            />
          </label>
          <button type="submit">Lookup Data</button>
          {Info && (
            <>
              <PreviewItem />
            </>
          )}
        </form>
      </div>
      <hr />
      <div>
        <h1>Featured Portfolios</h1>
        <div className="cm-featured-tiles">
          {Ports ? (
            Ports.map((port: any) => (
              <ListItem visualization="port" data={port} buttons={true} />
            ))
          ) : (
            <h2>None Set</h2>
          )}
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div>
        <h1>Featured Opportunities</h1>
        <div className="cm-featured-tiles">
          {Opps ? (
            Opps.map((opp: any) => (
              <ListItem visualization="opportunity" data={opp} buttons={true} />
            ))
          ) : (
            <h2>None Set</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminFeatured;
