import React from "react";
import { Dropdown } from "antd";
import { TextField } from "@material-ui/core";
import IconArrow from "../assets/images/ic_arrow_down.svg";
import classnames from "classnames";
import "./component_location_detail.less";

interface Props {
  className?: string;
  location: any;
  index?: number;
  onValueChange: Function;
  autoCompleteService: any;
  onAddressSelect: Function;
}

const LocationDetail: React.FC<Props> = (props) => {
  const { className = "", location, index = 0, onValueChange } = props;

  const menuArea = (
    <div className="menu-area">
      <div
        className="square-item"
        onClick={() => {
          const newLocation = { ...location, areaUnit: "ft" };
          onValueChange(newLocation);
        }}
      >
        ft²
      </div>
      <div
        className="square-item"
        onClick={() => {
          const newLocation = { ...location, areaUnit: "m" };
          onValueChange(newLocation);
        }}
      >
        m²
      </div>
    </div>
  );

  return (
    <div className={classnames(className, "location-detail-root")}>
      <div className="divider" />
      <span className="location-title">{`Location ${
        index > 0 ? index : ""
      } Information`}</span>

      <TextField
        key="employee"
        label="Number of employees"
        variant="filled"
        className="material-input input"
        type="number"
        value={location.employeeNumber || ""}
        onChange={(e) => {
          const value = e.target.value;
          if (Number(value) < 0) return;
          const newLocation = { ...location, employeeNumber: e.target.value };
          onValueChange(newLocation);
        }}
        onKeyDown={(e) =>
          (e.keyCode === 69 ||
            e.keyCode === 187 ||
            e.keyCode === 189 ||
            e.keyCode === 190) &&
          e.preventDefault()
        }
      />
      <TextField
        key="chairs"
        label="Number of chairs"
        variant="filled"
        className="material-input input"
        type="number"
        value={location.chairs || ""}
        onChange={(e) => {
          const value = e.target.value;
          if (Number(value) < 0) return;
          const newLocation = { ...location, chairs: e.target.value };
          onValueChange(newLocation);
        }}
        onKeyDown={(e) =>
          (e.keyCode === 69 ||
            e.keyCode === 187 ||
            e.keyCode === 189 ||
            e.keyCode === 190) &&
          e.preventDefault()
        }
      />
      <div className="footage-wrap">
        <TextField
          key="footage"
          label={location.areaUnit === "m" ? "Meter square" : "Square footage"}
          variant="filled"
          className="material-input footage-input"
          type="number"
          value={location.area || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value) < 0) return;
            const newLocation = { ...location, area: e.target.value };
            onValueChange(newLocation);
          }}
          onKeyDown={(e) =>
            (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) &&
            e.preventDefault()
          }
        />
        <Dropdown overlay={menuArea} trigger={["click"]}>
          <span className="unit-btn">
            {location.areaUnit === "m" ? "m²" : "ft²"}
            <img src={IconArrow} alt="" />
          </span>
        </Dropdown>
      </div>
    </div>
  );
};

export default LocationDetail;
