import React, { useEffect } from "react";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "antd";
import ListItemText from "@material-ui/core/ListItemText";
import BtnStatusIcon from "./component_btn_status_icon";
import IconDropdown from "../assets/images/ic_btn_dropdown.svg";
import _ from "lodash";
import "./component_status_dropdown.less";

interface DropdownMenuItem {
  option: string;
  key: string;
  btnColor: string;
  btnBgColor: string;
  statusBgColor: string;
  statusBorderColor: string;
}

interface Props {
  otherInfo: any;
  defaultOption: string;
  dropdownMenu: Array<DropdownMenuItem>;
  output: Function;
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

// const colorEnum: any = {
//   0: {
//     color: "#FFFFFF",
//     backgroundColor: "#83B23A",
//   },
//   1: {
//     color: "rgba(45, 46, 61, 0.5)",
//     backgroundColor: "#F1DD23",
//   },
//   2: {
//     color: "#96979E",
//     backgroundColor: "#EAEAEC",
//   },
// };

export const StatusDropdown: React.FC<Props> = (props) => {
  const { otherInfo, defaultOption, dropdownMenu, output } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = React.useState<any>({
    btnText: "Button",
    style: {},
  });
  useEffect(() => {
    if (defaultOption) {
      selectOption(_.find(dropdownMenu, { key: defaultOption }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption]);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedItem: any) => {
    selectOption(selectedItem);
    if (selectedItem.key) {
      output({ ...selectedItem, otherInfo });
    }
    handleClose();
  };

  const selectOption = (selectedItem: any) => {
    setSelectedOption({
      btnText: selectedItem.option,
      style: {
        color: selectedItem.btnColor,
        backgroundColor: selectedItem.btnBgColor,
      },
    });
  };

  return (
    <div className="status-dropdown-root">
      <Button
        className="btn-secondary status-btn"
        onClick={(event) => toggleMenu(event)}
        style={selectedOption.style}
      >
        <div className="btn-text">{selectedOption.btnText}</div>
        <img src={IconDropdown} className="dropdown-icon" alt="" />
      </Button>

      <StyledMenu
        id="status-dropdopwn-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dropdownMenu.map((item: any, index) => {
          const { statusBgColor, statusBorderColor } = item;
          return (
            <MenuItem
              className="menu-item"
              key={index}
              onClick={() => handleSelect(item)}
            >
              <BtnStatusIcon
                iconStyle={{
                  color: statusBgColor,
                  borderColor: statusBorderColor,
                }}
              ></BtnStatusIcon>
              <ListItemText className="option-text" primary={item.option} />
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};
