import React, { useState, useCallback } from "react";
import { Modal, Button } from "antd";
import _ from "lodash";
import { TextField, MenuItem } from "@material-ui/core";
import Close from "../assets/images/ic_close.svg";
import "./component_edit_category_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  onAdd: Function;
  data?: any;
  categoryList?: any[];
  stateIssueList?: any[];
}

const EditCategoryModal: React.FC<Props> = (props) => {
  const {
    className,
    visible,
    onClose,
    onAdd,
    data,
    categoryList = [],
    stateIssueList = [],
  } = props;
  const [name, setName] = useState(data.name || "");
  const [otherName, setOtherName] = useState(data.otherName || "");
  const [id, setId] = useState(data.id || "");
  const [licenseNumber, setLicenseNumber] = useState(data.licenseNumber || "");
  const [stateId, setStateId] = useState(data.stateId || "");
  const [showTitleError, setShowTitleError] = useState(false);
  const [showOtherError, setShowOtherError] = useState(false);

  const getStateName = useCallback(() => {
    if (!stateId) {
      return "";
    }
    const state = _.find(stateIssueList, { id: stateId });
    if (state && state.name) {
      return state.name;
    }
    return "";
  }, [stateId, stateIssueList]);

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="edit-category-modal-wrap"
    >
      <div className="edit-category-modal">
        <img className="close" src={Close} alt="" onClick={() => onClose()} />
        <span className="title">Add category</span>
        <TextField
          key="category"
          label="Category"
          helperText={showTitleError ? "Category is required" : ""}
          error={showTitleError}
          variant="filled"
          select
          className="material-input input"
          value={name}
          onChange={(e) => {
            const item = _.find(categoryList, { name: e.target.value });
            if (name !== item.name) {
              setId(item.id);
              setName(item.name);
              setLicenseNumber("");
              setOtherName("");
            }
            setShowTitleError(false);
            setShowOtherError(false);
          }}
        >
          {categoryList.map((item: any, index: number) => (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        {name === "Other" && (
          <TextField
            key="categoryName"
            label="Category name"
            helperText={showOtherError ? "Category Name is required" : ""}
            error={showOtherError}
            variant="filled"
            className="material-input input"
            value={otherName || ""}
            onChange={(e) => {
              setShowOtherError(false);
              setOtherName(e.target.value);
            }}
          />
        )}

        <TextField
          key="licenseNumber"
          label="License number"
          variant="filled"
          className="material-input input"
          value={licenseNumber || ""}
          onChange={(e) => {
            setLicenseNumber(e.target.value);
          }}
        />
        <TextField
          key="stateIssue"
          label="State issue"
          variant="filled"
          select
          className="material-input input"
          placeholder="State issue"
          value={getStateName()}
          onChange={(e) => {
            setStateId(_.find(stateIssueList, { name: e.target.value }).id);
          }}
        >
          {stateIssueList.map((item: any, index: number) => (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          className="btn-primary btn-add"
          onClick={() => {
            if (name === "Other" && !otherName) {
              setShowOtherError(true);
            } else if (!name) {
              setShowTitleError(true);
            } else {
              const newItem = {
                id: id,
                name: name,
                licenseNumber: licenseNumber,
                stateId: stateId || 0,
                stateName: getStateName(),
                otherName: otherName || "",
              };
              onAdd(newItem);
            }
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
export default EditCategoryModal;
