import React from "react";
import { Layout, Button } from "antd";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import _ from 'lodash';
import { mapProp } from "module-reaction";
import {
  module_post_job,
  IPostJobOpportunity
} from '../../models/model_post_job';
import { CanvasType } from '../../utils/enum';
import CValidateForm, { ValidateForm } from '../../components/component_validate_form';
import "./post_job.less";
const { Content } = Layout;

@mapProp(module_post_job)
export default class PostJob extends React.Component<IPostJobOpportunity, {}> {
  postJobFormRef: React.RefObject<ValidateForm>;

  constructor(props: any) {
    super(props)
    this.postJobFormRef = React.createRef();
  }

  componentDidMount() {
    window.onresize = () => {
      this.setState({ isMobile: window.innerWidth < 1024 })
    }
  }

  componentWillUnmount() {
    window.onresize = () => { }
  }

  state = {
    isMobile: window.innerWidth <= 1024,
  };

  postSubmit = () => {
    this.postJobFormRef.current!.handleSubmit().then((res: any) => {
      console.log(res)
    }).catch((err: any) => {
    })
  }
  save = () => {
    const values = this.postJobFormRef.current!.getFieldsValue()
    console.log(values)
  }

  render() {
    const { isMobile } = this.state
    const { postJobFormElement, postJobValue } = this.props
    const formElement = postJobFormElement(isMobile)
    formElement[_.findIndex(formElement, { name: 'category' })].onChange = (data: any, value: any) => {
      console.log(data, value)
    }

    return (
      <Layout>
        <CreateBusinessCanvasHeader type={CanvasType.BUSINESS} step={3} />
        <Content>
          <div className="post-job-wrap">
            <div className="common-title">Post Job Opportunity</div>
            <CValidateForm
              wrappedComponentRef={this.postJobFormRef}
              formElement={formElement}
              defaultValue={postJobValue}
            />
            <div className="btn-wrap">
              <div className="submit">
                <Button
                  className="btn-primary"
                  onClick={this.postSubmit}
                >Post Job</Button>
                <Button
                  className="btn-secondary"
                  onClick={this.save}
                >Save as Private</Button>
              </div>
              <Button
                type="link"
              >Cancel</Button>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}
