import React from "react";
import { Modal, Button } from "antd";
import Close from "../assets/images/ic_close.svg";
import "./component_confirm_modal.less";

interface Props {
  visible?: boolean;
  onClose: Function;
  onConfirm: Function;
  title?: string;
  description?: string;
  confirmText?: string;
}

const ConfirmModal: React.FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onConfirm,
    title,
    description,
    confirmText,
  } = props;

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      centered={true}
      wrapClassName="confirm-modal-wrap"
    >
      <div className="confirm-modal-root">
        <img className="close" src={Close} alt="" onClick={() => onClose()} />
        <span className="title">{title || "Are you sure?"}</span>
        <span className="desc">{description || ""}</span>
        <div className="btn-sec">
          <Button
            className="btn-primary btn-confirm"
            onClick={() => onConfirm()}
          >
            {confirmText || "OK"}
          </Button>
          <Button
            className="btn-secondary btn-cancel"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmModal;
