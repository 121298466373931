import { ModuleStore, ModuleAction, KV } from "module-reaction";
import _ from "lodash";
import { getCanvas, updateCanvasStatus } from "../api/Canvas";
import { IMediaItem } from "../components/component_media_item";
import { MediaType, CanvasType } from "../utils/enum";
import { IAward } from "../components/component_award_item";
import { ISocialMedia } from "../components/component_social_media_item";
export const MODULE_BUSINESS_CANVAS_PREVIEW = "module_business_canvas_preview";

const tempSocial = [
  {
    type: 0,
    label: "instagram",
  },
  {
    type: 1,
    label: "youtube",
  },
  {
    type: 1,
    label: "facebook",
  },
  {
    type: 0,
    label: "tiktok",
  },
];

export interface ModuleBusinessCanvasPreview extends ModuleStore {
  id: number;
  type: string;
  slug: string;
  owner: number;
  awardList: IAward[];
  socialMediaList: ISocialMedia[];
  companyName: string;
  headline: string;
  desc: string;
  whyJoin: string;
  defaultLocation: any;
  contactNumber: string;
  category: any[];
  additional: any;
  extraLocations: any[];
  logo: IMediaItem; // type, link
  featuredPhoto: IMediaItem;
  teamPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  extraVideo: IMediaItem[];
  emptyFields: string[];
  payorder: any;
}

export const module_business_canvas_preview: ModuleBusinessCanvasPreview = {
  module: MODULE_BUSINESS_CANVAS_PREVIEW,
  id: 0,
  slug: "",
  owner: 0,
  type: CanvasType.BUSINESS,
  awardList: [],
  socialMediaList: tempSocial,
  companyName: "",
  headline: "",
  desc: "",
  whyJoin: "",
  defaultLocation: {},
  contactNumber: "",
  category: [],
  additional: {
    retailBrands: [],
    softwares: [],
    backbarBrands: [],
    specialties: [],
    companyBenefits: [],
    paymentMode: {},
  },
  extraLocations: [],
  logo: {
    image: "",
    title: "Company Logo",
    dimensions: "100x60",
    fileSize: 1,
    url: "",
    type: MediaType.LOGO,
  },
  featuredPhoto: {
    image: "",
    title: "Featured Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.FEATURED_PHOTO,
  },
  teamPhoto: {
    image: "",
    title: "Team Photo",
    dimensions: "1240x800",
    fileSize: 5,
    url: "",
    type: MediaType.TEAM,
  },
  featuredVideo: {
    image: "",
    title: "Featured Video",
    dimensions: "1240x800",
    fileSize: 10,
    url: "",
    type: MediaType.FEATURED_VIDEO,
  },
  extraVideo: [] as IMediaItem[],
  emptyFields: [] as string[],
  payorder: {},
};

export const UpdatePageInfoAction: ModuleAction<
  any,
  ModuleBusinessCanvasPreview
> = {
  module: MODULE_BUSINESS_CANVAS_PREVIEW,
  process: async (options: KV, module: ModuleBusinessCanvasPreview) => {
    return { ...options };
  },
};

export const GetPageDataAction: ModuleAction<
  any,
  ModuleBusinessCanvasPreview
> = {
  module: MODULE_BUSINESS_CANVAS_PREVIEW,
  process: async (slug: string, module: ModuleBusinessCanvasPreview) => {
    if (!slug) {
      return {};
    }
    const res = await getCanvas(slug);
    if (res) {
      const mappedLocations = _.map(res.detail.locations, (item) => {
        return {
          uuid: item.uuid,
          location: item.address,
          area: item.area,
          areaUnit: item.areaUnit,
          chairs: item.chairAmount,
          city: item.city,
          coordinates: item.coordinates,
          country: item.country,
          countryShort: item.countryShort,
          employeeNumber: item.employeeAmount,
          state: item.state,
          stateShort: item.stateShort,
          members: _.map(item.members, (item) => {
            const newItem = { ...item };
            delete newItem.status;
            return newItem;
          }),
          isAccredited: item.isAccredited,
          nightStudents: item.nightStudents,
          supportedProgram: _.map(item.supportedProgram, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }),
          classType: item.classType,
          dayStudents: item.dayStudents,
        };
      });

      const defaultLoc = _.first(mappedLocations) || {};
      const extraLoc =
        _.slice(mappedLocations, 1, mappedLocations.length) || [];

      let social = [...tempSocial];
      social = _.map(social, (socialItem) => {
        if (_.get(res.socialMedia, socialItem.label)) {
          return {
            ...socialItem,
            value: _.get(res.socialMedia, socialItem.label),
          };
        } else {
          return socialItem;
        }
      });

      let pageData = {
        id: res.id,
        slug: res.slug,
        type: res.type,
        owner: res.owner || 0,
        awardList: res.achievements,
        additional: {
          backbarBrands: _.map(
            res.additionalInformation.backbarBrands,
            (item) => {
              return { id: item.optionId, name: item.name };
            }
          ),
          companyBenefits: _.map(
            res.additionalInformation.companyBenefits,
            (item) => {
              return { id: item.optionId, name: item.name };
            }
          ),
          paymentMode: res.additionalInformation.paymentMode,
          retailBrands: _.map(
            res.additionalInformation.retailBrands,
            (item) => {
              return { id: item.optionId, name: item.name };
            }
          ),
          softwares: _.map(res.additionalInformation.softwares, (item) => {
            return { id: item.optionId, name: item.name };
          }),
          specialties: _.map(res.additionalInformation.specialities, (item) => {
            return { id: item.optionId, name: item.name };
          }),
        },
        contactNumber: res.detail.primaryContactNumber || "",
        category: res.detail.categories,
        defaultLocation: defaultLoc,
        extraLocations: extraLoc,
        companyName: res.introduction.name,
        headline: res.introduction.headline,
        desc: res.introduction.description,
        whyJoin: res.introduction.reason,
        logo: res.media.logo || {
          image: "",
          title: "Company Logo",
          dimensions: "100x60",
          fileSize: 1,
          url: "",
          type: MediaType.LOGO,
        },
        featuredPhoto: res.media.featuredPhoto || {
          image: "",
          title: "Featured Photo",
          dimensions: "1440x758",
          fileSize: 5,
          url: "",
          type: MediaType.FEATURED_PHOTO,
        },
        teamPhoto: res.media.teamPhoto || {
          image: "",
          title: "Team Photo",
          dimensions: "1240x800",
          fileSize: 5,
          url: "",
          type: MediaType.TEAM,
        },
        featuredVideo: res.media.featuredVideo || {
          image: "",
          title: "Featured Video",
          dimensions: "1240x800",
          fileSize: 10,
          url: "",
          type: MediaType.FEATURED_VIDEO,
        },
        extraVideo: (res.media && res.media.additionalVideo) || [],
        socialMediaList: social,
        payorder: res.payorder,
      };
      return pageData;
    }

    return {};
  },
};

export const SubmitToPublishAction: ModuleAction<
  any,
  ModuleBusinessCanvasPreview
> = {
  module: MODULE_BUSINESS_CANVAS_PREVIEW,
  process: async (params: any, module: ModuleBusinessCanvasPreview) => {
    const { fromPost, history } = params;
    if (!module.id) {
      return {};
    }
    const emptyFields = [] as string[];
    if (!module.companyName) {
      emptyFields.push(
        module.type === CanvasType.BUSINESS ? "Company name" : "School name"
      );
    }
    if (!module.contactNumber) {
      emptyFields.push("Primary Contact Number");
    }
    let addressHasEmpty = false;
    _.forEach(module.extraLocations, (loc) => {
      if (!loc.location) {
        addressHasEmpty = true;
      }
    });
    if (!module.defaultLocation.location || addressHasEmpty) {
      emptyFields.push("Location");
    }
    if (!_.isEmpty(emptyFields)) {
      return { emptyFields: emptyFields };
    } else {
      if (module.payorder && module.payorder.status === "succeeded") {
        const publish = await updateCanvasStatus(module.id);
        if (publish && publish.success) {
          history.push("/my-canvases");
        }
      } else {
        history.push(
          (module.type === CanvasType.BUSINESS
            ? "/business-payment/"
            : "/school-payment/") +
            module.slug +
            `${fromPost ? "?isPostOpportunity=true" : ""}`
        );
      }
    }
    return {};
  },
};
