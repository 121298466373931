import React, { useState, useEffect } from "react";
import { Modal, Progress } from "antd";
import "./component_loading_modal.less";
import { RootState } from '../utils/store.js';
import { useSelector } from 'react-redux';
import store from '../utils/store';

interface Props {
  className?: string;
  visible?: boolean;
  isVideoLoading?: boolean;
}

const LoadingModal: React.FC<Props> = ({ className, visible, isVideoLoading }) => {
  const percent = useSelector((state: RootState) => state.upload.progress);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsShowModal(true);
    } else {
      setTimeout(() => {
        setIsShowModal(false);
        store.dispatch({
          type: 'SET_UPLOAD_PROGRESS',
          payload: 0
        });
      }, 500);
    }
  }, [visible, percent]);

  return (
    <Modal
      footer={null}
      visible={isShowModal}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="loading-modal-style"
    >
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Progress
          type="circle"
          percent={percent}
          width={80}
          style={{
            fontSize: "48px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
          strokeColor="#2d2e3d"
        />
      </div>
    </Modal>
  );
};

export default LoadingModal;
