import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Layout, Button, Divider, message } from "antd";
import queryString from "query-string";
import { doAction, doFunction } from "module-reaction";
import { User, LogOutAction } from "../../../models/model_user";
import CRHeader from "../../../components/component_cr_header";
import IconSuccess from "../../../assets/images/ic_success_check.svg";
import IconCopy from "../../../assets/images/ic_copy_link.svg";
import IconFacebook from "../../../assets/images/ic_facebook.svg";
import IconTwitter from "../../../assets/images/ic_twitter.svg";
import { getUserInfo } from "../../../api/Auth";
import { getToken } from "../../../utils/authStorage";
import {
  router_opportunity_detail,
  router_my_dashboard,
} from "../../../utils/enum";
import "./publish_success.less";
import { copyToClipboard } from "../../../utils/utils";
const { Content } = Layout;

export default class OpportunityPublishSuccess extends React.Component<
  RouteComponentProps,
  {}
> {
  state = {
    canvasId: 0,
    // id: 0,
    slug: '',
    user: {} as User,
  };

  componentDidMount() {
    const { canvasId, slug } = queryString.parse(window.location.search);
    this.setState({ canvasId, slug });
    this.getUserInfo();
  }

  private getUserInfo = async () => {
    if (getToken()) {
      const res = await getUserInfo();
      if (res && res.user) {
        this.setState({ ...this.state, user: res.user });
      }
    }
  };

  private getLink = () => {
    const { slug } = this.state;
    return `${window.location.origin}/opportunity-detail/${slug}`
  };

  copyLink = () => {
    copyToClipboard(this.getLink())
  };

  jumpToLoginPage = () => {
    this.props.history.push({
      pathname: "/sign-in",
      search: `?from=${window.location.pathname}`,
    });
  };

  render() {
    // const { canvasId, id } = this.state;
    return (
      <div className="opportunity-publish-success-root">
        <Layout>
          <CRHeader
            className="home-header"
            history={this.props.history}
            onLogin={() => this.jumpToLoginPage()}
            onLogout={() => {
              doAction(LogOutAction);
              doFunction(async () => {
                this.props.history.push("/");
              });
            }}
          />
        </Layout>
        <Content>
          <div className="publish-success-wrap">
            <img className="success-icon" src={IconSuccess} alt="" />
            <div className="common-title">
              Opportunity published successfully
            </div>
            <div className="intro">
              You can share the opportunity link on social media and start
              managing your applicants in Posted Oppotunity section in My
              Account page
            </div>
            <div className="copy-link">
              <span>{this.getLink()}</span>
              <div onClick={this.copyLink}>
                <img src={IconCopy} alt="" />
                <span>Copy link</span>
              </div>
            </div>
            <div className="divider">
              <Divider>or</Divider>
            </div>
            <div className="share">
              <span>Share to social media</span>
              <div className="social-icons">
                <img
                  src={IconFacebook}
                  alt=""
                  onClick={() =>
                    window.open(
                      `https://www.facebook.com/sharer.php?u=${this.getLink()}`,
                      "_blank"
                    )
                  }
                />
                <img
                  src={IconTwitter}
                  alt=""
                  onClick={() =>
                    window.open(
                      `http://twitter.com/share?url=${this.getLink()}`,
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
            <div className="btn-wrap">
              <Button
                className="btn-primary"
                onClick={() => {
                  this.props.history.push({
                    pathname: router_opportunity_detail.replace(':slug', this.state.slug),
                  });
                }}
              >
                View Opportunity
              </Button>
              <Button
                className="btn-secondary"
                onClick={() => {
                  this.props.history.push({
                    pathname: router_my_dashboard,
                    search: `?tabType=posted_opportunity&viewCanvas=${this.state.canvasId}`,
                  });
                }}
              >
                Return to My Opportunity
              </Button>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}
