import React from "react";
import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import classnames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./component_dashboard_sidebar.styl";
import { doAction } from "module-reaction";
import { OpenReferralsAction } from "../models/model_user";

interface Props {
  sidebarList: any[];
  curTab: string;
  notificationCount: number;
  unreadCount: number;
  dashboardDot: boolean;
}

export class DashboardSidebar extends React.Component<Props, {}> {
  render() {
    const sidebarList = cloneDeep(this.props.sidebarList);
    sidebarList.reverse();
    const pathname = window.location.pathname;
    const { notificationCount, dashboardDot } = this.props;
    return (
      <div className="dashboard-sidebar-wrap">
        {sidebarList.map(
          (item: any, index: number) =>
            !item.hide && (
              <Link
                key={index}
                to={item.path}
                className={classnames({
                  active:
                    pathname.indexOf(item.path) > -1 ||
                    item.name === this.props.curTab,
                  "notification-active":
                    item.path === "/notifications" && notificationCount > 0,
                  "dashboard-active":
                    item.path === "/my-dashboard" && dashboardDot,
                })}
              >
                {item.name}
                <span
                  hidden={
                    this.props.unreadCount === 0 || item.name !== "Message"
                  }
                  className="unread-count"
                >
                  {this.props.unreadCount}
                </span>
              </Link>
            )
        )}
        
        {
          (window as any).grsfEnabled ? <Link key="referral" to="" onClick={e => {
            e.preventDefault();
            doAction(OpenReferralsAction);
          }}>Referral</Link> : null
        }
        
        <div className="account-completion" hidden>
          <div className="completion-header">
            <span className="title">Account completion</span>
            <span className="total">40%</span>
          </div>
          <div className="progress-wrap">
            <LinearProgress
              variant="determinate"
              value={30}
              classes={{ root: "progress-bar" }}
            />
          </div>

          <span className="title">CheckList</span>
          <div className="check-item">
            <Checkbox checked={true} className="check-box" />
            <span className="key">Create your school canvas</span>
            <span className="value">+40%</span>
          </div>
          <div className="check-item">
            <Checkbox checked={false} className="check-box" />
            <span className="key">Post an opportunity</span>
            <span className="value">+30%</span>
          </div>
          <div className="check-item">
            <Checkbox checked={false} className="check-box" />
            <span className="key">Add your students</span>
            <span className="value">+30%</span>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardSidebar;
