import React from "react";
import { listInvoice } from "../../../api/Pay";
import View from "../../../assets/images/ic_view.svg";
import {
  IBodyItem,
  IHeadItem,
  ListTable,
} from "../../../components/component_list_table";
import "./membership.less";
import moment from "moment";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  router_membership,
  router_membership_invoice_view,
} from "../../../utils/enum";

export class InvoiceList extends React.Component<any, any> {
  public static pageSize: number = 10;

  constructor(props: any) {
    super(props);
    this.state = {
      invoiceList: null,
      page: 1,
      isMobile: window.innerWidth <= 1024
    };
  }

  public componentDidMount() {
    this.getInvoiceList();
  }

  public render() {
    return <div className="membership-view">{this.paymentHistory()}</div>;
  }

  private paymentHistory() {
    let content;
    const { orderHistory,isMobile }= this.state;

    if (orderHistory == null) {
      content = <div className="no-content-message">Loading...</div>;
    } else if (orderHistory.length > 0) {
      const tHeadRender: IHeadItem[] = [
        { name: "Invoice Number" },
        { name: "Item" },
        { name: "No. Opportunities" },
        { name: "Billing Period" },
        { name: "Date Paid" },
        { name: "Total Amount" },
        { name: "Actions" },
      ];

      const tBodyRender: IBodyItem[] = [
        { name: "Invoice Number",key: "number" },
        { name: "Item",key: "canvasName" },
        { name: "Number",key: "maxOpp" },
        { name: "Billing Period",key: "billingPeriod" },
        {
          name: "Date Paid",
          component: (row: any) => <>{moment(row.paidDate).format("l")}</>,
        },
        { name: "Total Amount",key: "amount", type: "amount" },
        {
          key: "action",
          type:'link-actions',
          component: (row: any) => (
            <Link to={router_membership_invoice_view.replace(":id", row.id)}>
              <img src={View} alt="View" />
            </Link>
          ),
        },
      ];

      content = (
        <ListTable
            tableClassName ="noHeaderSelect payment"
          dataList={orderHistory}
          tHeadRender={tHeadRender}
          tBodyRender={tBodyRender}
          total={orderHistory.length}
          size={InvoiceList.pageSize}
          page={this.state.page}
          pageOnChange={(event: unknown, newPage: number) => {
            this.setState({ page: newPage }, () => {
              this.getInvoiceList();
            });
          }}
            hasHeader = { false }
            hasSelect={ false }
            isMobile={isMobile}
        />
      );
    } else {
      content = (
        <div className="no-content-message">You don't have any invoices.</div>
      );
    }

    return (
      <section>
        {this.breadcrumbs()}
        <h1 className="common-title no-margin">{this.props.title}</h1>
        {content}
      </section>
    );
  }

  private breadcrumbs() {
    return (
      <Breadcrumbs>
        <Link to={router_membership}>Membership</Link>
        <span>{this.props.title}</span>
      </Breadcrumbs>
    );
  }

  private async getInvoiceList() {
    const orderHistory = await listInvoice({
      page: this.state.page,
      size: InvoiceList.pageSize,
    });
    this.setState({
      orderHistory,
    });
  }
}
