import React from "react";
// import { RouteComponentProps } from "react-router-dom";
import { Layout, Button, Dropdown } from "antd";
import { TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CRTextArea from "../../components/component_cr_textarea";
import queryString from "query-string";
import _ from "lodash";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import { EmployeeGridView } from "../../components/component_employee_gridview";
import AddressTextField from "../../components/component_address_text_field";
import ContactNumberMask from "../../components/component_contact_number_mask";
import AddEmployeeModal from "./add_employee_modal";
import FieldRequireModal from "../../components/component_field_require_modal";
import { mapProp, doAction, doFunction } from "module-reaction";
import {
  module_company_info,
  UpdateBaseInfoAction,
  DefaultLocationEditAction,
  AddExtraLocationAction,
  EditExtraLocationAction,
  DelExtraLocationAction,
  DelEmployeeAction,
  SubmitAction,
  UpdatePageInfoAction,
  InitDataAction,
  MODULE_COMPANY_INFO,
} from "../../models/model_company_info";
import { modle_user, LoginWithTokenAction } from "../../models/model_user";
import { CanvasType, TargetType, SchoolRole } from "../../utils/enum";
import IconCompany from "../../assets/images/ic_company.svg";
import IconAdd from "../../assets/images/ic_add_green.svg";
import IconDelete from "../../assets/images/ic_delete.svg";
import IconArrow from "../../assets/images/ic_arrow_down.svg";
import IconEmployee from "../../assets/images/ic_employee.svg";
import IconLocation from "../../assets/images/ic_locate.svg";
import { isString } from "util";
import { payStatus } from "../../api/Canvas";
import "./company_info.less";

const { Content } = Layout;
declare var google: any;

interface CompanyInfoProps {
  id: number | null;
  payOrderStatus: string;
  slug: string;
  companyName: string;
  contactNumber: string;
  scSalonId: number | null;
  businessCategory: any[];
  categoryOptions: any[];
  defaultLocation: any;
  extraLocation: any[];
  history: any;
  curUser: any;
  isDirect?: boolean;
}
interface LocationDetailProps {
  index?: number;
  location: any;
  onAddEmployee: Function;
  onUpdate: Function;
  onDeleteEmployee: Function;
  onAuthChange: Function;
  autoCompleteService?: any;
  onAddressSelect: Function;
}

const LocationDetail: React.FC<LocationDetailProps> = (props) => {
  const {
    index = 0,
    location,
    onUpdate,
    onAddEmployee,
    onDeleteEmployee,
    onAuthChange,
    autoCompleteService,
    onAddressSelect,
  } = props;
  const menuArea = (
    <div className="menu-area">
      <div className="square-item" onClick={() => onUpdate({ areaUnit: "ft" })}>
        ft²
      </div>
      <div className="square-item" onClick={() => onUpdate({ areaUnit: "m" })}>
        m²
      </div>
    </div>
  );
  return (
    <div className="location-detail">
      <div className="location-divider" />
      <span className="location-title">
        <img alt="" src={IconLocation} />
        {`Location ${index ? `${index} ` : ""}Information`}
      </span>
      <AddressTextField
        label="Address"
        className="input"
        value={location.address || ""}
        onInput={(value: string) => onUpdate({ address: value })}
        onAddressSelect={onAddressSelect}
        autoCompleteService={autoCompleteService}
      />
      <CRTextArea
        key="employee"
        label="Number of employees"
        className="material-input input"
        type="number"
        value={location.employeeNumber || ""}
        onInput={(e: number) =>
          onUpdate({
            employeeNumber: Number(e) < 0 ? 0 : e,
          })
        }
        onKeyDown={(e: any) =>
          (e.keyCode === 69 ||
            e.keyCode === 187 ||
            e.keyCode === 189 ||
            e.keyCode === 190) &&
          e.preventDefault()
        }
      />
      <div className="chais-sec">
        <CRTextArea
          key="chairs"
          label="Number of chairs"
          variant="filled"
          className="chairs-input"
          type="number"
          value={location.chairs || ""}
          onInput={(e: number) =>
            onUpdate({
              chairs: Number(e) < 0 ? 0 : e,
            })
          }
          onKeyDown={(e: any) =>
            (e.keyCode === 69 ||
              e.keyCode === 187 ||
              e.keyCode === 189 ||
              e.keyCode === 190) &&
            e.preventDefault()
          }
        />
        <div className="footage-wrap">
          <CRTextArea
            key="footage"
            label={
              location.areaUnit === "ft" ? "Square footage" : "Meter square"
            }
            className="footage-input"
            type="number"
            value={location.area || ""}
            onInput={(e: number) =>
              onUpdate({
                area: Number(e) < 0 ? 0 : e,
              })
            }
            onKeyDown={(e: any) =>
              (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) &&
              e.preventDefault()
            }
          />
          <Dropdown overlay={menuArea} trigger={["click"]}>
            <span className="unit-btn">
              {location.areaUnit === "ft" ? "ft²" : "m²"}
              <img src={IconArrow} alt="" />
            </span>
          </Dropdown>
        </div>
      </div>
      {_.isEmpty(location.employee) ? (
        <div className="empty-employee-sec">
          <div className="left-sec">
            <img alt="" src={IconEmployee} className="icon-employee" />
            <div className="left-center">
              <span className="employee">Employee</span>
              <span className="later">You can leave it for later</span>
            </div>
          </div>
          <Button
            className="btn-secondary btn-add-employee"
            onClick={() => onAddEmployee()}
          >
            <img alt="" src={IconAdd} />
            Add more employees
          </Button>
        </div>
      ) : (
        <div className="employee-sec">
          <div className="employee-header">
            <img alt="" src={IconEmployee} className="icon-employee" />
            Employee
          </div>
          <EmployeeGridView
            data={location.employee}
            onAddMore={() => onAddEmployee()}
            onDeleteEmployee={(id: number) => {
              onDeleteEmployee(id);
            }}
            onAuthChange={onAuthChange}
          />
        </div>
      )}
    </div>
  );
};

@mapProp(module_company_info)
// @mapProp(modle_user)
export default class CompanyInfo extends React.Component<CompanyInfoProps, {}> {
  state = {
    showAddEmployee: false,
    curEditLocation: {} as any,
    autoCompleteService: {} as any,
    requiredFields: [] as string[],
    fromPost: false,
  };

  autoCompleteService: any;
  placeService: any;

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  componentDidMount() {
    const { slug, fromPost } = queryString.parse(window.location.search);
    if (fromPost) {
      this.setState({ fromPost: true });
    }
    doAction(InitDataAction, slug);
    if (google) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
      const map = new google.maps.Map(document.createElement("div"));
      this.placeService = new google.maps.places.PlacesService(map);
    }
    if (!this.props.curUser?.profile) {
      doAction(LoginWithTokenAction);
    }
  }

  componentWillUnmount() {
    doAction(MODULE_COMPANY_INFO, module_company_info);
  }

  private onAddressSelected = (address: any, uuid: string) => {
    if (!address.place_id || !uuid) return;
    this.placeService.getDetails(
      { placeId: address.place_id },
      (place: any, status: any) => {
        if (status === "OK") {
          let country = "";
          let countryShort = "";
          let state = "";
          let stateShort = "";
          let city = "";
          let coordinates = [] as number[];
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          if (lat && lng) {
            coordinates = [lng, lat];
          }
          _.forEach(place.address_components, (item) => {
            if (item.types.indexOf("country") > -1) {
              country = item.long_name;
              countryShort = item.short_name;
            } else if (item.types.indexOf("administrative_area_level_1") > -1) {
              state = item.long_name;
              stateShort = item.short_name;
            } else if (item.types.indexOf("locality") > -1) {
              city = item.long_name;
            }
          });
          if (uuid === this.props.defaultLocation?.uuid) {
            doAction(DefaultLocationEditAction, {
              ...this.props.defaultLocation,
              country: country,
              countryShort: countryShort,
              state: state,
              stateShort: stateShort,
              city: city,
              coordinates: coordinates,
            });
          } else {
            const extraLoc = [...this.props.extraLocation];
            const loc = _.find(extraLoc, { uuid: uuid });
            if (loc) {
              loc.country = country;
              loc.countryShort = countryShort;
              loc.state = state;
              loc.stateShort = stateShort;
              loc.city = city;
              loc.coordinates = coordinates;
            }
            doAction(UpdatePageInfoAction, {
              extraLocation: extraLoc,
            });
          }
        }
      }
    );
  };

  public submitCompanyInfo = () => {
    return this.onSubmit();
  }

  private onSubmit = (isSkip = false) => {
    //check fields
    const emptyField = [];
    if (!this.props.companyName) {
      emptyField.push("Company name");
    }
    // if (!this.props.contactNumber) {
    //   emptyField.push("Primary contact number");
    // }
    if (_.isEmpty(this.props.businessCategory)) {
      emptyField.push("Business category");
    }
    let extraHasEmptyAddress = false;
    _.forEach(this.props.extraLocation, (extra) => {
      if (!extra.address) {
        extraHasEmptyAddress = true;
      }
    });
    if (!this.props.defaultLocation?.address || extraHasEmptyAddress) {
      emptyField.push("Location");
    }
    if (_.isEmpty(emptyField)) {
      doAction(SubmitAction, {
        history: this.props.history,
        isSkip: isSkip,
        fromPost: this.state.fromPost,
        isDirect: this.props.isDirect
      });
      return true;
    } else {
      this.updateState({
        requiredFields: emptyField,
      });
      return false;
    }
  };

  render() {
    return (
      <Layout>
        {this.props.isDirect && <CreateBusinessCanvasHeader
          type={CanvasType.BUSINESS}
          step={0}
          jumpToStep={(step: number) => {
            if (step === 1 && this.props.slug) {
              this.props.history.push(`/business-canvas/${this.props.slug}`);
            }
          }}
        />}
        <Content>
          <div className="container">
            <div className="center-wrap">
              <span className="subtitle">
                <img alt="" src={IconCompany} />
                Your Company Information
              </span>
              <TextField
                key="name"
                label="Company name"
                variant="filled"
                className="material-input input"
                value={this.props.companyName || ""}
                onChange={(e) => {
                  doAction(UpdateBaseInfoAction, {
                    companyName: e.target.value,
                  });
                }}
                onKeyDown={(e) =>
                  (e.keyCode === 191) &&
                    e.preventDefault()
                }
              />
              <TextField
                key="number"
                label="Primary contact number"
                variant="filled"
                className="material-input input"
                placeholder="Primary contact number"
                value={this.props.contactNumber}
                onChange={(e) => {
                  doAction(UpdateBaseInfoAction, {
                    contactNumber: e.target.value,
                  });
                }}
                onKeyDown={(e) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 187 ||
                    e.keyCode === 189 ||
                    e.keyCode === 190) &&
                  e.preventDefault()
                }
                InputProps={{ inputComponent: ContactNumberMask }}
              />
              <TextField
                key="scid"
                label="Saloncentric Account number (Sold to Code) (Optional)"
                placeholder="Saloncentric Account number (Sold to Code) Get this from your rep!"
                variant="filled"
                className="material-input input"
                value={this.props.scSalonId || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    doAction(UpdateBaseInfoAction, {
                      scSalonId: value,
                    });
                  }
                }}
                onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
                inputProps={{ maxLength: 10 }}
              />
              <Autocomplete
                multiple
                freeSolo
                className="tag-input input"
                options={this.props.categoryOptions}
                getOptionLabel={(option) => option.name}
                value={this.props.businessCategory}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option.name} {...getTagProps({ index })} />
                  ))
                }
                onChange={(event: object, value: any, reason: string) => {
                  const mapped = _.map(value, (item) => {
                    if (isString(item)) {
                      return { name: item };
                    } else {
                      return item;
                    }
                  });
                  doAction(UpdateBaseInfoAction, {
                    businessCategory: mapped,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-input"
                    key="category"
                    label="Business category"
                    placeholder="Business category"
                    variant="filled"
                  />
                )}
              />
              <AddressTextField
                key="location"
                label={`Location${
                  this.props.extraLocation?.length > 0 ? "1" : ""
                }`}
                className="input"
                value={this.props.defaultLocation?.address || ""}
                placeholder={`Location${
                  this.props.extraLocation?.length > 0 ? "1" : ""
                }`}
                onInput={(value: string) => {
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    address: value,
                  });
                }}
                onAddressSelect={(address: any) => {
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    address: address.description,
                  });
                  doFunction(async () => {
                    this.onAddressSelected(
                      address,
                      this.props.defaultLocation?.uuid
                    );
                  });
                }}
                autoCompleteService={this.autoCompleteService}
              />
              {_.map(this.props.extraLocation, (location: any, idx: number) => {
                return (
                  <div className="extra-location" key={idx}>
                    <AddressTextField
                      key={`location${idx + 2}`}
                      label={`Location${idx + 2}`}
                      className="input"
                      value={location.address || ""}
                      placeholder={`Location${idx + 2}`}
                      onInput={(value: string) => {
                        doAction(EditExtraLocationAction, {
                          ...location,
                          address: value,
                        });
                      }}
                      onAddressSelect={(address: any) => {
                        doAction(EditExtraLocationAction, {
                          ...location,
                          address: address.description,
                        });
                        doFunction(async () => {
                          this.onAddressSelected(address, location.uuid);
                        });
                      }}
                      autoCompleteService={this.autoCompleteService}
                    />
                    <img
                      src={IconDelete}
                      alt=""
                      className="icon-delete"
                      onClick={() =>
                        doAction(DelExtraLocationAction, location.uuid)
                      }
                    />
                  </div>
                );
              })}
              <Button
                className="btn-secondary btn-add"
                onClick={() => {
                  doAction(AddExtraLocationAction);
                }}
              >
                <img alt="" src={IconAdd} />
                Add another location
              </Button>
              {/* <LocationDetail
                index={_.isEmpty(this.props.extraLocation) ? 0 : 1}
                location={this.props.defaultLocation}
                onUpdate={(options: any) =>
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    ...options,
                  })
                }
                onAddEmployee={() =>
                  this.updateState({
                    curEditLocation: this.props.defaultLocation,
                    showAddEmployee: true,
                  })
                }
                onDeleteEmployee={(id: number) => {
                  doAction(DelEmployeeAction, {
                    locationId: this.props.defaultLocation?.uuid,
                    employeeId: id,
                  });
                }}
                onAuthChange={(
                  item: { id: number; email: string },
                  auth: number
                ) => {
                  const employeeList = [...this.props.defaultLocation?.employee];
                  const editItem = _.find(
                    employeeList,
                    (o) => o.id === item.id || o.email === item.email
                  );
                  if (editItem) {
                    editItem.authority = auth;
                    doAction(DefaultLocationEditAction, {
                      ...this.props.defaultLocation,
                      employee: employeeList,
                    });
                  }
                }}
                autoCompleteService={this.autoCompleteService}
                onAddressSelect={(address: any) => {
                  doAction(DefaultLocationEditAction, {
                    ...this.props.defaultLocation,
                    address: address.description,
                  });
                  doFunction(async () => {
                    this.onAddressSelected(
                      address,
                      this.props.defaultLocation?.uuid
                    );
                  });
                }}
              /> */}
              {/* {!_.isEmpty(this.props.extraLocation) &&
                _.map(this.props.extraLocation, (location, idx) => {
                  return (
                    <LocationDetail
                      index={idx + 2}
                      key={idx}
                      location={location}
                      onUpdate={(options: any) =>
                        doAction(EditExtraLocationAction, {
                          ...location,
                          ...options,
                        })
                      }
                      onAddEmployee={() =>
                        this.updateState({
                          curEditLocation: location,
                          showAddEmployee: true,
                        })
                      }
                      onDeleteEmployee={(id: number) => {
                        doAction(DelEmployeeAction, {
                          locationId: location.uuid,
                          employeeId: id,
                        });
                      }}
                      onAuthChange={(
                        item: { id: number; email: string },
                        auth: number
                      ) => {
                        const employeeList = [...location.employee];
                        const editItem = _.find(
                          employeeList,
                          (o) => o.id === item.id || o.email === item.email
                        );
                        if (editItem) {
                          editItem.authority = auth;
                          doAction(EditExtraLocationAction, {
                            ...location,
                            employee: employeeList,
                          });
                        }
                      }}
                      autoCompleteService={this.autoCompleteService}
                      onAddressSelect={(address: any) => {
                        doAction(EditExtraLocationAction, {
                          ...location,
                          address: address.description,
                        });
                        doFunction(async () => {
                          this.onAddressSelected(address, location.uuid);
                        });
                      }}
                    />
                  );
                })} */}
              
              { this.props.isDirect && 
              <div className="bottom-sec">
                
                {this.props.payOrderStatus !== 'succeeded' && (
                  <div className="next-sec">
                    <Button
                      className="btn-primary btn-skip"
                      onClick={() => this.onSubmit(true)}
                    >
                      Purchase Job Opportunity
                    </Button>
                    <span className="tip">
                      You can skip to purchase an opportunity post right away.
                    </span>
                  </div>
                )}
                <div className="skip-sec">
                  <Button
                    className="btn-secondary btn-next"
                    onClick={() => this.onSubmit()}
                  >
                    {this.props.slug ? (<>Save</>) : (<>Create</> ) }&nbsp;Business Canvas
                  </Button>
                  <span className="tip">
                    We’d recommend creating your business canvas first so that
                    your candidates can view your business profile.
                  </span>
                </div>
              </div>
              }
              

            </div>
          </div>
          <div id="map" hidden />
          {this.state.showAddEmployee && (
            <AddEmployeeModal
              visible
              onClose={() => this.updateState({ showAddEmployee: false })}
              onSend={(selected: any[]) => {
                this.updateState({ showAddEmployee: false });
                const addAthor = _.map(selected, (item) => {
                  return { ...item, authority: SchoolRole.OTHER };
                });
                const oldEmployeeList =
                  this.state.curEditLocation.employee || [];
                const newEmployeeList = _.uniqBy(
                  [...oldEmployeeList, ...addAthor],
                  "id"
                );
                doAction(
                  this.state.curEditLocation.uuid ===
                    this.props.defaultLocation?.uuid
                    ? DefaultLocationEditAction
                    : EditExtraLocationAction,
                  {
                    ...this.state.curEditLocation,
                    employee: newEmployeeList,
                  }
                );
              }}
            />
          )}
          <FieldRequireModal
            onClose={() => this.updateState({ requiredFields: [] })}
            visible={!_.isEmpty(this.state.requiredFields)}
            data={this.state.requiredFields}
          />
        </Content>
      </Layout>
    );
  }
}
