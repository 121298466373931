import { getThumbnails } from "video-metadata-thumbnails";
import { IOption } from "video-metadata-thumbnails/lib/video/ioption";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
const _URL = window.URL || window.webkitURL;

export const getVideoThumbnail = async (file: any) => {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    const thumbnails: any = await getiOsVideoCover(file);
    console.log(thumbnails);
    const result = new File([thumbnails!!], `${uuidv4()}.png`, {
      type: "image/png",
      lastModified: Date.now(),
    });
    return result;
  }else{
    const thumbnails = await getThumbnails(file, {
      quality: 1,
      scale: 1,
    } as IOption);
    if (!_.isEmpty(thumbnails)) {
      const firstBlob = thumbnails[0].blob;
      if (firstBlob) {
        const result = new File([firstBlob!!], `${uuidv4()}.png`, {
          type: "image/png",
          lastModified: Date.now(),
        });
        return result;
      }
    }
  }
  return null;
};

export const getMediaInfo = async (file: File, maxMb?: number) => {
  let error = "";
  const fileSize = Number(file.size / (1024 * 1024)).toFixed(1);
  if (maxMb && Number(fileSize) > maxMb) {
    message.error(`Please upload file smaller than ${maxMb}mb`);
    error = "file size error";
  }
  let photoFile: any = file;
  let videoFile: File;
  if (file.type.indexOf("video") > -1) {
    videoFile = file;
    const result = await getVideoThumbnail(file);
    photoFile = result;
  }
  if (photoFile) {
    const image = new Image();
    const objectUrl = _URL.createObjectURL(photoFile);
    return new Promise<any>((resolve, reject) => {
      image.onload = () => {
        _URL.revokeObjectURL(objectUrl);
        const info = {
          image: photoFile,
          video: videoFile,
          dimensions: `${image.width}x${image.height}`,
          fileSize: Number(fileSize),
        };
        resolve(error ? { error: error } : info);
      };
      image.src = objectUrl;
    });
  } else {
    return new Promise<any>((resolve, reject) => {
      const info = {
        image: null,
        video: videoFile,
        dimensions: "Unknown",
        fileSize: Number(fileSize),
      };
      resolve(error ? { error: error } : info);
    });
  }
};

export function getiOsVideoCover(file: any, seekTo = 0.2) {
  return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
              reject("video is too short.");
              return;
          }
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener('seeked', () => {
              // define a canvas to have the same dimension as the video
              const canvas = document.createElement("canvas");
              canvas.width = videoPlayer.videoWidth;
              canvas.height = videoPlayer.videoHeight;
              // draw the video frame to canvas
              const ctx: any = canvas.getContext("2d");
              ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
              //return the canvas image as a blob
              ctx.canvas.toBlob(
                function(blob: any) {
                      resolve(blob);
                  },
                  "image/png",
                  1 /* quality */
              );
          });
      });
  });
}
