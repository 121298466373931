import React, { useState } from "react";
import { Modal, Button } from "antd";
import Close from "../../../assets/images/ic_close.svg";
import "./edit_note_modal.less";
import CRTextArea from "../../../components/component_cr_textarea";

interface Props {
  visible?: boolean;
  onClose: Function;
  onEdit: Function;
  note: string;
}

const EditNoteModal: React.FC<Props> = (props) => {
  const { visible, onClose, onEdit, note } = props;

  const [text, setText] = useState(note || "");

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      centered={true}
      wrapClassName="edit-note-modal-wrap"
    >
      <div className="edit-note-root">
        <div className="top-sec">
          <span className="title">Note for this bookmark</span>
          <img className="close" src={Close} alt="" onClick={() => onClose()} />
        </div>
        <div className="content">
          <CRTextArea
            className="input"
            label="Your comment"
            value={text}
            onInput={(value: string) => {
              setText(value);
            }}
          />
        </div>
        <Button className="btn-primary btn-add" onClick={() => onEdit(text)}>
          Add
        </Button>
      </div>
    </Modal>
  );
};
export default EditNoteModal;
