import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../utils/store';
import { login } from '../api/Auth';
import './component_relogin.less';

const ReloginModal = () => {
  const [params, setParams] = useState({
    email: '',
    password: '',
  });
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const showModal = useSelector((state: RootState) => state.auth.showReloginModal);

  // Function to close the modal (update Redux state)
  const handleClose = () => {
    console.log('Close modal');
    dispatch({ type: 'HIDE_RELOGIN_MODAL' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParams(prevParams => ({
      ...prevParams,
      [name]: value
    }));
  };

  const onLogin = async () => {
    setMessage("");
    // Check for empty email or password
    if (!params.email.trim() || !params.password.trim()) {
      setMessage("Email and password cannot be empty.");
      return;
    }

    try {
      const response = await login({
        platformType: "internal",
        email: params.email,
        password: params.password,
      });

      if (response && response.access_token) {
        dispatch({ type: 'HIDE_RELOGIN_MODAL' });

      } else {
        setMessage("Login failed, please try again.");
      }
    } catch (error) {
      // Type assertion
      const e = error as { message?: string };
      console.error("Login error:", e.message || "Unknown error");

      // Handle the error here, possibly setting an error message
      setMessage("Login failed: " + (e.message || "Unknown error"));
    }
  };

  const forgotPassword = () => {
    window.location.href = '/sign-in';
  }

  const homePage = () => {
    window.location.href = '/';
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onLogin();
    }
  };

  if (!showModal) return null;

  const pathname = window.location.pathname.split('?')[0];
  if (pathname === '/studentpro' || pathname === '/sign-in' || pathname === '/sign-up') {
    return null;
  }

  return (
    <div className="relogin-modal">
      <div className="modal-dialog">
        <div className="modal-body">
          <p className='modal-message'>{message}</p>
          <h2>Session Expired...</h2>
          <h2>Please login and try again</h2>
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={params.email}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          /><br />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={params.password}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          /><br />
          <button className="btn" onClick={onLogin}>Login</button>
        </div>
        <div className="modal-footer">
          <button className="btn" onClick={forgotPassword}>Forgot Password</button>
          <button className="btn" onClick={homePage}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ReloginModal;
