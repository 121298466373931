import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { YseOrNo } from "../views/create_student_canvas/education_info";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Close from "../assets/images/ic_close_grey.svg";

import "./component_education_background.less";

var moment = require('moment');
interface Props {
  className?: string;
  data: any;
  programList?: any[];
  onEdit: Function;
  number: number;
}

const EducationBackground: React.FC<Props> = (props) => {
  const { className = "", data, onEdit, number = 0, programList = [] } = props;
  data.graduationDate = data.graduationDate !== null ? moment.utc(data.graduationDate).format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')

  const [showPermit, handleShowPermit] = useState(false);
  useEffect(() => {
    if (data && data.hasWorkPermit === 'yes' && !showPermit) {
      handleShowPermit(true);
    }
  }, [data, showPermit]);

  return (
    <div className={classnames(className, "education-background-root")}>
      <span className="header">{`Education Background ${number + 1}`}</span>
      <Autocomplete
        key="program"
        freeSolo
        className="tag-input input"
        options={programList}
        value={(data.program && data.program.name) || ""}
        getOptionLabel={(option) => {
          return option.name || option;
        }}
        onChange={(event: object, value: any, reason: string) => {
          onEdit(value, "program");
        }}
        onInputChange={(event: object, value: string, reason: string) => {
          if (
            data.program &&
            data.program.name &&
            data.program.name === value
          ) {
            return;
          }
          onEdit({ name: value }, "program");
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Your program"
            variant="filled"
            className="material-input"
          />
        )}
      />

      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="date-picker-wrap">
          <DatePicker
            disableToolbar
            autoOk
            variant="inline"
            openTo="year"
            format="MM/DD/YY"
            label={"Graduation date" + (data.graduationDate ? "(MM/DD/YY)" : "")}
            className="material-date-picker input"
            views={["year", "month", "date"]}
            value={data.graduationDate}
            onChange={(value) => {
              onEdit(value, "graduationDate");
            }}
          />
          {data.graduationDate && (<img className="close" src={Close} alt="" onClick={() => { onEdit(null, "graduationDate") }} />)}
        </div>
      </MuiPickersUtilsProvider>
      <TextField
        key="attendance"
        label="Attendance (%)"
        variant="filled"
        className="material-input input"
        placeholder="Attendance (%)"
        value={data.attendance}
        type="number"
        onChange={(e) => {
          const value = e.target.value;
          if (Number(value) < 0) return;
          onEdit(Number(value) > 100 ? 100 : value, "attendance");
        }}
        onKeyDown={(e) =>
          (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) &&
          e.preventDefault()
        }
      />
      <TextField
        key="gpa"
        label="GPA"
        variant="filled"
        className="material-input input"
        placeholder="GPA"
        type="number"
        value={data.gpa}
        onChange={(e) => {
          const value = e.target.value;
          if (Number(value) < 0) return;
          onEdit(Number(value) > 100 ? 100 : value, "gpa");
        }}
        onKeyDown={(e) =>
          (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) &&
          e.preventDefault()
        }
      />
      <YseOrNo
        title="Work permit"
        enable={data && data.hasWorkPermit === 'yes'}
        afterSelect={(value: boolean) => {
          handleShowPermit(value);
          onEdit(value? 'yes': 'no', 'hasWorkPermit')
          if (!value) {
            onEdit("", "workPermitHour");
          }
        }}
      />
      {showPermit && (
        <TextField
          key="workPermit"
          label="Work permit"
          variant="filled"
          className="material-input input"
          value={data.workPermitHour}
          type="number"
          onChange={(e) => {
            onEdit(e.target.value, "workPermitHour");
          }}
          onKeyDown={(e) =>
            (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) &&
            e.preventDefault()
          }
        />
      )}
    </div>
  );
};

export default EducationBackground;
