import React, { useEffect } from "react";
import { setTrackId } from "../../utils/authStorage";
const Track: React.FC<Props> = (props: Props) => {
  const { history } = props;
  const id = history.location.search
    ? history.location.search?.match(/[?&]id=([^&]+)/i)[1]
    : false;
  useEffect(() => {
    if (id) {
      setTrackId(id);
      history.push(history.location.search.match(/[?&]url=([^&]+)/i)[1]);
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  return <div className="track-referral-container"></div>;
};
interface Props {
  history: any;
}
export default Track;
