import React, {useState} from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { Layout, Button, Progress } from "antd";
import GetStartCategoryView from "./get_start_category_view";
import GetStartMultiSelView from "./get_start_multi_sel_view";
import _ from "lodash";
import classnames from "classnames";
import { mapProp, doAction, doFunction } from "module-reaction";
import {
  module_get_started,
  GetSelectionData,
  ModuleGetStarted,
  CompleteSinupAction,
  MODULE_GET_STARTED,
} from "../../models/model_get_started";
import { UserRole, Permit, Employment, TargetType, SourceName } from "../../utils/enum";
import LogoSvg from "../../assets/images/new_logo_white.svg";
import IconCrown from "../../assets/images/ic_crown.svg";
import "./get_started.less";
import { router_homepage_index } from "../../utils/enum";
import NewSignUpEmailPage, { SignUpProfilePage, SignupVerifyPage } from "./comp_sign_up";
import { getTrackId } from "../../utils/authStorage";
import { sessionStorageGet, sessionStorageSet } from "../../utils/utils";
import { JoinChoiceComponent } from "./join_choice";
import IconRadioOff from "../../assets/images/ic_radio_off.svg";
import IconRadioOn from "../../assets/images/ic_radio_on.svg";
import JoinSource from "./join_source";

const { Content } = Layout;

const accoutTypeB = [
  {
    id: 0,
    name: "A Personal/Professional Canvas",
    desc:
      "As a beauty student, professional or someone that works in the beauty space, your free Beautista portfolio will provide you with a digital representation of your work. You can share your portfolio like a website to connect, network, recruit and/or apply for all career enhancing opportunities.",
    isPremium: false,
  },
  {
    id: 3,
    name: "A School Canvas Portfolio + Dashboard",
    desc: "As a School owner or leadership team member you can leverage our Beautista Dashboards to empower your students to be seen through a digital portfolio versus word doc resume. You can share out their portfolio links and keep track of placement rate. Leverage Beautista portfolios for Placement Documentation.",
    videoUrl: "https://youtu.be/_pf22GGuAKE",
    isPremium: true,
  },
  {
    id: 2,
    name: "A Career Opportunity",
    desc: "As a beauty business owner you can post your career opportunity to attract the right candidate. Starting at 39.99/mo our career opportunities will empower you to humanize your business through imagery and video; highlighting a day in the life or what your culture looks like so that you are attracting the right candidate.  You can also link to your career opportunity right from your website.",
    videoUrl: "https://youtu.be/bWWiJJ8Qm0A",
    isPremium: true,
  },
];

interface GetStartedProp extends RouteComponentProps, ModuleGetStarted {}
interface PickerProp {
  onItemSelected: Function;
  data: any[];
}

type Category = {
  id: number;
  name: string;
  license: string;
  isOther: boolean;
};

const SimplePicker: React.FC<PickerProp> = (props) => {
  const { onItemSelected, data } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const nextButton = () => {
    if(selectedOption !== null){
      onItemSelected(selectedOption)
    }
  }

  return (
    <div className="select-list">
      {!_.isEmpty(data) &&
        _.map(data, (item, index) => {
          return (
            <div
              className="select-option"
              onClick={() => setSelectedOption(item)}
              key={index}
            >
              <span className="select-name">{item.name}</span>
              <img className="ellipse" src={selectedOption == item ? IconRadioOn : IconRadioOff} alt={selectedOption == item ? "Selected" : "Not Selected"}></img>
              {index < data.length - 1 && <div className="picker-divider" />}
            </div>
          );
        })}
        <button className="btn-primary next-button" onClick={() => nextButton()}>NEXT TO STEP 3</button>
    </div>
  );
};

@mapProp(module_get_started)
export default class GetStartedView extends React.Component<GetStartedProp, {}> {
  initState = {
    step: 1,
    leftText: "",
    progress: 0,
    category: [] as Category[],
    role: UserRole.STUDENT,
    permit: "",
    employ: "",
    enrolls: [],
    target: null,
    prefilled: null,
    source: null,
    isBusy: false,
  };
  state = {...this.initState}

  componentDidMount() {
    doAction(GetSelectionData);

    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    if (source) {
      this.setState({...this.state, source});
    }
    const prefilled = urlParams.get('prefilled');
    if (prefilled) {
      this.setState({...this.state, prefilled: decodeURIComponent(prefilled)});
    }
  }
  static getDerivedStateFromProps(props: any, preState: any) {
    const backUserInfo = sessionStorageGet('onboarding-back-from-profile')
    if (backUserInfo?.id) {
      sessionStorageSet('onboarding-back-from-profile', null)
      // back from profile page
      const { categories, profile } = backUserInfo
      const st = {
        ...preState,
        category: categories.map((_: any) => ({
          ..._,
          license: _.licenseNumber,
        })),
        role: profile.career || UserRole.STUDENT,
        permit: profile.workPermitStatus,
        employ: profile.employmentStatus,
        enrolls: [profile.program, profile.program2].filter(Boolean)
      }
      
      // to the last step
      if (st.role === UserRole.STUDENT) {
        st.step = 2
        st.enrolls = []
      }else if (st.role === UserRole.BEAUTY_PRO) {
        st.step = 2
      }else if (st.role === UserRole.INDUSTRY) {
        st.step = 2
      }

      // have bug for student and industry...
      return st
    }
    return preState
  }

  private jumpToStep = (number: number) => {
    this.setState({
      ...this.state,
      step: number,
      category: number < 3 ? [] : this.state.category,
    });
  };

  private renderDisplayText = () => {
    if (this.state.step === 1) {
      return "Tell us where you are in your career.";
    } else if (this.state.step === 2) {
      if (this.state.role === UserRole.STUDENT) {
        this.jumpToStep(1)
        return (
          <>
            I am{" "}
            <span className="high-light" onClick={() => this.jumpToStep(1)}>
              a student
            </span>{" "}
            and
          </>
        );
      } else {
        return (
          <>
            What Category are you licensed in?
            {/* I am{" "}
            <span className="high-light" onClick={() => this.jumpToStep(1)}>
              {this.state.role === UserRole.BEAUTY_PRO
                ? "a beauty pro with license"
                : "Industry Professional (not licensed)"}
            </span>
            . My{" "}
            {this.state.role === UserRole.BEAUTY_PRO
              ? "categories are"
              : "category is"} */}
          </>
        );
      }
    } else if (this.state.step === 3) {
      if (this.state.role === UserRole.STUDENT) {
        return (
          <>
            {/* I am{" "}
            <span className="high-light" onClick={() => this.jumpToStep(1)}>
              a student */}
            {/* </span>{" "}
            and{" "}
            <span className="high-light" onClick={() => this.jumpToStep(2)}>
              {this.state.permit === Permit.OWNED
                ? "I have a work permit"
                : this.state.permit === Permit.GETTING
                ? "I am working towards my permit"
                : "permits don't apply to my state"} */}
            {/* </span> */}
            Which program(s) are you enrolled in?🎓
          </>
        );
      } else if (this.state.role === UserRole.BEAUTY_PRO) {
        let cateText = "";
        _.forEach(this.state.category, (cate, idx) => {
          cateText =
            cateText +
            `${idx === 0 ? "" : " and "}${cate.name} - license:${cate.license}`;
        });
        return (
          <>
            I am{" "}
            <span className="high-light" onClick={() => this.jumpToStep(1)}>
              {this.state.role === UserRole.BEAUTY_PRO
                ? "What Category are you licensed in?"
                : "Industry Professional (not licensed)"}
            </span>
            . My categories are{" "}
            <span className="high-light" onClick={() => this.jumpToStep(2)}>
              {cateText}
            </span>{" "}
            and I'm currently
          </>
        );
      } else {
        return (
          <>
            I am{" "}
            <span className="high-light" onClick={() => this.jumpToStep(1)}>
              Industry Professional (not licensed)
            </span>
            . My category is{" "}
            <span className="high-light" onClick={() => this.jumpToStep(2)}>
              {this.state.category[0].name || ""}
            </span>
          </>
        );
      }
    } else if (this.state.step === 4) {
      let cateText = "";
      _.forEach(this.state.category, (cate, idx) => {
        cateText =
          cateText +
          `${idx === 0 ? "" : " and "}${cate.name} - license:${cate.license}`;
      });
      return (
        <>
          I am{" "}
          <span className="high-light" onClick={() => this.jumpToStep(1)}>
            a beauty pro with license
          </span>
          . My categories are{" "}
          <span className="high-light" onClick={() => this.jumpToStep(2)}>
            {cateText}
          </span>{" "}
          and I'm currently{" "}
          <span className="high-light" onClick={() => this.jumpToStep(3)}>
            {this.state.employ === Employment.EMPLOYED
              ? "employed"
              : "not employed"}
          </span>
          ,now I want to create
        </>
      );
    }
  };

  

  private renderChooseBox = () => {
    if (this.state.step === 1) {
      let roles = this.props.user_roles;
      if (this.isJpmsLanding()) {
        roles = roles.splice(1);
      }
      return (
        <SimplePicker
          data={roles}
          onItemSelected={(item: any) => {
            if (item.id !== 0 && item.id !== 1) {
              this.jumpToCompletionInfo(item.id);
              return;
            }
            this.setState({
              ...this.state,
              role:
                item.id === 0
                  ? UserRole.STUDENT
                  : item.id === 1
                  ? UserRole.BEAUTY_PRO
                  : UserRole.INDUSTRY,
              step: item.id === 0? this.state.step + 2 : this.state.step + 1, // skip step2 if student
            });
            if (item.id === 0) {
              this.setState({ target: TargetType.STUDENT });
            }
          }}
        />
      );
    } else if (this.state.step === 2) {
      if (this.state.role === UserRole.STUDENT) {
        return (
          <SimplePicker
            data={this.props.permits}
            onItemSelected={(item: any) => {
              const permit =
                item.id === 0
                  ? Permit.OWNED
                  : item.id === 1
                  ? Permit.GETTING
                  : Permit.NOT_APPLY;
              this.setState({
                ...this.state,
                permit: permit,
                step: this.state.step + 1,
              });
            }}
          />
        );
      } else if (this.state.role === UserRole.BEAUTY_PRO) {
        return (
          <GetStartMultiSelView
            hideOther={true}
            data={this.props.licenseCategory}
            onItemAdd={(item: Category) => {
              const oldCate = [...this.state.category];
              const exsitOne = _.find(oldCate, { name: item.name });
              let newCate: Category[];
              if (exsitOne) {
                exsitOne.license = item.license;
                newCate = oldCate;
              } else {
                newCate = [...this.state.category, item];
              }
              this.setState({
                ...this.state,
                category: newCate,
              });
            }}
            onOtherAdd={(item: Category) => {
              const filtered = _.filter(this.state.category, (cate) => {
                return cate.isOther !== true;
              });
              const newCate = [...filtered, item];
              this.setState({
                ...this.state,
                category: newCate,
              });
            }}
            onItemRemove={(item: { name: string }) => {
              const newCate = _.filter(this.state.category, (cate) => {
                return cate.name !== item.name;
              });
              this.setState({ ...this.state, category: newCate });
            }}
          />
        );
      } else {
        return (
          <GetStartCategoryView
            data={this.props.ipCategory}
            showOther={true}
            onItemSelected={(item: any, isOther: boolean) => {
              if (isOther) {
                this.setState({
                  ...this.state,
                  category: [item],
                });
              } else {
                this.setState({
                  ...this.state,
                  category: [item],
                  step: this.state.step + 1,
                });
              }
            }}
          />
        );
      }
    } else if (this.state.step === 3) {
      if (getTrackId() && Number(getTrackId()) === 1) {
        this.jumpToCompletionInfo(1);
        return;
      }
      if (this.state.role === UserRole.STUDENT) {
        return (
          <>
            <GetStartMultiSelView
              data={this.props.program}
              hideOther={true}
              hideLicense={true}
              limit={{ limit: 2, now: this.state.enrolls.length }}
              onItemAdd={(item: any) => {
                const oldEnrolls = [...this.state.enrolls];
                const exsitOne = _.find(oldEnrolls, { name: item.name });
                let newEnrolls: any[];
                if (exsitOne) {
                  newEnrolls = oldEnrolls;
                } else {
                  newEnrolls = [...this.state.enrolls, item];
                }
                this.setState({
                  ...this.state,
                  enrolls: newEnrolls,
                });
              }}
              onItemRemove={(item: { name: string }) => {
                const newEnrolls = _.filter(this.state.enrolls, (prog: any) => {
                  return prog.name !== item.name;
                });
                this.setState({ enrolls: newEnrolls });
              }}
            />
            <div className="category-limit">Select up to 2 programs</div>
            
          </>
        );
      } else if (this.state.role === UserRole.BEAUTY_PRO) {
        return (
          <SimplePicker
            data={this.props.employStatus}
            onItemSelected={(item: any) => {
              const employ =
                item.id === 0 ? Employment.EMPLOYED : Employment.NOT_EMPLOYED;
              this.setState({
                ...this.state,
                step: this.state.step + 1,
                employ: employ,
              });
            }}
          />
        );
      } else {
        return (
          <div className="box3">
            {_.map(accoutTypeB, (item, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => this.jumpToCompletionInfo(item.id)}
                >
                  <div className="box-item">
                    <div className="box-item-center">
                      <span className="box-item-name">{item.name}</span>
                      <span className="box-item-desc">{item.desc}</span>
                      {item.videoUrl && (
                        <span className="box-item-desc"><a href={item.videoUrl} target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer"> Watch the Video</a></span>
                      )}
                    </div>
                    {item.isPremium && (
                      <div className="premium">
                        <img alt="" src={IconCrown} />
                        Premium
                      </div>
                    )}
                  </div>
                  <div className="divider-right" />
                </div>
              );
            })}
            <div className="box-help-item">
              <span className="skip">You can add more later</span>
            </div>
          </div>
        );
      }
    } else {
      if (getTrackId() && Number(getTrackId()) === 1) {
        this.jumpToCompletionInfo(1);
        return;
      }
      return (
        <div className="box3">
          {_.map(accoutTypeB, (item, idx) => {
            return (
              <div key={idx} onClick={() => this.jumpToCompletionInfo(item.id)}>
                <div className="box-item">
                  <div className="box-item-center">
                    <span className="box-item-name">{item.name}</span>
                    <span className="box-item-desc">{item.desc}</span>
                  </div>
                  {item.isPremium && (
                    <div className="premium">
                      <img alt="" src={IconCrown} />
                      Premium
                    </div>
                  )}
                </div>
                {idx < accoutTypeB.length - 1 && (
                  <div className="divider-right" />
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };

  private isJpmsLanding(): boolean {
    return this.props.history.location.search.indexOf("school=paul_mitchell") > 0;
  }

  private getLeftText = () => {
    return '';
  };

  private getProgress = () => {
    if (this.state.role === UserRole.BEAUTY_PRO) {
      return (100 * (this.state.step - 1)) / 4;
    } else {
      return Math.floor((100 * (this.state.step - 1)) / 3);
    }
  };

  private jumpToCompletionInfo = (id: number) => {
    const { target } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    const params = {
      category: this.state.category,
      role: this.state.role,
      employ: this.state.employ,
      enrolls: this.state.enrolls,
      target: target,
      source
    };
    this.setState({...this.state, ...{ isBusy: true }})
    doAction(CompleteSinupAction, params);
    doFunction(async () => {
      this.setState({...this.state, ...{ isBusy: false }})
    });
  };

  private jumpToHome = () => {
    this.props.history.push(router_homepage_index);
  };
  
  private selectTemp = (target: string) => {
    this.setState({ target }, () => {
      if (target === TargetType.BUSINESS || target === TargetType.SCHOOL) {
        this.jumpToCompletionInfo(-1);
      } else {
        doAction(MODULE_GET_STARTED, { pageName: "info" });
      }
    });
  }

  private isBackDisabled = (): boolean => {
    return this.state.step < 1 && this.isJoin();
  }

  private isJoin = (): boolean => {
    return this.props.pageName === "join";
  }

  private renderOccupation = () => {
    if (this.props.pageName === "signup_main") {
      return <NewSignUpEmailPage history={this.props.history} prefilled={this.state.prefilled} />;
    } 
    // else if (this.props.pageName === "signup_email") {
    //   return <SignUpEmailPage />;
    // }
    else if (this.props.pageName === "profile") {
      return <SignUpProfilePage />;
    } else if (this.props.pageName === "verifyCode") {
      return <SignupVerifyPage />;
    } else if (this.props.pageName === "join") {
      return <JoinChoiceComponent selectItem={this.selectTemp} />
    } else if (this.props.pageName === "source") {
      return <JoinSource />
    }
    return (
      <>
        <div className="occupation">
          <div className="content">
            <span className="display-text">{this.renderDisplayText()}</span>
            {this.renderChooseBox()}
          </div>
          
          {((this.state.step === 2 &&
            this.state.role === UserRole.BEAUTY_PRO) ||
            (!_.isEmpty(this.state.category) &&
              this.state.role === UserRole.INDUSTRY)) && (
            <Button
              disabled={this.state.isBusy || _.isEmpty(this.state.category)}
              className="btn-primary btn-next"
              onClick={() => this.jumpToCompletionInfo(-1)}
            >
              NEXT TO TEXT VERIFICATION
            </Button>
          )}
          {
            this.state.step === 3 && this.state.role === UserRole.STUDENT &&
            <Button
              disabled={ this.state.isBusy ||
                (_.isEmpty(this.state.enrolls) &&
                this.state.role === UserRole.STUDENT)
              }
              className="btn-primary btn-next"
              onClick={() => this.jumpToCompletionInfo(-1)}
            >
              NEXT TO TEXT VERIFICATION
            </Button>
          }
        </div>
        <div className="top-bar">
        <Button
            disabled={this.isBackDisabled()}
            className="btn-secondary btn-back"
            icon="left"
            onClick={e => {
              const step = 1;
              const st: any = {step, ...(this.initState) }
              this.setState(st)
              doAction(MODULE_GET_STARTED, { pageName: "join" });
            }}
          >
            Back
          </Button>
        <Progress
          percent={this.getProgress()}
          showInfo={false}
          strokeWidth={4}
          strokeColor="#2D2E3D"
          className="progress"
        />
        </div>
      </>
    );
  };

  render() {
    const { pageName } = this.props;
    const isSignup = this.props.pageName === "signup_main";
    const secMaskOpacity = isSignup ? 0.5 : 1;
    console.log(this.props, this.state)
    return (
      <Layout className="get-started">
        <Content>
          <div
            className={classnames("left-sec", {
              backImage: isSignup,
              isBlank: true
            })}
          >
            <div className="sec-mask" style={{ opacity: secMaskOpacity }}></div>
            <Link to={router_homepage_index}>
              <img
                alt=""
                src={LogoSvg}
                onClick={this.jumpToHome}
                className={classnames({
                  isBlack: this.state.step !== 0,
                })}
              />
            </Link>
            <div
              className={classnames("progress", {
                isBlack: this.state.step !== 0,
              })}
            >
              <div
                className={classnames("progress-item", {
                  isActive: pageName.includes("signup"),
                })}
              >
                <span
                  className={classnames("progress-circle-1", {
                    isActive: pageName.includes("signup"),
                  })}
                />
                Sign Up
              </div>
              <span className="stash-1" />
              <div
                className={classnames("progress-item", {
                  isActive: pageName.includes("info"),
                })}
              >
                <span
                  className={classnames("progress-circle-1", {
                    isActive: pageName.includes("info"),
                  })}
                />
                Get Started
              </div>
              <span className="stash-1" />
              <div
                className={classnames("progress-item", {
                  isActive: pageName.includes("profile"),
                })}
              >
                <span
                  className={classnames("progress-circle-1", {
                    isActive: pageName.includes("profile"),
                  })}
                />
                Tell the world who you are.
              </div>
              <span className="stash-1" />
              <div
                className={classnames("progress-item", {
                  isActive: pageName.includes("verifyCode"),
                })}
              >
                <span
                  className={classnames("progress-circle-1", {
                    isActive: pageName.includes("verifyCode"),
                  })}
                />
                We sent you a text!
              </div>
            </div>
            <p className="left-text">{this.getLeftText()}</p>
          </div>
          <div className="right-sec">{this.renderOccupation()}</div>
        </Content>
      </Layout>
    );
  }
}
