import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const RedirectComponent = ({ url }) => {

  useEffect(() => {
    if(url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <div>Redirecting...</div>
  );
}

RedirectComponent.propTypes = {
  url: PropTypes.string.isRequired,
};

export default RedirectComponent;
