import React, { useEffect, useState, useRef } from "react";
import { getStaticAssetPath } from "../../../utils/utils";

interface Props {
  headline?: string;
  isSalonCentric?: boolean;
  isSalonCentricStopInterview?: boolean;
}

const StudentProBrands: React.FC<Props> = ({
  headline = "TRUSTED BY:",
  isSalonCentric,
  isSalonCentricStopInterview
}) => {
  const [brands, setBrands] = useState([
    { name: `L'Oréal`, path: "loreal" },
    { name: "Matrix", path: "matrix" },
    { name: "Pulp Riot", path: "pulpriot" },
    { name: "Redken", path: "redken" },
    { name: "Ulta", path: "ulta" },
    { name: "Madison Reed", path: "madisonreed" },
    { name: "Mizani", path: "mizani" },
    { name: "Sola", path: "sola" },
    { name: `Floyd's Barbershop`, path: "floyds" },
  ]);

  useEffect(() => {
    if (isSalonCentric) {
      setBrands([
        { name: `Biolage`, path: "biolage" },
        { name: `Kérastase`, path: "kerastase" },
        { name: `L'Oréal`, path: "lorealppd" },
        { name: "Matrix", path: "matrix" },
        { name: "Mizani", path: "mizani" },
        { name: "Pulp Riot", path: "pulpriot" },
        { name: "Pureology", path: "pureology" },
        { name: "Redken", path: "redken" },
      ]);
    }
  }, [isSalonCentric]);

  useEffect(() => {
    if (isSalonCentricStopInterview) {
      setBrands([
        { name: "Pulp Riot", path: "pulpriot" },
        { name: "Redken", path: "redken" },
        { name: "Summit", path: "summit" },
        { name: "Paul Mitchell", path: "pmschools" },
        { name: "Drybar", path: "drybar" },
        { name: "Mitchells", path: "mitchells" },
      ]);
    }
  }, [isSalonCentricStopInterview]);

  return (
    <div className="cm-sp-brands cm-flex-column cm-flex-center">
      <h4>{headline}</h4>
      <div className="cm-sp-logos-wrap">
        {brands.map((brand) => (
          <img
            src={getStaticAssetPath(`studentpro-landing/${brand.path}.png`)}
            alt={brand.name}
          />
        ))}
      </div>
    </div>
  );
};

export default StudentProBrands;
