import React from 'react';

import caretDown from "../../../assets/images/home/caret-down.svg";

interface Props {
  question: string;
  answer: any;
}

export class FaqExpander extends React.Component<Props, {}> {
  state = {
    isExpanded: false
  }

  render() {
    const { question, answer } = this.props;
    const { isExpanded } = this.state;
    return <div className="cm-faq-expander">
      <div className="cm-flex-row" onClick={() => this.setState({ isExpanded: !isExpanded })}>
        <h3 className="cm-flex-1">{question}</h3>
        <img className="caret-med" src={caretDown} alt="caret" />
      </div>
      {isExpanded && <p dangerouslySetInnerHTML={{ __html: answer }}>
      </p>}
    </div>
  }
}
