import React from "react";
import IconEmptyImg from "../../../assets/images/ic_empty_image.png";
import IconAddr from "../../../assets/images/ic_locate_stroke.svg";
import IconHouse from "../../../assets/images/ic_house.svg";
import IconCal from "../../../assets/images/ic_calendar_grey.svg";
import "./opportunity_bookmark_item.less";
import { Button } from "antd";
import moment from "moment";

interface OpportunityBookmarkItemProps {
  opportunity: any;
  history?: any;
}

const OpportunityBookmarkItem: React.FC<OpportunityBookmarkItemProps> = (
  props
) => {
  const { opportunity, history } = props;

  const getCreateDateText = () => {
    if (!opportunity.createdAt) return "";
    const years = moment().diff(moment(opportunity.createdAt), "years");
    const months = moment().diff(moment(opportunity.createdAt), "months");
    const days = moment().diff(moment(opportunity.createdAt), "days");
    const hours = moment().diff(moment(opportunity.createdAt), "hours");
    const minutes = moment().diff(moment(opportunity.createdAt), "minutes");
    if (years) {
      return years + " years ago";
    }
    if (months) {
      return months + " months ago";
    }
    if (days) {
      return days + " days ago";
    }
    if (hours) {
      return hours + " hours ago";
    }
    if (minutes) {
      return minutes + " minutes ago";
    }
    return "";
  };

  return (
    <div className="opportunity-bookmark-item-root">
      <img
        alt=""
        className="feature-img"
        src={
          (opportunity.media &&
            opportunity.media.featuredPhoto &&
            opportunity.media.featuredPhoto.image) ||
          IconEmptyImg
        }
      />

      <div className="info-sec">
        <span className="canvas-name">{opportunity.name}</span>
        <span className="info-item">
          <img alt="" src={IconAddr} />
          {opportunity.location && opportunity.location.address}
        </span>
        <span className="name info-item">
          <img alt="" src={IconHouse} />
          {opportunity.canvasName}
        </span>
        <span className="info-item">
          <img alt="" src={IconCal} />
          Published: {getCreateDateText()};{" "}
          {/* {opportunity.expire &&
            `Expiration: ${moment(opportunity.expire).format("MM/DD")}`} */}
        </span>
      </div>
      <span className="space" />
      <div className="btn-sec">
        <Button className="btn-primary btn-view" onClick={() => history.push(`/opportunity-detail/${opportunity.slug || opportunity.id}`)}>View</Button>
      </div>
    </div>
  );
};

export default OpportunityBookmarkItem;
