import { ModuleStore, ModuleAction, KV, plusAction, getModuleProp } from "module-reaction";
import _ from "lodash";
import { doAction } from "module-reaction";
import { getCustomized } from "../api/Options";
import { getCanvas } from "../api/Canvas";
import { Storage } from "aws-amplify";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { IMediaItem } from "../components/component_media_item";
import {
  MediaType,
  router_opportunity_publish_success,
  router_my_dashboard,
} from "../utils/enum";
import { AWS_URL } from "../utils/constants";
import { getMediaInfo } from "../utils/mediaHelper";
import {
  setPosterAction,
  setOppStatusAction,
} from "./model_opportunity_detail";
import {
  createOpportunity,
  updateOpportunity,
  getOppDtls,
} from "../api/Opportunity";
import { addBookmark } from "../api/Bookmark";
import { isHtmlTag, htmlDecode, getExpireDate } from "../utils/utils";
import store from "../utils/store";
export const MODULE_POST_JOB_OPP = "module_post_job_opp";

export const general_location_address = 'National Opportunity'
export interface OpportunityInfo {
  jobTitle: object;
  jobCategory: object;
  jobType: object;
  selectLocation: any;
  email: string;
  applyUrl?: string;
  expirationDate: Date;
  editor: any;
  isShowEditor: boolean;
  jobTitleList: any;
  locationList: any;
  jobCatagoryList: any;
  jobTypeList: any;
  validForAllState: boolean;
  competition: boolean;
  brand: string;
}

export interface AdditionalInfo {
  brandList: any;
  retailBrands: any;
  softwares: any;
  softwareList: any;
  paymentList: any;
  paymentStructure: object;
}

export interface MediaInfo {
  thumbnailPhoto: IMediaItem;
  featuredPhoto: IMediaItem;
  companyPhoto: IMediaItem;
  featuredVideo: IMediaItem;
}

export interface ModulePostJobOpp extends ModuleStore {
  opportunityId: number;
  slug: string;
  canvasDetails: any;
  website: string;
  tagLine: string;
  about: string;
  opportunityInfo: OpportunityInfo;
  additionalInfo: AdditionalInfo;
  mediaInfo: MediaInfo;
  emptyFields: string[];
  bookmarkTag: boolean;
  status: string;
  getStartedOverlay: boolean;
}

export const module_post_job_opp: ModulePostJobOpp = {
  module: MODULE_POST_JOB_OPP,
  opportunityId: -1,
  canvasDetails: null,
  slug: "",
  website: "",
  tagLine: "",
  about: "",
  opportunityInfo: {
    selectLocation: {},
    jobTitle: {},
    jobCategory: {},
    jobType: {},
    jobTitleList: [],
    locationList: [],
    jobCatagoryList: [],
    // TODO: add new option here https://trello.com/c/LsbHA7tu
    jobTypeList: [],
    validForAllState: false,
    competition: false,
    brand: null,
  } as OpportunityInfo,
  additionalInfo: {
    brandList: [],
    retailBrands: [],
    softwareList: [],
    softwares: [],
    paymentList: [],
    paymentStructure: {},
  } as AdditionalInfo,
  mediaInfo: {
    thumbnailPhoto: {
      image: "",
      title: "Thumbnail photo",
      dimensions: "500x400",
      fileSize: 1,
      url: "",
      type: MediaType.THUMBNAIL_PHOTO,
    },
    featuredPhoto: {
      image: "",
      title: "Featured Photo",
      dimensions: "1440x800",
      fileSize: 1,
      url: "",
      type: MediaType.FEATURED_PHOTO,
    },
    companyPhoto: {
      image: "",
      title: "Company Photo",
      dimensions: "400x400",
      fileSize: 1,
      url: "",
      type: MediaType.COMPANY_PHOTO,
    },
    featuredVideo: {
      image: "",
      title: "Featured Video",
      dimensions: "1240x800",
      fileSize: 10,
      url: "",
      type: MediaType.FEATURED_VIDEO,
    },
  },
  emptyFields: [] as string[],
  bookmarkTag: false,
  status: "",
  getStartedOverlay: false,
};

export const UpdateBaseInfoAction: ModuleAction<KV, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (info: KV) => {
    return { ...info };
  },
};

export const UpdateOppInfoAction: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (info: OpportunityInfo) => {
    return { opportunityInfo: info };
  },
};

export const AdditionalInfoAction: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (info: AdditionalInfo) => {
    return { additionalInfo: info };
  },
};

export const UpdateMediaInfoAction: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (info: any, module: ModulePostJobOpp) => {
    return {
      mediaInfo: {
        ...module.mediaInfo,
        ...info,
      },
    };
  },
};

export const UploadFileAction: ModuleAction<KV, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (params: KV, module: ModulePostJobOpp) => {
    const { mediaInfo, type } = params;
    // let maxSize;
    // switch (type) {
    //   case MediaType.THUMBNAIL_PHOTO:
    //     maxSize = 1;
    //     break;
    //   case MediaType.FEATURED_PHOTO:
    //     maxSize = 1;
    //     break;
    //   case MediaType.COMPANY_PHOTO:
    //     maxSize = 1;
    //     break;
    //   case MediaType.FEATURED_VIDEO:
    //     maxSize = 10;
    //     break;
    //   default:
    //     maxSize = 10;
    // }
    if (mediaInfo.error) return {};
    //
    const remotePath = `canvas/${type}/`;
    const customPrefix = {
      public: remotePath,
    };
    let imageKey = "";
    let videoKey = "";
    let hasError = false;
    if (mediaInfo.image) {
      const result: any = await Storage.put(
        uuidv4() + ".png",
        mediaInfo.image,
        {
          customPrefix,
          progressCallback(progress: any) {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            store.dispatch({
              type: 'SET_UPLOAD_PROGRESS',
              payload: uploadProgress
            });
          }
        }
      ).catch((error) => {
        message.error(error.message);
        hasError = true;
      });
      if (result && result.key) {
        imageKey = result.key;
      }
    }
    if (mediaInfo.video) {
      const sf = _.last(mediaInfo.video.name.split(".")) || ".MP4";
      const result: any = await Storage.put(
        uuidv4() + `.${sf}`,
        mediaInfo.video,
        {
          customPrefix,
          progressCallback(progress: any) {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            store.dispatch({
              type: 'SET_UPLOAD_PROGRESS',
              payload: uploadProgress
            });
          }
        }
      ).catch((error) => {
        message.error(error.message);
        hasError = true;
      });
      if (result && result.key) {
        videoKey = result.key;
      }
    }
    if (hasError) {
      return {};
    }
    const imgUrl = imageKey ? AWS_URL + remotePath + imageKey : "";
    const videoUrl = videoKey ? AWS_URL + remotePath + videoKey : "";
    switch (type) {
      case MediaType.THUMBNAIL_PHOTO:
        return {
          mediaInfo: {
            ...module.mediaInfo,
            [MediaType.THUMBNAIL_PHOTO]: {
              ...module.mediaInfo[MediaType.THUMBNAIL_PHOTO],
              image: imgUrl,
              dimensions: mediaInfo.dimensions,
              fileSize: mediaInfo.fileSize,
              keepSize: mediaInfo.keepSize,
              reposition: mediaInfo.reposition,
            },
          },
        };
      case MediaType.FEATURED_PHOTO:
        return {
          mediaInfo: {
            ...module.mediaInfo,
            [MediaType.FEATURED_PHOTO]: {
              ...module.mediaInfo[MediaType.FEATURED_PHOTO],
              image: imgUrl,
              dimensions: mediaInfo.dimensions,
              fileSize: mediaInfo.fileSize,
              keepSize: mediaInfo.keepSize,
              reposition: mediaInfo.reposition,
            },
          },
        };
      case MediaType.COMPANY_PHOTO:
        return {
          mediaInfo: {
            ...module.mediaInfo,
            [MediaType.COMPANY_PHOTO]: {
              ...module.mediaInfo[MediaType.COMPANY_PHOTO],
              image: imgUrl,
              dimensions: mediaInfo.dimensions,
              fileSize: mediaInfo.fileSize,
              keepSize: mediaInfo.keepSize,
              reposition: mediaInfo.reposition,
            },
          },
        };
      case MediaType.FEATURED_VIDEO:
        if (videoUrl) {
          return {
            mediaInfo: {
              ...module.mediaInfo,
              [MediaType.FEATURED_VIDEO]: {
                ...module.mediaInfo[MediaType.FEATURED_VIDEO],
                image: imgUrl,
                video: videoUrl,
                dimensions: mediaInfo.dimensions,
                fileSize: mediaInfo.fileSize,
              },
            },
          };
        } else {
          return {};
        }
    }

    return {};
  },
  maxProcessSeconds: 300,
};

export const DeleteMediaAction: ModuleAction<KV, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (params: KV, module: ModulePostJobOpp) => {
    const { type } = params;
    switch (type) {
      case MediaType.THUMBNAIL_PHOTO:
        return {
          mediaInfo: {
            ...module.mediaInfo,
            thumbnailPhoto: {
              image: "",
              title: "Thumbnail photo",
              dimensions: "500x400",
              fileSize: 1,
              url: "",
              type: MediaType.THUMBNAIL_PHOTO,
            },
          },
        };
      case MediaType.FEATURED_PHOTO:
        return {
          mediaInfo: {
            ...module.mediaInfo,
            featuredPhoto: {
              image: "",
              title: "Featured Photo",
              dimensions: "1440x800",
              fileSize: 1,
              url: "",
              type: MediaType.FEATURED_PHOTO,
            },
          },
        };
      case MediaType.COMPANY_PHOTO:
        return {
          mediaInfo: {
            ...module.mediaInfo,
            companyPhoto: {
              image: "",
              title: "Company Photo",
              dimensions: "400x400",
              fileSize: 1,
              url: "",
              type: MediaType.COMPANY_PHOTO,
            },
          },
        };
      case MediaType.FEATURED_VIDEO:
        return {
          mediaInfo: {
            ...module.mediaInfo,
            featuredVideo: {
              image: "",
              title: "Featured Video",
              dimensions: "1240x800",
              fileSize: 10,
              url: "",
              type: MediaType.FEATURED_VIDEO,
            },
          },
        };
    }
    return {};
  },
};

export const getFileUrlAction = async (file: File, maxSize: number) => {
  const mediaInfo = await getMediaInfo(file);
  if (mediaInfo.error) return "";
  const remotePath = `/canvas`;
  const customPrefix = {
    public: remotePath,
  };
  let imageKey = "";
  let hasError = false;
  if (mediaInfo.image) {
    const result: any = await Storage.put(uuidv4() + ".png", mediaInfo.image, {
      customPrefix,
      progressCallback(progress: any) {
        const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
        store.dispatch({
          type: 'SET_UPLOAD_PROGRESS',
          payload: uploadProgress
        });
      }
    }).catch((error) => {
      message.error(error.message);
      hasError = true;
    });
    if (result && result.key) {
      imageKey = result.key;
    }
  }
  if (hasError) {
    return "";
  }
  const imgUrl = AWS_URL + remotePath + imageKey;
  return imgUrl;
};

export const getOptionsActions: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (params: any, module: ModulePostJobOpp) => {
    const res = await getCustomized(true, [
      "jobTitle",
      "category",
      "jobType",
      "brand",
      "software",
      "paymentMode",
    ]);
    if (res) {
      const { jobTitle, category, jobType, brand, software, paymentMode } = res;
      return {
        opportunityInfo: {
          ...module.opportunityInfo,
          jobTitleList: jobTitle,
          jobCatagoryList: category.business,
          jobTypeList: jobType,
        },
        additionalInfo: {
          ...module.additionalInfo,
          brandList: brand,
          softwareList: software,
          paymentList: paymentMode,
        },
      };
    }
    return {};
  },
};

const formatOptions = (list: any) => {
  return list.map((item: any) => {
    const { id, name } = item;
    const newItem = { optionName: name };
    if (id) {
      Object.assign(newItem, { optionId: id });
    }
    return newItem;
  });
};

export const postOpportunityAction: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (params: any, module: ModulePostJobOpp) => {
    const { history, isPublish } = params;
    const status = module.status;
    const emptyFields = checkRequestParameter(module);
    if (
      !_.isEmpty(emptyFields) &&
      (isPublish || (status && status === "public"))
    ) {
      return { emptyFields };
    }
    const parameter = getPublishParameter(module, isPublish);
    let res: any;
    if (module.opportunityId > -1) {
      Object.assign(parameter, { id: module.opportunityId });
      res = await updateOpportunity(parameter);
    } else {
      res = await createOpportunity(parameter);
    }
    if (res) {
      if (isPublish) {
        const { canvasId, id, slug } = res;
        doAction(setOppStatusAction, {
          id,
          status: "public",
          opportunityInfo: module.opportunityInfo,
        });
        history.push({
          pathname: router_opportunity_publish_success,
          search: `?canvasId=${canvasId}&slug=${slug}`,
        });
      } else {

        history.push({
          pathname: router_my_dashboard,
          search: `?tabType=posted_opportunity&viewCanvas=${res.canvasId}`,
        });

      }
    }
    return {};
  },
};

const checkRequestParameter = (module: ModulePostJobOpp) => {
  const emptyFields = [] as string[];
  if (!module.website) {
    emptyFields.push("Website");
  }
  if (!(module.opportunityInfo.selectLocation.uuid || module.opportunityInfo.validForAllState) ) {
    emptyFields.push("Select Location");
  }
  if (_.isEmpty(module.opportunityInfo.jobTitle)) {
    emptyFields.push("Job Title");
  } else if (
    (module.opportunityInfo.jobTitle as any).name === "Other" ||
    !(module.opportunityInfo.jobTitle as any).name
  ) {
    emptyFields.push("Your job title");
  }
  if (!module.opportunityInfo.editor) {
    emptyFields.push("Job description");
  }
  if (!module.opportunityInfo.expirationDate) {
    const date = getExpireDate();
    module.opportunityInfo.expirationDate = date;
  }
  if (_.isEmpty(module.additionalInfo.paymentStructure)) {
    emptyFields.push("Payment structure");
  }
  if (!module.mediaInfo.thumbnailPhoto.image) {
    emptyFields.push("Thumbnail Photo");
  }
  return emptyFields;
};

const getPublishParameter = (
  module: ModulePostJobOpp,
  isPublish: boolean = true
) => {
  const {
    canvasDetails: { id },
    website,
    tagLine,
    about,
    opportunityInfo: {
      jobTitle,
      jobCategory,
      jobType,
      selectLocation: { uuid, country, countryShort, state, city, address },
      email,
      expirationDate,
      editor,
      validForAllState,
      applyUrl,
      competition,
      brand
    },
    additionalInfo: { retailBrands, softwares, paymentStructure },
    mediaInfo: { thumbnailPhoto, featuredPhoto, companyPhoto, featuredVideo },
  } = module;
  const parameter = {
    canvasId: id,
    website,
    tagLine,
    about,
    jobTitle:
      _.isEmpty(jobTitle) ||
        (jobTitle as any).name === "Other" ||
        !(jobTitle as any).name
        ? null
        : jobTitle,
    jobCategory,
    jobType,
    location: { uuid, country, countryShort, state, city, address },
    email,
    applyUrl,
    expire: expirationDate ? new Date(expirationDate).toISOString() : null,
    validForAllState,
    description: editor,
    additionalInformation: {
      brands: formatOptions(retailBrands),
      softwares: formatOptions(softwares),
    },
    paymentMode: paymentStructure,
    media: {
      thumbPhoto: thumbnailPhoto,
      featuredPhoto,
      companyPhoto,
      featuredVideo,
    },
    competition,
    brand,
  };
  return parameter;
};

export const getCanvasDetailAction: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (id: number, module: ModulePostJobOpp) => {
    const res = await getCanvas(id + "");
    if (res) {
      return {
        canvasDetails: res,
        opportunityInfo: {
          ...module.opportunityInfo,
          locationList:
            res.type === "school"
              ? res.detail.locations.slice(0, 1)
              : res.detail.locations,
        },
      };
    }
    return {};
  },
};

export const getOppDtlsAction: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (slug: string, module: ModulePostJobOpp) => {
    const res: any = await getOppDtls(slug);
    if (res) {
      const {
        id,
        canvasId,
        website,
        tagLine,
        about,
        bookmarkTag,
        jobCategory,
        jobTitle,
        jobType,
        location,
        email,
        expire,
        createdAt,
        updatedAt,
        validForAllState,
        description,
        additionalInformation: { brands, softwares },
        paymentMode,
        media,
        poster,
        status,
        slug,
        applyUrl,
        competition,
        brand
      } = res;
      const {
        thumbnailPhoto,
        featuredPhoto,
        companyPhoto,
        featuredVideo,
      } = module.mediaInfo;
      plusAction(setPosterAction, poster);
      canvasId && plusAction(getCanvasDetailAction, Number(canvasId));
      const newOpp = {
        opportunityId: id,
        competition,
        website,
        tagLine,
        about,
        bookmarkTag,
        status,
        slug,
        opportunityInfo: {
          ...module.opportunityInfo,
          selectLocation: location,
          jobTitle,
          jobCategory,
          jobType,
          email,
          applyUrl,
          expirationDate: expire,
          createdAt: createdAt,
          updatedAt: updatedAt,
          validForAllState,
          editor: isHtmlTag(description)
            ? description
            : htmlDecode(description),
          isShowEditor: true,
          brand: brand
        },
        additionalInfo: {
          ...module.additionalInfo,
          retailBrands: parseOptions(brands),
          softwares: parseOptions(softwares),
          paymentStructure: paymentMode,
        },
        mediaInfo: media
          ? {
            thumbnailPhoto: media.thumbPhoto || thumbnailPhoto,
            featuredPhoto: media.featuredPhoto || featuredPhoto,
            companyPhoto: media.companyPhoto || companyPhoto,
            featuredVideo: media.featuredVideo
              ? parseVideo(media.featuredVideo, featuredVideo)
              : featuredVideo,
          }
          : { ...module.mediaInfo },
      };
      return newOpp;
    }
    return {};
  },
};

const parseOptions = (list: any) => {
  return list.map((item: any) => {
    const { optionId, optionName } = item;
    const newItem = { name: optionName };
    if (optionId) {
      Object.assign(newItem, { id: optionId });
    }
    return newItem;
  });
};

const parseVideo = (featureDVideo: any, modelVideo: any) => {
  const { image, url } = featureDVideo;
  if (url && !image) {
    const v = url.substring(url.lastIndexOf("/") + 1);
    if (v) {
      return {
        ...modelVideo,
        url: `https://www.youtube.com/watch?v=${v}`,
        image: `https://img.youtube.com/vi/${v}/0.jpg`,
      };
    }
  }
  return featureDVideo;
};

export const addBookmarkAction: ModuleAction<any, ModulePostJobOpp> = {
  module: MODULE_POST_JOB_OPP,
  process: async (params: any, module: ModulePostJobOpp) => {
    const res = await addBookmark(
      "job",
      module.opportunityId,
      module.bookmarkTag ? "remove" : "add"
    );
    if (res && res.success) {
      return {
        bookmarkTag: !module.bookmarkTag,
      };
    }
    return {};
  },
};
