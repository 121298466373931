import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import { getToken } from "../utils/authStorage";
import { appRouters, router_need_auth, global_router } from "../routers";

const _hsq = (window._hsq = window._hsq || []);

interface Props {}
const RouterGuard: React.FC<Props> = (props) => {
  if (!global_router.location) {
    global_router.location = (props as any).location;
  }
  if (
    window.location.pathname.indexOf(
      "do-you-own-a-salon-spa-or-barbershop-why-you-must-be-a-part-of-the-canvas-recruit-community"
    ) > -1
  ) {
    window.location.href =
      "/blog/do-you-own-a-salon-spa-or-barbershop-why-you-must-be-a-part-of-the-canvas-recruit-community/";
    return null;
  }
  if (
    window.location.pathname.indexOf("5-reasons-you-must-join-canvas-recruit") >
    -1
  ) {
    window.location.href = "/blog/5-reasons-you-must-join-canvas-recruit/";
    return null;
  }
  if (window.location.pathname.startsWith("/job/receptionist")) {
    window.location.href = "/opportunity-detail/107";
    return null;
  }
  if (window.location.pathname.startsWith("/job/seasoned-stylist-5-years")) {
    window.location.href = "/opportunity-detail/108";
    return null;
  }
  if (window.location.pathname.startsWith("/job/salon-apprentice")) {
    window.location.href = "/opportunity-detail/109";
    return null;
  }

  const renderNormalRoute = () => {
    return appRouters.map((_) => <Route key={_.path} {..._} />);
  };

  const renderLoginRoute = (pathname: string, resetPasswordLink: string) => {
    return (
      <Redirect
        to={`${pathname}?resetPasswordLink=${resetPasswordLink}&step=3`}
      />
    );
  };

  const renderRoute = () => {
    const logined = !_.isEmpty(getToken());
    if (!logined && router_need_auth(window.location.pathname)) {
      const path = window.location.pathname;
      return <Redirect to={`/sign-in?from=${path}`} />;
    }
    const searchObj = queryString.parse(window.location.search);
    const action: any = searchObj.action,
      resetPasswordLink: any = searchObj.resetPasswordLink;
    const actionPath: any = {
      forgot_pwd: "/sign-in",
    };
    const pathname = (action && actionPath[action]) || window.location.pathname;
    _hsq.push(["setPath", pathname]);
    _hsq.push(["trackPageView"]);
    if (action && pathname === "/sign-in" && !logined) {
      return renderLoginRoute(pathname, resetPasswordLink);
    } else {
      return renderNormalRoute();
    }
  };

  return <Switch>{renderRoute()}</Switch>;
};

export default RouterGuard;
