import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CanvasCardItem,
  FetchCanvasesAction,
  module_homepage_canvases,
  MODULE_HOMEPAGE_CANVASES,
  ModelHomepageCanvases,
  browse_canvas_filter_options,
  cur_filter_key,
  CacheFilterAction,
} from "../../models/model_homepage_canvases";
import {
  Input,
  Radio,
  Select,
  Button,
  List,
  Pagination,
  Icon,
  Modal,
  Spin,
} from "antd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Button as MatButton, capitalize } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ic_close from "../../assets/images/ic_close_black.svg";
import ic_filter from "../../assets/images/ic_filter.svg";
import ic_location from "../../assets/images/ic_item_location.svg";
import ic_canvas_mark from "../../assets/images/ic_canvas_mark.svg";
import { CanvasSearchItem } from "../../components/component_canvas_search_item";
import { doAction, mapProp, doFunction, MODULE_COMMON } from "module-reaction";
import { RadioChangeEvent } from "antd/lib/radio";
import "./homepage_canvases.styl";
import AddressTextField from "../../components/component_address_text_field";
import {
  GoogleUtil,
  sessionStorageGet,
  sessionStorageSet,
} from "../../utils/utils";
import _ from "lodash";

import SearchComponent from "./SearchComponent";

const { Search } = Input;
const { Option } = Select;
const { Group: RadioGroup } = Radio;
// let keywords_input: any;
interface HomepageCanvasesProp
  extends RouteComponentProps,
    ModelHomepageCanvases {}

@mapProp(module_homepage_canvases)
@mapProp(MODULE_COMMON)
export class HomepageCanvases extends React.Component<
  HomepageCanvasesProp,
  {}
> {
  state = {
    showMoreFilter: false,
    openMbMoreFilter: false,
    curType: "",
    filterKey: "",
  };

  private fetchData = (payload: any) => {
    doAction(FetchCanvasesAction, payload, "ok");
  };

  render() {
    if (!browse_canvas_filter_options.category.options) {
      return <Spin className="loading-spin" />;
    }
    const {
      page_idx,
      total,
      num_per_page,
      filters,
      use_location,
      loadingTag,
      list,
      canvas_type,
    } = this.props;

    let { type } = this.props;
    if (!type) {
      type = (this.props.match.params as any).type;
    }
    const { showMoreFilter } = this.state;

    const {
      locationInfo,
      radius,
      yearOfExperience,
      keywords,
      program,
    } = filters;

    return (
      <>
        <div className="homepage-canvases">
          <div className="search-item">
            <div className="item-title">{type} canvases</div>
            <SearchComponent
              value={{
                location: locationInfo.location,
                keywords,
              }}
              autoCompleteService={GoogleUtil.googleAutoCompleteService}
              disabled={loadingTag === "ok"}
              onChange={(val: any) => {
                if (Object.keys(val)[0] === "keywords") {
                  this.updateFilter(val["keywords"], "keywords");
                } else {
                  doAction(
                    MODULE_HOMEPAGE_CANVASES,
                    {
                      filters: {
                        ...filters,
                        locationInfo: {
                          ...locationInfo,
                          location: val.location,
                        },
                      },
                    },
                    "ok"
                  );
                  this.fetchData({
                    filters: {
                      ...filters,
                      locationInfo: {
                        ...locationInfo,
                        location: val.location,
                        ...val.addressInfo,
                      },
                    },
                  });
                  doAction(CacheFilterAction);
                }
              }}
            />
          </div>
          {/*<div className="title">*/}
          {/*{ type } canvases*/}
          {/*</div>*/}
          <div className="filter-con">
            <div
              className="item keyword-input input-wrap"
              style={{ display: "none" }}
            >
              {use_location ? (
                <AddressTextField
                  className="search-input"
                  label={`Location`}
                  value={locationInfo.location}
                  onInput={(value: string) => {
                    doAction(MODULE_HOMEPAGE_CANVASES, {
                      filters: {
                        ...filters,
                        locationInfo: {
                          ...locationInfo,
                          location: value,
                        },
                      },
                    });
                  }}
                  onAddressSelect={async (address: any) => {
                    const addressInfo = await GoogleUtil.onGoogleAddressSelected(
                      address
                    );
                    if (addressInfo) {
                      this.fetchData({
                        filters: {
                          ...filters,
                          locationInfo: Object.assign(
                            {},
                            locationInfo,
                            addressInfo
                          ),
                        },
                      });
                      doAction(CacheFilterAction);
                    }
                  }}
                  autoCompleteService={GoogleUtil.googleAutoCompleteService}
                  onPressEnter={this.pressEnterToSearchLocation}
                />
              ) : (
                <Search
                  className="search-input"
                  placeholder="Business name or canvas title..."
                  onSearch={this.onSearch}
                  ref={(e: any) => {
                    // keywords_input = e
                    if (e) {
                      e.input.setState({ value: keywords });
                    }
                  }}
                />
              )}
              <RadioGroup
                className={`lato-bold keyword-location-switch`}
                onChange={this.switchKeywordLocation}
              >
                <Radio.Button checked={!use_location} value="keyword">
                  Keyword
                </Radio.Button>
                <Radio.Button checked={use_location} value="location">
                  Location
                </Radio.Button>
              </RadioGroup>
            </div>

            <div className="item location-radius">
              <Select
                defaultActiveFirstOption
                value={radius}
                onChange={(val: string) => this.updateFilter(val, "radius")}
              >
                {browse_canvas_filter_options.radius.options.map((o) => (
                  <Option key={`radius-o-${o.key}`} value={o.key}>
                    {o.name}
                  </Option>
                ))}
              </Select>
              <span className="item-tag">RADIUS</span>
            </div>

            {type !== "school" && (
              <div className="item yearOfExperience">
                <Select
                  disabled={
                    !browse_canvas_filter_options.yearOfExperience.validTypes.includes(
                      canvas_type
                    )
                  }
                  defaultActiveFirstOption
                  value={yearOfExperience}
                  onChange={(val: string) =>
                    this.updateFilter(val, "yearOfExperience")
                  }
                >
                  {browse_canvas_filter_options.yearOfExperience.options.map(
                    (o) => (
                      <Option key={`yearOfExperience-o-${o.key}`} value={o.key}>
                        {o.name}
                      </Option>
                    )
                  )}
                </Select>
                <span className="item-tag">Years of experience</span>
              </div>
            )}
            {type === "school" && (
              <div className="item" key={`more-filter-item-program`}>
                <Select
                  defaultActiveFirstOption
                  value={program}
                  onChange={(val: string) => {
                    console.log(val);
                    this.updateFilter(val, "program");
                  }}
                >
                  {browse_canvas_filter_options.program.options &&
                    browse_canvas_filter_options.program.options.map(
                      (o: any) => (
                        <Option
                          key={`filter-dropdown-o-${o.key}`}
                          value={o.key}
                        >
                          {o.name}
                        </Option>
                      )
                    )}
                </Select>
                <span className="item-tag">PROGRAM</span>
              </div>
            )}
            <div className="item select-btns">
              {type === "professional" && (
                <Button
                  className="item filter-btn"
                  onClick={this.toggleMoreFilter}
                >
                  <img src={ic_filter} alt="filter" />
                  {showMoreFilter ? "LESS" : "MORE"} FILTERS
                </Button>
              )}
              <Button
                className="item filter-btn clear-btn"
                onClick={this.resetData}
              >
                <img src={ic_close} alt="filter" />
                CLEAR SEARCH
              </Button>
            </div>

            <MatButton
              className="item-search-btn"
              startIcon={<SearchIcon />}
              variant="outlined"
              onClick={this.showMbMoreFilter}
            >
              {" "}
              SEARCH
            </MatButton>

            {/*<span className="filter-btn-mb" onClick={this.showMbMoreFilter}>*/}
            {/*<img src={ic_filter} alt="filter" />*/}
            {/*</span>*/}
          </div>
          {/* <Button icon="right" className="mb-to-right" onClick={this.quickJobtypesToRight}></Button> */}

          {type === "professional" &&
            showMoreFilter &&
            this.genSeveralMoreFilters("more-filters")}

          <Spin
            // className="loading-spin"
            spinning={this.props.loadingTag === "ok"}
          >
            <div className="list-con">
              <List
                className="card-list"
                dataSource={list}
                grid={{ gutter: 12, xs: 1, sm: 1, md: 1, lg: 3 }}
                renderItem={(item: CanvasCardItem, index: number) => {
                  return (
                    <List.Item>
                      <CanvasSearchItem
                        key={`opportunity-card-item-${index}`}
                        className="opportunity-card"
                        media={item.media}
                        videoCover={item.videoCover}
                        link={item.link}
                        history={this.props.history}
                        is_video={item.is_video}
                        tags={getItemTags(item)}
                        timestamp={item.timestamp}
                        title={item.title}
                        addtions={getItemAdditions(item)}
                      />
                    </List.Item>
                  );
                }}
              />
              <Pagination
                className="bottom-pagination"
                size="small"
                pageSize={num_per_page}
                current={page_idx}
                total={total}
                showLessItems
                // showQuickJumper={true}
                onChange={this.onPageChange}
              />
              {num_per_page * page_idx < total && (
                <Button
                  className="lato-bold mb-load-more"
                  onClick={this.loadNextPage}
                >
                  LOAD MORE
                </Button>
              )}
            </div>
          </Spin>
        </div>
        {this.genMbMoreFilterPanel()}
      </>
    );
  }

  // private genQuickJobtypes(quick_job_types: { key: string, lbl: string }[], jobtypes: Set<string>) {
  //     return (
  //         <div className="quick-jobtypes" ref={v => this.quickJobtypesCon = v!}>
  //             {
  //                 quick_job_types.map(t => <CheckableTag
  //                     className="lato-bold job-type"
  //                     key={`job-type-o-${t.key}`}
  //                     onChange={e => this.toggleJobTypes(t.key)}
  //                     checked={Boolean(jobtypes?.has(t.key))}
  //                 >{t.lbl}</CheckableTag>)
  //             }
  //         </div>
  //     )
  // }

  private genMbMoreFilterPanel() {
    const { canvas_type, filters, loadingTag } = this.props;
    const {
      radius,
      yearOfExperience,
      locationInfo,
      keywords,
      program,
    } = filters;
    const { showMoreFilter, openMbMoreFilter } = this.state;
    return (
      <Modal
        visible={openMbMoreFilter}
        wrapClassName="mb-more-filter-panel"
        maskClosable={false}
        closable={false}
        footer={null}
        bodyStyle={{ padding: 0 }}
      >
        <Icon
          type="close"
          className="close-btn"
          onClick={this.hideMbMoreFilter}
        />
        <div className="title">Filters</div>
        {/*<RadioGroup*/}
        {/*className="lato-bold keyword-location-switch"*/}
        {/*onChange={this.switchKeywordLocation}*/}
        {/*>*/}
        {/*<Radio.Button checked={!use_location} value="keyword">*/}
        {/*Keyword*/}
        {/*</Radio.Button>*/}
        {/*<Radio.Button checked={use_location} value="location">*/}
        {/*Location*/}
        {/*</Radio.Button>*/}
        {/*</RadioGroup>*/}
        {/*{use_location ? (*/}
        {/*<AddressTextField*/}
        {/*className="item keyword-input"*/}
        {/*label={`Location`}*/}
        {/*value={locationInfo.location}*/}
        {/*onInput={(value: string) => {*/}
        {/*doAction(MODULE_HOMEPAGE_CANVASES, {*/}
        {/*filters: {*/}
        {/*...filters,*/}
        {/*locationInfo: {*/}
        {/*...locationInfo,*/}
        {/*location: value,*/}
        {/*},*/}
        {/*},*/}
        {/*});*/}
        {/*}}*/}
        {/*onAddressSelect={async (address: any) => {*/}
        {/*const addressInfo = await GoogleUtil.onGoogleAddressSelected(*/}
        {/*address*/}
        {/*);*/}
        {/*if (addressInfo) {*/}
        {/*doAction(FetchCanvasesAction, {*/}
        {/*filters: {*/}
        {/*...filters,*/}
        {/*locationInfo: Object.assign({}, locationInfo, addressInfo),*/}
        {/*},*/}
        {/*});*/}
        {/*doAction(CacheFilterAction);*/}
        {/*}*/}
        {/*}}*/}
        {/*autoCompleteService={GoogleUtil.googleAutoCompleteService}*/}
        {/*onPressEnter={this.pressEnterToSearchLocation}*/}
        {/*/>*/}
        {/*) : (*/}
        {/*<Search*/}
        {/*className="item keyword-input"*/}
        {/*placeholder="Business name or canvas title..."*/}
        {/*onSearch={this.onSearch}*/}
        {/*ref={(e) => {*/}
        {/*this.mb_keyword_input = e;*/}
        {/*if (this.mb_keyword_input) {*/}
        {/*this.mb_keyword_input.input.setState({ value: keywords });*/}
        {/*}*/}
        {/*}}*/}
        {/*/>*/}
        {/*)}*/}

        <SearchComponent
          value={{
            location: locationInfo.location,
            keywords,
          }}
          disabled={loadingTag === "ok"}
          autoCompleteService={GoogleUtil.googleAutoCompleteService}
          textChange={(keywords: string) => {
            doAction(MODULE_HOMEPAGE_CANVASES, {
              filters: {
                ...filters,
                keywords,
              },
            });
          }}
          onChange={(val: any) => {
            if (Object.keys(val)[0] === "keywords") {
              this.updateFilter(val["keywords"], "keywords");
            } else {
              doAction(MODULE_HOMEPAGE_CANVASES, {
                filters: {
                  ...filters,
                  locationInfo: {
                    ...locationInfo,
                    location: val.location,
                  },
                },
              });
              this.fetchData({
                filters: {
                  ...filters,
                  locationInfo: {
                    ...locationInfo,
                    location: val.location,
                    ...val.addressInfo,
                  },
                },
              });
              doAction(CacheFilterAction);
            }
          }}
        />

        <div className="item location-radius">
          <Select
            defaultActiveFirstOption
            value={radius}
            onChange={(val: string) => this.updateFilter(val, "radius")}
          >
            {browse_canvas_filter_options.radius.options.map((o) => (
              <Option key={`radius-o-${o.key}`} value={o.key}>
                {o.name}
              </Option>
            ))}
          </Select>
          <span className="item-tag">RADIUS</span>
        </div>

        {canvas_type && canvas_type !== "school" && (
          <div className="item expericen-years">
            <Select
              disabled={
                !browse_canvas_filter_options.yearOfExperience.validTypes.includes(
                  canvas_type
                )
              }
              defaultActiveFirstOption
              value={yearOfExperience}
              onChange={(val: string) =>
                this.updateFilter(val, "yearOfExperience")
              }
            >
              {browse_canvas_filter_options.yearOfExperience.options.map(
                (o) => (
                  <Option key={`yearOfExperience-o-${o.key}`} value={o.key}>
                    {o.name}
                  </Option>
                )
              )}
            </Select>
            <span className="item-tag">YEARS OF XPERIENCE</span>
          </div>
        )}
        {canvas_type === "school" && (
          <div className="item" key={`more-filter-item-program`}>
            <Select
              defaultActiveFirstOption
              value={program}
              onChange={(val: string) => {
                console.log(val);
                this.updateFilter(val, "program");
              }}
            >
              {browse_canvas_filter_options.program.options &&
                browse_canvas_filter_options.program.options.map((o: any) => (
                  <Option key={`filter-dropdown-o-${o.key}`} value={o.key}>
                    {o.name}
                  </Option>
                ))}
            </Select>
            <span className="item-tag">PROGRAM</span>
          </div>
        )}
        <div className="item select-btns">
          {canvas_type === "professional" && (
            <Button
              className="lato-bold filter-btn"
              onClick={this.toggleMoreFilter}
            >
              <img src={ic_filter} alt="filter" />
              {showMoreFilter ? "LESS" : "MORE"} FILTERS
            </Button>
          )}
          <Button
            className="item filter-btn clear-btn"
            onClick={this.resetData}
          >
            <img src={ic_close} alt="filter" />
            CLEAR
          </Button>
        </div>
        {canvas_type === "professional" &&
          showMoreFilter &&
          this.genSeveralMoreFilters("mb-more-filters")}
        <Button className="lato-bold action-btn" onClick={this.appyFilter}>
          APPLY FILTER
        </Button>
      </Modal>
    );
  }

  private resetData = () => {
    const { type, canvas_type } = this.props;
    this.fetchData({
      ...module_homepage_canvases,
      type,
      canvas_type,
    });
    doAction(CacheFilterAction);
  };

  private appyFilter = () => {
    this.setState({ openMbMoreFilter: false });

    if (this.props.filters.keywords) {
      this.onSearch(this.props.filters.keywords);
    }
  };
  private pressEnterToSearchLocation = () => {
    const {
      filters: { locationInfo },
    } = this.props;
    if (!locationInfo.location) {
      //when press enter, allow search when location cleared
      doAction(FetchCanvasesAction);
      doAction(CacheFilterAction);
    }
  };

  private genSeveralMoreFilters(className: string) {
    const {
      type,
      canvas_type,
      filters: {
        category,
        program,
        school,
        graduationDate,
        software,
        combinedAverageTicket,
        annualFrequencyOfVisit,
        repeatClientRetention,
        serviceToRetailSales,
      },
    } = this.props;
    const renderList: any[] = [
      {
        val: category,
        valid: browse_canvas_filter_options.category.validTypes.includes(
          canvas_type
        ),
        key: "category",
        opt:
          type === "business"
            ? browse_canvas_filter_options.category.options
            : browse_canvas_filter_options.category.options.concat(
                browse_canvas_filter_options.student
              ),
        lbl: "CATEGORY",
      },
      {
        val: program,
        key: "program",
        valid: browse_canvas_filter_options.program.validTypes.includes(
          canvas_type
        ),
        opt: browse_canvas_filter_options.program.options,
        lbl: "PROGRAM",
      },
      {
        val: school,
        key: "school",
        valid: browse_canvas_filter_options.school.validTypes.includes(
          canvas_type
        ),
        opt: browse_canvas_filter_options.school.options,
        lbl: "COSMETOLOGY SCHOOL",
      },
      // {
      //   val: graduationDate,
      //   valid: browse_canvas_filter_options.graduationDate.validTypes.includes(
      //     canvas_type
      //   ),
      //   key: "graduationDate",
      //   opt: browse_canvas_filter_options.graduationDate.options,
      //   lbl: "GRADUATION DATE",
      // },
      // {
      //   val: software,
      //   key: "software",
      //   valid: browse_canvas_filter_options.software.validTypes.includes(
      //     canvas_type
      //   ),
      //   opt: browse_canvas_filter_options.software.options,
      //   lbl: "SOFTWARE",
      // },
      // {
      //   val: combinedAverageTicket,
      //   key: "combinedAverageTicket",
      //   valid: browse_canvas_filter_options.combinedAverageTicket.validTypes.includes(
      //     canvas_type
      //   ),
      //   opt: browse_canvas_filter_options.combinedAverageTicket.options,
      //   lbl: "COMBINE AVERAGE TICKET",
      // },
      // {
      //   val: annualFrequencyOfVisit,
      //   key: "annualFrequencyOfVisit",
      //   valid: browse_canvas_filter_options.annualFrequencyOfVisit.validTypes.includes(
      //     canvas_type
      //   ),
      //   opt: browse_canvas_filter_options.annualFrequencyOfVisit.options,
      //   lbl: "ANNUAL FREQUENCY VISIT",
      // },
      // {
      //   val: repeatClientRetention,
      //   key: "repeatClientRetention",
      //   valid: browse_canvas_filter_options.repeatClientRetention.validTypes.includes(
      //     canvas_type
      //   ),
      //   opt: browse_canvas_filter_options.repeatClientRetention.options,
      //   lbl: "REPEAT CLIENT RETENTION",
      // },
      // {
      //   val: serviceToRetailSales,
      //   key: "serviceToRetailSales",
      //   valid: browse_canvas_filter_options.serviceToRetailSales.validTypes.includes(
      //     canvas_type
      //   ),
      //   opt: browse_canvas_filter_options.serviceToRetailSales.options,
      //   lbl: "SERVICE TO RETAIL SALES",
      // },
    ];
    return (
      <div className={className}>
        {renderList.map((r, idx) => {
          if (r.valid) {
            if (r.key === "school")
              return (
                <div className="item" key={`more-filter-item-${idx}`}>
                  <Autocomplete
                    className="material-input school-name-input"
                    options={r.opt}
                    value={this.getSchoolValue(r)}
                    getOptionLabel={(option: any) => {
                      return option.name || option;
                    }}
                    onChange={(event: object, value: any, reason: string) => {
                      let newValue = "all";
                      if (value && typeof value === "object") {
                        newValue = value.key;
                      }
                      this.updateFilter(newValue, r.key);
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={r.lbl}
                        variant="filled"
                        className="material-input"
                      />
                    )}
                  />
                </div>
              );
            else
              return (
                <div className="item" key={`more-filter-item-${idx}`}>
                  <Select
                    disabled={!r.valid && type !== "professional"}
                    defaultActiveFirstOption
                    value={r.val}
                    onChange={(val: string) => {
                      console.log(val);
                      this.updateFilter(val, r.key);
                    }}
                  >
                    {r.opt.map((o: any) => (
                      <Option key={`filter-dropdown-o-${o.key}`} value={o.key}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                  <span className="item-tag">{r.lbl}</span>
                </div>
              );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  private getSchoolValue(item: any) {
    const { val, opt } = item;
    if (val) {
      return _.find(opt, { key: val }).name;
    }
    return "";
  }
  static getDerivedStateFromProps(
    props: HomepageCanvasesProp,
    preState: { curType: string; filterKey: string }
  ) {
    let filterKey;
    const getHistoryFilterData = (filterKey: string) => {
      const model_cache = sessionStorageGet(filterKey);
      sessionStorageSet(cur_filter_key, filterKey);
      // if (keywords_input) {
      //   keywords_input.input.state.value = model_cache.filters.keywords || "";
      // }
      doAction(FetchCanvasesAction, model_cache, "ok");
    };
    if (window.location.search) {
      filterKey = new window.URLSearchParams(window.location.search).get(
        "filterKey"
      );
    }
    const cur_filter_key_value = sessionStorageGet(cur_filter_key);
    if (
      filterKey &&
      cur_filter_key_value &&
      filterKey !== cur_filter_key_value
    ) {
      getHistoryFilterData(filterKey);
      return;
    }
    const { type } = props.match.params as any;
    const routerState: any = props.location.state;
    if (preState.curType !== type || routerState) {
      preState.curType = type;
      preState.filterKey = "";
      delete props.location.state;
      // reset modlestore
      doAction(MODULE_HOMEPAGE_CANVASES, module_homepage_canvases);
      if (filterKey && sessionStorageGet(filterKey)) {
        getHistoryFilterData(filterKey);
      } else {
        let modelParam = {};
        if (routerState) {
          modelParam = Object.keys(routerState as object).reduce((obj, key) => {
            if (key.includes(".")) {
              const firstKey = key.split(".")[0];
              obj[firstKey] = { ...module_homepage_canvases[firstKey] };
              _.set(obj, key, routerState[key]);
            } else {
              obj[key] = routerState[key];
            }
            return obj;
          }, {} as any);
        }
        // update type
        doAction(
          FetchCanvasesAction,
          {
            type,
            canvas_type: type,
            ...modelParam,
          },
          "ok"
        );

        // cache filterkey
        doAction(CacheFilterAction);
      }
    }
    return preState as any;
  }

  private onSearch = (value: string) => {
    this.updateFilter(value, "keywords");
  };

  private updateFilter(val: any, key: string) {
    const { filters, type } = this.props;
    let { canvas_type } = this.props;
    if (key === "category") {
      canvas_type = (val as string).startsWith("student-") ? "student" : type;
    }
    this.fetchData({
      canvas_type,
      filters: { ...filters, [key]: val },
      page_idx: 1,
    });
    doAction(CacheFilterAction);
  }

  // private quickJobtypesToRight = (e: any) => {
  //     let left = this.quickJobtypesCon.scrollLeft
  //     left += 50
  //     this.quickJobtypesCon.scroll({ left })
  // }
  private switchKeywordLocation = (e: RadioChangeEvent) => {
    doAction(MODULE_HOMEPAGE_CANVASES, {
      use_location: e.target.value === "location",
    });
  };

  // private toggleJobTypes = (type: string) => {
  //     const { filters } = this.props
  //     let jobtypes = filters.jobtypes = filters.jobtypes || new Set<string>()
  //     if (jobtypes.has(type)) {
  //         jobtypes.delete(type)
  //     } else {
  //         jobtypes.add(type)
  //     }
  //     doAction(FetchCanvasesAction, { filters: { ...filters } }) // force redux re-render
  // }

  private toggleMoreFilter = (e: any) => {
    this.setState({ showMoreFilter: !this.state.showMoreFilter });
  };

  // show mobile more filter pannel
  private showMbMoreFilter = (e: any) => {
    this.setState({ openMbMoreFilter: true });
  };
  private hideMbMoreFilter = (e: any) => {
    this.setState({ openMbMoreFilter: false });
  };

  private onPageChange = (page_idx?: number) => {
    doAction(FetchCanvasesAction, { page_idx }, "ok");
    doFunction(async () => {
      window.scrollTo(0, 0);
    });
    doAction(CacheFilterAction);
  };

  private loadNextPage = () => {
    const { page_idx, total, num_per_page } = this.props;
    if (page_idx * num_per_page < total) {
      this.fetchData({ page_idx: page_idx + 1, isAppend: true });
    }
  };
}

function getItemTags(item: CanvasCardItem) {
  const tags: { label: string; style: React.CSSProperties }[] = [];
  if(item.type == 'school'){
    tags.push({
      label: item.type.toUpperCase(),
      style: {
        backgroundColor: "#F4F5F5",
        color: "#2D2E3D",
      },
    });
  }else{
    tags.push({
      label: item.category.toUpperCase(),
      style: {
        backgroundColor: "#F4F5F5",
        color: "#2D2E3D",
      },
    });
  }
  if (item.is_unread) {
    tags.push({
      label: "NEW",
      style: {
        backgroundColor: "transparent",
        color: "#5A8E09",
        border: "1px solid #e0ecce",
        borderRadius: "2px",
      },
    });
  }

  return tags;
}

function getItemAdditions(item: CanvasCardItem) {
  const adds = [
    {
      ico: <img src={ic_canvas_mark} alt="type-ico" />,
      txt: 'Beauty ' + capitalize(item.type),
    },
    {
      ico: item.type === 'student' || item.type === 'professional' ? (
        <span 
          role="img" 
          aria-label="school-ico" 
          style={{ filter: 'grayscale(100%)' }}
        >
          🏫
        </span>
      ) : item.biz_ico ? (
        <img src={item.biz_ico} alt="biz-ico" />
      ) : (
        <span className="name-ico">{item.biz_name?.charAt(0) || ''}</span>
      ),
      txt: item.type === 'student' || item.type === 'professional' ? item.schoolName || '' : item.biz_name || '',
    },
    {
      ico: <img src={ic_location} alt="loc-ico" />,
      txt: item.location,
    },
  ];

  return adds;
}
