import React from "react";
import { MembershipUser } from "./membership_user";

interface IMembershipProps {
  history: any;
}

export class MembershipIndex extends React.Component<IMembershipProps, any> {
  public render() {
    return (
      <div className="membership-view">
        <h1 className="common-title">Membership</h1>
        <MembershipUser {...this.props} />
      </div>
    );
  }
}