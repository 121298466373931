import React from "react";
import "./component_canvas_link.less";
import CRTextArea from "./component_cr_textarea";
import IconCopy from "../assets/images/ic_copy_link.svg";
import { copyToClipboard } from "../utils/utils";

interface Props {
  slug?: string;
}

export const CanvasLink: React.FC<Props> = (props) => {
  const { slug } = props;
  const fullLink = `${window.location.origin}/canvas/${slug}`;
  return (
    <div className="cm-canvas-link-root">
      <div className="cm-canvas-link-wrapper">
        <CRTextArea
          onInput={() => { }}
          type="text"
          disabled={true}
          label="Your Canvas Link"
          placeholder="Your Canvas Link"
          value={fullLink}>
        </CRTextArea>
      </div>
      <button className="ant-btn" title="Copy to clipboard">
        <div onClick={() => {
          const link = `${window.location.origin}/canvas/${slug}`;
          copyToClipboard(link);
        }}>
          <img src={IconCopy} alt="Copy link" />
        </div>
      </button>
    </div>
  )
}
