import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { Modal, Button, Divider } from "antd";
import noop from "lodash/noop";
import get from "lodash/get";

const errorColor = "#fa755a";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: errorColor,
      iconColor: errorColor,
    },
  },
};

const CardForm = ({
  afterPay = noop,
  billingDetail = {} as any,
  onClose = noop,
  clientSec = "",
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event: any) => {
    if (event.error) {
      setErrorMsg(event.error.message);
    } else {
      setErrorMsg("");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const { name, email, address, city, state, country, zip } = billingDetail;
    setLoading(true);
    const billing_details = {
      address: {
        city,
        country,
        state,
        postal_code: zip,
        line1: address,
      },
      email,
      name,
    };
    let payload;
    if (clientSec) {
      payload = await stripe.confirmCardSetup(clientSec, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details,
        },
      });
    } else {
      payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
        billing_details,
      });
    }
    if (payload.error) {
      const message = payload.error.message ? payload.error.message : "";
      setErrorMsg(message);
      setLoading(false);
      return;
    }
    setErrorMsg("");
    afterPay(clientSec ? get(payload, 'setupIntent.payment_method') : get(payload, "paymentMethod.id"));
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />

      {errorMsg && (
        <div style={{ marginTop: 20, color: errorColor, minHeight: 20 }}>
          {errorMsg}
        </div>
      )}

      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="primary"
          disabled={!stripe}
          onClick={handleSubmit}
          style={{ marginLeft: 10 }}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const StripeModal = ({
  visible = false,
  afterPay = noop,
  onClose = noop,
  billingDetail = {},
  stripeKey = "",
  clientSec = "",
}) => {
  let stripePromise: any;
  if (stripeKey) {
    stripePromise = loadStripe(stripeKey);
  }
  return (
    <Modal
      footer={null}
      title="Update payment method"
      visible={visible}
      onCancel={onClose}
      centered={true}
    >
      {stripeKey && (
        <Elements stripe={stripePromise!}>
          <CardForm
            afterPay={afterPay}
            billingDetail={billingDetail}
            onClose={onClose}
            clientSec={clientSec}
          />
        </Elements>
      )}
    </Modal>
  );
};

export default StripeModal;
