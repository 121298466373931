/**
 * Http.ts
 *
 * Axios configuration for headers and request/response interceptors
 */
import axios from "axios";
import { getToken, clearUser, getTrackId } from "../utils/authStorage";
import { get } from "lodash";
import { message } from "antd";
import { router_get_started, router_sign_in } from "../utils/enum";
import { global_router } from "../routers";
import store from "../utils/store";
// base url for api requests

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
// UA
// const result = new UAParser().getResult();

// capture the last url in the event of a response error
let lastUrl: string | undefined;

http.interceptors.request.use(
  // request successful
  function (config) {
    // set the last url in the event of a response error
    lastUrl = config.url;
    const token = getToken();
    const trackId = getTrackId();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (trackId) {
      config.headers["referralId"] = trackId;
    }
    config.headers["platform"] = "web";
    config.headers["Accept"] = "application/json";
    return config;
  },

  // request error
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  // response successful
  function (response) {
    return response;
  },

  // response error so publish the error for subscribers to consume
  function (error) {
    console.error("API_ERROR", error.response);
    let showError = true;
    if (get(error, "response.data.statusCode") === 401) {
      if (window.location.pathname !== router_sign_in) {
        store.dispatch({ type: 'SHOW_RELOGIN_MODAL' });
      }
      clearUser();
    } else if (get(error, "response.data.statusCode") === 10006) {
      showError = false;
      if (window.location.pathname !== router_get_started) {
        global_router.history.push(router_get_started, { pageName: "verify" });
      }
    } else if (get(error, "response.config.url") === '/instagram/home-medias') {
      showError = false;
    }

    if (showError && error.response.data) {
      message.error(error.response.data.message);
    }
    return Promise.reject({
      status: get(error, "response.data.statusCode") || get(error, "status"),
      statusText:
        get(error, "response.statusText") || get(error, "statusText", ""),
      message: get(error, "response.data.message", ""),
      url: lastUrl,
    });
  }
);

export default http;
