import { ModuleStore, ModuleAction, KV } from "module-reaction";
import { getCustomized } from "../api/Options";
import { studentRows } from "./model_school_dashboard_students";
export const MODULE_SCHOOL_DASHBOARD_ADD_STUDENT =
  "module_school_dashboard_add_student";

export interface ModuleSchoolDashboardAddStudents extends ModuleStore {
  filter: {
    location?: any;
    program?: any;
    keyword?: string;
    orderBy?: string;
  };
  page_idx: number;
  num_per_page: number;
  total: number;
  locationOptions: any[];
  programOptions: any[];
  list: any[];
  selectList: number[];
}

export const module_school_dashboard_add_student: ModuleSchoolDashboardAddStudents = {
  module: MODULE_SCHOOL_DASHBOARD_ADD_STUDENT,
  filter: {
    location: 0,
    program: 0,
    keyword: "",
    orderBy: "",
  },
  page_idx: 1,
  num_per_page: 8,
  total: 100,
  locationOptions: [
    { name: "school1", id: 1 },
    { name: "school2", id: 2 },
    { name: "school3", id: 3 },
    { name: "All", id: 0 },
  ],
  programOptions: [{ name: "All", id: 0 }],
  list: studentRows,
  selectList: [],
};

export const GetStudentListAction: ModuleAction<
  KV,
  ModuleSchoolDashboardAddStudents
> = {
  module: MODULE_SCHOOL_DASHBOARD_ADD_STUDENT,
  process: async (filterItem: any, model: ModuleSchoolDashboardAddStudents) => {
    const newList = studentRows.reverse();
    return { ...filterItem, list: newList };
  },
};

export const UpdateSelectAction: ModuleAction<
  KV,
  ModuleSchoolDashboardAddStudents
> = {
  module: MODULE_SCHOOL_DASHBOARD_ADD_STUDENT,
  process: async (selectList: any) => {
    return { selectList };
  },
};

export const GetOptionsActions: ModuleAction<
  KV,
  ModuleSchoolDashboardAddStudents
> = {
  module: MODULE_SCHOOL_DASHBOARD_ADD_STUDENT,
  process: async () => {
    const res = await getCustomized(true, ["program"]);
    if (res) {
      return {
        programOptions: res.program.concat({ id: 0, name: "All" }),
      };
    }
    return {};
  },
};
