import React from "react";
import { Layout } from "antd";
import _ from "lodash";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import { mapProp, doAction } from "module-reaction";
import { CanvasType } from "../../utils/enum";
import { PreviewMode } from "./canvas_preview";
import { IMediaItem } from "../../components/component_media_item";
import {
  GetPageDataAction,
  SubmitToPublishAction,
  UpdatePageInfoAction,
  module_student_canvas_preview,
  MODULE_STUDENT_CANVAS_PREVIEW,
} from "../../models/model_student_canvas_preview";
import FieldRequireModal from "../../components/component_field_require_modal";
import IconMobile from "../../assets/images/ic_mobile.svg";
import IconPC from "../../assets/images/ic_desktop.svg";
import CanvasPreview from "./canvas_preview";
import "./preview.less";
const { Content } = Layout;

interface Props {
  match: any;
  type: string;
  history: any;
  awardList: any[];
  socialMediaList: [];
  companyName: string;
  headline: string;
  desc: string;
  whyJoin: string;
  defaultLocation: any;
  contactNumber: string;
  category: any;
  additional: any;
  extraLocations: any[];
  logo: IMediaItem; // type, link
  featuredPhoto: IMediaItem;
  teamPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  extraVideo: IMediaItem[];
  emptyFields: string[];
}

@mapProp(module_student_canvas_preview)
export default class StudentPreview extends React.Component<Props, {}> {
  state = {
    id: this.props.match.params.id,
    isMobile: window.innerWidth < 1024,
  };

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  componentDidMount() {
    doAction(GetPageDataAction, this.state.id);
  }

  componentWillUnmount() {
    doAction(MODULE_STUDENT_CANVAS_PREVIEW, module_student_canvas_preview);
  }

  render() {
    return (
      <Layout>
        <CreateBusinessCanvasHeader
          type={this.props.type}
          step={1}
          onEditClick={() => {
            this.props.history.replace(
              `/${
                this.props.type === CanvasType.STUDENT
                  ? "student-canvas"
                  : "professional-canvas"
              }/${this.state.id}`
            );
          }}
          onPublishClick={() => {
            doAction(SubmitToPublishAction, this.props.history);
          }}
          onSaveDraftClick={() => {
            this.props.history.push("/my-canvases");
          }}
        />
        <Content>
          <div className="preview-container">
            <div className="top-sec">
              <span className="desktop">
                <img alt="" src={IconPC} />
                Desktop view
              </span>
              <img
                className="icon-mobile"
                alt=""
                src={IconMobile}
                onClick={() =>
                  window.open(
                    `/${
                      this.props.type === CanvasType.STUDENT
                        ? "student-canvas"
                        : "professional-canvas"
                    }/preview/${this.state.id}`,
                    "",
                    "height=812, width=375"
                  )
                }
              />
              <span className="space" />
              {/* <span className="note">
                Note: Payment method will be required when publish
              </span> */}
            </div>
            <div className="preview-wrap">
              <CanvasPreview
                type={this.props.type}
                mode={PreviewMode.VIEW}
                pageData={this.props}
                isMobile={this.state.isMobile}
              />
            </div>
          </div>
          <FieldRequireModal
            onClose={() => doAction(UpdatePageInfoAction, { emptyFields: [] })}
            visible={!_.isEmpty(this.props.emptyFields)}
            data={this.props.emptyFields}
            onEdit={() =>
              this.props.history.replace(
                `/${
                  this.props.type === CanvasType.PROFESSIONAL
                    ? "professional-canvas"
                    : "student-canvas"
                }/${this.state.id}`
              )
            }
          />
        </Content>
      </Layout>
    );
  }
}
