import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Avatar, message, Tooltip } from "antd";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import IconDel from "../../../assets/images/ic_delete.svg";
import Close from "../../../assets/images/ic_close.svg";
import IconSearch from "../../../assets/images/ic_search.svg";
import "./manage_member_modal.less";

interface Props {
  visible?: boolean;
  onClose: Function;
  onAdd: Function;
  channel?: any;
  curUser?: any;
}

interface ItemProps {
  member: any;
  onDelete?: Function;
  showDelete?: boolean;
}

const MemberItem: React.FC<ItemProps> = (props) => {
  const { member, onDelete, showDelete = true } = props;
  return (
    <div className="member-item">
      <Avatar className="avatar" src={member.image || ""} />
      <span className="name">{member.name || ""}</span>
      <span className="space" />
      <img
        alt=""
        src={IconDel}
        className="icon-del"
        hidden={!showDelete}
        onClick={() => onDelete && onDelete(member.id)}
      />
    </div>
  );
};

const ManageMemberModal: React.FC<Props> = (props) => {
  const { visible, onClose, onAdd, channel, curUser } = props;
  const [value, setValue] = useState("");
  const [count, setCount] = useState(0);
  const [isOwner, setIsOwner] = useState(false);
  const [members, setMembers] = useState([] as any[]);

  const initPageData = useCallback(() => {
    if (channel && channel.state && channel.state.members) {
      let memberList = _.map(channel.state.members.asMutable(), (item) => {
        return item.user;
      });
      setCount(memberList.length);
      if (value) {
        memberList = _.filter(memberList, (item) => {
          return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
      }
      setMembers(memberList);
    }
    if (channel.data && channel.data.created_by && curUser) {
      if (`${curUser.id}` === channel.data.created_by.id) {
        setIsOwner(true);
      }
    }
  }, [channel, value, curUser]);

  useEffect(() => {
    initPageData();
  }, [initPageData]);

  const removeMembersFromChannel = async (id: string) => {
    if (channel) {
      const res = await channel.removeMembers([id]);
      if (res && res.channel) {
        message.success("Remove Members Success");
        setTimeout(() => {
          initPageData();
        }, 1000);
      }
    }
  };

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      centered={true}
      wrapClassName="manage_member_modal-wrap"
    >
      <div className="manage_member_modal-root">
        <div className="title-sec">
          <Tooltip title={(channel && channel.data.name) || ""}>
            <span className="title">
              {(channel && channel.data.name) || ""}
            </span>
          </Tooltip>
          <span className="title"> ({count} members)</span>
          <img className="close" src={Close} alt="" onClick={() => onClose()} />
        </div>
        <div className="content">
          <div className="search-sec">
            <div className="input-wrap">
              <img alt="" src={IconSearch} />
              <TextField
                label="Search member by name..."
                variant="filled"
                value={value}
                className="material-input input"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </div>
            <Button
              className="btn-secondary btn-confirm"
              onClick={() => onAdd()}
              hidden={!isOwner}
            >
              Add members
            </Button>
          </div>
          <div className="list">
            {_.map(members, (item, index) => {
              return (
                <MemberItem
                  key={index}
                  member={item}
                  onDelete={(id: number) => removeMembersFromChannel(`${id}`)}
                  showDelete={isOwner && item.id !== `${curUser.id}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ManageMemberModal;
