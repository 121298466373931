import { Button, Icon } from "antd";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import React from "react";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CRExpansionPanel from "../../components/component_expansion_panel";
import FileUploader from "../../components/component_file_uploader";
import MediaItem, { IMediaItem } from "../../components/component_media_item";
import IconUpload from "../../assets/images/ic_upload_green.svg";
import { reorder } from "../../utils/utils";

interface WorkMediaSectionProps {
  photos?: IMediaItem[];
  videos?: IMediaItem[];
  onPhotoEdit: Function;
  onPhotoDelete: Function;
  onPhotoPost: Function;
  onVideoPost: Function;
  onVideoDelete: Function;
  curLoadingMedia: number;
  onPhotosReordered: Function;
  onVideosReordered: Function;
};

export default class WorkMediaSection extends React.Component<WorkMediaSectionProps, {}> {
  onDragEnd = (result: any) => {
    const { photos, onPhotosReordered } = this.props;
    const localPhotos = _.sortBy(photos, photo => photo.order);
    if (!result.destination) {
      return;
    }

    const items: IMediaItem[] = JSON.parse(JSON.stringify(localPhotos))
    const reordered: IMediaItem[] = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    for (let i = 0; i < reordered.length; i++) {
      reordered[i].order = i + 1;
    }

    onPhotosReordered(reordered);
  }

  onVideoDragEnd = (result: any) => {
    const { videos, onVideosReordered } = this.props;
    const localVideos = _.sortBy(videos, video => video.order);
    if (!result.destination) {
      return;
    }

    const items: IMediaItem[] = JSON.parse(JSON.stringify(localVideos))
    const reordered: IMediaItem[] = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    for (let i = 0; i < reordered.length; i++) {
      reordered[i].order = i + 1;
    }

    onVideosReordered(reordered);
  }

  render() {
    const {
      photos,
      onPhotoEdit,
      onPhotoDelete,
      onPhotoPost,
      videos,
      onVideoPost,
      onVideoDelete,
      curLoadingMedia
    } = this.props;
    const localPhotos = _.sortBy(photos, photo => photo.order);
    const localVideos = _.sortBy(videos, video => video.order);
    return (
      <CRExpansionPanel className="panel" title="My Work Photos & Videos">
        <div className="media-detail">
        <DragDropContext onDragEnd={this.onVideoDragEnd}>
            <Droppable droppableId="droppableVideo">
              {(provided: any, snapshot: any) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {localVideos?.map((item, index) => (
                    <Draggable key={item.image} draggableId={item.image} index={index}>
                      {(provided: any, snapshot: any) => (
                        <div
                          className="draggable"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}

                        >
                          <DragHandleIcon />
                          <MediaItem
                            key={index}
                            data={item}
                            className="item-media flex-fill"
                            showYoutubeLink={false}
                            char=""
                            onDelete={() => onVideoDelete(index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided: any, snapshot: any) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {localPhotos?.map((item, index) => (
                    <Draggable key={item.image} draggableId={item.image} index={index}>
                      {(provided: any, snapshot: any) => (
                        <div
                          className="draggable"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}

                        >
                          <DragHandleIcon />
                          <MediaItem
                            key={index}
                            data={item}
                            className="item-media flex-fill"
                            onDelete={() => onPhotoDelete(index)}
                            onEdit={() => onPhotoEdit(item, index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="upload-btn-wrap">
            <FileUploader
              isVideo={false}
              onPost={(file: any) => { onPhotoPost(file) }}
            >
              <Button className="btn-secondary btn-upload-video">
                {curLoadingMedia === 5 ? (
                  <Icon
                    type="loading"
                    className="loading"
                    style={{ color: "#83b23a" }}
                  />
                ) : (
                  <img alt="" src={IconUpload} />
                )}
                Add photo
              </Button>
            </FileUploader>

            <FileUploader
              isVideo={true}
              onPost={(file: any) => { onVideoPost(file) }}
            >
              <Button className="btn-secondary btn-upload-video">
                {curLoadingMedia === 4 ? (
                  <Icon
                    type="loading"
                    className="loading"
                    style={{ color: "#83b23a" }}
                  />
                ) : (
                  <img alt="" src={IconUpload} />
                )}
                Upload video
              </Button>
            </FileUploader>
          </div>
        </div>
      </CRExpansionPanel >
    );
  }
}
