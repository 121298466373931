import { KV, mapProp, doAction, getModuleProp } from "module-reaction";
import React from "react";
import { TargetType } from "../../utils/enum";
import { Button } from "antd";
import { SchoolDashboardIndex } from "./school_dashboard_index/school_dashboard_index";
import { RouteComponentProps } from "react-router";
import { ModleUser, modle_user } from "../../models/model_user";
import { BusinessDashboardIndex } from "./business_dashboard_index/business_dashboard_index";
import { OpportunityDashboardIndex } from "./opportunity_dashboard_index/opportunity_dashboard";
import IconEmpty from "../../assets/images/ic_empty_dashboard.svg";
import {
  model_dashboad_index,
  ModleDashBoardIndex,
  freshDashboardAction,
  MODULE_DASHBOARD_INDEX,
} from "../../models/model_dashboard_index";
import { Spin } from "antd";
import "./dashboard_index.less";
import { getQueryString } from "../homepage/homepage_opportunity";
import queryString from "query-string";

@mapProp(model_dashboad_index)
@mapProp(modle_user)
export default class DashboardIndex extends React.Component<
  RouteComponentProps & ModleUser & ModleDashBoardIndex,
  KV
> {
  state = {viewCanvas: ''}
  componentDidMount() {
    this.initData();
  }
  componentWillUnmount() {
    doAction(MODULE_DASHBOARD_INDEX, model_dashboad_index);
  }

  private async initData() {    
    const { viewCanvas } = queryString.parse(window.location.search);
    if (viewCanvas) {
      doAction(freshDashboardAction, { id: Number(viewCanvas) });
    } else {
      doAction(freshDashboardAction);
    }
  }

  componentWillReceiveProps(props: KV) {
    const viewCanvas = getQueryString('viewCanvas')
    if (viewCanvas && this.state.viewCanvas !== viewCanvas) {
      console.log('viewcanvas', viewCanvas)
      this.state.viewCanvas = viewCanvas
      const list = getModuleProp(MODULE_DASHBOARD_INDEX, 'canvasList')
      const curCanvas = list.find((_: any) => _.id == viewCanvas)
      if (curCanvas) {
        doAction(MODULE_DASHBOARD_INDEX, { curCanvas })
      }
    }
  }

  private emptyLayout = () => {
    return (
      <div className="dashboard-empty-root">
        <div className="common-title bold-title">Dashboard</div>
        <div className="center-sec">
          <img alt="" src={IconEmpty} />
          <span className="title">
            Create a canvas to start seeing your dashboard
          </span>
          <span className="subtitle">
            You will need to go to the MY CANVASES page to add a canvas
            portfolio first
          </span>
          <Button
            className="btn-primary btn-create"
            onClick={() => this.props.history.push("/add-canvas")}
          >
            Create a canvas
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { canvasList, curCanvas, noCanvas } = this.props;
    if (canvasList.length && curCanvas) {
      switch (curCanvas.type) {
        case "business":
          return <BusinessDashboardIndex {...this.props}/>;
        case "school":
          return <SchoolDashboardIndex {...this.props} />;
        case "student":
        case "professional":
          return <OpportunityDashboardIndex {...this.props} />;
        default:
          return null;
      }
    } else if (canvasList.length) {
      // render blank by user target type
      const target =
        this.props.curUser &&
        this.props.curUser.profile &&
        this.props.curUser.profile.target;
      switch (target) {
        case TargetType.SCHOOL:
          return <SchoolDashboardIndex {...this.props} />;
        case TargetType.BUSINESS:
          return <BusinessDashboardIndex {...this.props} />;
        default:
          return null;
      }
    } else if (noCanvas) {
      return this.emptyLayout();
    } else {
      return <Spin />;
    }
  }
}
