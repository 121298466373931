import React from "react";
import { mapProp } from "module-reaction";
import { ModuleCompanyInfo, module_company_info } from "../../models/model_company_info";
import { modle_user } from "../../models/model_user";
import CompanyInfo from "./company_info";

@mapProp(module_company_info)
@mapProp(modle_user)
export default class CompanyInfoWrapper extends React.Component<ModuleCompanyInfo, {}> {
  render() {
    return <CompanyInfo
      id={this.props.id}
      payOrderStatus={this.props.payOrderStatus}
      curUser={this.props.curUser}
      history={this.props.history}
      slug={this.props.slug}
      isDirect={true}
      categoryOptions={this.props.categoryOptions}
      businessCategory={this.props.businessCategory}
      companyName={this.props.companyName}
      contactNumber={this.props.contactNumber}
      scSalonId={this.props.scSalonId}
      defaultLocation={this.props.defaultLocation}
      extraLocation={this.props.extraLocation}></CompanyInfo>
  }
}
