import Http from "./Http";
import { CanvasType } from "../utils/enum";
import { reject } from "lodash";

export const createCanvas = (type: CanvasType, params: any) => {
  return Http.post(`/canvas/${type}`, { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const getCanvas = (id: string) => {
  return Http.get(`/canvas/${id}`)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const deleteCanvas = (id: number) => {
  return Http.delete(`/canvas/${id}`)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const editCanvas = (id: number, params: any) => {
  return Http.put(`canvas/${id}`, { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const upgradeCanvasToPro = (id: number, params: any) => {
  return Http.post(`canvas/upgrade/${id}`, { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const revertCanvasToStudent = (id: number) => {
  return Http.post(`canvas/revert/${id}`, {})
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
}

export const searchCanvas = (params: {
  type: string[]; // student, professional, business, school
  keyword?: string;
  userCategory?: {licensed: number}; // 0 or 1
  category?: number;
  location?: { coordinates: number[]; radius: number };
  school?: number;
  program?: number;
  software?: number;
  yearOfExperience?: number[]; // min, max
  combinedAverageTicket?: number[]; // min, max
  annualFrequencyOfVisit?: number[]; // min, max
  repeatClientRetention?: number[]; // min, max
  serviceToRetailSales?: number[]; // min, max
  graduationDate?: string[]; // min, max
  page: number;
  size: number;
}) => {
  return Http.post("/canvas/browse/searchv2", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const searchMyCanvases = (type: string, status: string) => {
  let params = { status: status } as any;
  if (type) {
    params = { ...params, type: type };
  }
  return Http.post("/canvas/my/canvases", params)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const getMyCanvases = (params: any) => {
  return Http.post("/canvas/my/canvases", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

//======== /school/xxxx =======

export const getSchoolStatistics = (
  id: number,
  duration: string[],
  location: number
) => {
  let params = {
    canvas_id: id,
    duration: duration,
  } as any;
  if (location) {
    params = { ...params, location: location };
  }
  return Http.post("/canvas/school/statistics", params)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const getBusinessStatistics = (id: number, location: number) => {
  let params = {
    canvas_id: id,
  } as any;
  if (location) {
    params = { ...params, location_id: location };
  }
  return Http.post("/canvas/business/statistics", params)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const updateCanvasStatus = (canvasId: number, status = "public") => {
  return Http.put(`/canvas/${canvasId}/action/${status}`)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const removeStudentFromSchool = (
  canvasId: number,
  studentId: number
) => {
  const param = { canvasId: canvasId, studentId: studentId };
  console.log("params", param);

  return Http.delete(`/canvas/school/remove-student`, {
    data: param,
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const removeStaffFromSchool = (
  canvasId: number,
  locationId: number,
  employeeId: number,
  business: boolean = false
) => {
  const param = { canvasId, locationId, employeeId, business };
  console.log("params", param);

  return Http.delete(`/canvas/school/remove-empoyee`, {
    data: param,
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const editStaffRole = (
  canvasId: number,
  locationId: number,
  employeeId: number,
  role: number,
  business: boolean = false
) => {
  const param = { canvasId, locationId, employeeId, role, business };
  console.log("params", param);

  return Http.put(`/canvas/school/employee-role`, { ...param })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export async function addEmployee(
  canvasId: number,
  data: { locationId: number; members: any[]; resendEmail?: boolean; business?:boolean }
) {
  return Http.post(`/canvas/school/add-employee`, { canvasId, ...data })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
}

export async function payStatus(
  canvas_id: number,
) {
  return Http.get(`/canvas/${canvas_id}`)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data.payorder?.status || 'none');
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => reject(null));
}
