import React, { useState, useEffect } from "react";
import { Modal, Button, message, Select } from "antd";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "../assets/images/ic_close.svg";
import "./component_add_award_modal.less";
import { SchoolRole } from "../utils/enum";
import classnames from "classnames";
import { searchUser } from "../api/Auth";
import { isEmailValid } from "../utils/validator";

interface Props {
  className?: string;
  visible?: boolean;
  business?: boolean;
  onClose: Function;
  onAdd: Function;
}



const AddCoworkerModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onAdd, business } = props;
  const [role, setRole] = useState(0);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [tags, setTags] = useState([] as string[]);

  const [searchUserList, setSearchUserList] = useState([] as any[]);
  const [selectedUsers, setSelectedUsers] = useState([] as any[]);
  const [keyword, setKeywords] = useState("");

  const roleOpt = () => {
    return business ? [
      {
        name: "Admin",
        type: SchoolRole.ADMIN,
      },
      {
        name: "Non-admin",
        type: SchoolRole.OTHER,
      },
    ] :
      [
        {
          name: "Admin",
          type: SchoolRole.ADMIN,
        },
        {
          name: "Teacher",
          type: SchoolRole.TEACHER,
        },
        {
          name: "Teacher Assistant",
          type: SchoolRole.TEACHER_ASSISTANT,
        },
        {
          name: "Other",
          type: SchoolRole.OTHER,
        },
      ] as any[];
  }

  useEffect(() => {
    const newTags = _.map(selectedUsers, (item) => {
      return item.firstName || item.lastName
        ? `${item.firstName} ${item.lastName}`
        : item.email || "";
    });
    setTags(newTags);
  }, [selectedUsers]);

  useEffect(() => {
    if (!keyword) return;
    searchUserByWords(keyword);
  }, [keyword]);

  const onSelfClose = () => {
    setKeywords("");
    setSelectedUsers([]);
    setRole(0);
    onClose();
  };

  const searchUserByWords = async (keyword: string) => {
    const result = await searchUser(keyword);
    setSearchUserList(result);
  };

  const onEmployClick = (employ: any) => {
    const has = _.find(selectedUsers, { id: employ.id });
    if (has) {
      const newList: any[] = _.filter(selectedUsers, (item: any) => {
        return item.id !== employ.id;
      });
      setSelectedUsers(newList);
    } else {
      setSelectedUsers([...selectedUsers, employ]);
    }
    setKeywords("");
  };

  const dropDownRender = () => {
    return !_.isEmpty(searchUserList) || keyword ? (
      <div className="drop-down">
        {_.isEmpty(searchUserList) ? (
          <div
            className="no-result-item"
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              if (isEmailValid(keyword)) {
                onEmployClick({ email: keyword });
              } else {
                message.error("Invalid Email Address");
              }
            }}
          >
            <span className="avatar">{keyword[0]}</span>
            <div className="no-result-center">
              <span className="no-result-inputed">{keyword}</span>
              <span className="email-address">Email address not confirmed</span>
            </div>
          </div>
        ) : (
            _.map(searchUserList, (item, index) => {
              return (
                <div
                  className="result-item"
                  key={index}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => onEmployClick(item)}
                >
                  <span className="avatar">
                    {!_.isEmpty(item.firstName) && item.firstName[0]}
                  </span>
                  {item.firstName || item.lastName
                    ? `${item.firstName} ${item.lastName}`
                    : item.email || ""}
                </div>
              );
            })
          )}
      </div>
    ) : (
        <div></div>
      );
  };

  return (
    <Modal
      footer={null}
      onCancel={() => onSelfClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="add-award-modal-wrap"
    >
      <div className="add-award-modal">
        <img
          className="close"
          src={Close}
          alt=""
          onClick={() => onSelfClose()}
        />
        <span className="title">Add Co-worker</span>
        <TextField
          select
          key="role"
          label="Role"
          variant="filled"
          value={role}
          onChange={(e: any) => {
            setRole(e.target.value);
          }}
          className="material-input input"
        >
          {_.map(roleOpt(), (item, index) => {
            return (
              <MenuItem key={index} value={item.type}>
                {item.name}
              </MenuItem>
            );
          })}
        </TextField>
        <div className={classnames("select", { onFocus: inputFocused })}>
          <div className="select-center-warp">
            <span
              className={classnames("label", { inputVisible: inputVisible })}
              onClick={() => {
                setInputVisible(true);
              }}
            >
              Email address or name
            </span>
            {inputVisible && (
              <Select
                autoFocus={true}
                className={classnames("select-input", {
                  hasTag: !_.isEmpty(tags),
                })}
                mode="tags"
                dropdownRender={dropDownRender}
                onInputKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (isEmailValid(keyword)) {
                      onEmployClick({ email: keyword });
                    }
                  }
                }}
                value={tags}
                onDeselect={(value: any) => {
                  setSelectedUsers(
                    _.filter(selectedUsers, (item) => {
                      return value.includes("@")
                        ? item.email !== value
                        : `${item.firstName} ${item.lastName}` !== value;
                    })
                  );
                }}
                onBlur={() => {
                  _.isEmpty(selectedUsers) && setInputVisible(false);
                  setInputFocused(false);
                  setKeywords("");
                }}
                onFocus={() => setInputFocused(true)}
                onSearch={(value: string) => setKeywords(value)}
              ></Select>
            )}
          </div>
        </div>

        <Button
          className="btn-primary btn-add"
          onClick={() => {
            if (!selectedUsers.length) {
              message.warn("Please enter at lease one user or email");
              return;
            }
            if (!role) {
              message.warn("Please pick a role to continue");
              return;
            }
            const members = selectedUsers.map((u) => {
              return {
                id: u.id,
                email: u.email,
                role,
              };
            });

            onAdd({ members });
            onSelfClose();
          }}
        >
          Add staff
        </Button>
      </div>
    </Modal>
  );
};
export default AddCoworkerModal;
