import { useEffect, useRef } from "react";

const useDebounce = (fn: Function, ms = 200, deps: any[] = []) => {
  let timeout: any = useRef();
  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      fn();
    }, ms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const cancel = () => {
    clearTimeout(timeout.current);
    timeout = null;
  };

  return [cancel];
};

export default useDebounce;
