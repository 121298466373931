import React, { useState } from "react";
import CRTextArea from "./component_cr_textarea";
import _ from "lodash";
import classnames from "classnames";
import useDebounce from "../hooks/useDebounce";
import "./component_address_text_area.less";

interface Props {
  className?: string;
  label: string;
  value?: string;
  onInput: Function;
  onAddressSelect: Function;
  autoCompleteService?: any;
  onClick?: Function;
}

const AddressTextArea: React.FC<Props> = (props) => {
  const {
    className = "",
    label = "",
    onInput,
    value,
    autoCompleteService,
    onAddressSelect,
    onClick,
  } = props;

  const [keywords, setKeywords] = useState<string>();
  const [addressOpts, setAddressOpts] = useState<any[]>([]);

  useDebounce(
    () => {
      if (!keywords || !autoCompleteService) {
        setAddressOpts([]);
      } else {
        autoCompleteService.getPlacePredictions(
          { input: keywords },
          (results: any, status: any) => {
            if (results && !_.isEmpty(results)) {
              setAddressOpts(results);
            }
          }
        );
      }
    },
    500,
    [keywords, autoCompleteService]
  );

  return (
    <div className={classnames(className, "address-text-area")}>
      <CRTextArea
        className="text-area"
        label={label}
        value={value || ""}
        onInput={(value: string) => {
          setKeywords(value);
          onInput(value);
        }}
        onClick={onClick}
      />
      {!_.isEmpty(addressOpts) && (
        <div className="dropdown-address">
          {_.map(addressOpts, (address, index) => {
            return (
              <span
                key={index}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  onAddressSelect(address);
                  setAddressOpts([]);
                }}
              >
                {address.description || ""}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AddressTextArea;
