import Http from "./Http";

export async function createFeatured (data: any) {
    return await Http.post('/featured', data)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
  }

export async function getFeatured () {
    return await Http.get('/featured')
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
  }
  
  export async function updateFeatured (data: any) {
    return Http.put('/featured', data)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
  }

  export async function deleteFeatured (data: any) {
    return Http.delete('/featured', data)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
  }

  export async function info (type: string, slug: string) {
    return Http.post('/featured/info', {type, slug})
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
  }
