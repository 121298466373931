import React, { FC, useRef, useEffect } from 'react';
import Chart from 'chart.js';

interface Dataset {
  label: string;
  data: Array<{ x: string | number, y: number }>;
  borderColor: string;
  backgroundColor: string;
}

interface Props {
  datasets: Dataset[];
  timeUnit: 'day' | 'week' | 'month' | 'quarter' | 'year';
}

const ChartComponent: FC<Props> = ({ datasets, timeUnit }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current && !chartInstance.current) {
      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: datasets.map(dataset => ({
            ...dataset,
            fill: false,
          })),
        },
        options: {
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: timeUnit,
                tooltipFormat: 'll',
              },
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            }],
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.data.datasets = datasets.map(dataset => ({
        ...dataset,
        fill: false,
      }));
      chartInstance.current.options.scales.xAxes[0].time.unit = timeUnit;
      chartInstance.current.update();
    }
  }, [datasets, timeUnit]);

  useEffect(() => {
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return <canvas ref={chartRef} />;
};

export default ChartComponent;
