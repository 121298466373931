import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import Close from "../../../assets/images/ic_close.svg";
import "./add_student_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  onSend: Function;
}

const AddStudentModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onSend } = props;
  const [emailList, setEmailList] = useState([]);

  useEffect(()=>{
    if(visible){
      setEmailList([])
    }
  },[visible])

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="add-student-modal-style"
    >
      <div className="add-student-modal">
        <img className="close" src={Close} alt="" onClick={() => onClose()} />
        <span className="title">Add to School</span>
        <Autocomplete
          freeSolo
          multiple
          className="tag-input email-input"
          value={emailList}
          options={[]}
          getOptionLabel={(option: any) => {
            return option.name || option;
          }}
          onChange={(event: object, value: any, reason: string) => {
            setEmailList(value);
          }}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Email address"
              variant="filled"
              className="material-input"
            />
          )}
        />
        <Button
          className="btn-primary btn-continue"
          disabled={_.isEmpty(emailList)}
          onClick={() => {
            onSend(emailList);
          }}
        >
          Send Invite
        </Button>
      </div>
    </Modal>
  );
};
export default AddStudentModal;
