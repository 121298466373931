import React, { useEffect, useRef } from "react";

import ipad from "../../../assets/images/home/ipad.png";
import ipadContent from "../../../assets/images/home/ipad-portfolio.jpg";

const ScrollingIpad: React.FC = () => {
  const screenImage = useRef(null);

  useEffect(() => {
    
    let screenScrollInterval: any = null;
    let screenScrollPosition: number = 0;
    screenScrollInterval = setInterval(() => {
      if (screenScrollPosition <= 50) {
        screenScrollPosition += 34;
      } else {
        screenScrollPosition = 0;
      }
      if (screenImage.current) {
        screenImage.current.style.transform = `translateY(-${screenScrollPosition}%)`;
      }
    }, 4000);
    return () => {
      clearTimeout(screenScrollInterval);
      screenScrollInterval = null;
    };
  }, []);

  return (
    <div className="cm-sp-scrolling-ipad">
      <img className="cm-sp-scrolling-ipad__backdrop" alt="ipad" src={ipad} />
      <div className="cm-sp-scrolling-ipad__screen">
        <img ref={screenImage} alt="screen" src={ipadContent} />
      </div>
    </div>
  )
};

export default ScrollingIpad;
