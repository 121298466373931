import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "../assets/images/ic_close.svg";
import "./component_add_award_modal.less";
import { SchoolRole } from "../utils/enum";

interface Props {
  className?: string;
  visible?: boolean;
  business?: boolean;
  initRole: number;
  onClose: Function;
  onEdit: Function;
}


const EditCoworkerModal: React.FC<Props> = (props) => {
  const { className, initRole, visible, onClose, onEdit, business } = props;

  const [role, setRole] = useState(initRole);
  const onSelfClose = () => {
    setRole(0);
    onClose();
  };

  const roleOpt = () => {
    return business ? [
      {
        name: "Admin",
        type: SchoolRole.ADMIN,
      },
      {
        name: "Non-admin",
        type: SchoolRole.OTHER,
      },
    ] :
      [
        {
          name: "Admin",
          type: SchoolRole.ADMIN,
        },
        {
          name: "Teacher",
          type: SchoolRole.TEACHER,
        },
        {
          name: "Teacher Assistant",
          type: SchoolRole.TEACHER_ASSISTANT,
        },
        {
          name: "Other",
          type: SchoolRole.OTHER,
        },
      ] as any[];
  }

  return (
    <Modal
      footer={null}
      onCancel={() => onSelfClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="add-award-modal-wrap"
    >
      <div className="add-award-modal">
        <img
          className="close"
          src={Close}
          alt=""
          onClick={() => onSelfClose()}
        />
        <span className="title">Edit role</span>
        <TextField
          select
          key="role"
          label="Role"
          variant="filled"
          value={role || initRole}
          onChange={(e: any) => {
            setRole(e.target.value);
          }}
          className="material-input input"
        >
          {_.map(roleOpt(), (item, index) => {
            return (
              <MenuItem key={index} value={item.type}>
                {item.name}
              </MenuItem>
            );
          })}
        </TextField>

        <Button
          className="btn-primary btn-add"
          onClick={() => {
            if (!role) {
              message.warn("Please choose a role");
              return;
            }

            onEdit({ role });
            onSelfClose();
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
export default EditCoworkerModal;
