import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import { TextField } from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import IconFeatureEmpty from "../assets/images/ic_feature_photo_empty.svg";
import IconCanvasName from "../assets/images/ic_home_location.svg";
import IconCalendar from "../assets/images/ic_calendar.svg";
import IconSuccess from "../assets/images/ic_check.svg";
import { sendMsgEmail } from "../api/Common";
import "./component_my_opp_list.less";
import { useHistory } from 'react-router-dom';

interface Props {
  dataList: any[];
  clickWithdraw: Function;
  forbidEdit?:boolean
}

export const MyOpportunityList: React.FC<Props> = (props) => {
  const { dataList, clickWithdraw, forbidEdit } = props;
  const [visibleMsgModal, setVisibleMsgModal] = useState(false);
  const [msgContent, setMsgContent] = useState("");
  const [opportunityId, setOpportunityId] = useState(0);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);

  const history = useHistory();

  const statusStyle: any = {
    interviewing: {
      color: "#FFFFFF",
      backgroundColor: "#83B23A",
    },
    applied: {
      color: "rgba(45, 46, 61, 0.5)",
      backgroundColor: "#F1DD23",
    },
    withdrawed: {
      color: "#96979E",
      backgroundColor: "#EAEAEC",
    },
  };

  const onMsgClose = () => {
    setVisibleMsgModal(false);
    setMsgContent("");
  };

  const sendMessage = (id: number) => {
    setOpportunityId(id);
    setVisibleMsgModal(true);
  };

  const sendMsg = () => {
    if (!msgContent) {
      message.info("please input your message");
      return;
    }
    const params = {
      msg: msgContent,
      template: "msg_to_job_poster",
      extra: {
        opportunity_id: opportunityId,
      },
    };
    sendMsgEmail(params).then(() => {
      onMsgClose();
      setVisibleSuccessModal(true);
    });
  };

  const renderOppItem = (item: any, index: number) => {
    const {
      id,
      jobTitle,
      media,
      location,
      canvasName,
      createdAt,
      expire,
      applyStatus,
      slug
    } = item;

    const getDateInterval = () => {
      const date = moment(createdAt);
      const now = moment();
      const duration = moment.duration(now.diff(date));
      return duration.days();
    };

    const getStatus = () => {
      return _.includes(["applied", "interviewing", "withdrawed"], applyStatus)
        ? applyStatus
        : "Pending";
    };
    return (
      <div className="opportunity-item" key={index}>
        <div className="left-sec">
          <div className="avatar-wrap">
            <img
              src={
                (media && media.thumbPhoto && media.thumbPhoto.image) ||
                IconFeatureEmpty
              }
              alt=""
            />
          </div>
          <div className="card-info-wrap">
            <div className="card-title">{jobTitle}</div>
            <div className="card-location">
              <LocationOnRoundedIcon className="card-icon" />
              <div className="location">
                {location?.address || "unknown address"}
              </div>
            </div>
            <div className="card-canvas-name">
              <img src={IconCanvasName} alt="" className="card-icon" />
              <div className="location"> {canvasName}</div>
            </div>
            <div className="card-date">
              <img alt="" src={IconCalendar} className="card-icon" />
              Published:
              <span className="bold">
                &ensp;{getDateInterval()} days ago;&ensp;
              </span>
              {/* {expire && (
                <>
                  Expiration:&ensp;
                  <span className="bold">{moment(expire).format("MM/YY")}</span>
                </>
              )} */}
            </div>

            <div
              className="apply-status"
              style={
                statusStyle[applyStatus] || {
                  color: "#96979E",
                  backgroundColor: "#EAEAEC",
                }
              }
            >
              {getStatus().toUpperCase()}
            </div>
          </div>
        </div>
        <div className="right-sec">
          <div
            className="apply-status"
            style={
              statusStyle[applyStatus] || {
                color: "#96979E",
                backgroundColor: "#EAEAEC",
              }
            }
          >
            {getStatus().toUpperCase()}
          </div>
          <div className="bottom-sec">
          <Button
              className="btn-secondary bottom-btn"
              onClick={() => history.push('/opportunity-detail/'+slug)}
            >
              View Opportunity
            </Button>
            <Button
              className="btn-secondary bottom-btn"
              disabled={forbidEdit}
              onClick={() => clickWithdraw && clickWithdraw(item)}
            >
              Withdraw
            </Button>
            <Button
              className="btn-primary bottom-btn"
              disabled={forbidEdit}
              onClick={() => sendMessage(id)}
            >
              Message Poster
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="my-opp-list-root">
      {dataList.map((item: any, index: number) => {
        return renderOppItem(item, index);
      })}
      <Modal
        footer={null}
        closable={false}
        onCancel={onMsgClose}
        visible={visibleMsgModal}
        centered={true}
        width="80%"
        className="my-opportunity-message-modal"
      >
        <div className="send-msg-modal">
          <div className="common-title">Send Message</div>
          <div className="modal-text">
            You are sending a message via email.{" "}
          </div>
          <TextField
            label="Message goes here"
            placeholder="Message goes here"
            multiline
            rows={4}
            variant="filled"
            className="material-input"
            value={msgContent}
            onChange={(event) => setMsgContent(event.target.value)}
          />
          {/* <div className="modal-text">{msgText[msgType].intro2}</div> */}
          <div className="btn-wrap">
            <Button type="primary" onClick={sendMsg}>
              Send Message
            </Button>
            <Button onClick={onMsgClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setVisibleSuccessModal(false)}
        visible={visibleSuccessModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Message sent!</div>
          <div className="modal-text">
            Woohoo! Your message was sent successfully.
          </div>
          <img src={IconSuccess} alt="success" />
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={() => setVisibleSuccessModal(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
