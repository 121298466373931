import React from "react";
import { Modal, Button } from "antd";
import Close from "../assets/images/ic_close.svg";
import "./component_delete_canvas_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  history?: any;
  onClose: Function;
  onDelete: Function;
}

const DeleteCanvasModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onDelete } = props;

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="delete-canvas-modal-wrap"
    >
      <div className="delete-canvas-modal-root">
        <img className="close" src={Close} alt="" onClick={() => onClose()} />
        <span className="title">Are you sure?</span>
        <span className="desc">
          By clicking on Delete, you are permanently removing this canvas. You
          will lose access to all data related to this canvas such as
          opportunities. Your data will be stored within 2 months. Please
          contact support@beautista.com if you want to restore this canvas.
        </span>
        <div className="btn-sec">
          <Button className="btn-primary btn-delete" onClick={() => onDelete()}>
            Permanently Delete
          </Button>
          <Button
            className="btn-secondary btn-cancel"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteCanvasModal;
