import { ModuleStore, ModuleAction, doAction } from "module-reaction";
import { sendEmail, resetPassord } from "../api/Auth";
import { getToken, clearUser } from "../utils/authStorage";
import { getUserInfo, editUserInfo } from "../api/Auth";
import { setUserLoginInfoAction } from "./model_home";

export interface User {
  id: string;
  email: string;
  lastSuccessfulLogin: string; // date
  lastFailedLogin: string; // date
  profile: {
    avatar: string;
    career: string;
    employmentStatus: string;
    firstName: string;
    jobTitles: any[];
    lastName: string;
    onboarding: any;
    target: string;
    phoneNumber: string;
    program: any;
    workPermitStatus: string;
    settings: any;
    verify: boolean;
    dashboardDot: boolean;
    source: any;
  };
  categories: any[];
}
export interface ModleUser extends ModuleStore {
  logined: boolean;
  list: User[]; // current result for show
  total: number; // total num of current search
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  curTab: "active" | "banned";
  curUser: User;
  forgotPwStep: number;
  sendSuccess: boolean;
}
export const MODULE_USER = "MODULE_USER";
export const modle_user: ModleUser = {
  module: MODULE_USER,
  logined: false,
  list: [],
  total: 0,
  searchKey: "",
  pageIdx: 1,
  pageSize: 10,
  curTab: "active",
  curUser: {} as User,
  forgotPwStep: 0,
  sendSuccess: false,
};

export const LoginWithTokenAction: ModuleAction<any, ModleUser> = {
  module: MODULE_USER,
  process: async () => {
    if (getToken()) {
      const res = await getUserInfo();
      if (res && res.user) {
        return {
          logined: true,
          curUser: res.user,
        };
      }
      return {};
    }
    return {};
  },
};

export const LogOutAction: ModuleAction<any, ModleUser> = {
  module: MODULE_USER,
  process: async () => {
    clearUser();
    return {
      curUser: {},
    };
  },
};

export const changeStepAction: ModuleAction<any, ModleUser> = {
  module: MODULE_USER,
  process: async (step: number, module: ModleUser) => {
    return {
      forgotPwStep: step,
    };
  },
};

export const sendEmailAction: ModuleAction<any, ModleUser> = {
  module: MODULE_USER,
  process: async (email: string, module: ModleUser) => {
    const res = await sendEmail(email);
    if (res) {
      return {
        forgotPwStep: 2,
        sendSuccess: res.success,
      };
    }
    return {};
  },
};

export const resetPassordAction: ModuleAction<any, ModleUser> = {
  module: MODULE_USER,
  process: async (params: any, module: ModleUser) => {
    const { forgotPasswordLink, password, history, fromPage } = params;
    const res = await resetPassord({ forgotPasswordLink, password });
    if (res && res.access_token && res.user) {
      doAction(setUserLoginInfoAction, {
        res,
        history,
        fromPage,
      });
    }
    return {};
  },
};

export const EditUserInfoAction: ModuleAction<any, ModleUser> = {
  module: MODULE_USER,
  process: async (profile: any, module: ModleUser) => {
    const result = await editUserInfo(profile);
    if (result && result.user) {
      return {
        curUser: result.user,
      };
    }
    return {};
  },
};

export const OpenReferralsAction: ModuleAction<any, ModleUser> = {
  module: MODULE_USER,
  process: async () => {
    const win = <any>window;
    if (win.growsurf) {
      win.growsurf.open();
    }
    return {};
  },
}
