import React from "react";
// import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import _ from "lodash";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import { Button, Modal, message } from "antd";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
// import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "../assets/images/Vectoric_visibility.svg";
import IconFeatureEmpty from "../assets/images/ic_feature_photo_empty.svg";
import IconDuplicate from "../assets/images/ic_duplicate.svg";
import IconCalendar from "../assets/images/ic_calendar.svg";
import IconMore from "../assets/images/ic_more.svg";
import IconCode from "../assets/images/ic_code.svg";
import IconDelete from "../assets/images/ic_delete.svg";
import IconEdit from "../assets/images/ic_edit.svg";

import BtnStatusIcon from "./component_btn_status_icon";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./component_posted_opp_list.less";
import { general_location_address } from "../models/model_post_job_opp";
import { deleteOpportunity } from "../api/Opportunity";
import { copyToClipboard } from "../utils/utils";
import { OpportunityCountDetail } from "./component_opp_view_count_detail";

interface Props {
  dataList: any[];
  edit?: Function;
  viewDetail?: Function;
  duplicate?: Function;
  updateStatus?: Function;
  navigateToApplicants?: Function;
  refresh?: Function;
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const PostedOppList: React.FC<Props> = (props) => {
  const {
    dataList,
    edit,
    updateStatus,
    navigateToApplicants,
    viewDetail,
    duplicate,
    refresh,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorCardEle, setAnchorCardEle] = React.useState<null | HTMLElement>(null);
  const [actionCard, setActionCard] = React.useState<null | HTMLElement>(null);
  const [opportunityId, setopportunityIdId] = React.useState(0);
  const [selectedOpportunityId, setSelectedOpportunityId] = React.useState(null)
  const [selectedSlug, setSelectedSlug] = React.useState(null)

  const statusList: Array<object> = [
    {
      option: "Active",
      statusDesc: ["public"],
      statusIconStyle: {
        color: "#83B23A",
        borderColor: "rgba(131, 178, 58, 0.25)",
      },
    },
    {
      option: "Inactive",
      statusDesc: ["private", "downgrade"],
      statusIconStyle: {
        color: "#F1DD23",
        borderColor: "rgba(241, 221, 35, 0.25)",
      },
    },
  ];

  const toggleMenu = (index: number, event: React.MouseEvent<HTMLElement>) => {
    setopportunityIdId(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const closePopover = () => {
    setAnchorCardEle(null);
  };

  const openActionPopover = (anchor: HTMLElement, card: any) => {
    setActionCard(card)
    setAnchorCardEle(anchor)
  }

  const getLink = (card: any) => {
    const { slug, jobTitle } = card;
    const url = `${window.location.origin}/opportunity-detail/${slug}`
    const code = `<a href="${url}" style="text-decoration: none;"><button style="height: 56px; border-radius: 6px; background-color: #000000; padding: 0 20px; display: flex; justify-content: space-between; align-items: center;">
    <svg style="margin-right: 20px;" width="14" height="32" viewBox="0 0 14 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-0.0357285 16L7.019 31.9091L8.39991 28.7322L14.0355 16H13.6957L8.23063 28.3494L2.76173 16H-0.0357285Z" fill="white"/>
        <path d="M14.0355 16.0009L6.97954 0.0917969L5.59991 3.27113L-0.0357285 16.0009H0.305362L5.77045 3.65518L11.2355 16.0009H14.0355Z" fill="white"/>
        </svg>
    <i style="border-left: 2px solid #444444; height: 100%; width: 0; margin-right: 10px;"></i>
    <div style="text-align: center; color: #ffffff; font-size: 16px; font-weight: 800;">${jobTitle} Opportunity</span>                
</button></a>`
    return code
  };

  const copyLink = (card: any) => {
    copyToClipboard(getLink(card))
  }

  const deleteOpp = (card: any) => {
    const { id } = card
    deleteOpportunity(id).then(_ => {
      refresh && refresh()
    })
  }


  const actionList = [
    {
      name: 'View',
      icon: VisibilityIcon,
      onClick: viewDetail
    },
    {
      name: 'Edit',
      icon: IconEdit,
      onClick: edit
    },
    {
      name: 'Copy embedded code',
      icon: IconCode,
      onClick: copyLink,
    },
    {
      name: 'Duplicate',
      icon: IconDuplicate,
      onClick: duplicate,
    },
    {
      name: 'Delete',
      icon: IconDelete,
      onClick: deleteOpp
    }
  ]
  const selectOption = (statusInfo: any) => {
    const statusObj: any = {
      Active: "public",
      Inactive: "private",
    };
    updateStatus &&
      updateStatus({ id: opportunityId, status: statusObj[statusInfo.option] });
    handleClose();
  };

  const renderPostedOppCard = (cardData: any, index: number) => {
    const {
      id,
      jobType,
      jobTitle,
      location,
      createdAt,
      expire,
      media,
      status,
      applicants,
      viewCount,
      validForAllState,
      competition
    } = cardData;

    const now = moment();
    const getDateInterval = () => {
      const now = moment();
      const createdDate = moment(createdAt);
      const diffInMonths = now.diff(createdDate, 'months');
      const diffInDays = now.diff(createdDate, 'days');
    
      if (diffInMonths > 2) {
        return diffInMonths + (diffInMonths === 1 ? ' month ago' : ' months ago');
      } else {
        return diffInDays + (diffInDays === 1 ? ' day ago' : ' days ago');
      }
    };

    const expired = expire && moment(expire).isBefore(now);
    const statusObj: any = expired
      ? {
        option: "Expired",
        statusDesc: ["private", "downgrade"],
        statusIconStyle: {
          color: "#aa0000",
          borderColor: "rgba(200, 0, 0, 0.25)",
        },
      }
      : _.find(statusList, (item: any) => {
        return _.includes(item.statusDesc, status);
      });

      function openViewChart () {
        setSelectedOpportunityId(id);
        const selectedOpportunity = dataList.find((item) => item.id === id);
        const selectedSlug = selectedOpportunity ? selectedOpportunity.jobTitle : '';
        setSelectedSlug(selectedSlug);
      }

    return (
      <div className="posted-opp-card" key={index}>
        <div className="pc-view">
          <div className="left-sec">
            <div className="avatar-wrap">
              <div className="time-type">{jobType}</div>
              <img
                src={
                  (media && media.thumbPhoto && media.thumbPhoto.image) ||
                  IconFeatureEmpty
                }
                alt=""
              />
            </div>
            <div className="card-info-wrap">
              <div className="card-title">{jobTitle} {competition && (<span className="tag">CONTEST</span>)}</div>
              <div className="card-location">
                <LocationOnRoundedIcon />
                <div className="location">
                  {" "}
                  {validForAllState ? general_location_address : location?.address || "unknown address"}
                </div>
              </div>
              <div className="card-date" title={moment(createdAt).format('MMM Do YYYY')}>
                <img alt="" src={IconCalendar} />
                Created:
                <span className="bold">
                &ensp;{getDateInterval()}
                </span>
              </div>
              <div className="card-status">
                <Button
                  disabled={expired}
                  className="btn-secondary status-btn"
                  onClick={(event) => toggleMenu(id, event)}
                >
                  <BtnStatusIcon iconStyle={statusObj.statusIconStyle} />
                  <div className="btn-text">{statusObj.option}</div>
                  <ArrowDropDownIcon className="dropdown-icon" />
                </Button>
                <Button
                  className="btn-secondary"
                  onClick={() =>
                    navigateToApplicants && navigateToApplicants(id)
                  }
                >
                  {applicants} applicants
                </Button>
                <Button type="link" className="btn-secondary view-count" 
                  onClick={() => openViewChart() }
                >
                  {viewCount ? viewCount : 0 } view{viewCount !== 1 && 's'}
                </Button>
              </div>
            </div>
          </div>
          <div className="card-actions" onClick={e => openActionPopover(e.currentTarget, cardData)}><img src={IconMore} alt="more actions" /></div>
          {/* <div className="card-action-wrap">
            <Tooltip title="View">
              <div
                className="action-btn"
                onClick={() => viewDetail && viewDetail(cardData)}
              >
                <img src={VisibilityIcon} alt="" />
              </div>
            </Tooltip>
            <Tooltip title="Edit" onClick={() => edit && edit(cardData)}>
              <div className="action-btn">
                <EditIcon />
              </div>
            </Tooltip>
            <Tooltip title="Duplicate">
              <div
                className="action-btn"
                onClick={() => duplicate && duplicate(cardData)}
              >
                <img src={IconDuplicate} alt="" />
              </div>
            </Tooltip>
          </div> */}
        </div>

        <div className="mobile-view">
          <div className="top-sec">
            <div className="avatar-wrap">
              <div className="time-type">{jobType}</div>
              <img
                src={
                  (media && media.thumbPhoto && media.thumbPhoto.image) ||
                  IconFeatureEmpty
                }
                alt=""
              />
            </div>
            <div className="card-actions" onClick={e => openActionPopover(e.currentTarget, cardData)}><img src={IconMore} alt="more actions" /></div>

            {/* <div className="card-action-wrap">
              <Tooltip title="View">
                <div
                  className="action-btn"
                  onClick={() => viewDetail && viewDetail(cardData)}
                >
                  <img src={VisibilityIcon} alt="" />
                </div>
              </Tooltip>
              <Tooltip title="Edit" onClick={() => edit && edit(cardData)}>
                <div
                  className="action-btn"
                  onClick={() => edit && edit(cardData)}
                >
                  <EditIcon />
                </div>
              </Tooltip>
              <Tooltip title="Duplicate">
                <div className="action-btn">
                  <img src={IconDuplicate} alt="" />
                </div>
              </Tooltip>
            </div> */}
          </div>

          <div className="card-info-wrap">
            <div className="card-title">{jobTitle}</div>
            <div className="card-location">
              <LocationOnRoundedIcon />
              <div className="location">
                {validForAllState ? general_location_address : location?.address || "unknown address"}
              </div>
            </div>
            <div className="card-date" title={moment(createdAt).format('MMM Do YYYY')}>
              <img alt="" src={IconCalendar} />
              Published:
              <span className="bold">
              &ensp;{getDateInterval()}
              </span>
            </div>
            <div className="card-status">
              <Button
                className="btn-secondary status-btn"
                disabled={expired}
                onClick={(event) => toggleMenu(id, event)}
              >
                <BtnStatusIcon iconStyle={statusObj.statusIconStyle} />
                <div className="btn-text">{statusObj.option}</div>
                <ArrowDropDownIcon className="dropdown-icon" />
              </Button>
              <Button className="btn-secondary">{applicants} applicants</Button>
              <Button type="link" className="btn-secondary view-count" ghost>
                {viewCount ? viewCount + "views" : ""}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="posted-opp-list-container">
      {dataList.map((row, index) => {
        return renderPostedOppCard(row, index);
      })}
      <StyledMenu
        id="customized-menu"
        className="status-dropdopwn"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statusList.map((item: any, index) => {
          return (
            <MenuItem key={index} onClick={() => selectOption(item)}>
              <BtnStatusIcon iconStyle={item.statusIconStyle}></BtnStatusIcon>
              <ListItemText className="option-text" primary={item.option} />
            </MenuItem>
          );
        })}
      </StyledMenu>
      <Menu
        id={"action-popover"}
        anchorEl={anchorCardEle}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorCardEle)}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="action-popover"
      >
        {actionList.map((item: any, index: number) => (
          <MenuItem
            onClick={() => {
              closePopover();
              item.onClick(actionCard);
            }}
            className={item.orangeText ? "orange-text" : ""}
          >
            <img src={item.icon} alt="" width={16} />&nbsp;&nbsp; {item.name}
          </MenuItem>
        ))}
      </Menu>
      <Modal
        visible={selectedOpportunityId}
        footer={null}
        onCancel={() => setSelectedOpportunityId(null)}
      >
        {selectedOpportunityId &&
          <OpportunityCountDetail opportunityId={selectedOpportunityId} slug={selectedSlug} />
        }
      </Modal>
    </div>
  );
};
