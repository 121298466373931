//created by pegg on 2020-08-21
import React, {useEffect, useState} from "react";
import { Input,Select, Icon } from 'antd';

import './SearchComponent.less'
import {GoogleUtil} from "../../utils/utils";

const { Option } = Select;


const SearchComponent: React.FC<Props> = ({
  autoCompleteService,
  onChange,
  textChange,
  placeholder = "Search by keywords",
  disabled = false,
  value: { keywords, location },
}: Props) => {
  const [dataList, setDatalist] = useState([]);
  const [value, setValue] = useState<string | undefined>(undefined);
  const [textValue, setTextValue] = useState<string | undefined>(undefined);

  const handleChange = async (val: string, option: any) => {
    if (!val) {
      onChange({
        location: "",
        addressInfo: "",
      });
    } else {
      console.log(val, option);
      const addressInfo = await GoogleUtil.onGoogleAddressSelected(
        JSON.parse(val)
      );
      console.log(addressInfo);
      onChange({
        location: JSON.parse(val)?.description,
        addressInfo,
      });
    }

    setValue(val);
  };

  const handleSearch = (val: string) => {
    if (val) {
      fetch(val, (data: []) => {
        setDatalist(data);
      });
    } else {
      setDatalist([]);
    }
  };

  const handleTextSearch = (e: any) => {
    onChange({
      keywords: e.target.value,
    });
  };

  const handleTextChange = (e: any) => {
    console.log(e.target.value);
    setTextValue(e.target.value);
    textChange && textChange(e.target.value);
  };

  const [timeout, setTimeoutVal] = useState<any>(null);

  const fetch = (val: string, callback: any) => {
    if (timeout) {
      clearTimeout(timeout);
      setTimeoutVal(null);
    }

    function fake() {
      autoCompleteService.getPlacePredictions(
        { input: val },
        (results: any, status: any) => {
          if (results && results.length > 0) {
            callback(results);
          }
        }
      );
    }

    setTimeoutVal(setTimeout(fake, 300));
  };

  useEffect(() => {
    setTextValue(keywords);
    setValue(location);
  }, [keywords, location]);

  return (
    <div className="search-component-container">
      <Input
        placeholder={placeholder}
        prefix={<Icon type="search" />}
        value={textValue}
        onPressEnter={handleTextSearch}
        disabled={disabled}
        onChange={handleTextChange}
      />
      <div className="select-wrapper">
        <Select
          showSearch
          disabled={disabled}
          value={value || undefined}
          placeholder="Search by location"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          allowClear
        >
          {dataList.length > 0 &&
            dataList.map((item: any, index) => {
              return (
                <Option key={item.place_id} value={JSON.stringify(item)}>
                  {item.description}
                </Option>
              );
            })}
        </Select>
        <Icon type="environment" />
      </div>
    </div>
  );
};


interface Props {
  value: {
    location: string | undefined;
    keywords: string | undefined;
  };
  autoCompleteService: any;
  disabled?: boolean;
  onChange: Function;
  textChange?: Function;
  placeholder?: string;
}

export default SearchComponent