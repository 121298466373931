import React from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import IconImage from "../assets/images/ic_add_image.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getFileUrlAction } from "../models/model_post_job_opp";
import "./component_editor.less";
import { cloneDeep } from "lodash";

interface Props {
  content?: string;
  getEditorConent: Function;
  placeholder?: string;
  toolbar?: object;
  hideTools?: string[]
}

export class EditorComponent extends React.Component<Props, {}> {
  state = {
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    const blocksFromHtml = htmlToDraft(this.props.content || "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.onEditorStateChange(editorState);
  }

  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
    });
  };

  uploadCallback = async (file: any) => {
    const url = await getFileUrlAction(file, 10);
    return new Promise((resolve, reject) => {
      resolve({ data: { link: url } });
    });
  };

  setEditorContent = (editorState: any) => {
    this.onEditorStateChange(editorState);
    this.props.getEditorConent(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  private toolbarConfig: object = {
    options: ["inline", "textAlign", "list", "link", "image", "colorPicker", "fontSize", "fontFamily"],
    inline: {
      inDropdown: false,
      className: "inline-wrap",
      options: ["bold", "italic", "underline"],
    },

    textAlign: {
      inDropdown: false,
      className: "text-align-wrap",
      options: ["left", "center", "right"],
    },
    list: {
      inDropdown: false,
      className: "list-wrap",
      // options: ["unordered", "ordered", "indent"],
      options: ["unordered", "ordered"],
    },
    link: {
      inDropdown: false,
      className: "link-wrap",
      popupClassName: "link-popup-wrap",
      showOpenOptionOnHover: true,
      defaultTargetOption: "_self",
      options: ["link"],
    },
    image: {
      icon: IconImage,
      inDropdown: false,
      className: "image-wrap",
      popupClassName: "image-popup-wrap",
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: this.uploadCallback,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
  };

  render() {
    const { editorState } = this.state;
    const { toolbar,hideTools, placeholder} = this.props
    const tools = toolbar || cloneDeep(this.toolbarConfig)
    if (hideTools?.length) {
      const options: string[] = (tools as any).options
      hideTools.forEach(t => {
        const idx = options.lastIndexOf(t)
        idx > -1 && options.splice(idx, 1)
      })
    }
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="editor-container"
          toolbarClassName="editor-toolbar-wrap"
          editorClassName="editor-content-wrap"
          onEditorStateChange={this.setEditorContent}
          placeholder={placeholder}
          toolbar={tools}
        />
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      </div>
    );
  }
}
