import Joi from "@hapi/joi";

const emailSchema = Joi.object({
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: false },
  }),
});
const passwordSchema = Joi.object({
  password: Joi.string().regex(/^(?=.*[a-z_])(?=.*[A-Z_])(?=.*[0-9_])(?=.*[!-\/:-@[-`{-~_]).{8,}$/),
});

const numberSchema = Joi.object({
  number: Joi.string().pattern(new RegExp("^\\d+(\\.{0,1}\\d+){0,1}$")),
});

const groupNameSchema = Joi.object({
  groupName: Joi.string().pattern(new RegExp("^[a-z0-9A-Z-_]+$")),
});

export const isEmailValid = (email: string) => {
  const result = emailSchema.validate({ email: email });
  return result.error ? false : true;
};

export const emailValidator = {
  validator: (rule: any, value: string, callback: any) => {
    if (value && !isEmailValid(value)) {
      callback("Please input your email address correctly");
    } else {
      callback();
    }
  },
};

export const isPasswordValid = (password: string) => {
  const result = passwordSchema.validate({ password: password });
  return result.error ? false : true;
};

export const isNumberValid = (number: string) => {
  const result = numberSchema.validate({ number: number });
  return result.error ? false : true;
};

export const numberValidator = {
  validator: (rule: any, value: string, callback: any) => {
    if (value && !isNumberValid(value)) {
      callback("Please input number only");
    } else {
      callback();
    }
  },
};

export const isGroupNameValid = (name: string) => {
  const result = groupNameSchema.validate({ groupName: name });
  return result.error ? false : true;
};
