import { ModuleStore, ModuleAction, getModuleProp } from "module-reaction";
import { doAction } from "module-reaction";
import { UpdateOppInfoAction, MODULE_POST_JOB_OPP } from "./model_post_job_opp";
import {
  opportunityApply,
  updateOppStatus,
  getSimilarData
} from "../api/Opportunity";
import { message } from "antd"
export const MODULE_OPPORTUNITY_DETAIL = "module_opportunity_detail";

export interface ModuleOpportunityDetail extends ModuleStore {
  opportunityDetail: any;
  modalParameters: {
    visible: false;
    title: string;
    desc: string;
    tips: string;
    applyBtnText: string;
    cancelText: string;
    onApply: Function;
    onClose: Function;
  };
  applyResultParam: {
    resultVisible: false;
    title: string;
    desc: string;
    btnText: string;
    clickBtn: Function;
    onClose: Function;
  };
  poster: number;
  similarData: any[];
}

export const module_opportunity_detail: ModuleOpportunityDetail = {
  module: MODULE_OPPORTUNITY_DETAIL,
  opportunityDetail: {},
  poster: 0,
  modalParameters: {
    visible: false,
    title: "",
    desc: "",
    tips: "",
    applyBtnText: "",
    cancelText: "",
    onApply: (msg: string) => {},
    onClose: () => {}
  },

  applyResultParam: {
    resultVisible: false,
    title: "",
    desc: "",
    btnText: "",
    clickBtn: () => {},
    onClose: () => {}
  },
  similarData: []
};

export const setModalParamAction: ModuleAction<any, ModuleOpportunityDetail> = {
  module: MODULE_OPPORTUNITY_DETAIL,
  process: async (param: any, module: ModuleOpportunityDetail) => {
    return {
      modalParameters: {
        ...module.modalParameters,
        ...param
      }
    };
  }
};

export const OpportunityApplyAction: ModuleAction<
  any,
  ModuleOpportunityDetail
> = {
  module: MODULE_OPPORTUNITY_DETAIL,
  process: async (param: any, module: ModuleOpportunityDetail) => {
    const res = await opportunityApply(param);
    if (res && res.success) {
      message.success('Your application has been submitted!')
      setApplySuccessModal();
      return {
        modalParameters: {
          ...module.modalParameters,
          visible: false
        }
      };
    }
    return {};
  }
};

const setApplySuccessModal = () => {
  const params = {
    resultVisible: true,
    title: "Message sent!",
    desc: "Woohoo! Your application was sent successfully.",
    btnText: "Back to My Applied Opportunities"
  };
  doAction(setApplyResultParamAction, params);
};

export const setApplyResultParamAction: ModuleAction<
  any,
  ModuleOpportunityDetail
> = {
  module: MODULE_OPPORTUNITY_DETAIL,
  process: async (param: any, module: ModuleOpportunityDetail) => {
    return {
      applyResultParam: {
        ...module.applyResultParam,
        ...param
      }
    };
  }
};

export const setOppStatusAction: ModuleAction<any, ModuleOpportunityDetail> = {
  module: MODULE_OPPORTUNITY_DETAIL,
  process: async (param: any, module: ModuleOpportunityDetail) => {
    const { id, status, opportunityInfo } = param;
    const res = await updateOppStatus({ id, status });
    if (res && res.success) {
      doAction(UpdateOppInfoAction, { ...opportunityInfo, status: status });
    }
    return {};
  }
};

export const setPosterAction: ModuleAction<any, ModuleOpportunityDetail> = {
  module: MODULE_OPPORTUNITY_DETAIL,
  process: async (poster: number, module: ModuleOpportunityDetail) => {
    return {
      poster
    };
  }
};

export const getSimilarDataAction: ModuleAction<
  any,
  ModuleOpportunityDetail
> = {
  module: MODULE_OPPORTUNITY_DETAIL,
  process: async (payload: any, module: ModuleOpportunityDetail) => {
    const id = getModuleProp(MODULE_POST_JOB_OPP, 'opportunityId')
    const res = await getSimilarData(id);
    if (res) {
      return {
        similarData: res.list
      };
    }
    return {};
  }
};

