import Http from "./Http";

export const getMyBookmarks = () => {
  return Http.get("/bookmark/me")
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const addBookmark = (
  type: string,
  id: number,
  action: string,
  note?: string
) => {
  let params = {} as any;
  if (note) {
    params = { note: note };
  }
  return Http.put(`/bookmark/${type}/${id}/${action}`, params)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};
