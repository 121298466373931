import React, { useState, useEffect, useCallback } from "react";
import { Pie } from "ant-design-pro/lib/Charts";
import { Row, Col } from "antd";
// import _ from "lodash";
import { TextField, MenuItem } from "@material-ui/core";
import { getBusinessStatistics } from "../../../api/Canvas";
import "./business_dashboard_stats.less";
// import "ant-design-pro/dist/ant-design-pro.css";

interface InfoCardProps {
  title: string;
  value: string;
}
interface BusinessStatsProps {
  canvas: any;
}

const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { title, value } = props;

  return (
    <div className="card-root">
      <span className="card-title">{title || ""}</span>
      <span className="card-value">{value || ""}</span>
    </div>
  );
};

const BusinessDashboardStats: React.FC<BusinessStatsProps> = (props) => {
  const { canvas } = props;

  const [location, setLocation] = useState(0);
  const [locationList, setLocationList] = useState([] as any[]);
  const [pieData, setPieData] = useState([] as any[]);
  const [pageData, setPageData] = useState({} as any);

  useEffect(() => {
    if (!canvas || !canvas.id || !canvas.locations) return;
    const loc = [{ id: 0, address: "All" }].concat(canvas.locations);
    setLocationList(loc);
    setLocation(0);
  }, [canvas]);

  const getPageData = useCallback(async () => {
    if (!canvas.id) return;
    const result = await getBusinessStatistics(canvas.id, location);
    setPageData(result);
    const {
      apply,
      scheduledInterviewDate,
      beingScreened,
      interviewing,
      scheduledTechDemonstration,
      interviewed,
      submittedToHiringLeader,
      offerPending,
      offerAccepted,
      hired,
      offerDeclined,
      notAFit,
      withdrawed,
    } = result;
    const pie = [
      {
        x: "Applied",
        y: apply || 0,
      },
      {
        x: "Scheduled Interview Date",
        y: scheduledInterviewDate || 0,
      },
      {
        x: "Being screened ",
        y: beingScreened || 0,
      },
      {
        x: "Currently Interviewing",
        y: interviewing || 0,
      },
      {
        x: "Scheduled technical demonstration ",
        y: scheduledTechDemonstration || 0,
      },
      {
        x: "Interviewed",
        y: interviewed || 0,
      },
      {
        x: "Submitted to a  hiring leader",
        y: submittedToHiringLeader || 0,
      },
      {
        x: "Offer pending",
        y: offerPending || 0,
      },
      {
        x: "Offer accepted",
        y: offerAccepted || 0,
      },
      {
        x: "Hired",
        y: hired || 0,
      },
      {
        x: "Offer declined",
        y: offerDeclined || 0,
      },
      {
        x: "Not a fit",
        y: notAFit || 0,
      },
      {
        x: "Withdrawed",
        y: withdrawed || 0,
      },
    ];
    setPieData(pie);
  }, [canvas, location]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  return (
    <div className="business-dashboard-stats-root">
      <div className="title">Business Dashboard</div>
      <div className="subtitle-sec">
        <span className="subtitle">Overall Statistics</span>
        <TextField
          label="location"
          variant="filled"
          select
          className="material-input location-select"
          value={location}
          onChange={(e) => setLocation(Number(e.target.value))}
        >
          {locationList.map((item: any, index: number) => (
            <MenuItem key={index} value={item.id}>
              {item.address}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="content">
        <div className="chart-sec">
          <span className="chart-title">Opportunities Status</span>
          <Pie
            hasLegend
            title="Total No.   Candidates"
            subTitle="Total No.   Candidates"
            total={() => <span>{pageData.apply}</span>}
            data={pieData}
            valueFormat={(val) => <span>{val}</span>}
            height={150}
          />
        </div>
        <div className="total-sec">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InfoCard
                title={"Active Opportunities"}
                value={(pageData.opportunity || 0) + ""}
              />
            </Col>
            <Col span={12}>
              <InfoCard
                title={"Active Opportunity Views"}
                value={(pageData.opportunityView || 0) + ""}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InfoCard
                title={"No. Candidates"}
                value={(pageData.apply || 0) + ""}
              />
            </Col>
            <Col span={12}>
              <InfoCard
                title={"Currently Interviewing "}
                value={(pageData.interviewing || 0) + ""}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InfoCard
                title={"Interviewed"}
                value={(pageData.interviewed || 0) + ""}
              />
            </Col>
            <Col span={12}>
              <InfoCard title={"Hired"} value={(pageData.hired || 0) + ""} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BusinessDashboardStats;
