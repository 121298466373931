import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import { Layout, Button, message } from "antd";
import classnames from "classnames";
import { mapProp, doAction, doFunction } from "module-reaction";
import queryString from "query-string";
import Logo from "../../assets/images/new_logo_white.svg";
import IconCheck from "../../assets/images/ic_check.svg";
import BlackLogo from "../../assets/images/logo.svg";
import IconFb from "../../assets/images/ic_fb.png";
import IconClose from "../../assets/images/ic_close_black.svg";
import "./user_signin.less";
import { isPasswordValid } from "../../utils/validator";
import { LoginWithEmailAction } from "../../models/model_home";
import CommonLoadingModal from "../../components/component_common_loading_modal";
import {
  modle_user,
  changeStepAction,
  sendEmailAction,
  resetPassordAction,
} from "../../models/model_user";
import { onClickGoogleLogin } from "../../utils/googleLogin";
import IconGoogle from "../../assets/images/ic_google.svg";
import { isEmailValid } from "../../utils/validator";
import { router_homepage_index, router_get_started } from "../../utils/enum";
const { Header, Content } = Layout;

interface UserSigninProp extends RouteComponentProps {
  sendSuccess: boolean;
  forgotPwStep: number;
}

@mapProp(modle_user)
export default class UserSigninView extends React.Component<
  UserSigninProp,
  {}
> {
  state = {
    email: "",
    password: "",
    emailValid: false,
    canLogin: false,
    resetPwEmail: "",
    newPassword: "",
    confirm: "",
    fromPage: "",
    forgotPasswordLink: "",
    showTip: true,
    showLoading: false,
  };

  componentDidMount() {
    const {
      from = "/",
      step = 0,
      resetPasswordLink,
      type,
      slug,
    } = queryString.parse(this.props.history.location.search);
    let fromP = from?.toString().indexOf("￥")
      ? from.toString().replace(/￥/g, "&")
      : from;
    if (type) {
      if (type === "student") {
        fromP = `/student-canvas${slug ? `/${slug}` : ""}`;
      }
    }

    if (this.checkSchoolFlow()) {
      fromP = `/company-info`;
    }

    this.setState({
      fromPage: fromP,
      forgotPasswordLink: resetPasswordLink,
    });
    doAction(changeStepAction, Number(step));

    this.initFacebook();
  }

  private initFacebook(): void {
    const win = window as any;
    if (!win.isFbInit) {
      win.isFbInit = true;
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }
  }

  private checkSchoolFlow(): boolean {
    const query = new URL(window.location.href);
    const school = query.searchParams.get("school");
    if (school === "paul_mitchell") {
      return true;
    }
    return false;
  }

  private recPassword(password: string) {
    this.setState({ password });
  }

  private recEmail(email: string) {
    this.setState({ email });
  }

  private onLogin = () => {
    const query = new URL(window.location.href);
    const redirect_uri = query.searchParams.get("redirect_uri");
    const state = query.searchParams.get("state");
    if (redirect_uri && state) {
      this.oauthLogin(redirect_uri, state);
      return;
    }

    this.setState({ showLoading: true });
    doAction(LoginWithEmailAction, {
      type: "email",
      email: this.state.email,
      password: this.state.password,
      history: this.props.history,
      fromPage: this.state.fromPage,
    });
    doFunction(async () => {
      this.setState({ showLoading: false });
    });
  };

  private oauthLogin = async (redirect_uri: string, state: string) => {
    const { email, password } = this.state;
    this.setState({ showLoading: true });    
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/oauth/authorize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password
        })
      });

      if (response.ok) {
        const data = await response.json();
        window.location.href = `${redirect_uri}?code=${data.code}&state=${state}`;
      } else {
        message.error('Login failed');
      }
    } catch (err) {
      console.error('OAuth login error:', err);
      message.error('Login failed');
    } finally {
      this.setState({ showLoading: false });
    }
  }

  private checkLoginParameters = () => {
    const { email, password } = this.state;
    let emailValid: boolean, canLogin: boolean;
    if (email) {
      emailValid = isEmailValid(email);
      canLogin = !_.isEmpty(password) && emailValid;
    } else {
      emailValid = true;
      canLogin = false;
    }
    return { emailValid, canLogin };
  };

  private checkNewPw = () => {
    const { newPassword, confirm } = this.state;
    let isPwdValid: boolean, isConfirmValid: boolean;
    if (newPassword) {
      isPwdValid = isPasswordValid(newPassword);
    } else {
      isPwdValid = true;
    }
    if (confirm) {
      isConfirmValid = confirm === newPassword;
    } else {
      isConfirmValid = true;
    }
    return { isPwdValid, isConfirmValid };
  };

  private onFbLoginClick = () => {
    (window as any).FB &&
      (window as any).FB.login(
        (response: any) => {
          if (response.status !== "connected" || !response.authResponse) {
            return message.error("Login Failure");
          }
          this.setState({ showLoading: true });
          doAction(LoginWithEmailAction, {
            type: "facebook",
            userId: response.authResponse.userID,
            history: this.props.history,
          });
          doFunction(async () => {
            this.setState({ showLoading: false });
          });
          //   onLogin({ type: "facebook", userId: response.authResponse.userID });
        },
        { scope: "public_profile,email" }
      );
  };

  private onSignIn = (profile: any) => {
    this.setState({ showLoading: true });
    doAction(LoginWithEmailAction, {
      type: "google",
      userId: profile.userId,
      history: this.props.history,
    });
    doFunction(async () => {
      this.setState({ showLoading: false });
    });
  };

  private sendEmail = () => {
    doAction(sendEmailAction, this.state.resetPwEmail);
  };

  private resetPassord = () => {
    const { forgotPasswordLink, newPassword, resetPwEmail, fromPage } =
      this.state;
    doAction(resetPassordAction, {
      forgotPasswordLink: forgotPasswordLink,
      password: newPassword,
      email: resetPwEmail,
      history: this.props.history,
      fromPage: fromPage,
    });
  };

  private renderStepTemplete = () => {
    const step = this.props.forgotPwStep;
    if (!step) {
      return this.renderSignIn();
    }
    if (_.includes([1, 2], step)) {
      return this.renderSendEmail();
    }
    // if (step === 3) {
    //   return this.renderEmailTemplete();
    // }
    return this.renderChangePwStep();
  };

  private renderSignIn = () => {
    const isInvite = queryString.parse(
      this.props.history.location.search
    ).isInvite;
    const show = isInvite === "1";
    return (
      <div className="sign-in">
        <div className="invite-tip" hidden={!this.state.showTip || !show}>
          <span>
            Looks like you already have an account at Canvas. Sign in to accept
            the invitation.
          </span>
          <img
            alt=""
            src={IconClose}
            onClick={() => this.setState({ showTip: false })}
          />
        </div>
        <div className="content">
          <span className="login-title">Sign in</span>
          {/* <div className="login-type">
            <Button
              className="continue-btn"
              onClick={() => this.onFbLoginClick()}
            >
              <img src={IconFb} alt="" className="ic-third-party" />
              Continue with Facebook
            </Button>
            <Button
              className="continue-btn"
              onClick={() => onClickGoogleLogin(this.onSignIn)}
            >
              <img src={IconGoogle} alt="" className="ic-third-party" />
              Continue with Google
            </Button>
          </div>
          <div className="divider">
            <span className="stash" />
            <span className="or">or</span>
            <span className="stash" />
          </div> */}
          <div className="login-with-email">
            <TextField
              key="email"
              label="Email address"
              variant="filled"
              className={classnames("material-input input", {
                showErroe: !this.checkLoginParameters().emailValid,
              })}
              placeholder="Enter your email"
              error={!this.checkLoginParameters().emailValid}
              helperText={
                this.checkLoginParameters().emailValid || !this.state.email
                  ? ""
                  : "Please enter correct email address."
              }
              onChange={(e) => this.recEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.charCode === 13 && this.checkLoginParameters().canLogin) {
                  this.onLogin();
                }
              }}
            />
            <TextField
              key="password"
              label="Password"
              variant="filled"
              className="material-input input"
              placeholder="Enter your password"
              onChange={(e) => this.recPassword(e.target.value)}
              type="password"
              onKeyPress={(e) => {
                if (e.charCode === 13 && this.checkLoginParameters().canLogin) {
                  this.onLogin();
                }
              }}
            />
          </div>

          <span
            className="forget"
            onClick={() => doAction(changeStepAction, 1)}
          >
            Forgot password?
          </span>
          <Button
            className="btn-primary btn-sign-up btn-sign-in"
            onClick={() => this.onLogin()}
            disabled={!this.checkLoginParameters().canLogin}
          >
            Sign in
          </Button>
          <div className="sign-up">
            Not a member?
            <span
              className="sign-up-btn"
              onClick={() => this.props.history.push(router_get_started)}
            >
              Sign up now
            </span>
          </div>
        </div>
      </div>
    );
  };

  private renderSendEmail = () => {
    return (
      <div className="send-email">
        <div className="title">Recover password</div>
        {this.renderSendEmailStep()}
      </div>
    );
  };

  private renderSendEmailStep = () => {
    const step = this.props.forgotPwStep;
    if (step === 1) {
      return (
        <div className="sent-email-container">
          <div className="tips">
            We’ll send you an email with instruction of how to reset your
            password.
          </div>
          <TextField
            key="resetPwEmail"
            label="Email"
            variant="filled"
            className={classnames("material-input input", {
              showErroe: !isEmailValid(this.state.resetPwEmail),
            })}
            placeholder="Enter your email"
            error={!isEmailValid(this.state.resetPwEmail)}
            helperText={
              isEmailValid(this.state.resetPwEmail) || !this.state.resetPwEmail
                ? ""
                : "Please enter correct email address."
            }
            onChange={(e) => this.setState({ resetPwEmail: e.target.value })}
          />
          <div className="send-email-btn">
            <Button
              className="btn-primary btn-sign-up"
              onClick={() => this.sendEmail()}
              disabled={!isEmailValid(this.state.resetPwEmail)}
            >
              Send recovery link
            </Button>
          </div>
        </div>
      );
    } else if (step === 2 && this.props.sendSuccess) {
      return (
        <div className="sent-result-container">
          <img alt="" className="ic-check" src={IconCheck} />
          <div className="tips">
            We have sent{" "}
            <span className="tips-email">{this.state.resetPwEmail}</span> the
            password recovery link with reset instruction.
          </div>
          <Button
            className="btn-primary btn-sign-up"
            onClick={() => doAction(changeStepAction, 0)}
          >
            Return to log in
          </Button>
          <div className="send-again">
            Didn’t get it?{" "}
            <span
              className="resend-link"
              onClick={() => doAction(changeStepAction, 1)}
            >
              Resend the recovery link
            </span>
          </div>
        </div>
      );
    }
  };

  // private renderEmailTemplete = () => {
  //   return (
  //     <div className="email-templete">
  //       <div className="templete-container">
  //         <div className="templete-title">Reset Password</div>
  //         <div className="templete-content">
  //           We heard that you lost your GitHub password. Sorry about that!
  //           <br /> <br />
  //           But don’t worry! You can click on the following button to reset your
  //           pabutton.
  //           <br /> <br />
  //           If you don’t take action within 3 hours, it will expire.
  //           <br /> <br />
  //           Thanks, <br />
  //           Your friends at GitHub
  //         </div>
  //         <div className="btn-box">
  //           <Button
  //             className="btn-primary btn-sign-up"
  //             onClick={() => doAction(changeStepAction, 4)}
  //           >
  //             Reset password
  //           </Button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  private renderChangePwStep = () => {
    return (
      <div className="change-pw">
        <div className="title">Change password</div>
        <div className="tips">
          Enter your new password and confirm one more time to complete the
          reset.{" "}
        </div>

        <TextField
          key="newPassword"
          label="New password"
          variant="filled"
          className={classnames("material-input input", {
            showError: !this.checkNewPw().isPwdValid,
          })}
          placeholder="Enter new password"
          error={!this.checkNewPw().isPwdValid}
          helperText={
            this.checkNewPw().isPwdValid
              ? ""
              : "Password must be more than 8 characters long, should contain at leat 1 uppercase, 1 lowercase, 1 numeric and 1 special character."
          }
          onChange={(e) => this.setState({ newPassword: e.target.value })}
          type="password"
        />
        <TextField
          key="confirm"
          label="Confirm new password"
          variant="filled"
          className={classnames("material-input input", {
            showError: !this.checkNewPw().isConfirmValid,
          })}
          placeholder="Enter confirm password"
          error={!this.checkNewPw().isConfirmValid}
          helperText={
            this.checkNewPw().isConfirmValid || !this.state.confirm
              ? ""
              : "Different from the new password"
          }
          onChange={(e) => this.setState({ confirm: e.target.value })}
          type="password"
        />
        <Button
          className="btn-primary btn-continue btn-sign-up"
          disabled={
            !this.checkNewPw().isPwdValid ||
            !this.state.newPassword ||
            !this.checkNewPw().isConfirmValid ||
            !this.state.confirm
          }
          onClick={() => this.resetPassord()}
        >
          Reset and log in
        </Button>
      </div>
    );
  };

  render() {
    return (
      <Layout className="signin-view">
        {this.props.forgotPwStep > 0 && (
          <Header>
            <img src={BlackLogo} alt="canvas recruit" />
          </Header>
        )}
        <Content>
          {!this.props.forgotPwStep && (
            <div className="left-sec">
              <a href="https://beautista.com">
                <img
                  alt=""
                src={Logo}
                // onClick={() => this.props.history.push(router_homepage_index)}
                className={classnames({
                  isBlack: this.props.forgotPwStep !== 0,
                })}
              />
              </a>
              <p className="left-text">
                Welcome to Beautista!
                <br /> <br />
                Create your portfolio, show off your work, connect with artists,
                find new career opportunities, and shine.
              </p>
            </div>
          )}
          <div className="right-sec">{this.renderStepTemplete()}</div>
          <CommonLoadingModal visible={this.state.showLoading} />
        </Content>
      </Layout>
    );
  }
}
