import Http from "./Http";
import { setToken } from "../utils/authStorage";
import axios from "axios";

export const sendSms = (data: any) => {
  return Http.post("/admin/send-sms", data)
  .then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  })
  .catch((err) => {
    return Promise.reject(err);
  });
}

export const userList = (data: any) => {
  return Http.post("/admin/query-users", data)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getAccessAccount = (data: { id: number }) => {
  return Http.get(`/admin/access-account/token/${data.id}`)
    .then((resp) => {
      if (resp.status === 200) {
        const token = resp.data?.access_token || null;
        if (token) {
          setToken(token);
        }
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

//school dashboard
export const schoolList = (data: any) => {
  return Http.post("/admin/query-schools", data)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const schoolListExport = (params: any) => {
  return Http.get("/admin/school/export-to-csv", {
    params,
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getDataSchool = (type: string, params: any) => {
  return Http.get(`/admin/school/dashboard/${type}`, {
    params,
  })
    .then((resp) => {
      if (resp.status === 200) {
        const token = resp.data?.access_token || null;
        if (token) {
          setToken(token);
        }
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getDataCanvas = (params: any) => {
  return Http.post(`/admin/canvas/dashboard`, 
    params,
  )
    .then((resp) => {
      if (resp.status === 200) {
        const token = resp.data?.access_token || null;
        if (token) {
          setToken(token);
        }
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const canvasList = (data: any) => {
  return Http.post("/admin/query-canvases", data)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
//opportunity dashboard

export const opportunityList = (data: any) => {
  return Http.post("/admin/query-opportunities", data)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const opportunityDelete = (data: any) => {
  return Http.delete(`/opportunity/remove/${data.id}`)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getMapData = async () => {
  const response = await Http.get('/admin/map-data');
  return response.data;
};

export const getDataDashboard = async (interval: { startDate: string, endDate: string, groupBy: string }) => {
  const response = await Http.post('/admin/dashboard-data', interval);
  return response.data;
};

export const getDetailedReportData = async (report: string, cat: string, interval: { startDate: string, endDate: string }) => {
  const response = await Http.post('/admin/detailed-report-data', {report, cat, interval});
  return response.data;
}

export const getGroupList = (keyword?: string) => {
  return Http.get(`/group${keyword? '?keyword=' + keyword : ''}` )
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const delGroup = (id: number) => {
  return Http.delete(`/group/${id}` )
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const createGroup = (groupName: string, groupMembers: {canvasId: number, userId: number}[]) => {
  return Http.post(`/group`,  {groupName, groupMembers})
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateGroup = (id: number, groupName: string) => {
  return Http.put(`/group/${id}`,  {groupName})
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const delUser = (id: number) => {
  return Http.delete(`/admin/user/${id}`)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};