import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import CRTextArea from "../../../components/component_cr_textarea";
import Close from "../../../assets/images/ic_close.svg";
import IconCheck from "../../../assets/images/ic_check.svg";
import "./opportunity_apply_modal.less";

interface Props {
  visible?: boolean;
  onClose: Function;
  onApply: Function;
  closeResultModal: Function;
  clickResultBtn: Function;
  title: string;
  desc: string;
  tips: string;
  applyBtnText?: string;
  cancelText?: string;
  applyResultParam?: any;
  applyInfoParam?: any;
}

const OpportunityApplyModal: React.FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onApply,
    title,
    desc,
    tips,
    applyBtnText,
    cancelText,
    applyResultParam,
    closeResultModal,
    clickResultBtn,
    applyInfoParam,
  } = props;

  const [applyInfo, setApplyInfo] = useState("");
  useEffect(() => {
    if (applyInfoParam) {
      const placeholder = `Dear ${
        applyInfoParam.companyName || ""
      },\nI am very interested in the ${
        applyInfoParam.jobTitle || ""
      } position at ${
        applyInfoParam.companyName || ""
      }. I believe my skills and work experience make me an ideal candidate for this role. I look forward to speaking with you about this very soon.\nBest,\n${
        applyInfoParam.userName || ""
      }`;
      setApplyInfo(placeholder);
    }
  }, [applyInfoParam]);

  return (
    <>
      <Modal
        className="opportunity-apply-modal"
        footer={null}
        onCancel={() => onClose()}
        visible={visible}
        closable={false}
        centered={true}
      >
        <div className="apply-opportunity-content">
          <span className="title">{title}</span>
          <span className="desc">{desc}</span>
          <CRTextArea
            key="applyInfo"
            label=""
            className="material-input input apply-info"
            value={applyInfo || ""}
            onInput={(value: string) => {
              setApplyInfo(value);
            }}
          />
          <span className="tips">
            <span dangerouslySetInnerHTML={{ __html: tips }}></span>
          </span>
          <div className="btn-wrap">
            <Button
              className="btn-primary modal-btn"
              onClick={() => {
                onApply(applyInfo);
                // setApplyInfo("");
              }}
            >
              {applyBtnText || "Apply Now"}
            </Button>
            <Button
              className="btn-secondary modal-btn"
              onClick={() => {
                setApplyInfo("");
                onClose();
              }}
            >
              {cancelText || "Cancel"}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="opportunity-apply-modal sent-result"
        footer={null}
        visible={applyResultParam && applyResultParam.resultVisible}
        closable={false}
        centered={true}
      >
        <div className="apply-opportunity-content">
          <img
            className="close"
            src={Close}
            alt=""
            onClick={() => closeResultModal()}
          />
          <span className="title">{applyResultParam.title}</span>
          <span className="desc">{applyResultParam.desc}</span>
          <img alt="" className="ic-check" src={IconCheck} />
          <div className="btn-wrap">
            <Button
              className="btn-primary modal-btn"
              onClick={() => clickResultBtn()}
            >
              {applyResultParam.btnText || "Back to My Applied Opportunities"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default OpportunityApplyModal;
