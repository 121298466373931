import Http from "./Http";

export const isSalonCentric = () => {
  return new Promise((resolve, reject) => {
    Http.get("/user/is-salon-centric/")
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const addScSalonId = (canvasId: number, scSalonId: number) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/add-sc-salon-id/", {canvasId, scSalonId})
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};
