import { ModuleStore, ModuleAction, KV } from "module-reaction";
import { getCustomized } from "../api/Options";
import ImgAvatar from "../assets/images/student_avatar.png";
export const MODULE_SCHOOL_DASHBOARD_STUDENTS =
  "module_school_dashboard_students";

function createData(
  id: number,
  avatar: any,
  name: string,
  phone: string,
  location: string,
  graduated: string,
  program: string,
  views: number
) {
  return { id, avatar, name, phone, location, graduated, program, views };
}

export const studentRows = [
  createData(
    1,
    ImgAvatar,
    "Brandie Russell",
    "(406) 555-0120",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
  createData(
    2,
    ImgAvatar,
    "Bessie Richards",
    "(209) 555-0104",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
  createData(
    3,
    ImgAvatar,
    "Claire Murphy",
    "(270) 555-0117",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
  createData(
    4,
    ImgAvatar,
    "Bessie Alexander",
    "(303) 555-0105",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
  createData(
    5,
    ImgAvatar,
    "Bessie Alexander",
    "(303) 555-0105",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
  createData(
    6,
    ImgAvatar,
    "Bessie Alexander",
    "(303) 555-0105",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
  createData(
    7,
    ImgAvatar,
    "Bessie Alexander",
    "(303) 555-0105",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
  createData(
    8,
    ImgAvatar,
    "Bessie Alexander",
    "(303) 555-0105",
    "West Jordan",
    "01/14/20",
    "Barber",
    341
  ),
];

export interface ModuleSchoolDashboardStudents extends ModuleStore {
  school: any;
  filter: {
    graduated?: boolean;
    location?: any;
    program?: any;
    keyword?: string;
    orderBy?: string;
  };
  page_idx: number;
  num_per_page: number;
  total: number;
  locationOptions: any[];
  programOptions: any[];
  list: any[];
  selectList: number[];
}

export const module_school_dashboard_students: ModuleSchoolDashboardStudents = {
  module: MODULE_SCHOOL_DASHBOARD_STUDENTS,
  school: {},
  filter: {
    graduated: true,
    location: 0,
    program: 0,
    keyword: "",
    orderBy: "",
  },
  page_idx: 1,
  num_per_page: 8,
  total: 100,
  locationOptions: [
    { name: "school1", id: 1 },
    { name: "school2", id: 2 },
    { name: "school3", id: 3 },
    { name: "All", id: 0 },
  ],
  programOptions: [{ name: "All", id: 0 }],
  list: studentRows,
  selectList: [],
};

export const GetStudentListAction: ModuleAction<
  KV,
  ModuleSchoolDashboardStudents
> = {
  module: MODULE_SCHOOL_DASHBOARD_STUDENTS,
  process: async (filterItem: any, model: ModuleSchoolDashboardStudents) => {
    const newList = studentRows.reverse();
    return { ...filterItem, list: newList };
  },
};

export const UpdateSelectAction: ModuleAction<
  KV,
  ModuleSchoolDashboardStudents
> = {
  module: MODULE_SCHOOL_DASHBOARD_STUDENTS,
  process: async (selectList: any) => {
    return { selectList };
  },
};

export const GetOptionsActions: ModuleAction<
  KV,
  ModuleSchoolDashboardStudents
> = {
  module: MODULE_SCHOOL_DASHBOARD_STUDENTS,
  process: async () => {
    const res = await getCustomized(true, ["program"]);
    if (res) {
      return {
        programOptions: res.program.concat({ id: 0, name: "All" }),
      };
    }
    return {};
  },
};
