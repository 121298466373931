import { ModuleStore, ModuleAction, KV } from "module-reaction";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { searchCanvas } from "../api/Canvas";
import { getCustomized } from "../api/Options";
import { router_canvas_detail, router_homepage_canvas } from "../utils/enum";
import { sessionStorageSet } from "../utils/utils";

export const MODULE_HOMEPAGE_CANVASES = "MODULE_HOMEPAGE_CANVASES";
export const cur_filter_key = "homepage_canvas_cur_filter_key";
export interface CanvasCardItem {
  id: number;
  type: string;
  link: string;
  media: string;
  videoCover?: string;
  is_video: boolean;
  title: string;
  timestamp: string;
  category: string;
  is_unread: boolean;
  biz_name: string;
  biz_ico: string;
  location: string;
  schoolName: string;
}
export interface ModelHomepageCanvases extends ModuleStore {
  type: "professional" | "business" | "school";
  canvas_type: "professional" | "business" | "student" | "school";
  filters: {
    keywords?: string;
    locationInfo: {
      uuid: string;
      areaUnit: string;
      location?: string;
      coordinates?: number[];
    };
    radius?: string;
    yearOfExperience?: string;
    category?: string;
    program?: string;
    school?: string;
    graduationDate?: string;
    software?: string;
    combinedAverageTicket?: string;
    annualFrequencyOfVisit?: string;
    repeatClientRetention?: string;
    serviceToRetailSales?: string;
    jobtypes?: Set<string>;
  };
  use_location: boolean; // if use_location, keyword will be ignored
  list: CanvasCardItem[];
  page_idx: number;
  total: number;
  num_per_page: number;
}

export const module_homepage_canvases: ModelHomepageCanvases = {
  module: MODULE_HOMEPAGE_CANVASES,
  type: undefined as any,
  canvas_type: undefined as any,
  filters: {
    locationInfo: {
      uuid: uuidv4(),
      areaUnit: "ft",
      location: "",
    },
    radius: "radius-50",
  },
  use_location: true,
  list: [],
  page_idx: 1,
  num_per_page: 9, // for now, always be 9
  total: 0,
};
export interface IOpt {
  id?: number;
  key: string;
  name: string;
}

const all_canvas_types = ["business", "professional", "student", "school"];

export const emptyOption = { key: "all", name: "All" };

const professionalCategorys = [
  emptyOption,
  {
    key: "userCategory-1",
    name: "Licensed Profesional",
  },
  {
    key: "userCategory-0",
    name: "Non-licensed beauty Profesional",
  },
];

export const browse_canvas_filter_options: {
  professionalCategory: { validTypes: string[]; options: IOpt[] };
  category: { validTypes: string[]; options: IOpt[] };
  student: IOpt[]; // show student in category when type is professional
  program: { validTypes: string[]; options: IOpt[] };
  software: { validTypes: string[]; options: IOpt[] };

  // other FE defined options
  radius: { validTypes: string[]; options: IOpt[] };
  yearOfExperience: { validTypes: string[]; options: IOpt[] };
  school: { validTypes: string[]; options: IOpt[] };
  graduationDate: { validTypes: string[]; options: IOpt[] };
  combinedAverageTicket: { validTypes: string[]; options: IOpt[] };
  annualFrequencyOfVisit: { validTypes: string[]; options: IOpt[] };
  repeatClientRetention: { validTypes: string[]; options: IOpt[] };
  serviceToRetailSales: { validTypes: string[]; options: IOpt[] };
} = {
  category: {
    validTypes: ["student", "professional", "busines"], // without school
    options: undefined as any,
  },
  student: undefined as any,
  program: {
    validTypes: ["student", "professional", "school"],
    options: undefined as any,
  },
  software: {
    validTypes: ["student", "professional"],

    options: undefined as any,
  },
  professionalCategory: {
    validTypes: ["professional"],
    options: undefined as any,
  },
  // FE defined options
  radius: {
    validTypes: all_canvas_types,

    options: [
      {
        key: "radius-10",
        name: "10 miles",
      },
      {
        key: "radius-25",
        name: "25 miles",
      },
      {
        key: "radius-30",
        name: "30 miles",
      },
      {
        key: "radius-50",
        name: "50 miles",
      },
      {
        key: "radius-100",
        name: "100 miles",
      },
      {
        key: "radius-250",
        name: "250 miles",
      },
    ],
  },
  yearOfExperience: {
    validTypes: [],
    options: [
      emptyOption,
      {
        key: "yearOfExperience-0-2.9",
        name: "less than 3 years",
      },
      {
        key: "yearOfExperience-3-5",
        name: "3-5 years",
      },
      {
        key: "yearOfExperience-5-10",
        name: "5-10 years",
      },
      {
        key: "yearOfExperience-11-15",
        name: "11-15 years",
      },
      {
        key: "yearOfExperience-15-99",
        name: "more than 15 years",
      },
    ],
  },
  school: {
    validTypes: ["professional", "student"],

    options: [],
  }, // tmp has no data
  graduationDate: {
    validTypes: ["professional", "student"],

    options: [
      emptyOption,
      {
        key: "graduatedDate.-12.0",
        name: "in past year"
      },
      {
        key: "graduationDate.1.3",
        name: "in the next 1-3 months",
      },
      {
        key: "graduationDate.3.6",
        name: "in the next 3-6 months",
      },
      {
        key: "graduationDate.6.9",
        name: "in the next 6-9 months",
      },
      {
        key: "graduationDate.9.12",
        name: "in the next 9-12 months",
      },
      {
        key: "graduationDate.12.72",
        name: "in the next year or more",
      },
    ],
  },
  combinedAverageTicket: {
    validTypes: ["student", "professional"],

    options: [
      emptyOption,
      {
        key: "combinedAverageTicket-0-50",
        name: "less than $50",
      },
      {
        key: "combinedAverageTicket-50-100",
        name: "$50 - $100",
      },
      {
        key: "combinedAverageTicket-100-200",
        name: "$100 - $200",
      },
      {
        key: "combinedAverageTicket-200-999999",
        name: "more than $200",
      },
    ],
  },
  annualFrequencyOfVisit: {
    validTypes: ["professional", "student"],

    options: [
      emptyOption,
      {
        key: "annualFrequencyOfVisit-1-5",
        name: "1 - 5",
      },
      {
        key: "annualFrequencyOfVisit-6-10",
        name: "6 - 10",
      },
      {
        key: "annualFrequencyOfVisit-11-20",
        name: "11 - 20",
      },
      {
        key: "annualFrequencyOfVisit-20-9999999",
        name: "more than 20",
      },
    ],
  },
  repeatClientRetention: {
    validTypes: ["professional", "student"],

    options: [
      emptyOption,
      {
        key: "repeatClientRetention-1-20",
        name: "1 - 20%",
      },
      {
        key: "repeatClientRetention-21-50",
        name: "21 - 50%",
      },
      {
        key: "repeatClientRetention-51-80",
        name: "51 - 80%",
      },
      {
        key: "repeatClientRetention-81-100",
        name: "81 - 100%",
      },
    ],
  },
  serviceToRetailSales: {
    validTypes: ["professional", "student"],

    options: [
      emptyOption,
      {
        key: "serviceToRetailSales-1-20",
        name: "1 - 20%",
      },
      {
        key: "serviceToRetailSales-21-50",
        name: "21 - 50%",
      },
      {
        key: "serviceToRetailSales-51-80",
        name: "51 - 80%",
      },
      {
        key: "serviceToRetailSales-81-100",
        name: "81 - 100%",
      },
    ],
  },
};

export function getFilterNum<T = any>(key_with_value?: string): T {
  if (!key_with_value) {
    return (undefined as unknown) as T;
  }
  const [key, val1, val2] = key_with_value.split("-");
  if (val2) {
    // return array
    return ([val1, val2].map((_) => parseInt(_)) as unknown) as T;
  } else {
    return (parseInt(val1) as unknown) as T;
  }
}

export function getFilterNumDot<T = any>(key_with_value?: string): T {
  if (!key_with_value) {
    return (undefined as unknown) as T;
  }
  const [key, val1, val2] = key_with_value.split(".");
  if (val2) {
    // return array
    return ([val1, val2].map((_) => parseInt(_)) as unknown) as T;
  } else {
    return (parseInt(val1) as unknown) as T;
  }
}

export const FetchCanvasesAction: ModuleAction<KV, ModelHomepageCanvases> = {
  module: MODULE_HOMEPAGE_CANVASES,
  maxProcessSeconds: 60,
  process: async (payload: KV, model: ModelHomepageCanvases) => {
    const {
      page_idx,
      num_per_page,
      type,
      canvas_type,
      filters,
      use_location,
      isAppend,
    } = Object.assign({}, model, payload);
    // fetch options first is need
    if (
      !browse_canvas_filter_options.category.options ||
      payload.type !== model.type
    ) {
      const options = await getCustomized(true, [
        "category",
        "program",
        "software",
        "school",
      ]);
      if (type !== "school") {
        if (type === "professional") {
          browse_canvas_filter_options.category.options = [];
          browse_canvas_filter_options.category.options = professionalCategorys;
        } else {
          browse_canvas_filter_options.category.options = [
            emptyOption,
            ...options.category[type].map((_: any) => ({
              ..._,
              key: `${type}-${_.id}`,
            })),
          ];
        }
      } else {
        browse_canvas_filter_options.category.options = [];
      }
      browse_canvas_filter_options.student = options.category.student.map(
        (_: any) => ({ ..._, key: `student-${_.id}` })
      );
      // program only useful on category is student
      browse_canvas_filter_options.program.options = [emptyOption].concat(
        options.program.map((_: any) => ({ ..._, key: `program-${_.id}` }))
      );
      browse_canvas_filter_options.software.options = [emptyOption].concat(
        options.software.map((_: any) => ({ ..._, key: `software-${_.id}` }))
      );
      browse_canvas_filter_options.school.options = [emptyOption].concat(
        options.school.map((_: any) => ({ ..._, key: `school-${_.id}` }))
      );
    }

    // const { list } = model
    const searchTypes = [canvas_type];
    if (
      canvas_type === "professional" &&
      (filters.category === "all" || !filters.category)
    ) {
      searchTypes.push("student");
    }
    const categoryVal = getFilterNum<number>(filters.category);
    const res = await searchCanvas({
      type: searchTypes,
      keyword: filters.keywords,
      ...(type === "professional"
        ? !isNaN(categoryVal) && !searchTypes.includes("student")
          ? { userCategory: { licensed: categoryVal } }
          : {}
        : { category: categoryVal }),
      location: filters.locationInfo.location
        ? {
            coordinates: filters.locationInfo.coordinates || [0, 0],
            radius: (getFilterNum<number>(filters.radius) || 0) * 1609.344, // convert miles to meter
          }
        : undefined,
      // school: undefined, // tmp no data
      school: browse_canvas_filter_options.school.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number>(filters.school)
        : undefined,
      program: browse_canvas_filter_options.program.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number>(filters.program)
        : undefined,
      software: browse_canvas_filter_options.software.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number>(filters.software)
        : undefined,
      yearOfExperience: browse_canvas_filter_options.yearOfExperience.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number[]>(filters.yearOfExperience)
        : undefined,
      combinedAverageTicket: browse_canvas_filter_options.combinedAverageTicket.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number[]>(filters.combinedAverageTicket)
        : undefined,
      annualFrequencyOfVisit: browse_canvas_filter_options.annualFrequencyOfVisit.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number[]>(filters.annualFrequencyOfVisit)
        : undefined,
      repeatClientRetention: browse_canvas_filter_options.repeatClientRetention.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number[]>(filters.repeatClientRetention)
        : undefined,
      serviceToRetailSales: browse_canvas_filter_options.serviceToRetailSales.validTypes.includes(
        canvas_type
      )
        ? getFilterNum<number[]>(filters.serviceToRetailSales)
        : undefined,
      graduationDate:
        browse_canvas_filter_options.graduationDate.validTypes.includes(
          canvas_type
        ) && filters.graduationDate !== "all"
          ? getFilterNumDot<number[]>(filters.graduationDate)?.map((_) => {
              return moment().add(_, "month").format("YYYY-MM-DD");
            })
          : undefined,
      page: page_idx,
      size: num_per_page,
    });

    const list = isAppend ? model.list : [];
    if (res?.rows?.length) {
      // id: item.id,
      // type: item.type,
      // name: item.name,
      // media: item.media,
      // createdAt: item.createdAt,
      // updatedAt: item.updatedAt,
      // categories: categories[item.id],
      // locations: locations[item.id],
      // fullName: users[item.userId],
      // schools: educations[item.id],
      res.rows.forEach((item: any) => {
        const videoUrl =
          item.media?.featuredVideo?.url || item.media?.featuredVideo?.video;
        const card: CanvasCardItem = {
          id: item.id,
          type: item.type,
          link: router_canvas_detail.replace(":id", item.slug || item.id),
          media:
            videoUrl ||
            (type === "professional"
              ? item.media?.portraitPhoto?.image ||
                item.media?.featuredPhoto?.image
              : item.media?.featuredPhoto?.image),
          is_video: !!videoUrl,
          videoCover:
            item.media?.featuredVideo?.image ||
            item.media?.featuredPhoto?.image,
          is_unread: false, // lack
          title: item.name,
          timestamp: item.updatedAt,
          schoolName: item.schoolName,
          category:
            (item.type === "student" ? item.program : item.categories?.[0]) ||
            "unknown",
          // browse_canvas_filter_options.category.find(
          //   (_) => item.categories?.length && _.id === item.categories[0]
          // )?.name || "unknown",
          biz_ico: item.media?.logo?.image,
          biz_name: item.fullName,
          location: item.locations?.length
            ? item.locations.find((l: any) => l.address)?.address
            : "",
        };
        list.push(card);
      });
    }
    return {
      page_idx,
      num_per_page,
      type,
      canvas_type,
      filters,
      use_location,
      list,
      total: res?.count || 0,
    };
  },
};

export const CacheFilterAction: ModuleAction<any, ModelHomepageCanvases> = {
  module: MODULE_HOMEPAGE_CANVASES,
  process: async (payload: any, model: ModelHomepageCanvases) => {
    const randomKey = uuidv4();
    const url = `${window.location.origin}${router_homepage_canvas.replace(
      ":type",
      model.type
    )}?filterKey=${randomKey}`;
    window.history.pushState(null, "", url);
    sessionStorageSet(randomKey, model);
    sessionStorageSet(cur_filter_key, randomKey);
    return {};
  },
};
