import React from "react";
import StudentProHeadline from "./studentpro_headline";
import { Button } from "antd";
import { getStartedViaSource } from "../../../utils/utils";

interface Props {
  setCurPlayUrl: Function;
  ctaLabel?: string;
  source: string;
}

const StudentProOpportunities: React.FC<Props> = ({ setCurPlayUrl, ctaLabel = 'Join in less than 3 minutes', source }) => {
  return (
    <div className="cm-sp-well">
      <StudentProHeadline headline="The opportunities are endless." subtitle="Whether you are looking to find your full-time career home, level up, find a mentor, or network with your people, Beautista is the place to discover the endless possibilities." />
      <div className="cm-sp-opp-wrapper cm-flex-responsive-row">
        <div className="cm-sp-opp">
          <h3>Flexible Opportunities.</h3>
          <p>Whether you are looking for a full or part-time role, Beautista has you covered.</p>
        </div>
        <div className="cm-sp-opp">
          <h3>Brand Educators.</h3>
          <p>Ready to represent some of the biggest names in beauty? Beautista is here to help you level up.</p>
        </div>
        <div className="cm-sp-opp">
          <h3>Find Your Next Mentor.</h3>
          <p>Meet industry icons and learn what it takes to achieve your goals. <a onClick={() => setCurPlayUrl('https://www.youtube.com/watch?v=8MoCzSrwAww')}>Check out our Mentorship Mashup video and get inspired.</a></p>
        </div>
      </div>
      <div className="cm-flex-row cm-flex-center">
        <Button className="btn-primary" onClick={() => getStartedViaSource(source)}>{ctaLabel}</Button>
      </div>

    </div>
  )
};

export default StudentProOpportunities;
