import React from "react";
import classnames from "classnames";
import IconAward from "../assets/images/ic_award.svg";
import IconDelete from "../assets/images/ic_delete.svg";
import IconEdit from "../assets/images/ic_edit.svg";
import "./component_award_item.less";

export interface IAward {
  uuid: string;
  title: string;
  issuer?: string;
  issueYear?: string;
}

interface Props {
  className?: string;
  data: IAward;
  onEdit: Function;
  onDelete: Function;
}

const AwardItem: React.FC<Props> = (props) => {
  const { className = "", data, onEdit, onDelete } = props;

  const getIssueText = () => {
    if (data.issueYear && data.issuer) {
      return `${data.issueYear} - ${data.issuer}`;
    } else {
      return data.issueYear ? data.issueYear : data.issuer ? data.issuer : "";
    }
  };

  return (
    <div className={classnames(className, "award-item")}>
      <img alt="" src={IconAward} className="icon-award" />
      <div className="info-sec">
        <span className="award-title ">{data.title}</span>
        <span className="issue">{getIssueText()}</span>
      </div>
      <img
        alt=""
        className="icon-edit"
        src={IconEdit}
        onClick={() => onEdit(data)}
      />
      <img
        alt=""
        className="icon-delete"
        src={IconDelete}
        onClick={() => onDelete(data)}
      />
    </div>
  );
};

export default AwardItem;
