
import {
  router_sign_in,
  router_get_started,
  router_business_canvas,
  router_business_canvas_preview,
  router_company_info,
  router_business_payment,
  router_post_job,
  router_publish_success,
  router_school_info,
  router_school_canvas,
  router_school_canvas_preview,
  router_school_payment,
  router_education_info,
  router_student_canvas,
  router_student_canvas_preview,
  router_professional_info,
  router_professional_canvas,
  router_student_canvas_create,
  router_professional_canvas_create,
  router_professional_canvas_preview,
  router_post_job_opp,
  router_post_job_opp_simple,
  router_homepage_canvas,
  router_homepage_opportunity,
  router_canvas_detail,
  router_my_dashboard,
  router_add_student,
  router_my_canvases,
  router_bookmarks,
  router_message,
  router_notifications,
  router_membership,
  router_membership_invoice_list,
  router_membership_invoice_view,
  router_membership_billing_update,
  router_membership_plan_update,
  router_settings,
  router_opportunity_detail,
  router_opportunity_publish_success,
  router_add_canvas,
  router_bookmarks_all,
  router_change_password,
  router_opportunity_applicants,
  router_dashboard_post_job_opp,
  router_insta_auth,
  router_upgrade_student_canvas,
  //admin
  router_admin_user,
  router_admin_data,
  router_admin_school,
  router_admin_canvas,
  router_admin_opportunity,
  router_admin_featured,
  router_opportunity_detail_by_id,
  router_track,
  router_school_payment_with_id,
  router_business_payment_with_id,
  router_admin_sms,
  router_admin_external_payment,
} from "./utils/enum";
import { InstagramAuth } from "./views/instagram/instagram_auth";
import pathToRegexp from 'path-to-regexp'

import HomePageView from "./views/homepage/homepage"
import MyAccount from "./views/my_account/my_account";
import CanvasDetail from "./views/canvas_detail/canvas_detail";
import OpportunityDetail from "./views/opportunity/opportunity_detail/opportunity_detail";
import BusinessCanvas from "./views/create_business_canvas/business_canvas";
import BusinessPayment from "./views/create_business_canvas/business_canvas_payment";
import PostJob from "./views/create_business_canvas/post_job";
import Preview from "./views/create_business_canvas/preview";
import PublishSuccess from "./views/create_business_canvas/publish_success";
import ProfessionalInfo from "./views/create_professional_canvas/professional_info";
import EducationInfo from "./views/create_student_canvas/education_info";
import StudentPreview from "./views/create_student_canvas/preview";
import StudentCanvas from "./views/create_student_canvas/student_canvas";
import Track from "./views/homepage/track";
import ChangePassword from "./views/my_account/change_password";
import GetStartedView from "./views/onboarding/get_started";
import { PostJobOpportunity } from "./views/opportunity/post_job_opp/post_job_opp";
import { PostJobOpportunitySimple } from "./views/opportunity/post_job_opp/post_job_opp_simple";
import OpportunityPublishSuccess from "./views/opportunity/publish_success/publish_success";
import UserSigninView from "./views/user/user_signin";
import CompanyInfoWrapper from "./views/create_business_canvas/company_info_wrapper";
import SchoolInfoWrapper from "./views/create_school_canvas/school_info_wrapper";

// b/c the module-store can't be split and reuse correctly, most page fails to manage data when using lazyWrapper
// import { lazyWrapper } from './lazywrapper';
// const UserSigninView = lazyWrapper(() => import("./views/user/user_signin"));
// const GetStartedView = lazyWrapper(() => import("./views/onboarding/get_started"));
// const CompanyInfo = lazyWrapper(() => import("./views/create_business_canvas/company_info"));
// const BusinessCanvas = lazyWrapper(() => import("./views/create_business_canvas/business_canvas"));
// const Preview = lazyWrapper(() => import("./views/create_business_canvas/preview"));
// const BusinessPayment = lazyWrapper(() => import("./views/create_business_canvas/business_canvas_payment"));
// const SchoolInfo = lazyWrapper(() => import("./views/create_school_canvas/school_info"));
// const EducationInfo = lazyWrapper(() => import("./views/create_student_canvas/education_info"));
// const ProfessionalInfo = lazyWrapper(() => import("./views/create_professional_canvas/professional_info"));
// const PostJob = lazyWrapper(() => import("./views/create_business_canvas/post_job"));
// const PublishSuccess = lazyWrapper(() => import("./views/create_business_canvas/publish_success"));
// const StudentCanvas = lazyWrapper(() => import("./views/create_student_canvas/student_canvas"));
// const StudentPreview = lazyWrapper(() => import("./views/create_student_canvas/preview"));
// const CanvasDetail = lazyWrapper(() => import("./views/canvas_detail/canvas_detail"));
// const OpportunityDetail = lazyWrapper(() => import("./views/opportunity/opportunity_detail/opportunity_detail"));
// const ChangePassword = lazyWrapper(() => import("./views/my_account/change_password"));
// const OpportunityPublishSuccess = lazyWrapper(() => import("./views/opportunity/publish_success/publish_success"));
// const Track = lazyWrapper(() => import("./views/homepage/track"));
// const PostJobOpportunity = lazyWrapper(() => import("./views/opportunity/post_job_opp/post_job_opp"), 'PostJobOpportunity')

const businessRouter: RouterCfg[] = [
  {
    path: router_company_info,
    component: CompanyInfoWrapper,
    exact: true,
  },
  {
    path: router_business_canvas,
    component: BusinessCanvas,
    exact: true,
  },
  {
    path: router_business_canvas_preview,
    component: Preview,
    exact: true,
  },
  {
    path: router_business_payment,
    component: BusinessPayment,
    exact: true,
  },
  {
    path: router_business_payment_with_id,
    component: BusinessPayment,
    exact: true,
  },
  {
    path: router_post_job,
    component: PostJob,
    exact: true,
  },
];

const myAccountRouter: RouterCfg[] = [
  {
    path: router_my_dashboard,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_my_canvases,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_bookmarks,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_message,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_notifications,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_membership,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_membership_invoice_view,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_membership_invoice_list,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_membership_billing_update,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_membership_plan_update,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_settings,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_add_student,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_add_canvas,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_upgrade_student_canvas,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_bookmarks_all,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_change_password,
    component: ChangePassword,
    exact: true,
  },
  {
    path: router_opportunity_applicants,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_dashboard_post_job_opp,
    component: MyAccount,
    exact: true,
  },
];

const adminRouter: RouterCfg[] = [
  {
    path: router_admin_user,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_admin_school,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_admin_data,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_admin_external_payment,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_admin_canvas,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_admin_opportunity,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_admin_featured,
    component: MyAccount,
    exact: true,
  },
  {
    path: router_admin_sms,
    component: MyAccount,
    exact: true
  }
];

const schoolRouter: RouterCfg[] = [
  {
    path: router_school_info,
    component: SchoolInfoWrapper,
    exact: true,
  },
  {
    path: router_school_canvas,
    component: BusinessCanvas,
    exact: true,
  },
  {
    path: router_school_canvas_preview,
    component: Preview,
    exact: true,
  },
  {
    path: router_school_payment,
    component: BusinessPayment,
    exact: true,
  },
  {
    path: router_school_payment_with_id,
    component: BusinessPayment,
    exact: true,
  },
];

const studentRouter: RouterCfg[] = [
  {
    path: router_education_info,
    component: EducationInfo,
    exact: true,
  },
  {
    path: router_student_canvas,
    component: StudentCanvas,
    exact: true,
  },
  {
    path: router_student_canvas_preview,
    component: StudentPreview,
    exact: true,
  },
];

const professionalRouter: RouterCfg[] = [
  {
    path: router_professional_info,
    component: ProfessionalInfo,
    exact: true,
  },
  {
    path: router_professional_canvas,
    component: StudentCanvas,
    exact: true,
  },
  {
    path: router_professional_canvas_create,
    component: StudentCanvas,
    exact: true,
  },
  {
    path: router_student_canvas_create,
    component: StudentCanvas,
    exact: true,
  },
  {
    path: router_professional_canvas_preview,
    component: StudentPreview,
    exact: true,
  },
];

const opportunityRouter: RouterCfg[] = [
  {
    path: router_post_job_opp,
    component: PostJobOpportunity,
  },
];
export const appRouters: RouterCfg[] = [
  {
    path: router_track,
    component: Track,
    exact: true,
  },
  {
    path: router_get_started,
    component: GetStartedView,
    exact: true,
  },
  {
    path: router_post_job_opp_simple,
    component: PostJobOpportunitySimple,
  },
  // {
  //   path: router_sign_up,
  //   component: SignUpView,
  //   exact: true,
  // },
  {
    path: router_sign_in,
    component: UserSigninView,
    exact: true,
  },
  {
    path: router_canvas_detail,
    component: CanvasDetail,
    exact: true,
  },
  {
    path: router_opportunity_detail,
    component: OpportunityDetail,
    exact: true,
  },
  {
    path: router_opportunity_detail_by_id,
    component: OpportunityDetail,
    exact: true,
  },
  {
    path: router_opportunity_publish_success,
    component: OpportunityPublishSuccess,
    exact: true,
  },
  ...businessRouter,
  ...schoolRouter,
  ...studentRouter,
  ...professionalRouter,
  ...myAccountRouter,
  ...opportunityRouter,
  ...adminRouter,
  {
    path: router_publish_success,
    component: PublishSuccess,
    exact: true,
  },
  {
    path: router_insta_auth,
    component: InstagramAuth,
    exact: true,
  },
  {
    path: "/",
    component: HomePageView,
  },
];

const _need_auth_routers = new Set<RegExp>();
/**
 * get or set path needAuth
 * @param path
 * @param needAuth will update the flag if this param is not undefined
 */
export function router_need_auth(path: string, needAuth?: boolean) {
  if (needAuth === undefined) {
    return Array.from(_need_auth_routers).some((r) => {
      const match = r.exec(path);
      return match?.length;
    });
  } else {
    const reg = pathToRegexp(path);
    if (needAuth === true) {
      _need_auth_routers.add(reg);
    } else {
      _need_auth_routers.delete(reg);
    }
  }
}

// tag some routers as need_auth
router_need_auth(router_homepage_canvas, true);
router_need_auth(router_homepage_opportunity, true);
router_need_auth(router_company_info, true);
router_need_auth(router_message, true);
router_need_auth(router_add_canvas, true);
router_need_auth(router_dashboard_post_job_opp, true);

export const global_router: {
  history: any;
  location: any;
} = {
  history: undefined,
  location: undefined,
};
