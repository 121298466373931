import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import { mapProp, doAction, KV } from "module-reaction";
import { TextField, MenuItem, IconButton } from "@material-ui/core";
import cloneDeep from "lodash/cloneDeep";
import queryString from "query-string";
import {
  MODULE_PROFESSIONCAL_INFO,
  module_professional_info,
  ModuleProfessionalInfo,
  professionalItem,
  UpdateBaseInfoAction,
  SubmitAction,
  InitDataAction,
} from "../../models/model_professioncal_info";
import FieldRequireModal from "../../components/component_field_require_modal";
import {
  awardRenderList,
  educationItem,
} from "../../models/model_education_info";
import _ from "lodash";
import { CanvasType, TargetType } from "../../utils/enum";
import {
  YseOrNo,
  AwardsList,
  EducationItem,
} from "../create_student_canvas/education_info";
import { modle_user, LoginWithTokenAction } from "../../models/model_user";
import IconProfessionalInfo from "../../assets/images/ic_ professional_info.svg";
import IconAwardAdd from "../../assets/images/ic_award_add.svg";
import IconDelete from "../../assets/images/ic_delete.svg";

export const ProfessionalItem = (props: any) => {
  const {
    proIndex,
    categoryList,
    stateIssueList,
    isMobile,
    professionalList,
    setListData,
    delListItem,
    user,
    isEdit,
  } = props;
  const [showLicense, handleShowLicense] = useState(false);
  const formData = professionalList[proIndex];
  const setItemData = (value: any) => {
    const newList = [...professionalList];
    const curItem = newList[proIndex];
    if (value.id >= 0) {
      curItem.id = value.id;
    }
    if (value.name) {
      curItem.name = value.name;
    }
    if (value.licenseNumber !== undefined && value.licenseNumber !== null) {
      curItem.licenseNumber = value.licenseNumber;
    }
    if (value.stateId) {
      curItem.stateId = value.stateId;
    }
    if (value.otherName !== undefined && value.otherName !== null) {
      curItem.otherName = value.otherName;
    }
    setListData(newList);
  };

  useEffect(() => {
    const formData = professionalList[proIndex];
    if ((formData.licenseNumber || formData.stateId) && !showLicense) {
      handleShowLicense(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionalList]);

  useEffect(() => {
    if (
      !isEdit &&
      user &&
      !_.isEmpty(user.categories) &&
      user.profile &&
      user.profile.target === TargetType.PERSONAL &&
      !_.isEmpty(categoryList) &&
      proIndex === 0
    ) {
      const userCate = user.categories[0];
      const find = _.find(categoryList, { id: userCate.id });
      if (find) {
        setItemData(userCate);
      } else {
        setItemData({
          id: 0,
          name: "Other",
          otherName: userCate.name,
          licenseNumber: userCate.licenseNumber,
        });
      }
      if (user.categories[0].licenseNumber) {
        handleShowLicense(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, categoryList, proIndex]);

  // useEffect(() => {
  //   if (!showLicense) {
  //     const newList = [...professionalList];
  //     const curItem = newList[proIndex];
  //     const newItem = {
  //       id: curItem.id,
  //       name: curItem.name,
  //       otherName: curItem.otherName,
  //     };
  //     newList[proIndex] = newItem;
  //     setListData(newList);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showLicense]);

  const getStateName = () => {
    if (!formData.stateId) {
      return "";
    }
    const state = _.find(stateIssueList, { id: formData.stateId });
    if (state && state.name) {
      return state.name;
    }
    return "";
  };

  return (
    <div className="about-wrap">
      <div className="title-with-icon">
        <img alt="" src={IconProfessionalInfo} />
        <span>Professional Information {proIndex + 1}</span>
        {professionalList.length > 1 ? (
          <IconButton
            className="del-edu"
            onClick={() => {
              delListItem(proIndex);
            }}
          >
            <img src={IconDelete} alt="" />
          </IconButton>
        ) : null}
      </div>
      <Row gutter={24}>
        <Col span={isMobile ? 24 : formData.id === 0 ? 6 : 12}>
          <TextField
            key="category"
            label="Category"
            variant="filled"
            select
            className="material-input input"
            placeholder="Category"
            value={formData.name}
            onChange={(e) => {
              const item = _.find(categoryList, { name: e.target.value });
              if (formData.name !== item.name) {
                setItemData({ ...item, licenseNumber: "" });
              } else {
                setItemData(item);
              }
            }}
          >
            {categoryList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Col>
        {formData.id === 0 ? (
          <Col span={isMobile ? 24 : 6}>
            <TextField
              key="categoryName"
              label="Category name"
              variant="filled"
              className="material-input input"
              placeholder="Category name"
              value={formData.otherName || ""}
              onChange={(e) => setItemData({ otherName: e.target.value })}
            />
          </Col>
        ) : null}
        <Col span={isMobile ? 24 : 12}>
          <YseOrNo
            title="License"
            enable={showLicense}
            afterSelect={(value: boolean) => {
              if (!value) {
                const newList = [...professionalList];
                const curItem = newList[proIndex];
                const newItem = {
                  id: curItem.id,
                  name: curItem.name,
                  otherName: curItem.otherName,
                };
                newList[proIndex] = newItem;
                setListData(newList);
              }
              handleShowLicense(value);
            }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        {showLicense ? (
          <>
            <Col span={isMobile ? 24 : 12}>
              <TextField
                key="licenseNumber"
                label="License number"
                variant="filled"
                className="material-input input"
                placeholder="License number"
                value={formData.licenseNumber || ""}
                onChange={(e) => {
                  setItemData({ licenseNumber: e.target.value });
                }}
              />
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <TextField
                key="stateIssue"
                label="State issue"
                variant="filled"
                select
                className="material-input input"
                placeholder="State issue"
                value={getStateName()}
                onChange={(e) => {
                  setItemData({
                    stateId: _.find(stateIssueList, { name: e.target.value })
                      .id,
                  });
                }}
              >
                {stateIssueList.map((item: any, index: number) => (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          </>
        ) : null}
      </Row>
    </div>
  );
};

const { Content } = Layout;
@mapProp(module_professional_info)
@mapProp(modle_user)
export default class ProfessionalInfo extends React.Component<
  ModuleProfessionalInfo,
  KV
> {
  componentDidMount() {
    window.onresize = () => {
      this.setState({ isMobile: window.innerWidth < 1024 });
    };
    const { slug } = queryString.parse(window.location.search);
    doAction(InitDataAction, slug);
    if (slug) {
      this.setState({ ...this.state, editMode: true });
    }
    if (!this.props.curUser.profile) {
      doAction(LoginWithTokenAction);
    }
  }
  componentWillUnmount() {
    window.onresize = () => {};
    doAction(MODULE_PROFESSIONCAL_INFO, module_professional_info);
  }

  state = {
    isMobile: window.innerWidth <= 1024,
    requiredFields: [] as string[],
    editMode: false,
  };

  changeField = (value: any, name: string) => {
    doAction(UpdateBaseInfoAction, {
      [name]: value,
    });
  };

  delListItem = (index: number, name: string) => {
    const newList = [...this.props[name]];
    newList.splice(index, 1);
    this.changeField(newList, name);
  };

  submit = () => {
    //check fields
    const emptyField = [] as any[];
    _.forEach(this.props.professionalList, (item) => {
      if (!item.name) {
        emptyField.push("Category");
      }
      if (item.name === "Other" && !item.otherName) {
        emptyField.push("Category");
      }
    });
    _.forEach(this.props.educationList, (item) => {
      if (!item.program.name) {
        emptyField.push("Program");
      }
    });
    if (_.isEmpty(emptyField)) {
      doAction(SubmitAction, this.props.history);
    } else {
      this.setState({
        requiredFields: emptyField,
      });
    }
  };

  render() {
    const { isMobile } = this.state;
    const {
      programOptions,
      educationList,
      professionalList,
      categoryList,
      stateIssueList,
    } = this.props;
    return (
      <Layout>
        <CreateBusinessCanvasHeader type={CanvasType.PROFESSIONAL} step={0} />
        <Content>
          <div className="info-container">
            <div className="common-title">Your Professional Information</div>
            {professionalList.map((item: any, index: number) => (
              <ProfessionalItem
                key={index}
                proIndex={index}
                categoryList={categoryList}
                stateIssueList={stateIssueList}
                isMobile={isMobile}
                professionalList={professionalList}
                setListData={(value: any) => {
                  this.changeField(value, "professionalList");
                }}
                delListItem={(index: number) =>
                  this.delListItem(index, "professionalList")
                }
                user={this.props.curUser}
                isEdit={this.props.slug}
              />
            ))}
            <Button
              className="btn-secondary"
              style={{ marginBottom: "50px" }}
              onClick={() => {
                const newList = this.props.professionalList.concat(
                  cloneDeep(professionalItem)
                );
                this.changeField(newList, "professionalList");
              }}
            >
              <img src={IconAwardAdd} alt="" />
              Add more categories
            </Button>

            <div className="common-title">About Your Education</div>
            {educationList.map((item: any, index: number) => (
              <EducationItem
                key={index}
                eduIndex={index}
                programList={programOptions}
                isMobile={isMobile}
                educationList={educationList}
                setListData={(value: any) => {
                  this.changeField(value, "educationList");
                }}
                delEducation={(index: number) =>
                  this.delListItem(index, "educationList")
                }
                user={this.props.curUser}
              />
            ))}
            <Button
              className="btn-secondary"
              onClick={() => {
                const newList = this.props.educationList.concat(
                  cloneDeep(educationItem)
                );
                this.changeField(newList, "educationList");
              }}
            >
              <img src={IconAwardAdd} alt="" />
              Add more education
            </Button>
            {!this.state.editMode &&
              awardRenderList.map((item: any, index: number) => (
                <AwardsList
                  key={"AwardsList" + index}
                  isMobile={isMobile}
                  {...item}
                  listData={this.props[item.name]}
                  setListData={(value: any) => {
                    this.changeField(value, item.name);
                  }}
                />
              ))}
            <Button className="btn-primary" onClick={this.submit}>
              Next to Professional Canvas
            </Button>
          </div>
          <FieldRequireModal
            onClose={() => this.setState({ requiredFields: [] })}
            visible={!_.isEmpty(this.state.requiredFields)}
            data={this.state.requiredFields}
          />
        </Content>
      </Layout>
    );
  }
}
