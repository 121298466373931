import React from "react";
import { Row, Col } from "antd";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./component_preview_card.less";

export default ({ previewData = [], column = 1, loading = false, noDataTips = "No Data" }) => {
  return (
    <div className="preview-card-wrap">
      {loading ? (
        <div className="tip-text"><CircularProgress color="inherit" /></div>
      ) : previewData.length ? (
        <Row gutter={24}>
          {previewData.map((item: any, index) => (
            <Col xs={{ span: 24 }} lg={{ span: 24 / column }} key={index}>
              <Row className="card-item">
                <Col span={10} className="card-label">
                  {item.label}
                </Col>
                <Col span={14} className="card-value">
                  {item.value}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      ) : (
            <div className="tip-text">{ noDataTips }</div>
          )}
    </div>
  );
};
