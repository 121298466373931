import React, { useEffect, useState } from "react";

import { TablePagination, Button } from "@material-ui/core";
import TablePaginationActions from "./pagination";
import moment from "moment";

import {
  getNotiList,
  notiRead,
  deleteNotification,
} from "../../../api/Notifications";
import {
  module_notifications,
  GetNotificationCount,
} from "../../../models/model_notifications";

import { mapProp, doAction } from "module-reaction";

import "./list.less";
import IconEmpty from "../../../assets/images/ic_notification.svg";
import { router_homepage_opportunity } from "../../../utils/enum";
import { PreFillLocationAndGoHomeOpportunities } from "../../../models/model_homepage_opportunity";
import { Checkbox } from "antd";

const Empty = () => (
  <div className="empty-layout">
    <img alt="" src={IconEmpty} />
    <span>No notifications</span>
    {/*<span className="detail">*/}
    {/*We’re working hard on the message feature. It will allow you to communicate seamlessly with your staff, students, and candidates.*/}
    {/*</span>*/}
  </div>
);

const NotificationList: React.FC<Props> = (props: Props) => {
  const size = 8;

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [dataList, setDatalist] = useState([]);
  const [check, setCheck] = useState<any>([]);
  const [checkAll, setCheckAll] = useState(false);

  const handleRead = (row: any) => {
    if (!row) {
      let _data = {
        id: row,
      };
      notiRead(_data).then((data) => {
        doAction(GetNotificationCount);
        setPage(0);
        list(0);
      });
    } else {
      if (row.message && row.message.link) {
        // check browse-opportunity has the defaultLocation filter
        if (
          row.message.link.includes(router_homepage_opportunity) &&
          row.message.link.includes("defaultLocation=true")
        ) {
          doAction(PreFillLocationAndGoHomeOpportunities);
        } else {
          window.location.href = row.message.link;
        }
      }
    }
  };

  const handleDelete = () => {
    if (check.length) {
      let _data = {
        deleteAll: checkAll,
        notifications: check,
      };
      deleteNotification(_data).then((data) => {
        doAction(GetNotificationCount);
        setPage(0);
        list(0);
      });
    }
  };

  const onCheckboxChange = (e: any) => {
    if (check.includes(e)) {
      let _check = JSON.parse(JSON.stringify(check));
      _check.splice(
        _check.findIndex((item: any) => item === e),
        1
      );
      setCheck(_check);
      setCheckAll(false);
    } else {
      setCheck([...check, e]);
    }
  };

  const handleChangeAll = () => {
    if (!!!checkAll) {
      setCheckAll(true);
      // setCheck(Array.from(new Set([...check,...dataList.map((item:any) => item.id)])))
    } else {
      setCheckAll(false);
      setCheck([]);
    }
  };

  const list = (page = 0) => {
    let _data = {
      size,
      page: page + 1,
    };
    getNotiList(_data)
      .then((data) => {
        setTotal(data.count);
        setDatalist(data.items);
        if (checkAll) {
          setCheck(
            Array.from(
              new Set([...check, ...data.items.map((item: any) => item.id)])
            )
          );
        }
      })
      .catch((e) => {});
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log(111, newPage);
    setPage(newPage);
  };

  useEffect(() => {
    if (check.length === total && check?.length > 0) {
      setCheckAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  useEffect(() => {
    if (checkAll) {
      setCheck(
        Array.from(new Set([...check, ...dataList.map((item: any) => item.id)]))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAll]);

  useEffect(() => {
    list(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="notification-container">
      <div className="title">
        Notifications
        <span className="title-btn">
          <Button onClick={handleDelete} variant="outlined">
            Delete
          </Button>
          <Button onClick={() => handleRead("")} variant="outlined">
            Mark as read
          </Button>
        </span>
      </div>
      {dataList.length ? (
        <>
          <div className="list-wrapper">
            <div className="select-wrapper">
              <Checkbox
                className="box-item-check"
                checked={checkAll}
                onClick={handleChangeAll}
              />
              Select all
            </div>
            {dataList.map((item: any, index) => {
              return (
                <div
                  className={`list-item ${item.viewedAt ? "" : "unread"} ${
                    item.message?.link && "link"
                  }`}
                >
                  <p className="list-item-content">
                    <Checkbox
                      className="box-item-check"
                      checked={check.includes(item.id)}
                      onClick={() => onCheckboxChange(item.id)}
                    />
                    <i onClick={() => handleRead(item)}>{item.message?.body}</i>
                  </p>
                  <span className="list-item-date">
                    {moment(item.createdAt).format("[at ]HH:mm MMMM Do YYYY")}
                  </span>
                </div>
              );
            })}
          </div>
          <TablePagination
            rowsPerPageOptions={[size]}
            component="div"
            count={total}
            rowsPerPage={size}
            page={page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
};

interface Props {
  dataList: any[];
  total: number;
  size: number;
  page: number;
  pageOnChange?: any;
  loading?: boolean;
  history: any;
}

export default mapProp(module_notifications)(NotificationList);
