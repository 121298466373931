import axios from "axios";
import { InsApi } from "../utils/constants";
import Http from "./Http";

export const getInsListByName = (name: string) => {
  return axios
    .get(InsApi.replace("#", name))
    .then((res) => {
      if (res.status === 200) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const sendContactEmail = (from: string, msg: string) => {
  return new Promise((resolve, reject) => {
    Http.post("/user/contact-us", { from, msg })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};


export const sendShowcaseEmail = (role: string, email: string) => {
  return new Promise((resolve, reject) => {
    Http.post("/user/brief-info", { role, email })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};

export const getUserCount = () => {
  return new Promise((resolve, reject) => {
    Http.get("/user/count")
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
}
