import React from "react";
import { Button, Spin } from "antd";
import _ from "lodash";
import ConfirmModal from "../../../components/component_confirm_modal";
import CanvasBookmarkItem from "./canvas_bookmark_item";
import OpportunityBookmarkItem from "./opportunity_bookmark_item";
import EditNoteModal from "./edit_note_modal";
import {
  MODULE_BOOKMARKS,
  GetCanvasAndOppBookmarks,
  ModelBookmarks,
  module_bookmarks,
  RemoveBookmarkAction,
  EditBookmarkNoteAction,
} from "../../../models/model_bookmarks";
import IconEmpty from "../../../assets/images/ic_bookmark_empty.svg";
import "./bookmarks.less";
import { doAction, doFunction, mapProp } from "module-reaction";
import { router_bookmarks_all } from "../../../utils/enum";

@mapProp(module_bookmarks)
export class Bookmarks extends React.Component<ModelBookmarks, {}> {
  state = {
    showDelete: false,
    deleteId: 0,
    showEdit: false,
    curNote: "",
    editId: 0,
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    doAction(GetCanvasAndOppBookmarks);
    doFunction(async () => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    doAction(MODULE_BOOKMARKS, module_bookmarks);
  }

  onNoteChange = (note: string) => {
    doAction(EditBookmarkNoteAction, { id: this.state.editId, note: note });
  };

  onCanvasDelete = (id: number) => {
    this.setState({
      ...this.state,
      showDelete: true,
      deleteId: id,
    });
  };

  renderEmpty = (
    <div className="empty-layout">
      <img alt="" src={IconEmpty} />
      <span>You have no bookmarks.</span>
      <span className="detail">
        Find a canvas and bookmark to keep posted by valuable candidates
      </span>
    </div>
  );

  render() {
    return this.state.loading ? (
      <Spin />
    ) : (
      <div className="bookmarks-root">
        <span className="title">Bookmarks</span>
        {_.isEmpty(this.props.canvasList) &&
        _.isEmpty(this.props.opportunityList) ? (
          this.renderEmpty
        ) : (
          <>
            {!_.isEmpty(this.props.canvasList) && (
              <>
                <span className="subtitle">Saved Canvases</span>
                <div className="canvas-list">
                  {_.map(_.take(this.props.canvasList, 3), (canvas, index) => {
                    return (
                      <CanvasBookmarkItem
                        canvas={canvas.canvas}
                        key={index}
                        note={canvas.note}
                        history={this.props.history}
                        onNote={() => {
                          this.setState({
                            ...this.state,
                            curNote: canvas.note,
                            editId: canvas.canvas.id,
                            showEdit: true,
                          });
                        }}
                        onDelete={(id: number) => this.onCanvasDelete(id)}
                      />
                    );
                  })}
                </div>

                <div
                  hidden={this.props.canvasList.length <= 3}
                  onClick={() =>
                    this.props.history.push(
                      `${router_bookmarks_all}?type=canvas`
                    )
                  }
                >
                  <Button className="btn-secondary btn-more">View more</Button>
                </div>
              </>
            )}
            <div
              className="divider"
              hidden={
                !_.isEmpty(this.props.opportunityList) ||
                !_.isEmpty(this.props.canvasList)
              }
            />
            {!_.isEmpty(this.props.opportunityList) && (
              <>
                <span className="subtitle">Saved Opportunities</span>
                <div className="canvas-list">
                  {_.map(
                    _.take(this.props.opportunityList, 3),
                    (opportunity, index) => {
                      return (
                        <OpportunityBookmarkItem
                          opportunity={opportunity.job}
                          key={index}
                          history={this.props.history}
                        />
                      );
                    }
                  )}
                </div>

                <div
                  hidden={this.props.opportunityList.length <= 3}
                  onClick={() =>
                    this.props.history.push(
                      `${router_bookmarks_all}?type=opportunity`
                    )
                  }
                >
                  <Button className="btn-secondary btn-more">View more</Button>
                </div>
              </>
            )}
          </>
        )}

        <ConfirmModal
          visible={this.state.showDelete}
          description="Are you sure to remove this bookmark?"
          onClose={() => this.setState({ ...this.state, showDelete: false })}
          onConfirm={() => {
            this.setState({ ...this.state, showDelete: false });
            doAction(RemoveBookmarkAction, this.state.deleteId);
          }}
        />
        {this.state.showEdit && (
          <EditNoteModal
            visible
            onClose={() => this.setState({ ...this.state, showEdit: false })}
            onEdit={(note: string) => {
              this.setState({ ...this.state, showEdit: false });
              this.onNoteChange(note);
            }}
            note={this.state.curNote}
          />
        )}
      </div>
    );
  }
}
