import React, { useState } from 'react';
import './sc.less';
import { getStaticAssetPath } from '../../../utils/utils';
import { global_router } from '../../../routers';
import { router_get_started } from '../../../utils/enum';
import { TextField } from '@material-ui/core';
import { Button } from 'antd';
import ScrollingIpad from '../studentpro/scrolling_ipad';

const SalonCentricHero: React.FC = () => {
  const [mode, setMode] = useState('portfolio');
  const [email, setEmail] = useState('');
  const image = mode === 'biz' ? getStaticAssetPath(`studentpro-landing/hero-biz.png`) : getStaticAssetPath(`studentpro-landing/hero-portfolio.png`);
  const rootStyle = { backgroundImage: `url(${image})` };
  const headline = mode === 'biz' ? 'Highlight Your Salon and Find Your People' : 'Get Noticed by Top Beauty Brands with Beautista.';
  const copy = mode === 'biz' ? `It's much bigger than a job board. Beautista humanizes your brand and elevates your recruitment process. Save time, money, and find your people.` : 'Create your free profile on Beautista and get noticed by top brands and salons.';

  const initSignup = () => {
    if (!email) {
      global_router.history.push(`${router_get_started}?source=saloncentric`);
    }

    global_router.history.push(`${router_get_started}?prefilled=${encodeURIComponent(email)}&source=saloncentric`);
  }

  return (
    <div className="cm-sc-hero cm-flex-responsive-row" style={rootStyle}>
      <div className="cm-sc-hero__info">
        <div className="cm-sc-hero__selector">
          <div className={`cm-sc-hero__selector-item ${mode === 'portfolio' ? 'cm-sc-hero__selector-item--selected' : ''}`} onClick={() => setMode('portfolio')}>
            <img src={getStaticAssetPath(`studentpro-landing/icon-career.png`)} alt="Career" />
            <p>Create Your Portfolio</p>
          </div>
          <div className={`cm-sc-hero__selector-item ${mode === 'biz' ? 'cm-sc-hero__selector-item--selected' : ''}`} onClick={() => setMode('biz')}>
            <img src={getStaticAssetPath(`studentpro-landing/icon-handshake.png`)} alt="Handshake" />
            <p>Post Your Career Opportunity</p>
          </div>
        </div>
        <div className="cm-sc-hero__content">
          <h2>{headline}</h2>
          <p>{copy}</p>
          <form onSubmit={initSignup} className="cm-flex-responsive-row cm-full-width cm-flex-v-center">
            <TextField
              key="email"
              label="Email"
              variant="filled"
              className="material-input input cm-flex-1"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              htmlType="submit"
              className="btn-primary btn-sign-up">
              Join in less than 3 minutes
            </Button>
          </form>
        </div>
      </div>
      <div className="cm-sc-hero__extras">
        {mode === 'biz' && <img className="cm-sc-hero__overlay" src={getStaticAssetPath('studentpro-landing/hero_overlay_biz.png')} alt="hero overlay" />}
        {mode === 'portfolio' && <img className="cm-sc-hero__overlay" src={getStaticAssetPath('studentpro-landing/hero_overlay.png')} alt="hero overlay" />}
        <ScrollingIpad />
      </div>
    </div>
  )
};

export default SalonCentricHero;
