import Http from "./Http";

export const searchOpportunity = (params: {
  keyword?: string;
  category?: number;
  jobtype?: number;
  brand?: number;
  software?: number;
  paymentMode?: number;
  location?: { coordinates: number[]; radius: number; locationInfo: any };
  page: number;
  size: number;
}) => {
  return Http.post("/opportunity/browse/search", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const getMyOppList = (params: any) => {
  return Http.post("/opportunity/my/posted", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const createOpportunity = (params: any) => {
  return Http.post("/opportunity", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const updateOpportunity = (params: any) => {
  return Http.put("/opportunity", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const updateOppStatus = (params: any) => {
  return Http.put("/opportunity/status", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const getOppDtls = (id: number | string) => {
  return Http.get("/opportunity/" + id)
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const getUsrOppAnchor = () => {
  return Http.get("/opportunity/canvas/location")
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const opportunityApply = (params: any) => {
  return Http.post("/opportunity/apply", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const getMyAskedOppList = (canvas_id?: number) => {
  return Http.get("/opportunity/my/asked" + (canvas_id ? `?canvas_id=${canvas_id}` : ''))
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const opportunityWithdraw = (params: any) => {
  return Http.post("/opportunity/withdraw", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const opportunityApplicants = (params: any) => {
  return Http.post("/opportunity/applicants", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const changeApplicantStatus = (params: any) => {
  return Http.post("/opportunity/applicant-status", { ...params })
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const getSimilarData = (id: number) => {
  return Http.get("/opportunity/similar/" + id)
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const addOppView = (id: number) => {
  return Http.post("/opportunity/addview", {id})
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const deleteOpportunity = (id: number) => {
  return Http.delete(`/opportunity/${id}`)
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};
export const getOppsOfcanvas = (id: number) => {
  return Http.get("/opportunity/belongto/" + id)
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};

export const getOppViewDetail = (id: number) => {
  return Http.get("/opportunity/opp-view-details/" + id)
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
}
