import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import queryString from "query-string";
// import Close from "../assets/images/ic_close_grey.svg";
import "./component_video_modal.less";
// var videojs = require('../../video.js/dist/video.cjs.js');

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  url: string;
}

const VideoModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, url } = props;
  const [isYoutube, setIsYoutube] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const isYoutube = url.indexOf("youtube") > -1;
    setIsYoutube(isYoutube);
    if (isYoutube) {
      const query = url.split("?");
      let queryStr = "";
      if (query.length > 0) {
        queryStr = query[1];
      }
      const { v } = queryString.parse(queryStr);
      if (v) {
        setVideoUrl(`https://www.youtube.com/embed/${v}`);
      }
    } else {
      setVideoUrl(url);
    }
  }, [url]);

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      wrapClassName="video-modal-wrap"
      centered={true}
    >
      <div className="video-modal-root">
        {isYoutube ? (
          <iframe
            className="youtube-video"
            title="youtube-video"
            src={videoUrl}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        ) : (
          <video id="my-video" className="video-js" controls autoPlay>
            <source src={videoUrl} type="video/mp4" />
            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a href="https://videojs.com/html5-video-support/">
                supports HTML5 video
              </a>
            </p>
          </video>
        )}

        <script src="https://vjs.zencdn.net/7.7.6/video.js"></script>
      </div>
    </Modal>
  );
};
export default VideoModal;
