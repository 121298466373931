import React, { useState, useEffect } from "react";
import { Button, Input, Modal, Avatar, message } from "antd";
import _ from "lodash";
import { TextField, MenuItem } from "@material-ui/core";
import {
  getCandidateStudents,
  inviteCandidateStudents,
} from "../../../api/School";
import AddStudentModal from "./add_student_modal";
import {
  ListTable,
  IHeadItem,
  IBodyItem,
} from "../../../components/component_list_table";
import { inviteStudentsByEmail } from "../../../api/School";
import IconLocation from "../../../assets/images/ic_location_hollow.svg";
import IconAddStudent from "../../../assets/images/ic_add_student.svg";
import IconAddGreen from "../../../assets/images/ic_add_green.svg";
import { getCanvas } from "../../../api/Canvas";
import "./school_dashboard_students.less";

import { doAction, mapProp } from "module-reaction";
import {
  module_school_dashboard_add_student,
  GetOptionsActions,
} from "../../../models/model_school_dashboard_add_student";

const { Search } = Input;

interface AddStudensProps {
  school: any;
  changeTab: any;
  programOptions: any;
}

const SchoolDashboardAddStudent: React.FC<AddStudensProps> = (props) => {
  const { school, changeTab, programOptions } = props;
  const isMobile = window.innerWidth < 1024;
  const size = 8;
  const [locationList, setLocationList] = useState([] as any[]);
  const [location, setLocation] = useState(0);
  const [program, setProgram] = useState(0);
  const [programList, setProgramList] = useState([
    { name: "All", optionId: 0 },
  ]);
  const [keyword, setKeyword] = useState("");
  const [selectList, setSelectList] = useState([] as any);
  const [curPage, setCurPage] = useState(1);
  const [list, setList] = useState([] as any[]);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [showEmailAdd, setShowEmaillAdd] = useState(false);
  const [addEnable, setAddEnable] = useState(true);

  useEffect(() => {
    if (school && school.id) {
      getSchoolPrograms(school.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school]);

  const getSchoolPrograms = async (id: number) => {
    const res = await getCanvas(id + "");
    if (res) {
      const locs = _.map(res.detail.locations, (loc) => {
        let newItem = { ...loc } as any;
        if (!_.isEmpty(school.locations)) {
          const temp = _.find(school.locations, { uuid: loc.uuid });
          if (temp && temp.id) {
            newItem = { ...newItem, id: temp.id };
          }
        }

        return newItem;
      });
      // locs.unshift({ address: "All", id: 0 });

      setLocationList(locs);
      const programs = [{ name: "All", optionId: 0 }];
      _.forEach(res.detail.locations, (item) => {
        programs.push(...item.supportedProgram);
      });
      setProgramList(programs);
      setLocation(locs[0].id);
      setProgram(programs[0].optionId);
    }
  };

  useEffect(() => {
    if (!location) {
      let programs = [{ optionId: "", name: "All" }] as any[];
      _.forEach(locationList, (item: any) => {
        if (item.supportedProgram && !_.isEmpty(item.supportedProgram)) {
          programs = [...programs, ...item.supportedProgram];
        }
      });
      setProgramList(programs);
    } else {
      const loc: any = _.find(locationList, { id: location });
      if (loc && !_.isEmpty(loc.supportedProgram)) {
        setProgramList([
          { optionId: "", name: "All" },
          ...loc.supportedProgram,
        ]);
      }
    }
  }, [location, locationList]);

  const getList = (page = 1) => {
    if (!school.id || loading) return;
    setLoading(true);
    const params: any = {
      canvas_id: school.id,
      page,
      size,
    };
    const filter = {
      keyword,
      location: +location,
      program: +program,
      sortBy: orderBy,
    };
    _.map(filter, (value, key) => {
      if (value) params[key] = value;
    });
    getCandidateStudents(params)
      .then((res: any) => {
        setCurPage(page);
        setList(res.list || []);
        setTotal(res.count || 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school, keyword, location, program, orderBy]);

  useEffect(() => {
    doAction(GetOptionsActions);
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    getList(newPage);
  };

  function addStudent() {
    if (!school.id) return;
    setAddEnable(false);
    const params: any = {
      canvas_id: school.id,
      students: selectList,
    };
    if (location) {
      params.location = +location;
    }
    inviteCandidateStudents(params)
      .then((res) => {
        message.success("email sent successfully");
        setSelectList([]);
      })
      .finally(() => {
        setAddEnable(true);
        setVisibleAddModal(false);
      });
  }

  const tHeadRender: IHeadItem[] = isMobile
    ? [{ name: "Student name", isSort: true, key: "name" }]
    : [
        { name: "Student name", isSort: true, key: "name" },
        { name: "Phone No." },
        { name: "Location", isSort: true, key: "location" },
        { name: "Graduation Date", isSort: true, key: "graduate" },
        { name: "Program" },
      ];

  const tBodyRender: IBodyItem[] = [
    {
      type: "avatar",
      component: (row: any) => (
        <>
          <Avatar
            icon="user"
            src={row && row.avatar}
            className="avatar"
            style={{ marginRight: "8px" }}
          />
          {row.firstName} {row.lastName}
        </>
      ),
      className: "avatar-name",
    },
    { name: "Phone No.", key: "phoneNumber" },
    { name: "Location", key: "location.city" },
    { name: "Graduation Date", key: "graduationDate", type: "date" },
    { name: "Program", key: "programName" },
  ];

  const addStudentEmail = async (emails: any[]) => {
    if (school && school.id && location && !_.isEmpty(emails)) {
      const res = await inviteStudentsByEmail(school.id, location, emails);
      console.log("inviteStudentsByEmail", res);
      if (res) {
        message.success("email sent successfully");
      }
    }
  };

  return (
    <div className="school-dashboard-add-students">
      <div className="title-wrap">
        <div className="common-title bold-title">Add Student</div>
        <Button
          className="btn-secondary"
          onClick={() => changeTab("My Students")}
        >
          back
        </Button>
      </div>
      <div className="title-with-icon">
        <img src={IconLocation} alt="" />
        Pick a location
      </div>
      <TextField
        label="Pick a locattion to add students to"
        variant="filled"
        select
        className="material-input input"
        style={{ width: "100%" }}
        value={"" + location}
        onChange={(e) => setLocation(+e.target.value)}
      >
        {locationList.map((item: any, index: number) => (
          <MenuItem key={index} value={"" + item.id}>
            {item.address}
          </MenuItem>
        ))}
      </TextField>
      <div className="title-with-icon title-add-stu">
        <img src={IconAddStudent} alt="" />
        Add Students
      </div>
      <div className="filter-wrap">
        <TextField
          label="Program"
          variant="filled"
          select
          className="material-input input"
          value={"" + program}
          onChange={(e) => setProgram(+e.target.value)}
        >
          {programOptions.map((item: any, index: number) => (
            <MenuItem key={index} value={"" + item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <Search
          className="keyword-input"
          placeholder="Search student by name..."
          onSearch={setKeyword}
        />
        <Button
          className="btn-secondary"
          disabled={!selectList.length}
          onClick={() => setVisibleAddModal(true)}
        >
          <span className="center-wrap">
            <img src={IconAddGreen} alt="" />
            Add
          </span>
        </Button>
      </div>
      <span className="add-by-email">
        Can't find who you're looking for?
        <span className="hyper-link" onClick={() => setShowEmaillAdd(true)}>
          Add by email
        </span>
      </span>
      <ListTable
        tHeadRender={tHeadRender}
        tBodyRender={tBodyRender}
        loading={loading}
        hasSelect={true}
        idKey="userId"
        dataList={list}
        selectList={selectList}
        selectOnChange={setSelectList}
        total={total}
        size={size}
        page={curPage}
        pageOnChange={handleChangePage}
        orderBy={orderBy}
        orderByOnChange={(value: string) => setOrderBy(value)}
        isMobile={isMobile}
      />
      <Modal
        footer={null}
        onCancel={() => setVisibleAddModal(false)}
        visible={visibleAddModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Confirmation</div>
          <div className="modal-text">
            By choosing “Add Students”, you are going to send invitation emails
            to {selectList.length} student(s). Do you want to continue?
          </div>
          <div className="btn-wrap">
            <Button type="primary" onClick={addStudent} disabled={!addEnable}>
              Add Student
            </Button>
            <Button onClick={() => setVisibleAddModal(false)}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <AddStudentModal
        visible={showEmailAdd}
        onClose={() => setShowEmaillAdd(false)}
        onSend={(emails: any[]) => {
          addStudentEmail(emails);
          setShowEmaillAdd(false);
        }}
      />
    </div>
  );
};

export default mapProp(module_school_dashboard_add_student)(
  SchoolDashboardAddStudent
);
