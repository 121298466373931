import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Avatar } from "antd";
import classnames from "classnames";
import moment from "moment";
import _ from "lodash";
import IconSort from "../assets/images/ic_arrow_down.svg";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import IconMore from "../assets/images/ic_more.svg";
import "./component_list_table.less";

interface Props {
  tHeadRender: IHeadItem[];
  tBodyRender: IBodyItem[];
  hasSelect?: boolean;
  hasHeader?: boolean;
  idKey?: string;
  dataList: any[];
  selectList?: number[];
  selectOnChange?: any;
  total: number;
  size: number;
  page?: number;
  pageOnChange?: any;
  scrollLoadMore?: () => void;
  orderBy?: string;
  orderByOnChange?: any;
  loading?: boolean;
  noDataTips?: string;
  isMobile?: boolean;
  tableClassName?: string;
}

export interface IHeadItem {
  name?: string;
  isSort?: boolean;
  key?: string;
  col?: number
}

export interface IBodyItem {
  name?: string;
  key?: string;
  col?: number
  type?: string /** date,amount,dropdown-actions  */;
  component?: any;
  isSort?: boolean;
  className?: string;
  dropdownList?: any[];
  pendingComponent?: any;
}

const CustomTableHead = (props: any) => {
  const {
    tHeadRender,
    hasSelect,
    orderBy,
    onSelectAll,
    onRequestSort,
    selectedAll,
  } = props;
  return (
    <TableHead>
      <TableRow>
        {hasSelect && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={selectedAll}
              onChange={onSelectAll}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {tHeadRender.map((row: any, index: number) => (
          <TableCell
            className={classnames({ "sort-head": row.isSort})}
            style={{flex: row.col || 1}}
            key={index}
          >
            {row.name}
            {row.isSort ? (
              <img
                src={IconSort}
                alt=""
                onClick={() => onRequestSort(row.key)}
                className={classnames({ active: orderBy === row.key })}
              />
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

// Pagination start
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}
const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
// Pagination end

export const ListTable: React.FC<Props> = (props) => {
  const {
    tHeadRender,
    tBodyRender,
    hasSelect,
    idKey = "id",
    dataList,
    selectList,
    selectOnChange,
    total,
    size,
    page,
    pageOnChange,
    scrollLoadMore,
    orderBy,
    orderByOnChange,
    loading = false,
    noDataTips,
    isMobile = false,
    hasHeader = true,
    tableClassName,
  } = props;

  const [selectedAll, setSelectedAll] = useState(false);
  let tblBody: any

  useEffect(() => {
    return () => {
      if (tblBody) {
        tblBody.removeEventListener('scroll', listenScroll)
      }
    }
  }, [])
  useEffect(() => {
    if (!_.isEmpty(selectList) && !_.isEmpty(dataList)) {
      const childs = _.intersection(
        selectList || [],
        dataList.map((item) => {
          return item.id || item.userId;
        })
      );
      setSelectedAll(childs.length === dataList.length);
    } else {
      setSelectedAll(false);
    }
  }, [dataList, selectList]);

  // select
  const selectAll = (event: any) => {
    let newSelecteds = [] as number[];
    if (event.target.checked) {
      newSelecteds = dataList.map((n) => n[idKey]);
      selectOnChange(_.uniq(_.concat(selectList, newSelecteds)));
    } else {
      newSelecteds = _.cloneDeep(selectList || []);
      _.remove(newSelecteds, (id) => {
        return _.findIndex(dataList, { [idKey]: id }) >= 0;
      });
      selectOnChange(newSelecteds);
    }
  };

  const selectRow = (id: number) => {
    const newSelected = _.cloneDeep(selectList || []);

    if (selectList!.indexOf(id) < 0) {
      newSelected.push(id);
    } else {
      _.remove(newSelected, (n) => n === id);
    }

    selectOnChange(newSelected);
  };

  const handleRequestSort = (key: any) => {
    if (orderBy === key) {
      orderByOnChange("");
    } else {
      orderByOnChange(key);
    }
  };
  const isSelected = (id: any) =>
    selectList ? selectList.indexOf(id) !== -1 : false;

  // pagination
  let pagination = null;
  if (!scrollLoadMore && page !== undefined && !isNaN(page) && page > -1 && pageOnChange) {
    pagination = (
      <TablePagination
        rowsPerPageOptions={[size]}
        component="div"
        count={total}
        rowsPerPage={size}
        page={page - 1}
        onPageChange={(event, newPage) => pageOnChange(event, newPage + 1)}
        ActionsComponent={TablePaginationActions}
      />
    );
  }

  // actions popover
  const [anchorEl, setAnchorEl] = useState(null as any);
  const [popoverId, setPopoverId] = useState(null as any);
  const [currentItem, setCurrentItem] = useState({} as any);

  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(index);
    setCurrentItem(item);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const listenScroll = function(evt: any) {
    const { target: e } = evt
    if (!loading && e && e.clientHeight + e.scrollTop >= e.scrollHeight) {
      // load more
      console.log('on scroll,len', dataList.length);
      (total > dataList.length) && scrollLoadMore && scrollLoadMore()
    }
  }

  const open = Boolean(anchorEl);

  const getValue = (type: any, value: any) => {
    switch (type) {
      case "date":
        return value ? moment(value).format("MM/DD/YYYY") : "";
      case "amount":
        return "$" + value;
      default:
        return value === "null" || value === null || value === undefined
          ? ""
          : value;
    }
  };

  return (
    <>
      <TableContainer className="list-table-wrap">
        <Table aria-label="custom pagination table" className={tableClassName}>
          {isMobile === false || hasHeader ? (
            <CustomTableHead
              tHeadRender={tHeadRender}
              hasSelect={hasSelect}
              orderBy={orderBy}
              onSelectAll={selectAll}
              onRequestSort={handleRequestSort}
              selectedAll={selectedAll}
            />
          ) : (
              ""
            )}
          <TableBody
            className={classnames('tbl-body', {
              [`row-${size}`]: !!scrollLoadMore
            })}
            ref={scrollLoadMore ? e => {
              if (tblBody) {
                tblBody.removeEventListener('scroll', listenScroll)
              }
              tblBody = e
              e?.addEventListener('scroll', listenScroll)
            } : undefined}>
            {dataList.length ? (
              dataList.map((row, index) => {
                const isItemSelected = isSelected(row[idKey]);
                return isMobile ? (
                  <TableRow key={index}>
                    {hasSelect && (
                      <TableCell padding="checkbox">
                        {!row.pending && (
                          <Checkbox
                            checked={isItemSelected}
                            onChange={() => selectRow(row[idKey])}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="mobile-header">
                        {_.find(tBodyRender, { type: "avatar" }) && (
                          <div>
                            <Avatar
                              icon="user"
                              src={row && row.avatar}
                              className="avatar avatar-name"
                              style={{ marginRight: "8px" }}
                            />
                            {row.firstName} {row.lastName}{" "}
                            {row.pending ? " (pending)" : ""}
                          </div>
                        )}
                        <div className="space" />
                        {_.find(tBodyRender, { type: "dropdown-actions" }) &&
                          !row.pending && (
                            <IconButton
                              aria-describedby={"popover" + index}
                              onClick={(event) => {
                                const item = _.find(tBodyRender, {
                                  type: "dropdown-actions",
                                });
                                item && openPopover(event, index, item);
                              }}
                            >
                              <img src={IconMore} alt="" className="ic-more" />
                            </IconButton>
                          )}
                        {_.find(tBodyRender, { type: "link-actions" }) && (
                          <div className="payment-action">
                            {(
                              _.find(tBodyRender, { type: "link-actions" }) || {
                                component: () => { },
                              }
                            ).component(row, index)}
                          </div>
                        )}
                      </div>
                      <div className="mobile-info-list">
                        {row.pending
                          ? _.find(tBodyRender, {
                            type: "dropdown-actions",
                          })?.pendingComponent(row, index)
                          : tBodyRender.map((rowField: any, _index: number) => {
                            const {
                              name,
                              component,
                              key,
                              type,
                              className,
                            } = rowField;
                            return (
                              !_.includes(
                                [
                                  "avatar",
                                  "action",
                                  "dropdown-actions",
                                  "link-actions",
                                ],
                                type
                              ) && (
                                <div className="mobile-info-item">
                                  <span className="mobile-info-item-key">
                                    {name}
                                  </span>
                                  <span
                                    className={`mobile-info-item-value ${className || ""
                                      }`}
                                  >
                                    {component
                                      ? component(row, index)
                                      : type === "date"
                                        ? moment(row[key]).format("MM/DD/YYYY")
                                        : _.get(row, key)}
                                  </span>
                                </div>
                              )
                            );
                          })}
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                    <TableRow key={index}>
                      {hasSelect && (
                        <TableCell padding="checkbox">
                          {!row.pending && (
                            <Checkbox
                              checked={isItemSelected}
                              onChange={() => selectRow(row[idKey])}
                            />
                          )}
                        </TableCell>
                      )}
                      {tBodyRender.map((item: any, itemIndex: number) =>
                        item.component ? (
                          <TableCell
                            key={itemIndex}
                            className={item.className || ""}
                            style={{ flex: item.col || 1 }}
                          >
                            {item.component(row, index)}
                          </TableCell>
                        ) : item.type === "dropdown-actions" ? (
                          <TableCell
                            key={itemIndex}
                            className="table-cell-action"
                            style={{ flex: item.col || 1 }}
                          >
                            {row.pending ? (
                              item.pendingComponent(row, index)
                            ) : (
                                <IconButton
                                  aria-describedby={"popover" + index}
                                  onClick={(event) =>
                                    openPopover(event, index, item)
                                  }
                                >
                                  <img src={IconMore} alt="" />
                                </IconButton>
                              )}
                          </TableCell>
                        ) : (
                              <TableCell
                                key={itemIndex}
                                className={item.className || ""}
                                style={{ flex: item.col || 1 }}
                              >
                                {getValue(item.type, _.get(row, item.key))}
                              </TableCell>
                            )
                      )}
                    </TableRow>
                  );
              })
            ) : (
                <TableRow>
                  <TableCell
                    colSpan={tHeadRender.length + (hasSelect ? 1 : 0)}
                    align="center"
                    style={{justifyContent: "center"}}
                  >
                    {noDataTips || "No Data"}
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
            {scrollLoadMore ? <div className="total-cnt">{Math.min(page! * size, total)} of {total}</div> : pagination}
        {/* <div className="selected-count">
          {numSelected} students selected
        </div> */}
        {loading ? (
          <div className="loading-wrap">
            <CircularProgress color="inherit" className="table-loading" />
          </div>
        ) : null}
      </TableContainer>
      <Menu
        id={"popover" + popoverId}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="table-popover"
      >
        {(currentItem.dropdownList || []).map((item: any, index: number) => (
          <MenuItem
            onClick={() => {
              closePopover();
              item.onClick(dataList[popoverId], popoverId);
            }}
            className={item.orangeText ? "orange-text" : ""}
          >
            <img src={item.icon} alt="" /> {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
