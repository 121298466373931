import React, { useState, useEffect } from "react";
import "./component_cr_textarea.less";
import { TextField } from "@material-ui/core";

interface Props {
  className?: string;
  label: string;
  value?: string;
  onInput: Function;
  max?: number | undefined;
  limit?: number;
  type?: string;
  placeholder?: string;
  variant?: string;
  InputProps?: any;
  onKeyDown?: any;
  preventKeyCodes?: number[];
  onClick?: Function;
  disabled?: boolean
}

const CRTextArea: React.FC<Props> = (props) => {
  const {
    className = "",
    label = "",
    onInput,
    limit = 0,
    max,
    value,
    type,
    placeholder,
    onKeyDown,
    InputProps,
    preventKeyCodes = [],
    disabled = false,
    onClick,
  } = props;
  const [val, setVal] = useState<any>("");

  useEffect(() => {
    if (value && limit > 0 && value.length > limit) {
      const newValue = value.substring(0, limit);
      setVal(newValue);
      onInput(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, limit, onInput]);

  useEffect(() => {
    setVal(value);
  }, [value]);

  return type === "text" || type === "number" ? (
    <TextField
      className={`material-input input ${className}`}
      key={label}
      label={label}
      type={type}
      disabled={disabled}
      InputProps={InputProps}
      onKeyDown={onKeyDown}
      variant="filled"
      placeholder={placeholder}
      value={val || value || ""}
      onChange={(e) => {
        let value =
          type === "number" && max && max - Number(e.target.value) < 0
            ? max
            : e.target.value;
        setVal(value);
        onInput(value);
      }}
      onClick={(e) => onClick && onClick(e)}
    />
  ) : (
    <div className="cr-textarea">
      <TextField
        className={`material-input input ${className}`}
        key={label}
        label={label}
        placeholder={label}
        multiline
        variant="filled"
        value={val || value || ""}
        onChange={(e) => {
          setVal(e.target.value);
          onInput(e.target.value);
        }}
        onKeyDown={(e) =>
          preventKeyCodes.indexOf(e.keyCode) > -1 && e.preventDefault()
        }
        onClick={(e) => onClick && onClick(e)}
      />
      <span className="limit">{limit > 0 && `${val?.length}/${limit}`}</span>
    </div>
  );
};

export default CRTextArea;
