import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { ScStoreList } from '../../utils/constants';
import Spin from 'antd/lib/spin';
import IconRadioOff from "../../assets/images/ic_radio_off.svg";
import IconRadioOn from "../../assets/images/ic_radio_on.svg";
import { doAction } from 'module-reaction';
import { AddUserSelectedSource } from '../../models/model_get_started';
import './join_source.less';

const options = [
    { label: 'SalonCentric Store', value: 'SalonCentric Store' },
    { label: 'My School', value: 'My School' },
    { label: 'Social Media', value: 'Social Media' },
    { label: 'Google Search', value: 'Google Search' },
    { label: 'A Friend', value: 'A Friend' },
    { label: 'Other', value: 'Other' },
];

export default function JoinSource() {
    const [selectedOption, setSelectedOption] = useState('');
    const [sourceDetail, setSourceDetail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const sortedScStoreList = [...ScStoreList].sort();

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setSourceDetail('');
        setError('');
    };

    const handleDetailChange = (event, newValue) => {
        setSourceDetail(newValue || event.target.value);
    };

    const submitSource = async () => {
        if (!selectedOption) {
            setError('Please select a source.');
            return;
        }

        if (selectedOption === 'SalonCentric Store' && !sourceDetail) {
            setError('Please select a SalonCentric store.');
            return;
        }

        setLoading(true);
        const userSource = {
            source: selectedOption,
            detail: sourceDetail || null,
        };
        await doAction(AddUserSelectedSource, { ...userSource });
        setLoading(false);
    };

    return (
        <div className='cm-join-source-root'>
            <p className="title"><span className="hand-wave-emoji">👋</span> How did you hear about us?</p>
            <div className="join-list">
                {options.map(option => (
                    <div
                        key={option.value}
                        className="join-option"
                        onClick={() => handleOptionChange(option.value)}
                    >
                        <span className="canvas-type">{option.label}</span>
                        <img
                            className="ellipse"
                            src={selectedOption === option.value ? IconRadioOn : IconRadioOff}
                            alt="option"
                        />
                    </div>
                ))}

                {selectedOption === 'SalonCentric Store' && (
                    <Autocomplete
                        options={sortedScStoreList}
                        value={sourceDetail}
                        onChange={(event, newValue) => handleDetailChange(event, newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select a SalonCentric Store"
                                variant="filled"
                                className="material-input input"
                                placeholder="Select a SalonCentric Store"
                                fullWidth
                            />
                        )}
                    />
                )}

                {selectedOption === 'A Friend' && (
                    <TextField
                        label="Who referred you?"
                        variant="filled"
                        className="material-input input"
                        placeholder="Enter their name"
                        fullWidth
                        value={sourceDetail}
                        onChange={(event) => handleDetailChange(event, event.target.value)}
                    />
                )}

                {selectedOption === 'Other' && (
                    <TextField
                        label="Please specify"
                        variant="filled"
                        className="material-input input"
                        placeholder="Specify here"
                        fullWidth
                        value={sourceDetail}
                        onChange={(event) => handleDetailChange(event, event.target.value)}
                    />
                )}

                {error && <p className="error">{error}</p>}

                <button className="btn-primary next-button" disabled={loading} onClick={submitSource}>
                    Submit {loading && <Spin />}
                </button>
            </div>
        </div>
    );
}
