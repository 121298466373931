import React from "react";
import { RouteComponentProps } from "react-router";
import { Spin, message } from "antd";
import { getInstaAppInfo, getInstaUser } from "../../api/Insta";
import { router_insta_auth, router_homepage_index } from "../../utils/enum";
import { localStorageSet, localStorageGet } from "../../utils/utils";
import queryString from "query-string";
interface InstagramAuthProps extends RouteComponentProps {}
export class InstagramAuth extends React.Component<InstagramAuthProps, {}> {
  render() {
    return <Spin />;
  }

  componentDidMount() {
    // get router querys
    const query =
      (this.props.location as any).query || this.props.location.search;
    const queryKV =
      typeof query === "object"
        ? query
        : query
            .replace("?", "")
            .split("&")
            .reduce((obj: any, kv: string) => {
              if (kv && kv.includes("=")) {
                const [k, v] = kv.split("=");
                obj[k] = v;
              }
              return obj;
            }, {});

    // let { from, code, access_token, user_id } = queryKV;
    let { from, code, access_token } = queryKV;
    if (from && !(code || access_token)) {
      // here from app
      localStorageSet("inst_ap", null);
      getInstaAppInfo().then((info: any) => {
        const { appid } = info;
        info.from = from;
        localStorageSet("inst_ap", info);
        window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${appid}&redirect_uri=${window.location.origin}${router_insta_auth}&scope=user_profile,user_media&response_type=code`;
      });
    } else if (code) {
      // redirect from instagram with code
      code = code.split("#")[0];
      const info = localStorageGet("inst_ap");

      getInstaUser(code)
        .then((instaUser) => {
          const from = info?.from;
          if (from) {
            const instaStr = queryString.stringify(instaUser!);
            this.props.history.push(`${from}?${instaStr}`);
          }
        })
        .catch((err) => {
          message.error(err.message || err);
        });
    } else {
      // error
      message.error("some error, back to homepage");
      this.props.history.push(router_homepage_index);
    }
  }
}
