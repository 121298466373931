import React from "react";
import "./component_btn_status_icon.less";

interface IconStyle {
  color: string;
  borderColor: string;
}
interface Props {
  iconStyle: IconStyle;
}
const BtnStatusIcon: React.FC<Props> = props => {
  const {
    iconStyle: { borderColor, color }
  } = props;

  const containerStyles = {
    backgroundColor: borderColor
  };
  const contentStyles = {
    backgroundColor: color
  };
  return (
    <div className="btn-status-container" style={containerStyles}>
      <div className="status-content" style={contentStyles}></div>
    </div>
  );
};

export default BtnStatusIcon;
