import Http from "./Http";
import { getToken, setToken } from "../utils/authStorage";
import _ from "lodash";
// import { localStorageGet, localStorageSet } from "../utils/utils";
import { localStorageGet } from "../utils/utils";
import store from "../utils/store";

export const signUp = (params: any) => {
  const invitationCode = localStorageGet("invitationCode");
  if (invitationCode) {
    params.invitationCode = invitationCode;
    // localStorageSet('invitationCode', null)
  }

  return Http.post("/auth/sign-up", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        const token = _.get(resp.data, "access_token");
        if (token) {
          setToken(token);
        }

        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const addUserSelectedSource = (params: any) => {
  return Http.post("/auth/add-user-selected-source", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const completeSignup = (params: any) => {
  return Http.put("/auth/sign-up-complete-info", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        handleGrsf(resp.data);
        handleScSignUp(resp.data);
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const completeUserInfo = (params: any) => {
  return Http.put("/user/onboarding/info/complete", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const putUserInfo = (params: any) => {
  return Http.put("/v1/user/", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const getUserInfo = () => {
  return Http.get("/user/info")
    .then((resp) => {
      if (resp.status === 200) {
        if (resp.data.user?.role?.value === "administrator") {
          resp.data.user.profile.firstName = resp.data.user?.role?.value;
          resp.data.user.profile.lastName = "";
          resp.data.user.profile.target = "admin";
          sessionStorage.setItem("canvas:local:userInfo", JSON.stringify({
            access_token: getToken(),
            ...resp.data
          }));
        }

        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const editUserInfo = (profile: any) => {
  return Http.put("/user/info", { ...profile })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const login = (params: any) => {
  const invitationCode = localStorageGet("invitationCode");
  if (invitationCode) {
    params.invitationCode = invitationCode;
  }
  return Http.post("/auth/login", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        const token = _.get(resp.data, "access_token");
        if (token) {
          setToken(token);
        }

        const email = _.get(resp.data, "user.email");
        const hash = _.get(resp.data, "user.grsfHash");
        const firstName = _.get(resp.data, "user.firstName");
        const lastName = _.get(resp.data, "user.lastName");
        handleGrsfUser(email, hash, firstName, lastName);
        handleSc(email);

        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const searchUser = (keywords: string, tactics = [4, 20]) => {
  return Http.put("/user/search", { keyword: keywords, tactics: tactics })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const sendEmail = (email: string) => {
  return Http.post("/user/forgot-password", { email })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const resetPassord = (params: any) => {
  return Http.put("/user/reset-password", { ...params })
    .then((resp) => {
      if (resp.status === 200) {
        const token = _.get(resp.data, "access_token");
        if (token) {
          setToken(token);
        }
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const changePassword = (password: string) => {
  return Http.put("/user/change-password", { password: password })
    .then((resp) => {
      if (resp.status === 200) {
        const token = _.get(resp.data, "access_token");
        if (token) {
          setToken(token);
        }
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const sendVerify = () => {
  return Http.post("/user/send-verify-email")
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const checkChatToken = (ids: any[]) => {
  return Http.post("/user/check-user-chat", ids)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const fetchVerifyCode = (phone: string, resend = false) => {
  if (!phone.startsWith('+')) {
    phone = `+1${phone}`
  }
  return Http.post("/user/send-sms", { phone, resend })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};
export const msgNotEmail = (toUserIds: any[]) => {
  return Http.get(`/user/chat-email?toUserIds=[${toUserIds}]`)
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};
export const verifyPhoneCode = (phone: string, code: string) => {
  if (!phone.startsWith('+')) {
    phone = `+1${phone}`
  }
  return Http.post("/user/code-verify", { phone, code })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const skipVerifyCode = (phone: String) => {
  if (phone && !phone.startsWith('+')) {
    phone = `+1${phone}`
  }
  return Http.post("/user/skip-verify", { phone })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const removeUserDashboardDot = () => {
  return Http.get("/user/remove-dashboard-dot")
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const handleGrsf = (signUpResponse: any) => {
  const win = <any>window;
  if (!win.grsfEnabled) {
    return;
  }

  const email = _.get(signUpResponse, "user.email", null);
  const firstName = _.get(signUpResponse, "user.profile.firstName", null);
  const lastName = _.get(signUpResponse, "user.profile.lastName", null);
  if (win.grsfParam && email && win.growsurf) {
    win.growsurf.triggerReferral({ email });
  } else {
    win.growsurf.addParticipant({
      email,
      firstName,
      lastName
    });
  }
}

export const handleGrsfUser = (email: string, hash: string, firstName: string, lastName: string) => {
  const win = <any>window;
  if (!win.grsfEnabled) {
    return;
  }

  try {
    if (email && hash && win.growsurf) {
      win.growsurf.init({
        email,
        hash
      });
      win.growsurf.addParticipant({
        email
      });
    }
  } catch (error) {

  }
}

export const handleSc = (email: string) => {
  const win = <any>window;
  if (!win.landingSc) {
    return;
  }
  sendAnalyticsEvent({ eventCategory: 'SalonCentric', eventAction: 'Log In', eventLabel: email });
}

export const handleScSignUp = (data: any) => {
  const win = <any>window;
  if (win.landingSc) {
    const email = _.get(data, "user.email");
    sendAnalyticsEvent({ eventCategory: 'SalonCentric', eventAction: 'Sign Up', eventLabel: email });
  }
}

export const sendAnalyticsEvent = (payload: any) => {
  try {
    const win = window as any;
    if (!!win.ga) {
      const tracker = win.ga.getAll()[0];
      if (tracker) {
        tracker.send('event', payload);
      }
    }
  } catch (error) {
    console.info('Issue sending analytics.');
  }

}

export const getUtoken = () => {  
  return Http.get("/user/utoken")
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};
