import React, { useEffect, useRef } from "react";
import TargetIcon from "@material-ui/icons/TrackChangesOutlined";
import SchoolIcon from "@material-ui/icons/SchoolOutlined";
import { Button } from "antd";
import school01 from "../../assets/images/start_school_01.png";
import school02 from "../../assets/images/start_school_02.png";
import business01 from "../../assets/images/start_business_01.png";
import business02 from "../../assets/images/start_business_02.png";
import student from "../../assets/images/start_student.png";
import { Link } from "react-router-dom";
import LogoSvg from "../../assets/images/new_logo_white.svg";
import "./get_start_overlay.less";

interface Props {
  type: "pro" | "student" | "school" | "business";
  close: Function;
  getStartedOverlay?: boolean;
}

interface State {
  items: Array<any>;
  currentItem: number;
}

export default class GetStartedOverlay extends React.Component<Props, State> {
  state = {
    items: [],
    currentItem: 0,
  };

  componentWillMount() {
    const { type, close } = this.props;

    if (type === "student") {
      this.setState({
        items: [
          {
            image: <img src={student} alt="canvas screenshot"></img>,
            headline: `👋Welcome to test the Beautista movement!🎉`,
            subheader: `We are so excited you have chosen to empower yourself with a Beautista portfolio rather than a word doc resume! 
                        <b>There is so MUCH you can do with your portfolio to be seen and live out the career of your dreams!!</b>
                        Check out our Launch Party Video to make sure you leverage all the features Beautista offers.`,
            description: `Now, let's get your started. Ready?`,
            footer: (
              <>
                <Button className="btn-primary" onClick={() => close()}>
                  START BUILDING MY PORTFOLIO
                </Button>
                <br />
                <Link to="homepage-opportunity">Browse opportunites</Link>
              </>
            ),
          },
        ],
      });
    }

    if (type === "pro") {
      this.setState({
        items: [
          {
            image: <img src={student} alt="canvas screenshot"></img>,
            headline: "Welcome to Beautista",
            subheader: "Thanks so much for signing up!",
            description: `Now, let's get your canvas created. Ready?`,
            footer: (
              <Button className="btn-primary" onClick={() => close()}>
                START BUILDING MY PORTFOLIO
              </Button>
            ),
          },
        ],
      });
    }

    if (type === "school") {
      this.setState({
        items: [
          {
            icon: <SchoolIcon className="cm-get-start-icon"></SchoolIcon>,
            image: <img src={school01} alt="school screenshot"></img>,
            headline: "Welcome to Beautista",
            subheader: "Thanks so much for signing up!",
            description: `Step 1: Welcome to your dashboard. Your students will automatically appear in your student view once they build portfolios!`,
            footer: (
              <Button
                className="btn-primary"
                onClick={() => this.setState({ currentItem: 1 })}
              >
                Next
              </Button>
            ),
          },
          {
            icon: <SchoolIcon className="cm-get-start-icon"></SchoolIcon>,
            image: <img src={school02} alt="school screenshot"></img>,
            headline: "Onboard Your Students",
            description: `Step 2: If you haven't already, reach out to us to book a time to onboard your students. hello@beautista.com`,
            footer: (
              <Button className="btn-primary" onClick={() => close()}>
                Let's Go
              </Button>
            ),
          },
        ],
      });
    }

    if (type === "business") {
      this.setState({
        items: [
          {
            icon: <TargetIcon className="cm-get-start-icon"></TargetIcon>,
            image: <img src={business01} alt="business screenshot"></img>,
            headline: "Welcome to Beautista",
            subheader: "Thanks so much for signing up!",
            description: `Step 1: Let's get your career opportunity created.`,
            footer: (
              <Button
                className="btn-primary"
                onClick={() => this.setState({ currentItem: 1 })}
              >
                Next
              </Button>
            ),
          },
          {
            icon: <TargetIcon className="cm-get-start-icon"></TargetIcon>,
            image: <img src={business02} alt="business screenshot"></img>,
            headline: "Your Business Canvas",
            description: `Step 2: Once your career opportunity is live, putting the finishing touches on your business canvas is your next step in making your business shine. Ready?`,
            footer: (
              <Button className="btn-primary" onClick={() => close()}>
                Let's Go
              </Button>
            ),
          },
        ],
      });
    }
  }

  render() {
    const { items, currentItem } = this.state;
    const item: any = items[currentItem];
    const { type, close } = this.props;

    return (
      <div className="cm-get-start-overlay">
        <img className="logo" src={LogoSvg} />

        {(type == "student" || type == "pro") && (
          <>
            <div className="header">👋Welcome to the Beautista movement!🎉</div>
            <div className="subheader">
              We are so excited you have chosen to empower yourself with a
              Beautista portfolio rather than a word doc resume!&nbsp;
              <b>
                There is so MUCH you can do with your portfolio to be seen and
                live out the career of your dreams!!&nbsp;
              </b>
              Check out our Launch Party Video to make sure you leverage all the
              features Beautista offers.
            </div>
            <div className="media">
              <iframe
                className="video"
                src={
                  type == "student"
                    ? "https://player.vimeo.com/video/764150986?h=db3997c20d&color=0064b4&title=0&byline=0&portrait=0"
                    : "https://player.vimeo.com/video/816723454?h=a323d250cf&title=0&byline=0&portrait=0"
                }
                allow="autoplay; fullscreen; picture-in-picture"
              ></iframe>
            </div>
            <div className="footer">
              <Button className="btn-primary build-btn" onClick={() => close()}>
                START BUILDING MY PORTFOLIO
              </Button>
              <br />
              <Link className="opp-link" to="homepage-opportunity">
                Browse opportunites
              </Link>
            </div>
          </>
        )}
        {(type == "business" || type == "school") && (
          <>
            {item.icon}
            <h1>{item.headline}</h1>
            <h2>{item.subheader}</h2>
            {item.image}
            <p>{item.description}</p>
            {item.footer}
          </>
        )}
      </div>
    );
  }
}
