import { ModuleStore, ModuleAction, KV } from "module-reaction";

export const MODULE_CHAT = "module_chat";

export interface ModelChat extends ModuleStore {
  unreadCount: number;
}

export const module_chat: ModelChat = {
  module: MODULE_CHAT,
  unreadCount: 0,
};

export const UpdateChatInfo: ModuleAction<any, ModelChat> = {
  module: MODULE_CHAT,
  process: async (params: KV, module: ModelChat) => {
    return { ...params };
  },
};
