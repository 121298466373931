import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, message, Modal } from "antd";
import { TextField, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {
  ListTable,
  IHeadItem,
  IBodyItem,
} from "../../../components/component_list_table";

import { GoogleUtil } from "../../../utils/utils";

//getCustomized
import { getCustomized } from "../../../api/Options";
import {
  opportunityList,
  opportunityDelete,
  getAccessAccount,
} from "../../../api/admin";

import { router_opportunity_detail } from "../../../utils/enum";

import ConfirmModal from "../../../components/component_confirm_modal";
import SearchComponent from "../../homepage/SearchComponent";
import IconDelete from "../../../assets/images/ic_delete.svg";
import IconView from "../../../assets/images/ic_view.svg";
import IconEdit from "../../../assets/images/ic_edit.svg";
import IconCalendar from "../../../assets/images/ic_calendar.svg";
import ic_filter from "../../../assets/images/ic_filter.svg";
import ic_export from "../../../assets/images/ic_export.svg";
import ic_close from "../../../assets/images/ic_close_black.svg";
import "./opportunity.less";

import { mapProp, doAction } from "module-reaction";
import { modle_user, LoginWithTokenAction } from "../../../models/model_user";
import { setUserLoginInfoAction } from "../../../models/model_home";
import { getOppDtlsAction } from "../../../models/model_post_job_opp";

const radiusList: any[] = [
  {
    value: 10,
    label: "10 miles",
  },
  {
    value: 25,
    label: "25 miles",
  },
  {
    value: 30,
    label: "30 miles",
  },
  {
    value: 50,
    label: "50 miles",
  },
  {
    value: 100,
    label: "100 miles",
  },
  {
    value: 250,
    label: "250 miles",
  },
];
const expiredDateList: any[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "this_month",
    label: "This month",
  },
  {
    value: "in_the_next_1_to_3_months",
    label: "In the next 1 to 3 months",
  },
  {
    value: "in_the_next_3_to_6_months",
    label: "In the next  3 to 6 months ",
  },
  {
    value: "more_than_6_months",
    label: "More than 6 months",
  },
];
const canvasGroupList: any[] = [];

const School: React.FC<Props> = (props: Props) => {
  const { history } = props;
  const tHeadRender: IHeadItem[] = [
    { name: "Name", isSort: true, key: "name" },
    { name: "Category" },
    { name: "Business" },
    { name: "Location" },
    { name: "Created At", isSort: true, key: "createdAt" },
    { name: "View" },
    { name: "Applied" },
    { name: "Hired" },
    { name: "Status" },
    { name: "Actions" },
  ];

  const tBodyRender: IBodyItem[] = [
    {
      name: "name",
      key: "jobTitle",
    },
    { name: "Category", key: "jobCategory" },
    { name: "Business", key: "canvasName" },
    { name: "Location", key: "location.address" },
    { name: "Start", key: "createdAt", type: "date" },
    { name: "View", key: "jobView" },
    { name: "Applied", key: "jobApplied" },
    { name: "Hired", key: "jobHired" },
    {
      name: "Status",
      key: "status",
      className: "status-cell",
      component: (row: any) => {
        if (row?.status !== 'private' && (!row.expire || new Date() <= new Date(row.expire))) {
          return <span>active</span>;
        } else {
          return <span className="active">inactive</span>;
        }
      },
    },
    {
      type: "dropdown-actions",
      dropdownList: [
        {
          icon: IconEdit,
          name: "Edit Job   ",
          onClick: (item: any, index: number) => changeAuthority(item),
        },
        {
          icon: IconView,
          name: "View",
          onClick: (item: any, index: number) =>
            history.push(router_opportunity_detail.replace(/:slug/, item.slug)),
        },
        {
          icon: IconDelete,
          name: "Remove",
          onClick: (item: any, index: number) => handleConfirmShow(item),
        },
      ],
    },
  ];

  const size = 8;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [dataList, setDatalist] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectList, setSelectList] = useState([] as any);
  const [orderBy, setOrderBy] = useState("");
  const [options, setOptions] = useState();
  const [dataItem, setDataItem] = useState({
    applicants: {
      total: 0,
      hired: 0,
    },
    opportunity: {
      active: 0,
      total: 0,
    },
  });
  const initSearch = {
    keywords: null,
    radius: 30,
    category: 0,
    jobtype: 0,
    software: 0,
    brand: 0,
    paymentMode: 0,
    expiredDate: "all",
    canvasGroup: 0,
    startDate: null,
    endDate: null,
  }
  const [search, setSearch] = useState<any>({ ...initSearch });

  const list = (page = 1) => {
    const {
      keywords,
      addressInfo,
      radius,
      location,
      expiredDate,
      ...others
    } = search;
    let _data = {
      keyword: keywords,
      location: addressInfo
        ? {
          radius,
          coordinates: addressInfo?.coordinates || null,
        }
        : null,
      expiredDate: expiredDate === "all" ? null : expiredDate,
      page,
      size,
      sortBy: orderBy || null,
      ...others,
    };
    setLoading(true);
    opportunityList(_data).then((data) => {
      setDatalist(data.list);
      setTotal(data.count);
      setLoading(false);
      setDataItem({
        ...dataItem,
        ...data.dashboard,
      });
    });
    // getData(_data);
  };

  // const getData = (_data: any) => {
  //   getDataOpportunity(_data).then((data) => {
  //     setDataItem({
  //       ...dataItem,
  //       ...data,
  //     });
  //   });
  // };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(1);
    list(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    list(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy]);

  useEffect(() => {
    let _data = {
      kind: "official",
      types: ["category", "jobType", "paymentMode", "brand", "software"],
    };
    getCustomized(true, _data.types).then((data) => {
      setOptions(data);
    });
  }, []);

  //link normal account - access account
  const changeAuthority = (item: any) => {
    let _data = {
      id: item.userId,
    };
    getAccessAccount(_data).then((data: any) => {
      sessionStorage.setItem('canvas:local:adminpath', props.location.pathname)

      doAction(LoginWithTokenAction);
      if (item.id) {
        doAction(getOppDtlsAction, Number(item.id));
      }
      doAction(setUserLoginInfoAction, {
        res: data,
        history: props.history,
        fromPage: `dashboard-post-job-opportunity?id=${item.canvasId}&opportunityId=${item.id}&isInMyAccount=1`,
      });
    });
  };

  //dialog-search
  const [dialog, setDialog] = useState(false);
  const handleShow = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };
  const resetData = () => {
    setSearch({ ...initSearch })
  }
  //export
  const handleExport = () => {
    const {
      keywords,
      addressInfo,
      radius,
      location,
      expiredDate,
      ...others
    } = search;
    let _data = {
      keyword: keywords,
      location: addressInfo
        ? {
          radius,
          coordinates: addressInfo?.coordinates || null,
        }
        : null,
      expiredDate: expiredDate === "all" ? null : expiredDate,
      page,
      size,
      sortBy: orderBy || null,
      ...others,
    };

    let str = Object.keys(_data)
      .filter((item: any) => _data[item])
      .map(
        (prev: any) =>
          prev + "=" + encodeURIComponent(JSON.stringify(_data[prev]))
      )
      .join("&");

    window.open(
      decodeURIComponent(
        process.env.REACT_APP_BASE_URL +
        "/admin/opportunity/export-to-csv?" +
        str
      )
    );
  };

  //remove
  const [confirm, setConfirm] = useState(false);
  const [opportunityId, setOpportunityId] = useState(0);

  const handleConfirmShow = (item: any) => {
    setConfirm(true);
    setOpportunityId(item.id);
  };
  const handleRemove = () => {
    let _data = {
      id: opportunityId,
    };
    opportunityDelete(_data).then((data) => {
      message.success("remove success");
    });
  };

  return (
    <div className="admin-opportunity-container">
      <div className="title">
        Opportunity Dashboard
        <span className="title-btn"></span>
        <Button className="item filter-btn" onClick={handleExport}>
          <img src={ic_export} alt="filter" />
              Export csv
        </Button>
      </div>
      <div className="list-wrapper">
        <div className="search-item">
          <SearchComponent
            placeholder="Search by Name"
            value={{
              location: search.location,
              keywords: search.keywords,
            }}
            autoCompleteService={GoogleUtil.googleAutoCompleteService}
            onChange={(val: any) => {
              setSearch({
                ...search,
                ...val,
              });
            }}
          />
          <TextField
            label="Radius"
            variant="filled"
            select
            className="material-input input ml-24 redius-wrapper"
            value={search.radius}
            onChange={(e) =>
              setSearch({
                ...search,
                radius: e.target.value,
              })
            }
          >
            {radiusList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="search-item mt-16">
          <div className="date-sec">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/YY"
                label="Start Date"
                className="material-date-picker date-input"
                views={["year", "month", "date"]}
                value={search.startDate}
                onChange={(value) => {
                  setSearch({
                    ...search,
                    startDate: value?.format("MM/DD/YY"),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
            <img alt="" src={IconCalendar} />
          </div>
          <div className="date-sec ml-24">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="MM/DD/YY"
                label="End Date"
                className="material-date-picker date-input"
                views={["year", "month", "date"]}
                value={search.endDate}
                onChange={(value) => {
                  setSearch({
                    ...search,
                    endDate: value?.format("MM/DD/YY"),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
            <img alt="" src={IconCalendar} />
          </div>
          <div className="btn-item ml-24">
            <Button className="item filter-btn" onClick={handleShow}>
              <img src={ic_filter} alt="filter" />
              More Filters
            </Button>
            <Button
              className="item filter-btn clear-btn"
              onClick={resetData}
            >
              <img src={ic_close} alt="filter" />
                CLEAR SEARCH
              </Button>
          </div>
        </div>
        <div className="data-item">
          <span>
            <i>Number of opportunities</i>
            {dataItem.opportunity.total || 0}
          </span>
          <span>
            <i>Active opportunities</i>
            {dataItem.opportunity.active || 0}
          </span>
          <span>
            <i>Total Application</i>
            {dataItem.applicants.total || 0}
          </span>
          <span>
            <i>Total Hired</i>
            {dataItem.applicants.hired || 0}
          </span>
        </div>
        <div className="list-item">
          <ListTable
            tHeadRender={tHeadRender}
            tBodyRender={tBodyRender}
            loading={loading}
            hasSelect={false}
            idKey="id"
            dataList={dataList}
            selectList={selectList}
            selectOnChange={setSelectList}
            total={total}
            size={8}
            page={page}
            pageOnChange={handleChangePage}
            orderBy={orderBy}
            orderByOnChange={(value: string) => setOrderBy(value)}
          />
        </div>
      </div>
      <FilterModal
        classes="common-filter"
        visible={dialog}
        onClose={handleDialogClose}
        options={options}
        searchValue={search}
        onChange={(e: any) => {
          setSearch({
            ...search,
            ...e,
          });
        }}
      ></FilterModal>
      <ConfirmModal
        visible={confirm}
        title="Are you sure? "
        confirmText="Confirm"
        description="You are removing a opportunity. This action can’t be undone. Once you delete it, the opportunity and the data related to it will be removed from the user account. Please confirm you action."
        onClose={() => setConfirm(false)}
        onConfirm={handleRemove}
      />
    </div>
  );
};

interface Props extends RouteComponentProps {
  history: any;
  curUser: any;
}

const FilterModal: React.FC<ModalProps> = (props: ModalProps) => {
  const { visible, classes, onClose, options, onChange, searchValue } = props;

  const [filter, setFilter] = useState<any>({
    category: 0,
    jobtype: 0,
    software: 0,
    brand: 0,
    paymentMode: 0,
    expiredDate: "all",
    canvasGroup: 0,
  });

  useEffect(() => {
    if (visible) {
      const {
        category,
        jobtype,
        software,
        brand,
        paymentMode,
        expiredDate,
        canvasGroup,
      } = searchValue;
      setFilter({
        ...filter,
        category,
        jobtype,
        software,
        brand,
        paymentMode,
        expiredDate,
        canvasGroup,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={classes}
      centered={true}
      width={760}
    >
      <div className="modal-content">
        <p>More filter</p>
        <div className="filter-field">
          <TextField
            label="Job category"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.category}
            onChange={(e) =>
              setFilter({
                ...filter,
                category: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.category?.business.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Job type"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.jobtype}
            onChange={(e) =>
              setFilter({
                ...filter,
                jobtype: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.jobType.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Payment structure"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.paymentMode}
            onChange={(e) =>
              setFilter({
                ...filter,
                paymentMode: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.paymentMode.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="filter-field">
          <TextField
            label="expiration Date"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.expiredDate}
            onChange={(e) =>
              setFilter({
                ...filter,
                expiredDate: e.target.value,
              })
            }
          >
            {expiredDateList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="preferred Brand "
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.brand}
            onChange={(e) =>
              setFilter({
                ...filter,
                brand: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.brand.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Preferred Software"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.software}
            onChange={(e) =>
              setFilter({
                ...filter,
                software: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.software.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="filter-field">
          <TextField
            label="Canvas group"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.canvasGroup}
            onChange={(e) =>
              setFilter({
                ...filter,
                canvasGroup: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {canvasGroupList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Button
          className="btn-primary btn-add"
          onClick={() => {
            onChange(filter);
            onClose();
          }}
        >
          Apply filter
        </Button>
      </div>
    </Modal>
  );
};
interface ModalProps {
  visible: boolean;
  options: any;
  searchValue: any;
  classes?: string;
  onClose: any;
  onChange: any;
}

export default mapProp(modle_user)(School);
