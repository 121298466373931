import React from "react";
import classnames from "classnames";
import "./component_cr_switch.less";

interface Props {
  className?: string;
  enable?: boolean;
  onChange: Function;
}

const CRSwitch: React.FC<Props> = (props) => {
  const { className, enable = false, onChange } = props;

  return (
    <div className={classnames(className, "cr-switch")}>
      <span
        className={classnames({ active: enable })}
        onClick={() => {
          if (!enable) {
            onChange(true);
          }
        }}
      >
        Yes
      </span>
      <span
        className={classnames({ active: !enable })}
        onClick={() => {
          if (enable) {
            onChange(false);
          }
        }}
      >
        No
      </span>
    </div>
  );
};

export default CRSwitch;
