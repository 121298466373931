import React from "react";
import classnames from "classnames";
import IconAward from "../assets/images/ic_award.svg";
import IconDelete from "../assets/images/ic_delete.svg";
import IconEdit from "../assets/images/ic_edit.svg";
import "./component_category_item.less";

interface Props {
  className?: string;
  data: any;
  onEdit: Function;
  onDelete: Function;
}

const CategoryItem: React.FC<Props> = (props) => {
  const { className = "", data, onEdit, onDelete } = props;

  const getIssueText = () => {
    let text = data.otherName || data.name;
    if (data.licenseNumber) {
      text = text + ` - ${data.licenseNumber}`;
    }
    if (data.stateName) {
      text = text + ` - ${data.stateName}`;
    }
    return text;
  };

  return (
    <div className={classnames(className, "category-item")}>
      <img alt="" src={IconAward} className="icon-award" />
      <div className="issue">{getIssueText()}</div>
      <img
        alt=""
        className="icon-edit"
        src={IconEdit}
        onClick={() => onEdit(data)}
      />
      <img
        alt=""
        className="icon-delete"
        src={IconDelete}
        onClick={() => onDelete(data)}
      />
    </div>
  );
};

export default CategoryItem;
