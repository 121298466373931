import { getUserCount } from "../api/Homepage";

export const router_sign_in = "/sign-in";
export const router_user_detail = "/user/:id";
// export const router_sign_up = "/sign-up";
export const router_get_started = "/get-started";

export const router_homepage_index = "/";
export const router_homepage_opportunity = "/homepage-opportunity";
export const router_homepage_canvas = "/homepage-canvas/:type";
export const router_homepage_about = "/about-us";
export const router_homepage_about_1 = "/about";
export const router_homepage_contact = "/contact-us";
export const router_homepage_terms = "/terms";
export const router_homepage_privacy_policy = "/privacy-policy";
export const router_homepage_privacy_policy_ca = "/privacy-policy-ca";
export const router_homepage_privacy_policy_nev = "/privacy-policy-nev";
export const router_homepage_canvas_rights_data = "/privacy-rights";
export const router_homepage_land_paul = "/paulmitchell";
export const router_homepage_land_saloncentric = "/saloncentric";
export const router_homepage_land_wella = "/wella";

export const router_track = "/track-referral";

export const router_canvas_detail = "/canvas/:id";
export const router_company_info = "/company-info";
export const router_business_canvas = "/business-canvas/:id";
export const router_business_canvas_preview = "/business-canvas/preview/:id";
export const router_business_payment = "/business-payment";
export const router_business_payment_with_id = "/business-payment/:id";
export const router_post_job = "/post-job";

export const router_student_canvas = "/student-canvas/:id";
export const router_student_canvas_preview = "/student-canvas/preview/:id";

export const router_school_info = "/school-info";
export const router_school_canvas = "/school-canvas/:id";
export const router_school_canvas_preview = "/school-canvas/preview/:id";
export const router_school_payment = "/school-payment";
export const router_school_payment_with_id = "/school-payment/:id";

export const router_my_dashboard = "/my-dashboard";
export const router_add_student = "/add-student";
export const router_my_canvases = "/my-canvases";
export const router_add_canvas = "/add-canvas";
export const router_upgrade_student_canvas = "/upgrade-student";
export const router_bookmarks = "/bookmarks";
export const router_message = "/message";
export const router_bookmarks_all = "/bookmarks-all";
export const router_notifications = "/notifications";
export const router_membership = "/membership";
export const router_membership_invoice_list = "/membership/invoices";
export const router_membership_invoice_view = "/membership/invoice/:id";
export const router_membership_billing_update = "/membership/billing/update";
export const router_membership_plan_update =
  "/membership/plan/update/:type/:id";
export const router_settings = "/settings";
export const router_opportunity_applicants = "/opportunity-applicants";
export const router_school_dasboard_opportunities =
  "/school/dashboard/opportunities";
export const router_school_dasboard_students = "/school/dashboard/students";
export const router_school_dasboard_add_students =
  "/school/dashboard/add-students";

//admin
//some pages should be edit:
//*******  routes.ts(adminRouter)          */
//*******  my_account.tsx(adminMenuItem)   */
//*******  component_cr_header.tsx         */
//*******  component_home_menu_modal.tsx   */
export const router_admin_user = "/admin-user";
export const router_admin_school = "/admin-school";
export const router_admin_canvas = "/admin-canvas";
export const router_admin_opportunity = "/admin-opportunity";
export const router_admin_sms = "/admin-sms";
export const router_admin_featured = "/admin-featured";
export const router_admin_data = "/admin-data";
export const router_admin_external_payment = "/admin-external";

export const router_education_info = "/education-info";

export const router_professional_info = "/professional-info";
export const router_professional_canvas = "/professional-canvas/:id";
export const router_professional_canvas_create = "/professional-canvas";
export const router_student_canvas_create = "/student-canvas";
export const router_professional_canvas_preview =
  "/professional-canvas/preview/:id";

export const router_publish_success = "/publish-success/:type";
export const router_change_password = "/settings/change-password";
// export const router_my_account_posted_opportunities = "/my-account/dashboard/posted-opportunities";
export const router_my_account_applied_opportunities =
  "/my-account/dashboard/applied-opp";

export const router_post_job_opp = "/post-job-opportunity";
export const router_dashboard_post_job_opp = "/dashboard-post-job-opportunity";
export const router_post_job_opp_simple = "/post-job-opportunity-simple";

export const router_opportunity_detail = "/opportunity-detail/:slug";
export const router_opportunity_detail_by_id = "/opportunity-detail";
export const router_opportunity_publish_success =
  "/opportunity-publish-success";

export const router_insta_auth = "/insta-auth";
export const router_student_pro_landing = "/studentpro";
export const router_land_your_dream_career = "/land-your-dream-career"
export const router_stop_interview_no_shows = "/stop-interview-no-shows"
export const router_innovate_landing = "/innovate"
export const router_screps_landing = "/screps"

export enum UserRole {
  STUDENT = "student",
  BEAUTY_PRO = "beautyPro",
  INDUSTRY = "industryProfessional",
}

export enum Permit {
  OWNED = "alreadyOwned",
  GETTING = "getting",
  NOT_APPLY = "notApply",
}

export enum Employment {
  EMPLOYED = "employed",
  NOT_EMPLOYED = "notEmployed",
}
export enum MediaType {
  LOGO = "logo",
  FEATURED_PHOTO = "featuredPhoto",
  TEAM = "teamPhoto",
  FEATURED_VIDEO = "featuredVideo",
  ADDITIONAL = "additionalVideo",
  PORTRAIT_PHOTO = "portraitPhoto",
  PORTFOLIO_PHOTO = "portfolioPhoto",
  THUMBNAIL_PHOTO = "thumbnailPhoto",
  COMPANY_PHOTO = "companyPhoto",
}

export enum CanvasType {
  BUSINESS = "business",
  SCHOOL = "school",
  STUDENT = "student",
  PROFESSIONAL = "professional",
}

export enum ClassType {
  DAY = "day",
  NIGHT = "night",
  ALL = "dayAndNight",
}
export enum TargetType {
  STUDENT = "studentCanvas",
  PERSONAL = "personalCanvas",
  CAREER = "careerOpportunity",
  SCHOOL = "schoolDashboard",
  BUSINESS = "businessCanvas",
  ADMIN = "admin",
}

export enum SourceName {
  WELLA = "wella",
}

export enum CouponType {
  INVALID = 0,
  AMOUNT_OFF = 1,
  PERCENT_OFF = 2,
}

export enum SchoolRole {
  ADMIN = 1,
  TEACHER = 2,
  TEACHER_ASSISTANT = 3,
  OTHER = 4,
}

export enum ActiveCampaignCancelReason {
  DO_NOT_KNOW_HOW = 'do_not_know_how',
  NOT_ENOUGH_APPLICANTS = 'not_enough_applicants',
  COST = 'cost',
  IM_NOT_HIRING = 'im_not_hiring',
  NO_TIME_TO_BUILD_PAGE = 'no_time_to_build_page',
  OTHER = 'other',
}

export enum LandingPage {
  PAULMITCHELL = "paulmitchell",
  SALONCENTRIC = "saloncentric",
  WELLA = "wella",
  DREAM_CAREER = "land-your-dream-career",
  STOP_NO_SHOW = "stop-interview-no-shows",
  STUDENT_PRO = "studentpro",
}
