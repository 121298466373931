import React from "react";
import { Layout } from "antd";
import _ from "lodash";
import queryString from "query-string";
import { mapProp, doAction, doFunction } from "module-reaction";
import { CanvasType } from "../../utils/enum";
import { PreviewMode } from "../create_business_canvas/canvas_preview";
import { IMediaItem } from "../../components/component_media_item";
import {
  GetPageDataAction,
  module_canvas_detail,
  MODULE_CANVAS_DETAIL,
} from "../../models/model_canvas_detail";
import {
  LoginWithTokenAction,
  modle_user,
  LogOutAction,
} from "../../models/model_user";
import { chatClient, initChatClient, createChat } from "../../utils/chatHelper";
import { router_message } from "../../utils/enum";
import BusinessPreview from "../create_business_canvas/canvas_preview";
import SchoolPreview from "../create_student_canvas/canvas_preview";
import CRHeader from "../../components/component_cr_header";
import "./canvas_detail.less";
import { lorealIds } from "../../utils/utils";
const { Content } = Layout;

interface Props {
  match: any;
  history: any;
  id: number;
  slug: string;
  owner: number;
  type: string;
  awardList: any[];
  socialMediaList: [];
  companyName: string;
  headline: string;
  desc: string;
  whyJoin: string;
  defaultLocation: any;
  contactNumber: string;
  category: any;
  additional: any;
  extraLocations: any[];
  logo: IMediaItem; // type, link
  featuredPhoto: IMediaItem;
  teamPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  extraVideo: IMediaItem[];
  emptyFields: string[];
  bookmarkTag: boolean;
  curUser: any;
}

@mapProp(modle_user)
@mapProp(module_canvas_detail)
export default class CanvasDetail extends React.Component<Props, {}> {
  state = {
    slug: this.props.match.params.id,
    isMobile: window.innerWidth < 1024,
  };

  componentDidMount() {
    doAction(GetPageDataAction, {
      slug: this.state.slug,
      history: this.props.history,
    });
    if (!this.props.curUser.profile) {
      doAction(LoginWithTokenAction);
    }
    doFunction(async () => {
      // if using id, change url to slug
      if (!isNaN(parseInt(this.state.slug)) && this.props.slug) {
        window.history.replaceState(
          {},
          "",
          `${window.location.href.replace(this.state.slug, this.props.slug)}`
        );
        this.state.slug = this.props.slug
      }
    })
    doFunction(async () => {
      if (!chatClient) {
        await initChatClient(this.props.curUser);
      }
    });
  }

  componentWillUnmount() {
    doAction(MODULE_CANVAS_DETAIL, module_canvas_detail);
  }

  private jumpToLoginPage = () => {
    this.props.history.push({
      pathname: "/sign-in",
      search: `?from=${window.location.pathname}`,
    });
  };

  private onMessageMe = async () => {
    if (!chatClient) return;
    if (!this.props.curUser || !this.props.curUser.id) {
      this.jumpToLoginPage();
      return;
    }
    if (this.props.owner) {
      const channelId = await createChat([
        this.props.curUser.id,
        this.props.owner,
      ]);
      this.props.history.push(router_message, { channelId: channelId });
    }
  };

  private onGoBack = () => {
    if (!this.props.history) {
      return;
    }
    if (window.location.search.indexOf("page=/sign-in") > -1) {
      this.props.history.push("/");
      return;
    }
    if (this.props.history.length && this.props.history.length > 2) {
      const { tabType } = queryString.parse(window.location.search);
      if (tabType) {
        let path = `/my-dashboard?tabType=${tabType}`;
        const { viewCanvas } = queryString.parse(window.location.search);
        if (viewCanvas) {
          path = `${path}&viewCanvas=${viewCanvas}`;
        }
        this.props.history.replace(path);
      } else {
        this.props.history.goBack();
      }
    } else {
      this.props.history.replace("/");
    }
  };

  render() {
    const isLoreal = !!lorealIds.has(this.props.id);
    const rootClassNames = isLoreal ? 'cm-loreal' : '';
    return (
      <Layout className="canvas-detail-root">
        <CRHeader
          className="canvas-detail-header"
          history={this.props.history}
          onLogin={() => this.jumpToLoginPage()}
          onLogout={() => {
            doAction(LogOutAction);
            doFunction(async () => {
              this.props.history.push("/");
            });
          }}
        />
        <Content>
          <div className={`canvas-detail-content ${rootClassNames}`}>
            {this.props.type === CanvasType.BUSINESS ||
            this.props.type === CanvasType.SCHOOL ? (
              <BusinessPreview
                mode={PreviewMode.VIEW}
                type={this.props.type}
                isMobile={this.state.isMobile}
                isDetail={true}
                pageData={{
                  history: this.props.history,
                  owner: this.props.owner,
                  id: this.props.id,
                  logo: this.props.logo.image,
                  bookmarkTag: this.props.bookmarkTag,
                  companyName: this.props.companyName,
                  category: this.props.category,
                  headline: this.props.headline,
                  desc: this.props.desc,
                  whyJoin: this.props.whyJoin,
                  contactNumber: this.props.contactNumber,
                  teamPhoto: this.props.teamPhoto,
                  awardList: this.props.awardList,
                  additional: this.props.additional,
                  locations: _.filter(
                    [this.props.defaultLocation, ...this.props.extraLocations],
                    (item) => {
                      return !_.isEmpty(item.location);
                    }
                  ),
                  socialMedias: _.filter(
                    this.props.socialMediaList,
                    (item: any) => {
                      return !_.isEmpty(item.value);
                    }
                  ),
                  featuredImage: this.props.featuredPhoto,
                  videos: _.filter(
                    [this.props.featuredVideo, ...this.props.extraVideo],
                    (item) => {
                      return item.image !== "";
                    }
                  ),
                }}
                onMessage={() => this.onMessageMe()}
                onGoBack={this.onGoBack}
              />
            ) : (
              <SchoolPreview
                type={this.props.type}
                mode={PreviewMode.VIEW}
                pageData={this.props}
                isMobile={this.state.isMobile}
                isDetail={true}
                onMessage={() => this.onMessageMe()}
                onGoBack={this.onGoBack}
              />
            )}
          </div>
        </Content>
      </Layout>
    );
  }
}
