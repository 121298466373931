import React from "react";
import { Layout, Button, Icon, Modal } from "antd";
import CRExpansionPanel from "../../components/component_expansion_panel";
import _ from "lodash";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import CanvasPreview, { BusPositionTag } from "./canvas_preview";
import { v4 as uuidv4 } from "uuid";
import CRTextArea from "../../components/component_cr_textarea";
import AddressTextArea from "../../components/component_address_text_area";
import FieldRequireModal from "../../components/component_field_require_modal";
import EditAwardModal from "../../components/component_edit_award_modal";
import AddAwardModal from "../../components/component_add_award_modal";
import EditPhotoModal from "../../components/component_edit_photo_modal";
import MediaItem from "../../components/component_media_item";
import AwardItem from "../../components/component_award_item";
import SocialMediaItem from "../../components/component_social_media_item";
import LocationDetail from "../../components/component_location_detail";
import SchoolLocationDetail from "../../components/component_school_location_detail";
import LoadingModal from "../../components/component_loading_modal";
import { TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import queryString from "query-string";
import { IMediaItem } from "../../components/component_media_item";
import { mapProp, doAction, doFunction, getModuleProp } from "module-reaction";
import FileUploader from "../../components/component_file_uploader";
import { PreviewMode } from "./canvas_preview";
import IconAward from "../../assets/images/ic_award.svg";
import IconAdd from "../../assets/images/ic_add_green.svg";
import IconDelete from "../../assets/images/ic_delete.svg";
import IconUpload from "../../assets/images/ic_upload_green.svg";
import IconAwardAdd from "../../assets/images/ic_award_add.svg";
import {
  module_business_canvas,
  EditAwardAction,
  DeleteAwardAction,
  SocialMediaInputAction,
  UpdatePageInfoAction,
  GetSelectionsAction,
  UploadFileAction,
  DeleteMediaAction,
  GetPageDataAction,
  SubmitToPreviewAction,
  AutoSaveAction,
  SubmitToPublishAction,
  SaveAsDraftAction,
  MODULE_BUSINESS_CANVAS,
  DisconnectInstaAction,
} from "../../models/model_business_canvas";
import "./business_canvas.less";
import { MediaType, CanvasType, router_insta_auth } from "../../utils/enum";
import ContactNumberMask from "../../components/component_contact_number_mask";
import { RouteComponentProps } from "react-router";
import { global_router } from "../../routers";
import { putInstInfo } from "../../api/Insta";
import { EditorComponent } from "../../components/component_editor";
import { getMediaInfo } from "../../utils/mediaHelper";
import { CANVAS_AUTOSAVE_INTERVAL_MILLISECONDS } from "../../utils/constants";
import { CanvasLink } from "../../components/component_canvas_link";
import GetStartedOverlay from "../onboarding/get_start_overlay";
import { checkOverlay } from "../../utils/utils";
const { Content } = Layout;
declare var google: any;

interface BusinessCanvasProps extends RouteComponentProps {
  match: any;
  id: number;
  type: string;
  slug: string;
  businessCategory: [];
  charityOptions: any[];
  programOptions: any[];
  awardList: any[];
  socialMediaList: [];
  companyName: string;
  headline: string;
  showDesc: boolean;
  desc: string;
  whyJoin: string;
  defaultLocation: any;
  contactNumber: string;
  category: any;
  additional: any;
  extraLocations: any[];
  brandList: any[];
  companyBenefitList: any[];
  paymentModeList: any[];
  softwareList: any[];
  specialityList: any[];
  logo: IMediaItem; // type, link
  featuredPhoto: IMediaItem;
  teamPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  extraVideo: IMediaItem[];
  emptyFields: string[];
}

@mapProp(module_business_canvas)
export default class BusinessCanvas extends React.Component<BusinessCanvasProps, {}> {
  state = {
    id: this.props.match.params.id,
    autoSaveId: 0,
    showSaved: false,
    showEditAward: false,
    curEditAward: {},
    showEditPhoto: false,
    curEditMedia: {} as IMediaItem,
    showEditReposition: false,
    isMobile: window.innerWidth < 1024,
    curLoadingMedia: 0,
    showLoading: false,
    isVideoLoading: false,
    curPreviewPosition: BusPositionTag.NONE,
    fromPost: false,
    getStartedOverlay: false
  };
  autoCompleteService: any;
  placeService: any;

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  componentDidMount() {
    doAction(GetPageDataAction, this.state.id);
    doAction(GetSelectionsAction);
    const instaInfo = queryString.parse(window.location.search);
    const { username, fromPost } = instaInfo;
    if (username) {
      doFunction(async () =>
        putInstInfo({
          ...instaInfo,
          canvas_id: getModuleProp(MODULE_BUSINESS_CANVAS, "id"),
        })
      );
      doAction(SocialMediaInputAction, {
        type: 0,
        label: "instagram",
        value: username,
      });
    }
    if (fromPost && !username) {
      this.setState({ fromPost: true });
    }
    if (google) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
      const map = new google.maps.Map(document.createElement("div"));
      this.placeService = new google.maps.places.PlacesService(map);
    }
    
    if (checkOverlay()) {
      this.setState({ getStartedOverlay: true });
    }
  }

  private _autoSaveInterval: any = null;
  private initAutoSave(): void {
    if (this._autoSaveInterval) {
      return;
    }
    this._autoSaveInterval = setInterval(() => {
      doAction(AutoSaveAction, null);
    }, CANVAS_AUTOSAVE_INTERVAL_MILLISECONDS);
  }

  private scrollToPosition = (pos: BusPositionTag) => {
    this.setState(
      {
        curPreviewPosition: pos,
      },
      () => {
        this.setState({
          curPreviewPosition: BusPositionTag.NONE,
        });
      }
    );
  };

  private onAddressSelected = (address: any, uuid: string) => {
    if (!address.place_id || !uuid) return;
    this.placeService.getDetails(
      { placeId: address.place_id },
      (place: any, status: any) => {
        if (status === "OK") {
          let country = "";
          let countryShort = "";
          let state = "";
          let stateShort = "";
          let city = "";
          let coordinates = [] as number[];
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          if (lat && lng) {
            coordinates = [lng, lat];
          }
          _.forEach(place.address_components, (item) => {
            if (item.types.indexOf("country") > -1) {
              country = item.long_name;
              countryShort = item.short_name;
            } else if (item.types.indexOf("administrative_area_level_1") > -1) {
              state = item.long_name;
              stateShort = item.short_name;
            } else if (item.types.indexOf("locality") > -1) {
              city = item.long_name;
            }
          });
          if (uuid === this.props.defaultLocation.uuid) {
            doAction(UpdatePageInfoAction, {
              defaultLocation: {
                ...this.props.defaultLocation,
                country: country,
                countryShort: countryShort,
                state: state,
                stateShort: stateShort,
                city: city,
                coordinates: coordinates,
              },
            });
          } else {
            const extraLoc = [...this.props.extraLocations];
            const loc = _.find(extraLoc, { uuid: uuid });
            if (loc) {
              loc.country = country;
              loc.countryShort = countryShort;
              loc.state = state;
              loc.stateShort = stateShort;
              loc.city = city;
              loc.coordinates = coordinates;
            }
            doAction(UpdatePageInfoAction, {
              extraLocations: extraLoc,
            });
          }
        }
      }
    );
  };

  menuArea = (
    <div className="menu-area">
      <div
        className="square-item"
        onClick={() => doAction(UpdatePageInfoAction, { areaUnit: "ft" })}
      >
        ft²
      </div>
      <div
        className="square-item"
        onClick={() => doAction(UpdatePageInfoAction, { areaUnit: "m" })}
      >
        m²
      </div>
    </div>
  );

  componentWillUnmount() {
    doAction(MODULE_BUSINESS_CANVAS, {
      ...module_business_canvas,
    });
    clearInterval(this._autoSaveInterval);
    this._autoSaveInterval = null;
  }

  render() {
    return (
      <Layout>
        <CreateBusinessCanvasHeader
          showAutosaveHint={false}
          type={this.props.type}
          step={1}
          onPreviewClick={() =>
            doAction(SubmitToPreviewAction, {
              history: this.props.history,
              fromPost: this.state.fromPost,
            })
          }
          onPublishClick={() => {
            doAction(SubmitToPublishAction, {
              history: this.props.history,
              fromPost: this.state.fromPost,
            });
          }}
          onSaveDraftClick={() => {
            doAction(SaveAsDraftAction, this.props.history);
          }}
          jumpToStep={(step: number) => {
            if (step === 0) {
              if (!this.props.slug) return;
              this.props.history.push(
                `/${this.props.type === CanvasType.BUSINESS ? "company" : "school"
                }-info?slug=${this.props.slug}`
              );
            }
          }}
        // showSave={this.state.showSaved}
        />
        <Content>
          <div className="business-canvas-container">
            <div className="left-sec">
              <div className="title-sec">
                <span className="lb-bold title">
                  My{" "}
                  {`${this.props.type === CanvasType.BUSINESS
                    ? "Business"
                    : "School"
                    }`}{" "}
                  Canvas
                </span>
                <Button
                  className="btn-secondary btn-back"
                  onClick={() => {
                    if (!this.props.slug) return;
                    this.props.history.push(
                      `/${this.props.type === CanvasType.BUSINESS
                        ? "company"
                        : "school"
                      }-info?slug=${this.props.slug}`
                    );
                  }}
                >
                  <Icon
                    type="edit"
                    style={{ fontSize: "18px" }}
                    className="icon-edit"
                  />
                  <span className="txt-back">Edit Basic Info</span>
                </Button>
              </div>
              <CRExpansionPanel className="panel" title={"Introduction"}>
                <div className="intro-detail">
                  <CRTextArea
                    className="company-name"
                    label={`${this.props.type === CanvasType.BUSINESS
                      ? "Company"
                      : "School"
                      } name`}
                    value={this.props.companyName || ""}
                    onInput={(value: string) =>
                      doAction(UpdatePageInfoAction, { companyName: value })
                    }
                    preventKeyCodes={[191]}
                    onClick={() => this.scrollToPosition(BusPositionTag.TITLE)}
                  />
                  <CRTextArea
                    className="headline"
                    label="Headline"
                    value={this.props.headline || ""}
                    onInput={(value: string) =>
                      doAction(UpdatePageInfoAction, { headline: value })
                    }
                    onClick={() =>
                      this.scrollToPosition(BusPositionTag.HEADLINE)
                    }
                  />
                  {/* <CRTextArea
                    className="description"
                    label="Description"
                    value={this.props.desc || ""}
                    onInput={(value: string) =>
                      doAction(UpdatePageInfoAction, { desc: value })
                    }
                    onClick={() =>
                      this.scrollToPosition(BusPositionTag.HEADLINE)
                    }
                  /> 
                  <CRTextArea
                    label={`${this.props.type === CanvasType.BUSINESS
                      ? "Why join our team"
                      : "Why choose our school"
                      }`}
                    value={this.props.whyJoin || ""}
                    onInput={(value: string) =>
                      doAction(UpdatePageInfoAction, { whyJoin: value })
                    }
                    onClick={() => this.scrollToPosition(BusPositionTag.WHY)}
                  />
                  */}
                  <div
                    className="description"
                    onClick={() =>
                      this.scrollToPosition(BusPositionTag.HEADLINE)
                    }
                  >
                    <div className="cm-standalone-label">
                      About Us
                    </div>
                    {this.props.showDesc && <EditorComponent
                      placeholder="About Us"
                      content={this.props.desc}
                      hideTools={['image']}
                      getEditorConent={(desc: any) => doAction(UpdatePageInfoAction, { desc })}
                    />}
                  </div>
                  <div
                    className="description whyjoin"
                    onClick={() =>
                      this.scrollToPosition(BusPositionTag.WHY)
                    }
                  >
                    {
                      this.props.type === CanvasType.BUSINESS ?
                        <div className="cm-standalone-label">
                          Why join our team
                        </div> :
                        <div className="cm-standalone-label">
                          Why choose our school
                        </div>
                    }
                    {this.props.showDesc && <EditorComponent
                      placeholder={`${this.props.type === CanvasType.BUSINESS
                        ? "Why join our team"
                        : "Why choose our school"
                        }`}
                      content={this.props.whyJoin}
                      hideTools={['image']}
                      getEditorConent={(whyJoin: any) => doAction(UpdatePageInfoAction, { whyJoin })}
                    />}
                  </div>

                </div>
              </CRExpansionPanel>
              <CRExpansionPanel className="panel" title={"Featured Photos & Videos"}>
                <div className="media-detail">
                  <MediaItem
                    data={this.props.logo}
                    className="item-media"
                    onUpload={(mediaInfo: any) => {
                      this.updateState({
                        curLoadingMedia: 1,
                        showLoading: true,
                      });
                      doAction(UploadFileAction, {
                        mediaInfo: mediaInfo,
                        type: MediaType.LOGO,
                      });
                      doFunction(async () => {
                        this.updateState({
                          curLoadingMedia: 0,
                          showLoading: false,
                        });
                      });
                      this.scrollToPosition(BusPositionTag.TITLE);
                    }}
                    showLoading={this.state.curLoadingMedia === 1}
                    onDelete={() => {
                      doAction(DeleteMediaAction, { type: MediaType.LOGO });
                      this.scrollToPosition(BusPositionTag.TITLE);
                    }}
                    onEdit={(media: IMediaItem) => {
                      this.updateState({
                        curEditMedia: media,
                        showEditPhoto: true,
                        showEditReposition: false
                      });
                      this.scrollToPosition(BusPositionTag.TITLE);
                    }}
                    isShowUploadBtn={true}
                  />
                  <hr className="dotted-line-hr" />
                  <MediaItem
                    data={this.props.featuredPhoto}
                    className="item-media"
                    onUpload={(mediaInfo: any) => {
                      this.updateState({
                        curLoadingMedia: 2,
                        showLoading: true,
                        showEditReposition: true
                      });
                      doAction(UploadFileAction, {
                        mediaInfo: mediaInfo,
                        type: MediaType.FEATURED_PHOTO,
                      });
                      doFunction(async () => {
                        this.updateState({
                          curLoadingMedia: 0,
                          showLoading: false,
                        });
                      });
                      this.scrollToPosition(BusPositionTag.TITLE);
                    }}
                    onDelete={() => {
                      doAction(DeleteMediaAction, {
                        type: MediaType.FEATURED_PHOTO,
                      });
                      this.scrollToPosition(BusPositionTag.TITLE);
                    }}
                    onEdit={(media: IMediaItem) => {
                      this.updateState({
                        curEditMedia: media,
                        showEditPhoto: true,
                        showEditReposition: true
                      });
                      this.scrollToPosition(BusPositionTag.TITLE);
                    }}
                    showLoading={this.state.curLoadingMedia === 2}
                    isShowUploadBtn={true}
                  />
                  <hr className="dotted-line-hr" />
                  <MediaItem
                    data={this.props.teamPhoto}
                    className="item-media"
                    onUpload={(mediaInfo: any) => {
                      this.updateState({
                        curLoadingMedia: 3,
                        showLoading: true,
                        showEditReposition: false
                      });
                      doAction(UploadFileAction, {
                        mediaInfo: mediaInfo,
                        type: MediaType.TEAM,
                      });
                      doFunction(async () => {
                        this.updateState({
                          curLoadingMedia: 0,
                          showLoading: false,
                        });
                      });
                      this.scrollToPosition(BusPositionTag.WHY);
                    }}
                    onDelete={() => {
                      doAction(DeleteMediaAction, { type: MediaType.TEAM });
                      this.scrollToPosition(BusPositionTag.WHY);
                    }}
                    onEdit={(media: IMediaItem) => {
                      this.updateState({
                        curEditMedia: media,
                        showEditPhoto: true,
                      });
                      this.scrollToPosition(BusPositionTag.WHY);
                    }}
                    showLoading={this.state.curLoadingMedia === 3}
                    isShowUploadBtn={true}
                  />
                  <hr className="dotted-line-hr" />
                  <MediaItem
                    data={this.props.featuredVideo}
                    className="item-media"
                    onUpload={(file: any) => {
                      this.updateState({
                        curLoadingMedia: 4,
                        showLoading: true,
                        isVideoLoading: true,
                      });
                      getMediaInfo(file).then(mediaInfo => {
                        doAction(UploadFileAction, {
                          mediaInfo: mediaInfo,
                          type: MediaType.FEATURED_VIDEO,
                        });
                        doFunction(async () => {
                          this.updateState({
                            curLoadingMedia: 0,
                            showLoading: false,
                            isVideoLoading: false,
                          });
                        });
                        this.scrollToPosition(BusPositionTag.HEADLINE);
                      })
                    }}
                    onUrlInput={(value: string) => {
                      const query = value.split("?");
                      let queryStr = "";
                      if (query.length > 0) {
                        queryStr = query[1];
                      }
                      const { v } = queryString.parse(queryStr);
                      let image = "";
                      if (v) {
                        image = `https://img.youtube.com/vi/${v}/0.jpg`;
                      }
                      doAction(UpdatePageInfoAction, {
                        featuredVideo: {
                          ...this.props.featuredVideo,
                          url: value,
                          image: image,
                        },
                      });
                    }}
                    onDelete={() => {
                      doAction(DeleteMediaAction, {
                        type: MediaType.FEATURED_VIDEO,
                      });
                      this.scrollToPosition(BusPositionTag.HEADLINE);
                    }}
                    onEdit={(media: IMediaItem) => {
                      this.updateState({
                        curEditMedia: media,
                        showEditPhoto: true,
                      });
                      this.scrollToPosition(BusPositionTag.HEADLINE);
                    }}
                    showLoading={this.state.curLoadingMedia === 4}
                    char={
                      !_.isEmpty(this.props.companyName)
                        ? this.props.companyName[0]
                        : ""
                    }
                    isShowUploadBtn={true}
                  />
                  <hr className="dotted-line-hr" />
                  {!_.isEmpty(this.props.extraVideo) &&
                    _.map(this.props.extraVideo, (media, index) => {
                      return (
                        <MediaItem
                          data={media}
                          className="item-media"
                          key={index}
                          onDelete={() => {
                            doAction(DeleteMediaAction, {
                              type: MediaType.ADDITIONAL,
                              index: index,
                            });
                            this.scrollToPosition(BusPositionTag.HEADLINE);
                          }}
                          onEdit={(item: IMediaItem) => {
                            this.updateState({
                              curEditMedia: item,
                              showEditPhoto: true,
                              showEditReposition: false
                            });
                            this.scrollToPosition(BusPositionTag.HEADLINE);
                          }}
                          char={
                            !_.isEmpty(this.props.companyName)
                              ? this.props.companyName[0]
                              : ""
                          }
                        />
                      );
                    })}
                  <FileUploader
                    isVideo={true}
                    onPost={(file: any) => {
                      this.updateState({
                        curLoadingMedia: 5,
                        showLoading: true,
                      });
                      getMediaInfo(file).then(mediaInfo => {
                        doAction(UploadFileAction, {
                          mediaInfo: mediaInfo,
                          type: MediaType.ADDITIONAL,
                        });
                        doFunction(async () => {
                          this.updateState({
                            curLoadingMedia: 0,
                            showLoading: false,
                          });
                        });
                        this.scrollToPosition(BusPositionTag.HEADLINE);
                      })
                    }}
                  >
                    <Button
                      className="btn-secondary btn-upload-video"
                      disabled={
                        this.props.extraVideo &&
                        this.props.extraVideo.length > 8
                      }
                    >
                      {this.state.curLoadingMedia === 5 ? (
                        <Icon
                          type="loading"
                          className="loading"
                          style={{ color: "#83b23a" }}
                        />
                      ) : (
                        <img alt="" src={IconUpload} />
                      )}
                      Upload video to my work section
                    </Button>
                  </FileUploader>
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={`${this.props.type === CanvasType.SCHOOL ? "School " : ""
                  }
                  Details`}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(BusPositionTag.DETAIL);
                  }
                }}
              >
                <div className="detail-detail">
                  <TextField
                    key="phone"
                    label="Primary contact number"
                    variant="filled"
                    className="material-input input"
                    value={this.props.contactNumber || ""}
                    onChange={(e) => {
                      doAction(UpdatePageInfoAction, {
                        contactNumber: e.target.value,
                      });
                    }}
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189 ||
                        e.keyCode === 190) &&
                      e.preventDefault()
                    }
                    InputProps={{ inputComponent: ContactNumberMask }}
                  />
                  {this.props.type === CanvasType.BUSINESS && (
                    <Autocomplete
                      multiple
                      className="tag-input auto-complete"
                      options={this.props.businessCategory}
                      freeSolo
                      value={this.props.category}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(event: object, value: any, reason: string) => {
                        const newItems = _.map(value, (item) => {
                          if (typeof item === "string") {
                            if (value.find((_: any) => _.name === item)) {
                              return undefined;
                            }
                            return this.props.businessCategory.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                          }
                          return item;
                        }).filter(Boolean);
                        doAction(UpdatePageInfoAction, { category: _.uniqBy(newItems, 'name') });
                      }}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Business Category"
                          variant="filled"
                          className="material-input"
                        />
                      )}
                    />
                  )}
                  <AddressTextArea
                    className="text-area-location"
                    label={`Location${
                      this.props.extraLocations.length > 0 ? "1" : ""
                    }`}
                    value={this.props.defaultLocation.location || ""}
                    onInput={(value: string) => {
                      doAction(UpdatePageInfoAction, {
                        defaultLocation: {
                          ...this.props.defaultLocation,
                          location: value,
                        },
                      });
                    }}
                    onAddressSelect={(address: any) => {
                      doAction(UpdatePageInfoAction, {
                        defaultLocation: {
                          ...this.props.defaultLocation,
                          location: address.description,
                        },
                      });
                      doFunction(async () => {
                        this.onAddressSelected(
                          address,
                          this.props.defaultLocation.uuid
                        );
                      });
                    }}
                    autoCompleteService={this.autoCompleteService}
                  />
                  {_.map(
                    this.props.extraLocations,
                    (location: any, idx: number) => {
                      return (
                        <div className="extra-location" key={idx}>
                          <AddressTextArea
                            className="text-area-location"
                            label={`Location${idx + 2}`}
                            value={location.location || ""}
                            onInput={(value: string) => {
                              const oldExtras = [...this.props.extraLocations];
                              const cur = _.find(oldExtras, {
                                uuid: location.uuid,
                              });
                              if (cur) {
                                cur.location = value;
                                doAction(UpdatePageInfoAction, {
                                  extraLocations: oldExtras,
                                });
                              }
                            }}
                            onAddressSelect={(address: any) => {
                              const oldExtras = [...this.props.extraLocations];
                              const cur = _.find(oldExtras, {
                                uuid: location.uuid,
                              });
                              if (cur) {
                                cur.location = address.description;
                                doAction(UpdatePageInfoAction, {
                                  extraLocations: oldExtras,
                                });
                              }
                              doFunction(async () => {
                                this.onAddressSelected(address, location.uuid);
                              });
                            }}
                            autoCompleteService={this.autoCompleteService}
                          />
                          <img
                            src={IconDelete}
                            alt=""
                            className="icon-delete"
                            onClick={() => {
                              const newExtras = _.filter(
                                this.props.extraLocations,
                                (item) => {
                                  return item.uuid !== location.uuid;
                                }
                              );
                              doAction(UpdatePageInfoAction, {
                                extraLocations: newExtras,
                              });
                            }}
                          />
                        </div>
                      );
                    }
                  )}
                  <Button
                    className="btn-secondary btn-add-location"
                    onClick={() => {
                      const oldExtra = this.props.extraLocations;
                      doAction(UpdatePageInfoAction, {
                        extraLocations: [
                          ...oldExtra,
                          { uuid: uuidv4(), areaUnit: "ft" },
                        ],
                      });
                    }}
                  >
                    <img alt="" src={IconAdd} />
                    Add another location
                  </Button>
                  {this.props.type === CanvasType.BUSINESS ? (
                    <LocationDetail
                      location={this.props.defaultLocation}
                      index={_.isEmpty(this.props.extraLocations) ? 0 : 1}
                      onValueChange={(location: any) => {
                        doAction(UpdatePageInfoAction, {
                          defaultLocation: location,
                        });
                      }}
                      autoCompleteService={this.autoCompleteService}
                      onAddressSelect={(address: any) => {
                        doAction(UpdatePageInfoAction, {
                          defaultLocation: {
                            ...this.props.defaultLocation,
                            location: address.description,
                          },
                        });
                        doFunction(async () => {
                          this.onAddressSelected(
                            address,
                            this.props.defaultLocation.uuid
                          );
                        });
                      }}
                    />
                  ) : (
                    <SchoolLocationDetail
                      location={this.props.defaultLocation}
                      programOptions={this.props.programOptions}
                      index={_.isEmpty(this.props.extraLocations) ? 0 : 1}
                      onValueChange={(location: any) => {
                        doAction(UpdatePageInfoAction, {
                          defaultLocation: location,
                        });
                      }}
                    />
                  )}

                  {_.map(
                    this.props.extraLocations,
                    (item: any, idx: number) => {
                      return this.props.type === CanvasType.BUSINESS ? (
                        <LocationDetail
                          location={item}
                          key={idx}
                          index={idx + 2}
                          onValueChange={(location: any) => {
                            const oldExtras = [...this.props.extraLocations];
                            const cur = _.find(oldExtras, {
                              uuid: location.uuid,
                            });
                            if (cur) {
                              cur.location = location.location;
                              cur.area = location.area;
                              cur.areaUnit = location.areaUnit;
                              cur.employeeNumber = location.employeeNumber;
                              cur.chairs = location.chairs;
                              doAction(UpdatePageInfoAction, {
                                extraLocations: oldExtras,
                              });
                            }
                          }}
                          autoCompleteService={this.autoCompleteService}
                          onAddressSelect={(address: any) => {
                            const oldExtras = [...this.props.extraLocations];
                            const cur = _.find(oldExtras, {
                              uuid: item.uuid,
                            });
                            if (cur) {
                              cur.location = address.description;
                              doAction(UpdatePageInfoAction, {
                                extraLocations: oldExtras,
                              });
                            }
                            doFunction(async () => {
                              this.onAddressSelected(address, item.uuid);
                            });
                          }}
                        />
                      ) : (
                        <SchoolLocationDetail
                          key={idx}
                          location={item}
                          programOptions={this.props.programOptions}
                          index={idx + 2}
                          onValueChange={(location: any) => {
                            const oldExtras = [...this.props.extraLocations];
                            const cur = _.find(oldExtras, {
                              uuid: location.uuid,
                            });
                            if (cur) {
                              cur.location = location.location;
                              cur.employeeNumber = location.employeeNumber;
                              cur.isAccredited = location.isAccredited;
                              cur.nightStudents = location.nightStudents;
                              cur.supportedProgram = location.supportedProgram;
                              cur.classType = location.classType;
                              cur.dayStudents = location.dayStudents;
                              doAction(UpdatePageInfoAction, {
                                extraLocations: oldExtras,
                              });
                            }
                          }}
                        />
                      );
                    }
                  )}
                </div>
              </CRExpansionPanel>

              <CRExpansionPanel
                className="panel"
                title={"Additional Information"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(BusPositionTag.ADDITIONAL);
                  }
                }}
              >
                <div className="additional-detail">
                  <Autocomplete
                    multiple
                    className="tag-input auto-complete"
                    options={this.props.brandList}
                    freeSolo
                    value={this.props.additional.retailBrands}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return this.props.brandList.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                        }
                        return item;
                      }).filter(Boolean);
                      const newAdd = {
                        ...this.props.additional,
                        retailBrands: _.uniqBy(newItems, 'name'),
                      };
                      doAction(UpdatePageInfoAction, { additional: newAdd });
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Retail brands"
                        variant="filled"
                        className="material-input input"
                        placeholder={this.props.additional.retailBrands?.length ? "" : "Type in retail brand"}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="tag-input auto-complete"
                    freeSolo
                    options={this.props.softwareList}
                    value={this.props.additional.softwares}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return this.props.softwareList.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                        }
                        return item;
                      }).filter(Boolean);
                      const newAdd = {
                        ...this.props.additional,
                        softwares: _.uniqBy(newItems, 'name'),
                      };
                      doAction(UpdatePageInfoAction, { additional: newAdd });
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Software"
                        variant="filled"
                        className="material-input input"
                        placeholder={this.props.additional.softwares?.length ? "" : "Software"}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="tag-input auto-complete"
                    options={this.props.brandList}
                    value={this.props.additional.backbarBrands}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return this.props.brandList.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                        }
                        return item;
                      }).filter(Boolean);
                      const newAdd = {
                        ...this.props.additional,
                        backbarBrands: _.uniqBy(newItems, 'name'),
                      };
                      doAction(UpdatePageInfoAction, { additional: newAdd });
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Backbar brands"
                        variant="filled"
                        className="material-input input"
                        placeholder={this.props.additional.backbarBrands?.length ? "" : "Backbar brands"}

                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="tag-input auto-complete"
                    options={this.props.specialityList}
                    value={this.props.additional.specialties}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return this.props.specialityList.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                        }
                        return item;
                      }).filter(Boolean);
                      const newAdd = {
                        ...this.props.additional,
                        specialties: _.uniqBy(newItems, 'name'),
                      };
                      doAction(UpdatePageInfoAction, { additional: newAdd });
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Specialties"
                        variant="filled"
                        className="material-input input"
                        placeholder={this.props.additional.specialties?.length ? "" : "Specialties"}

                      />
                    )}
                  />
                  <Autocomplete
                    className="tag-input auto-complete"
                    options={this.props.paymentModeList}
                    value={this.props.additional.paymentMode}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(event: object, value: any, reason: string) => {
                      const payment = value;
                      if (payment) {
                        const newAdd = {
                          ...this.props.additional,
                          paymentMode: payment,
                        };
                        doAction(UpdatePageInfoAction, {
                          additional: newAdd,
                        });
                      }
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment preference"
                        variant="filled"
                        className="material-input payment-input"
                        placeholder="Payment preference"
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="tag-input auto-complete"
                    options={this.props.companyBenefitList}
                    value={this.props.additional.companyBenefits}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return this.props.companyBenefitList.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                        }
                        return item;
                      }).filter(Boolean);
                      const newAdd = {
                        ...this.props.additional,
                        companyBenefits: _.uniqBy(newItems, 'name'),
                      };
                      doAction(UpdatePageInfoAction, { additional: newAdd });
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company benefits"
                        variant="filled"
                        className="material-input input"
                        placeholder={this.props.additional.companyBenefits?.length ? "" : "Company benefits"}

                      />
                    )}
                  />
                  {CanvasType.SCHOOL === this.props.type && (
                    <Autocomplete
                      multiple
                      className="tag-input auto-complete"
                      freeSolo
                      options={this.props.charityOptions}
                      value={this.props.additional.charities}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      getOptionSelected={(option: any, value: any) => {
                        return option.name === value.name;
                      }}
                      onChange={(event: object, value: any, reason: string) => {
                        const newItems = _.map(value, (item) => {
                          if (typeof item === "string") {
                            if (value.find((_: any) => _.name === item)) {
                              return undefined;
                            }
                            return this.props.charityOptions.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                          }
                          return item;
                        }).filter(Boolean);
                        const newAdd = {
                          ...this.props.additional,
                          charities: _.uniqBy(newItems, 'name'),
                        };
                        doAction(UpdatePageInfoAction, {
                          additional: newAdd,
                        });
                      }}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Charity"
                          variant="filled"
                          className="material-input input"
                          placeholder={this.props.additional.charities?.length ? "" : "Charity"}

                        />
                      )}
                    />
                  )}
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={"Awards and Achievements"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(BusPositionTag.AWARD);
                  }
                }}
              >
                <div className="award-detail">
                  {_.isEmpty(this.props.awardList) ? (
                    <div className="award-empty">
                      <img
                        alt=""
                        src={IconAward}
                        className="icon-award-empty"
                      />
                      {/* <span className="empty-title">
                        No award or achievement available
                      </span>
                      <span className="empty-subtitle">
                        Having an award or achievement featured on your business
                        site helps to raise more trustability in your brand
                      </span> */}
                      <Button
                        className="btn-secondary btn-add-award"
                        onClick={() =>
                          this.updateState({ showEditAward: true })
                        }
                      >
                        <img alt="" src={IconAwardAdd} />
                        Add award
                      </Button>
                    </div>
                  ) : (
                    <div>
                      {_.map(this.props.awardList, (item, index) => {
                        return (
                          <AwardItem
                            data={item}
                            key={index}
                            className="item-award"
                            onEdit={(award: any) => {
                              this.updateState({
                                showEditAward: true,
                                curEditAward: award,
                              });
                            }}
                            onDelete={(award: any) => {
                              doAction(DeleteAwardAction, award);
                            }}
                          />
                        );
                      })}
                      <Button
                        className="btn-secondary btn-add-award"
                        onClick={() =>
                          this.updateState({
                            showEditAward: true,
                            curEditAward: {},
                          })
                        }
                      >
                        <img alt="" src={IconAwardAdd} />
                        Add award
                      </Button>
                    </div>
                  )}
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={"Social Media"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(BusPositionTag.INS);
                  }
                }}
              >
                <div className="social-detail">
                  {_.map(this.props.socialMediaList, (item, index) => {
                    return (
                      <SocialMediaItem
                        data={item}
                        key={index}
                        className="item-social-media"
                        onClickInstagram={() => {
                          doAction(AutoSaveAction);
                          doFunction(async () =>
                            global_router.history.push({
                              pathname: router_insta_auth,
                              query: { from: window.location.pathname },
                            })
                          );
                        }}
                        onInput={(value: string) => {
                          const newItem = {
                            ...(item as any),
                            value: value,
                          };
                          doAction(SocialMediaInputAction, newItem);
                        }}
                        onDisconnectInstagram={() => {
                          doAction(DisconnectInstaAction)
                        }}
                      />
                    );
                  })}
                </div>
              </CRExpansionPanel>

              <CanvasLink slug={this.props.slug}></CanvasLink>

            </div>
            <div className="right-sec">
              <div className="center-sec">
                <CanvasPreview
                  type={this.props.type}
                  mode={PreviewMode.CREATE}
                  isMobile={this.state.isMobile}
                  pageData={{
                    id: this.props.id,
                    logo: this.props.logo.image,
                    companyName: this.props.companyName,
                    category: this.props.category,
                    headline: this.props.headline,
                    desc: this.props.desc,
                    whyJoin: this.props.whyJoin,
                    contactNumber: this.props.contactNumber,
                    teamPhoto: this.props.teamPhoto,
                    awardList: this.props.awardList,
                    additional: this.props.additional,
                    locations: _.filter(
                      [
                        this.props.defaultLocation,
                        ...this.props.extraLocations,
                      ],
                      (item) => {
                        return !_.isEmpty(item.location);
                      }
                    ),
                    socialMedias: this.props.socialMediaList,
                    featuredImage: this.props.featuredPhoto,
                    videos: _.filter(
                      [this.props.featuredVideo, ...this.props.extraVideo],
                      (item) => {
                        return item.image !== "" || item.url !== "";
                      }
                    ),
                  }}
                  curPosition={this.state.curPreviewPosition}
                />
              </div>
            </div>
          </div>
          {this.state.showEditAward && (
            <AddAwardModal
              business={true}
              visible
              onClose={() => this.updateState({ showEditAward: false })}
              data={this.state.curEditAward}
              onAdd={(award: any) => {
                doAction(EditAwardAction, award);
                doFunction(async () =>
                  this.updateState({ showEditAward: false, curEditAward: {} })
                );
              }}
            />
          )}
          {this.state.showEditPhoto && (
            <EditPhotoModal
              visible
              showReposition={this.state.showEditReposition}
              data={this.state.curEditMedia}
              onClose={() => this.updateState({ showEditPhoto: false })}
              onApply={(mediaInfo: any) => {
                this.updateState({ showEditPhoto: false, showLoading: true });
                doAction(UploadFileAction, {
                  mediaInfo,
                  type: this.state.curEditMedia.type,
                });
                doFunction(async () => {
                  this.updateState({ showLoading: false });
                });
              }}
            />
          )}
          <FieldRequireModal
            onClose={() => doAction(UpdatePageInfoAction, { emptyFields: [] })}
            visible={!_.isEmpty(this.props.emptyFields)}
            data={this.props.emptyFields}
          />
          <LoadingModal
            visible={this.state.showLoading}
          />

          <Modal visible={this.state.getStartedOverlay}
            closable={true}
            onCancel={() => this.setState({ getStartedOverlay: false })}
            maskClosable={true}
            footer={null}
            centered={true}>
            <GetStartedOverlay getStartedOverlay={this.state.getStartedOverlay} type={'pro'} close={() => this.setState({ getStartedOverlay: false })}></GetStartedOverlay>
          </Modal>


        </Content>
      </Layout>
    );
  }
}
