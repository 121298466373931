import React, { useRef, useEffect } from 'react';
import { Chart, ChartDataSets, ChartOptions } from 'chart.js';
import 'chartjs-chart-geo';
import * as ChartGeo from 'chartjs-chart-geo';

interface ChoroplethMapProps {
  data: any;
  label: string;
}

interface CustomChartScales extends Chart.ChartScales {
  projection: {
    axis: string;
    projection: string;
  };
}

interface CustomChartOptions extends ChartOptions {
  scales: CustomChartScales;
}

const ChoroplethMap: React.FC<ChoroplethMapProps> = ({ data, label }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        fetch('https://unpkg.com/us-atlas/states-10m.json')
          .then((r) => r.json())
          .then((us) => {
            const nation = ChartGeo.topojson.feature(us, us.objects.nation).features[0];
            const states = ChartGeo.topojson.feature(us, us.objects.states).features;

            const counts: { [key: string]: number } = {};
            data.forEach(item => {
              counts[item.state] = item.count;
            });

            new Chart(ctx, {
              type: 'choropleth',
              data: {
                labels: states.map((d) => d.properties.name),
                datasets: [{
                  label: label,
                  outline: nation as any,
                  data: states.map((d) => ({
                    feature: d,
                    value: counts[d.properties.name] || 0,
                  })),
                }] as any
              },
              options: {
                plugins: {
                  legend: {
                    display: false
                  },
                },
                scales: {
                  projection: {
                    axis: 'x',
                    projection: 'albersUsa'
                  },
                  color: {
                    axis: 'x',
                    quantize: 5,
                    legend: {
                      position: 'bottom-right',
                      align: 'bottom'
                    },
                  }
                } as any
              } as CustomChartOptions,
            });
          });
      }
    }
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default ChoroplethMap;
