import React from "react";
import { Button, Modal } from "antd";
import { Breadcrumbs, Checkbox, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import _ from "lodash";
import {
  contactFormElement,
  contactInfoValue,
} from "../../../models/model_business_payment";
import {
  getPayPrices,
  getPaymentInfo,
  changePrice,
  previewPrice,
  payContact,
} from "../../../api/Pay";
import { getCanvas } from "../../../api/Canvas";
import CValidateForm, {
  ValidateForm,
} from "../../../components/component_validate_form";
import PreviewCard from "../../../components/component_preview_card";
import IconCheckWhite from "../../../assets/images/ic_check_white.svg";
import IconBillInfo from "../../../assets/images/ic_ professional_info.svg";
import IconContactInfo from "../../../assets/images/ic_contact_info.svg";
import IconPaymentCheck from "../../../assets/images/ic_payment_check.svg";
import IconPaymentCheckDark from "../../../assets/images/ic_payment_check_dark.svg";
import IconSummary from "../../../assets/images/ic_summary.svg";
import {
  router_membership,
  router_membership_billing_update,
  CouponType,
} from "../../../utils/enum";
import { previewAddress } from "../../../utils/utils";
import "../../create_business_canvas/business_canvas_payment.less";
import "./plan_update.less"
import { getCustomized } from "../../../api/Options";
import { mapProp, doAction } from "module-reaction";
import {
  module_busines_payment,
  setPromotionAction,
  applyPromotionCodeAction,
} from "../../../models/model_business_payment";
import { STRIPE_PRICE_ID_EXCLUSIONS } from "../../../utils/constants";


const paymentMethodText = {
  business_six_month: {
    paymentType: [
      // {
      //   value: 0,
      //   name: "Business Canvas only",
      //   premium: false,
      //   details: [
      //     <>
      //       A beautiful <strong>Business Canvas</strong> to highlight your staff
      //       members, benefits, awards and more
      //     </>,
      //     "Upload video and imagery that showcases your business",
      //   ],
      // },
      {
        value: 1,
        name: "Business Canvas & Opportunity Posts",
        premium: true,
        details: [
          <>
            FREE <strong>Business Canvas</strong> to showcase your staff
            members, benefits, awards and more
          </>,
          "Highlight your staff members, benefits, awards and more",
          "Attract talent and recruit candidates from the largest beauty-pro talent pools",
          "Fexible opportunity post bundle + a centralized dashboard to manage your candidates",
        ],
      },
    ],
  },
  business: {
    paymentType: [
      // {
      //   value: 0,
      //   name: "Business Canvas only",
      //   premium: false,
      //   details: [
      //     <>
      //       A beautiful <strong>Business Canvas</strong> to highlight your staff
      //       members, benefits, awards and more
      //     </>,
      //     "Upload video and imagery that showcases your business",
      //   ],
      // },
      {
        value: 1,
        name: "Business Canvas & Opportunity Posts",
        premium: true,
        details: [
          <>
            FREE <strong>Business Canvas</strong> to showcase your staff
            members, benefits, awards and more
          </>,
          "Highlight your staff members, benefits, awards and more",
          "Attract talent and recruit candidates from the largest beauty-pro talent pools",
          "Fexible opportunity post bundle + a centralized dashboard to manage your candidates",
        ],
      },
    ],
  },
  school: {
    paymentType: [
      {
        value: 1,
        name: "School Dashboard, School Canvas & Opportunity post",
        premium: true,
        details: [
          <>
            A beautiful <strong>School Canvas</strong> to highlight your offers
            and attract students
          </>,
          "Flexible opportunity post bundle that meet your hiring needs",
          <>
            A powerful <strong>School dashboard</strong> to help centralize and
            better manage your students and staff
          </>,
          "A FREE opportunity post/month if you purchase this school package",
        ],
      },
    ],
  },
};

@mapProp(module_busines_payment)
export class PlanUpdate extends React.Component<any, any> {
  methodFormRef: React.RefObject<ValidateForm>;
  contactFormRef: React.RefObject<ValidateForm>;

  constructor(props: any) {
    super(props);
    this.methodFormRef = React.createRef();
    this.contactFormRef = React.createRef();
  }

  componentWillMount() {
    const currentCanvasType = _.get(this.props, "match.params.type").replace(
      "_job",
      ""
    ).replace('_six_month','');
    const sixMonth = _.get(this.props, "match.params.type").indexOf('six_month') >= 0
    this.setState({
      currentCanvasType,
      selectedType: 1,
      sixMonth
    });
    doAction(module_busines_payment, {
      promotionCode: "",
      promotionResult: {
        type: -1,
        value: "",
      },
    });
  }

  componentDidMount() {
    window.onresize = () => {
      this.setState({ isMobile: window.innerWidth < 1024 });
    };
    this.getOptions();
  }
  componentWillUnmount() {
    window.onresize = () => {};
  }

  getOptions = async () => {
    let priceOptions: any =
      (await getPayPrices(this.state.currentCanvasType)) || [];
    
    priceOptions = priceOptions.filter(p => !STRIPE_PRICE_ID_EXCLUSIONS.includes(p.price_id))
    const paymentInfo: any = (await getPaymentInfo()) || {};
    const options = await getCustomized(true, ["state"]);
    priceOptions.push({ job_count: 26 });
    if(this.state.currentCanvasType === 'school'){
      priceOptions = priceOptions.filter(p => p.job_count === 1)
    }
    console.log(priceOptions)
    this.setState({
      priceOptions,
      paymentInfo,
      stateOptions: options.state,
    });

    getCanvas(_.get(this.props, "match.params.id")).then((res) => {
      this.setState({ canvasName: _.get(res, "introduction.name") });
      const status = _.get(res, "payorder.status");
      if (status === "succeeded") {
        const price = _.get(res, "payorder.price") || {};
        const canvasType = price.canvas_type || "";
        let selectedPost = 0;
        priceOptions.forEach((item: any, index: number) => {
          if (item.price_id === price.price_id) selectedPost = index;
        });
        this.setState({
          currentCanvasType: canvasType.replace("_job", "").replace('_six_month',''),
          selectedType: canvasType.indexOf("job") || canvasType.indexOf("six_month") >= 0 ? 1 : 0,
          selectedPost,
        });
      }
    });
  };

  state = {
    isMobile: window.innerWidth <= 1024,
    currentCanvasType: "business",
    selectedType: 0,
    canvasName: "",
    priceOptions: [] as any,
    selectedPost: 1,
    addition: {},
    paymentInfo: null as any,
    checked: false,
    visibleContactSuccess: false,
    visibleBillingSuccess: false,
    submitLoading: false,
    stateOptions: [],
    sixMonth: false
  };

  stateOnChange = (name: string, value: number) => {
    this.setState({ [name]: value });
  };

  currentPrice = () =>
    this.state.priceOptions[
      this.state.selectedType === 1 ? this.state.selectedPost : 0
    ] || {};

  postOnChange = (index: number) => {
    this.setState({ selectedPost: index }, () => {
      console.log(this.state.currentCanvasType)
      console.log(index)
      const num = this.state.currentCanvasType === 'business' ? 2 : 6
      if (index === num) return;
      const params = {
        canvas_id: _.get(this.props, "match.params.id"),
        price_id: this.currentPrice().price_id,
      };
      previewPrice(params).then((res) => {
        this.setState({ addition: res || {} });
      });
    });
  };

  subscribePayment = () => {
    this.setState({ stripeVisible: false, submitLoading: true });
    const canvasId = _.get(this.props, "match.params.id");
    const postParams: any = {
      canvas_id: canvasId,
      price_id: this.currentPrice().price_id,
      is_cancel: false,
    };
    changePrice(postParams)
      .then((res) => {
        this.setState({
          submitLoading: false,
          visibleBillingSuccess: true,
        });
      })
      .catch((err) => this.setState({ submitLoading: false }));
  };

  submitContactInfo = () => {
    this.contactFormRef
      .current!.handleSubmit()
      .then((res: any) => {
        this.setState({ submitLoading: true });
        const canvasId = _.get(this.props, "match.params.id");
        const { firstName, lastName, phoneNumber, email } = res;
        const postData = {
          canvasId,
          firstName,
          lastName,
          phoneNumber,
          email,
        };
        payContact(postData)
          .then(() => {
            this.setState({
              submitLoading: false,
              visibleContactSuccess: true,
            });
          })
          .catch((err) => this.setState({ submitLoading: false }));
      })
      .catch((err: any) => {});
  };

  private breadcrumbs() {
    return (
      <Breadcrumbs>
        <Link to={router_membership}>Membership</Link>
        <span>{this.props.title}</span>
      </Breadcrumbs>
    );
  }

  private getCouponValue = () => {
    const {
      promotionResult: { type, value },
    } = this.props;
    const amount = this.currentPrice().amount;
    if (type === CouponType.AMOUNT_OFF) {
      return value < amount ? value : amount;
    }
    if (type === CouponType.PERCENT_OFF) {
      return value < 100 ? amount * (value / 100) : amount;
    }
    return 0;
  };

  render() {
    const {
      selectedType,
      selectedPost,
      canvasName,
      isMobile,
      currentCanvasType,
      priceOptions,
      paymentInfo,
      sixMonth
    } = this.state;
    const currentCTMod = currentCanvasType === 'business_six_month' ? 'business' : currentCanvasType
    const currentPayText = _.get(paymentMethodText, [currentCTMod]);
    const address = _.get(paymentInfo, "billing_details.address") || {};
    const previewBillInfo: any = [
      { label: "Payment method", value: _.get(paymentInfo, "card.brand") },
      { label: "EMAIL", value: _.get(paymentInfo, "email") },
      { label: "Visa ending", value: "..." + _.get(paymentInfo, "card.last4") },
      {
        label: "Billing ADDRESS",
        value: previewAddress(this.state.stateOptions, address),
      },
      { label: "Name", value: _.get(paymentInfo, "billing_details.name") },
    ];
    const { promotionResult } = this.props;
    return (
      <div className="membership-view">
        {this.breadcrumbs()}
        <div
          className="business-payment-wrap"
          style={{ margin: 0, padding: 0, width: "100%", maxWidth: "100%" }}
        >
          <div className="title-wrap">
            <div className="common-title">{this.props.title}</div>
            <div className="update-intro">
              Current Canvas: {_.capitalize(currentCanvasType)} canvas for{" "}
              {canvasName}
            </div>
          </div>
          <div className="payment-content-wrap">
            <div
              className="payment-wrap"
              style={{ width: "100%", maxWidth: "100%" }}
            >
              {/* <div className="pay-type">
                {currentPayText.paymentType.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={classnames("pay-type-item", {
                      active: selectedType === item.value,
                      only: true,
                    })}
                    onClick={() =>
                      this.stateOnChange("selectedType", item.value)
                    }
                  >
                    <div className="info-wrap">
                      <div className="name-wrap">
                        {selectedType === item.value ? (
                          <img src={IconCheckWhite} alt="" />
                        ) : (
                          <span></span>
                        )}
                        <div className="name">{item.name}</div>
                      </div>
                      <div className="fee-wrap">
                        <span className="common-title">
                          $
                          {
                            (
                              _.find(priceOptions, {
                                job_count: item.value,
                              }) || {}
                            ).amount
                          }
                        </span>
                        <span className="unit">/mo</span>
                      </div>
                      {item.premium ? (
                        <div className="save">
                          <span>premium</span>
                        </div>
                      ) : null}
                    </div>
                    <div className="detail-list">
                      {item.details.map(
                        (detailItem: any, detailIndex: number) => (
                          <div key={detailIndex}>
                            <img
                              src={
                                selectedType === item.value
                                  ? IconPaymentCheck
                                  : IconPaymentCheckDark
                              }
                              alt=""
                            />
                            <span>{detailItem}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div> */}
              {selectedType === 1 ? (
                <div className="custom-post">
                  {this.state.currentCanvasType !== 'school' && (
                    <div className="custom-title">Select your number of career opportunites per { sixMonth ? <>6 month</> : <>month</> }. For example: Hairstylist, Front desk, Barber would be 3 opportunities. </div>
                  )}
                  <div className="post-rule">
                    <div className="post-wrap">
                      <div className="post-items">
                        {priceOptions.map((item: any, index: number) =>
                          item.job_count === 0 ? null : (
                            <span
                              className={classnames("post-item", {
                                active: index === selectedPost,
                              })}
                              key={index}
                              onClick={() =>
                                // this.stateOnChange("selectedPost", index)
                                this.postOnChange(index)
                              }
                            >
                              {item.job_count > 25 ? "25+" : item.job_count}
                            </span>
                          )
                        )}
                      </div>
                      <div className="unit">posts / { sixMonth ? <>6 month</> : <>month</> }</div>
                    </div>
                    <div className="common-title">
                      {this.currentPrice().job_count > 25
                        ? null
                        : "$" + this.currentPrice().amount}
                    </div>
                  </div>
                  {this.state.currentCanvasType !== 'school' && (
                    <div className="more">
                      Save more when you purchase more.{" "}
                    </div>
                  )}
                </div>
              ) : null}

              {selectedType === 1 &&
              selectedPost === priceOptions.length - 1 ? (
                <>
                  <div className="contact-info">
                    <div className="title-with-icon">
                      <img src={IconContactInfo} alt="" />
                      Contact Information
                    </div>
                    <CValidateForm
                      wrappedComponentRef={this.contactFormRef}
                      formElement={contactFormElement(isMobile)}
                      defaultValue={contactInfoValue}
                    />
                  </div>
                  <Button
                    className="btn-primary"
                    onClick={this.submitContactInfo}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <div className="product-summary">
                    <div className="title-with-icon">
                      <img src={IconSummary} alt="" />
                      Product Summary
                    </div>
                    <div className="summary-list">
                      <div>
                        <div>
                          {_.get(
                            _.find(currentPayText.paymentType, {
                              value: selectedType,
                            }),
                            "name"
                          ).replace(
                            "& ",
                            `& ${this.currentPrice().job_count} `
                          )}
                          {selectedType === 0 ? " - Monthly Plan" : ""}
                        </div>
                        <div className="amount">
                          ${this.currentPrice().amount}
                        </div>
                      </div>
                      {_.get(this.state.addition, "addition_amount", 0) > 0 ? (
                        <div>
                          <div>
                            Upgrade ProRation (
                            {_.get(this.state.addition, "proration_days", 0)} days)
                          </div>
                          <div className="amount">
                            ${_.get(this.state.addition, "addition_amount", 0)}
                          </div>
                        </div>
                      ) : null}

                      {promotionResult.type > 0 && (
                        <div className="promotion-amount">
                          <div>Promotion code</div>
                          <div className="amount">
                            -${this.getCouponValue()}
                          </div>
                        </div>
                      )}

                      <div className="summary-total">
                        <div>Total</div>
                        <div className="amount">
                          $
                          {(
                            +this.currentPrice().amount +
                            +(_.get(this.state.addition, "addition_amount", 0) > 0
                              ? _.get(this.state.addition, "addition_amount", 0)
                              : 0) -
                            this.getCouponValue()
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="promotion-code-wrap">
                      <TextField
                        key="promotionCode"
                        label="Promotion code"
                        variant="filled"
                        className="material-input promotion-code-input"
                        error={promotionResult.type === CouponType.INVALID}
                        helperText="Invalid code, please try another one"
                        onChange={(e) => {
                          const val = e.target.value;
                          doAction(setPromotionAction, {
                            promotionCode: e.target.value,
                          });
                          if (!val.length) {
                            doAction(setPromotionAction, {
                              promotionResult: {
                                type: -1,
                                value: "",
                              },
                            });
                          }
                        }}
                      />
                      <Button
                        className="btn-primary"
                        onClick={() => {
                          doAction(applyPromotionCodeAction);
                        }}
                      >
                        Apply code
                      </Button>
                    </div>
                    <div className="tip">
                      * You can always change your payment plan in Settings.
                      Subscription will renew { sixMonth ? <>biannually</> : <>monthly</> }. Cancel any time.
                    </div>
                  </div>
                  <div className="pay-method">
                    <div className="title-with-icon">
                      <img src={IconBillInfo} alt="" />
                      Billing Information
                    </div>
                    <PreviewCard
                      previewData={previewBillInfo}
                      column={2}
                      loading={!paymentInfo}
                    />
                    <Button
                      className="btn-secondary"
                      style={{ height: "41px" }}
                    >
                      <Link to={router_membership_billing_update}>
                        Update Billing Info
                      </Link>
                    </Button>
                  </div>
                  <div
                    className="understand-checkbox"
                    onClick={(e) => {
                      this.setState({ checked: !this.state.checked });
                    }}
                  >
                    <Checkbox checked={this.state.checked} color="default" />
                    <span>
                      I understand that my account will be changed to&nbsp;
                      <strong>
                        {_.get(
                          _.find(currentPayText.paymentType, {
                            value: selectedType,
                          }),
                          "name"
                        ).replace("& ", `& ${this.currentPrice().job_count} `)}
                        - { sixMonth ? <>BIANNUAL</> : <>MONTHLY</> } PLAN
                      </strong>
                      &nbsp;starting&nbsp;
                      <strong>{moment().format("MM/DD/yyyy")}</strong> and that
                      on this date, my account, automation and access will need
                      to be compliant within the Features of Plan.
                    </span>
                  </div>
                  <Button
                    className="btn-primary confirm-btn"
                    onClick={this.subscribePayment}
                    disabled={!this.state.checked}
                    loading={this.state.submitLoading}
                  >
                    Confirm to proceed
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <Modal
          footer={null}
          visible={this.state.visibleContactSuccess}
          closable={false}
          centered={true}
          className="payment-contact-success-modal"
          width="400px"
        >
          <div className="common-title">Message sent!</div>
          <div className="intro">
            Your Contact Information has been submitted successfully. <br />
            We will reach out to you in 3 working days.
          </div>
          <Button
            type="primary"
            onClick={() => this.props.history.push(router_membership)}
          >
            Return to membership
          </Button>
        </Modal>
        <Modal
          footer={null}
          visible={this.state.visibleBillingSuccess}
          centered={true}
          onCancel={() => this.setState({ visibleBillingSuccess: false })}
          className="payment-contact-success-modal"
          width="400px"
        >
          <div className="common-title">Payment initiated</div>
          <div className="intro">
            We have sheduled the payment on {moment().format("MM/DD/yyyy")}. You
            can later view your payment history in the Membership section.
          </div>
          <Button
            type="primary"
            onClick={() => this.props.history.push(router_membership)}
          >
            Return to membership
          </Button>
        </Modal>
      </div>
    );
  }
}
