/*
 * @Author: Gary
 * @Date: 2020-04-30 14:28:11
 * @Last Modified by: Gary
 * @Last Modified time: 2020-04-30 14:48:48
 */
import React from "react";
import { FormComponentProps } from "antd/lib/form/Form";
import { Form, Row, Col } from "antd";
import { TextField, MenuItem } from "@material-ui/core";
import noop from "lodash/noop";
import "./component_validate_form.less";

interface IFormComponentProps extends FormComponentProps {
  form: any;
  formElement: any;
  defaultValue: any;
  disabledForm?: boolean;
}

export interface IFormItem {
  type?: string;
  name: string;
  label: string;
  required?: boolean;
  options?: any[];
  rule?: any[];
  colSpan?: number;
  component?: any;
  onChange?: any;
  disable?: boolean;
}

export interface ISelectOptionItem {
  name: string;
  value?: any;
  id?: any;
  [propName: string]: any;
}

export class ValidateForm extends React.Component<IFormComponentProps, {}> {
  static defaultProps = {
    formElement: {},
    defaultValue: {},
    disabledForm: false,
  };

  handleSubmit = () => {
    return new Promise((resolve, reject) => {
      this.props.form
        .validateFieldsAndScroll((err: any, values: any) => {
          if (!err) {
            resolve(values);
          } else {
            reject(err);
          }
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };

  getFieldsValue = (keyList?: any[]) => this.props.form.getFieldsValue(keyList);

  getItemRule = (item: IFormItem) => {
    const requireRule = item.required
      ? [{ required: true, message: "Please input your " + item.label }]
      : [];
    return requireRule.concat(item.rule || []);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { disabledForm = false } = this.props;
    return (
      <Form className="validate-form">
        <Row gutter={24}>
          {this.props.formElement.map((item: IFormItem, index: number) => (
            <Col span={item.colSpan || 24} key={index}>
              <Form.Item>
                {getFieldDecorator(item.name, {
                  rules: this.getItemRule(item),
                  initialValue: this.props.defaultValue[item.name],
                  validateTrigger: !item.type ? "onBlur" : "onChange",
                })(formItem(item, disabledForm))}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    );
  }
}

const formItem = (itemData: IFormItem, disabledForm: boolean) => {
  const {
    type = "input",
    name = "",
    label = "",
    options = [] as ISelectOptionItem[],
    onChange = noop,
    disable = false,
  } = itemData;
  switch (type) {
    case "input":
      return (
        <TextField
          key={name}
          label={label}
          placeholder={label}
          variant="filled"
          className="material-input"
          onChange={(...args) => onChange(itemData, ...args)}
          disabled={disabledForm || disable}
        />
      );

    case "textarea":
      return (
        <TextField
          label={itemData.label}
          placeholder={label}
          multiline
          rows={4}
          variant="filled"
          className="material-input"
          onChange={(...args) => onChange(itemData, ...args)}
          disabled={disabledForm || disable}
        />
      );

    case "select":
      return (
        <TextField
          select
          key={name}
          label={label}
          placeholder={label}
          variant="filled"
          className="material-input"
          onChange={(...args) => onChange(itemData, ...args)}
          disabled={disabledForm || disable}
        >
          {options.map((item: ISelectOptionItem, index: number) => (
            <MenuItem key={index} value={item.value || item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      );

    case "customized":
      return itemData.component(itemData, disabledForm);

    default:
      return itemData.label;
  }
};

export default Form.create()(ValidateForm);
