import React, { useEffect, useState, useCallback } from "react";
import { Layout, Button, Avatar, Dropdown, Menu, Popover, message } from "antd";
import _ from "lodash";
import { Link } from "react-router-dom";
import HomeMenuModal from "./component_home_menu_modal";
import CommonLoadingModal from "./component_common_loading_modal";
import classnames from "classnames";
import Logo from "../assets/images/new_logo_white.svg";
import landingLogo from "../assets/images/landingpage/landing_logo_03.png";
import IconArrow from "../assets/images/ic_arrow_down.svg";
import IconMenu from "../assets/images/home/mobile-menu-white.svg";
import "./component_cr_header.less";
import { Button as MatButton } from "@material-ui/core";
import { getToken, setToken } from "../utils/authStorage";
import {
  router_homepage_canvas,
  router_homepage_opportunity,
  router_get_started,
  router_dashboard_post_job_opp,
  router_company_info,
  router_add_canvas,
  TargetType,
  router_student_pro_landing,
  router_business_payment,
  LandingPage,
} from "../utils/enum";
import { scBaseUrl, wordPressBlogUrl } from "../utils/constants";
import {
  ModelNotifications,
  module_notifications,
  GetNotificationCount,
} from "./../models/model_notifications";
import { modle_user, LoginWithTokenAction, User } from "../models/model_user";
import { setUserLoginInfoAction } from "../models/model_home";
import {
  module_post_job_opp,
  MODULE_POST_JOB_OPP,
} from "../models/model_post_job_opp";
import { module_chat } from "../models/model_chat";
import { initChatClient } from "../utils/chatHelper";
import { searchMyCanvases, getCanvas } from "../api/Canvas";
import { mapProp, doAction, doFunction } from "module-reaction";
import { HashLink } from "react-router-hash-link";
import { getStartedViaSource } from "../utils/utils";
import { getUtoken } from "../api/Auth";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import store from '../utils/store';


const { Header } = Layout;
const scImgPathLogo = scBaseUrl + "cm_sc_logo.png";

interface Props extends ModelNotifications {
  className?: string;
  hidden?: boolean;
  history?: any;
  pathname?: string;
  onLogin?: Function;
  onLogout?: Function;
  curUser?: User;
  unreadCount?: number;
}

const router_homepage_canvas_professional = router_homepage_canvas.replace(
  ":type",
  "professional"
);
const router_homepage_canvas_business = router_homepage_canvas.replace(
  ":type",
  "business"
);
const router_schol_canvas_business = router_homepage_canvas.replace(
  ":type",
  "school"
);

const CRHeader: React.FC<Props> = (props) => {
  const {
    className,
    hidden,
    history,
    pathname,
    onLogin,
    onLogout,
    notificationCount,
    unreadCount = 0,
    curUser,
  } = props;

  const [showMenuModal, setShowMenuModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(
    (curUser &&
      curUser.profile &&
      curUser.profile.target &&
      curUser.profile.target === TargetType.ADMIN) ||
      false
  );
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [wellaLanding, setWellaLanding] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const pageName = location.pathname.replace('/', '');
    let isLanding = false;
    for (const key in LandingPage) {
      if (LandingPage[key] === pageName) {
        isLanding = true;
        break;
      }
    }
    if (isLanding) {
      if(pageName === 'studentpro') {
        dispatch({
          type: 'MARK_LANDING_PAGE_VISITED',
          payload: { source: 'jeffrey', rep: null },
        });
        return;
      }
      const storeValue = store.getState().landingPage.visitedLandingPage;
      const urlParams = new URLSearchParams(location.search);
      const repValue = urlParams.get('rep');
      if (storeValue.source) {
        console.log('Landing Page Already Visited')
      } else {
        console.log('Landing Page Visited')
        if(pageName === 'saloncentric') {
          axios.get('https://api.ipify.org?format=json')
            .then(response => {
              console.log(response.data.ip);
              axios.post(process.env.REACT_APP_BASE_URL+'/landing/page-visited', 
                { ip: response.data.ip, source: pageName, rep: repValue, page: pageName
              }).then(response => {
                console.log(response);
              }).catch(error => {
                console.error("Error fetching IP address:", error);
              });
            })
            .catch(error => {
              console.error("Error fetching IP address:", error);
            });
        }
        setIsLandingPage(true);
        if(pageName === 'wella') {
          setWellaLanding(true);
        }
        
        dispatch({
          type: 'MARK_LANDING_PAGE_VISITED',
          payload: { source: pageName, rep: repValue },
        });
      }
    }
  }, [location]);

  const isHome =
    pathname === "/" ||
    pathname === "" ||
    pathname === "/paulmitchell" ||
    pathname === "/saloncentric";

  const renderMenuPopover = () => {
    return (
      <div className="menu-popover-content">
        {curUser?.profile?.target === "admin" ? (
          <>
            <span onClick={() => history.push("/admin-user")}>My Users</span>
            <span onClick={() => history.push("/admin-opportunity")}>
              Opportunity Dashboard
            </span>
            <span onClick={() => history.push("/admin-canvas")}>
              Canvas Dashboard
            </span>
            <span onClick={() => history.push("/admin-school")}>
              School Dashboard
            </span>
            <span onClick={() => history.push("/admin-data")}>
              KPI Dashboard
            </span>
            <span onClick={() => history.push("/admin-external")}>
              External Payment
            </span>
            <span
              className={unreadCount > 0 ? "notification-active" : ""}
              onClick={() => history.push("/message")}
            >
              Message
            </span>
            <span
              className={notificationCount > 0 ? "notification-active" : ""}
              onClick={() => history.push("/notifications")}
            >
              Notifications
            </span>
            <span onClick={() => history.push("/admin-featured")}>Featured</span>
          </>
        ) : (
          <>
            <span onClick={() => history.push("/my-dashboard")}>Dashboard</span>
            <span onClick={() => history.push("/my-canvases")}>My Portfolio</span>
            <span onClick={() => history.push("/bookmarks")}>Bookmarks</span>
            <span
              className={unreadCount > 0 ? "notification-active" : ""}
              onClick={() => history.push("/message")}
            >
              Message
            </span>
            <span
              className={notificationCount > 0 ? "notification-active" : ""}
              onClick={() => history.push("/notifications")}
            >
              Notifications
            </span>
            <span onClick={() => history.push("/membership")}>Membership</span>
            <span onClick={() => history.push("/settings")}>
              Account Setting
            </span>
            <span onClick={async () => {
              const tab = window.open();
              try {
                const { utoken } = await getUtoken();
                if (utoken) {
                   tab.location.href = `https://u.canvasme.com/?utoken=${utoken}`
                }else{
                  tab.location.href = `https://u.canvasme.com/`
                }
              } catch {}
            }}>
              Beautista Connect
            </span>
          </>
        )}
        <span className="sign-out" onClick={() => onLogout && onLogout()}>
          Sign Out
        </span>
      </div>
    );
  };

  var token = getToken();
  var userMenu: any = null;
  function checkLoginThenGo(location: string) {
    return token ? location : router_get_started;
  }

  if (token) {
    userMenu = (
      <div className="user-sec">
        <Avatar
          icon="user"
          src={curUser && curUser.profile && curUser.profile.avatar}
          className="avatar"
        />
        <Popover
          placement="bottomRight"
          content={renderMenuPopover()}
          trigger="click"
          overlayClassName="menu-popover"
        >
          <span className="name">
            {curUser &&
              curUser.profile &&
              `${curUser?.profile.firstName} ${curUser?.profile.lastName}`}
          </span>
          <img src={IconArrow} alt="" className="ic-arrow" />
        </Popover>
      </div>
    );
  } else if (!token) {
    userMenu = (
      <div>
        <span
          className="text login"
          onClick={() =>
            onLogin &&
            (history.location.pathname !== "/paulmitchell"
              ? onLogin()
              : history.push("/sign-in?school=paul_mitchell"))
          }
        >
          SIGN IN
        </span>
        <Button
          className={`btn-primary-light btn-start ${
            history.location.pathname === "/paulmitchell" ? "hidden" : ""
          }`}
          onClick={() => {
              if (history.location.pathname.includes(router_student_pro_landing)) {
                getStartedViaSource('jeffrey');
              } else if (history.location.pathname.includes('/wella')) {
                getStartedViaSource('wella');
              } else {
                history.push(
                  router_get_started
                )
              }              
            }
          }
        >
          {wellaLanding ? 'POST JOB LISTING' : 'CREATE YOUR FREE ACCOUNT'}
        </Button>
        <span
          onClick={() => {
            if (history.location.pathname.includes(router_student_pro_landing)) {
              getStartedViaSource('jeffrey');
            } else if (history.location.pathname.includes('/wella')) {
              getStartedViaSource('wella');
            } else {
              history.push(
                router_get_started
              )
            }              
          }}
          className="sign-up-mobile"
        >
          {wellaLanding ? 'POST JOB' : 'CREATE YOUR ACCOUNT'}
        </span>
      </div>
    );
  }

  const handleAdmin = () => {
    let info = JSON.parse(
      sessionStorage.getItem("canvas:local:userInfo") || ""
    );
    if (info.access_token) {
      const backPath = sessionStorage.getItem("canvas:local:adminpath");

      setToken(info.access_token);
      doAction(LoginWithTokenAction);
      doAction(setUserLoginInfoAction, {
        res: info,
        history: history!,
        ...(backPath ? { fromPage: backPath } : {}),
      });
    }
  };

  useEffect(() => {
    if (token) {
      doAction(GetNotificationCount);
    }
    if (!curUser || !curUser.id) {
      doAction(LoginWithTokenAction);
    }
    doFunction(async () => {
      await initChatClient(curUser);
    });
    setIsAdmin(
      (curUser &&
        curUser.profile &&
        curUser.profile.target &&
        curUser.profile.target === TargetType.ADMIN) ||
        false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curUser]);

  const checkCanvasAndPost = async (type: string) => {
    setLoading(true);
    const canvasList = await searchMyCanvases(type, "all");
    setLoading(false);
    if (canvasList && !_.isEmpty(canvasList.list)) {
      const first = _.first(canvasList.list) as any;
      if (first && first.id) {
        const res = await getCanvas(first.id);
        if (res && res.job && res.job.max <= res.job.now) {
          message.error(
            "Your current membership and plan does not cover this opportunity post. Please check your membership setting or contact support at hello@beautista.com"
          );
          return;
        }
        doAction(MODULE_POST_JOB_OPP, module_post_job_opp);
        doFunction(async () => {
          history.push({
            pathname: router_dashboard_post_job_opp,
            search: `?id=${first.id}&isInMyAccount=1`,
          });
        });
      }
    } else {
      history.push({
        pathname: router_business_payment,
        search: `?isPostOpportunity=true`,
      });
    }
  };

  const onPostOpportunityClick = useCallback(() => {
    if (curUser && curUser.profile && curUser.profile.target) {
      console.log(curUser.profile.target);
      switch (curUser.profile.target) {
        case TargetType.BUSINESS:
        case TargetType.CAREER:
          checkCanvasAndPost("business");
          break;
        case TargetType.SCHOOL:
          checkCanvasAndPost("school");
          break;
        default:
          history.push({
            pathname: router_company_info,
            search: `?fromPost=true`,
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curUser, history]);

  return (
    <Header
      className={classnames(
        "header-root",
        className
      )}
      hidden={hidden}
    >
      <div
        className={`logo-sec`}
      >
        <a href="https://beautista.com">
          <img
            alt=""
            src={Logo}
            className="logo"
            width={200}
            height={20}
          />
        </a>
      </div>
      <div className="left-sec">
        {!isLandingPage && (
          <>
            <Dropdown overlay={
              <Menu>
                <Menu.Item key={router_homepage_opportunity}>
                  <Link to={checkLoginThenGo(router_homepage_opportunity)}>
                    {" "}
                    View Opportunities
                  </Link>
                </Menu.Item>
                <Menu.Item key={router_dashboard_post_job_opp} onClick={onPostOpportunityClick}>
                  Create Opportunity
                </Menu.Item>
              </Menu>
            } 
            trigger={["click"]}>
              <span
                className={`text ${
                  pathname?.includes(router_homepage_canvas.split(":")[0])
                    ? "active"
                    : ""
                }`}
              >
                OPPORTUNITIES
                <img src={IconArrow} alt="" className="ic-arrow" />
              </span>
            </Dropdown>
            <Dropdown overlay={
              <Menu>
                {!token && (
                  <Menu.Item key={router_add_canvas}>
                    <Link to={checkLoginThenGo(router_add_canvas)}>
                      Create a Canvas Porfolio
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key={router_homepage_canvas_professional}>
                  <Link to={checkLoginThenGo(router_homepage_canvas_professional)}>
                    View Professional Canvases
                  </Link>
                </Menu.Item>
                <Menu.Item key={router_homepage_canvas_business}>
                  <Link to={checkLoginThenGo(router_homepage_canvas_business)}>
                    View Business Canvases
                  </Link>
                </Menu.Item>
                <Menu.Item key={router_homepage_canvas_business}>
                  <Link to={checkLoginThenGo(router_schol_canvas_business)}>
                    View School Canvases
                  </Link>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}>
              <span
                className={`text ${
                  pathname?.includes(router_homepage_canvas.split(":")[0])
                    ? "active"
                    : ""
                }`}
              >
                PORTFOLIOS
                <img src={IconArrow} alt="" className="ic-arrow" />
              </span>
            </Dropdown>
            <Dropdown overlay={
              <Menu>
                <Menu.Item key={router_homepage_canvas_professional}>
                  <a href="https://sites.canvasme.com/video/">How it Works</a>
                </Menu.Item>
                <Menu.Item key={router_homepage_canvas_business}>
                  <HashLink smooth to="/#faq">
                    FAQs
                  </HashLink>
                </Menu.Item>
                <Menu.Item key={wordPressBlogUrl}>
                  <a href={wordPressBlogUrl}>Blog</a>
                </Menu.Item>
              </Menu>
            } 
            trigger={["click"]}>
              <span
                className={`text ${
                  pathname?.includes(router_homepage_canvas.split(":")[0])
                    ? "active"
                    : ""
                }`}
              >
                RESOURCES
                <img src={IconArrow} alt="" className="ic-arrow" />
              </span>
            </Dropdown>
          </>
        )}
      </div>
      <div className="right-sec">
        {token &&
          sessionStorage.getItem("canvas:local:userInfo") &&
          curUser?.profile?.target !== "admin" && (
            <MatButton
              className="back-btn"
              onClick={handleAdmin}
              variant="outlined"
            >
              Back to admin
            </MatButton>
          )}
        {!!token && (
          <MatButton
            className="btn-primary post-opportunity-btn"
            onClick={onPostOpportunityClick}
            hidden={isHome || isAdmin}
          >
            Post Opportunity
          </MatButton>
        )}
        {userMenu}
        <img
          alt=""
          src={IconMenu}
          className="ic-menu"
          onClick={() => setShowMenuModal(true)}
        />
      </div>
      <HomeMenuModal
        onPostOpportunityClick={onPostOpportunityClick}
        visible={showMenuModal}
        onClose={() => setShowMenuModal(false)}
        history={history}
        onLogout={() => {
          setShowMenuModal(false);
          onLogout && onLogout();
        }}
        onLogin={() => {
          setShowMenuModal(false);
          onLogin &&
            (history.location.pathname !== "/paulmitchell"
              ? onLogin()
              : history.push("/sign-in"));
        }}
        isLogin={token !== "" && token !== null && token !== undefined}
        user={curUser}
      />
      <CommonLoadingModal visible={loading} closable={false} />
    </Header>
  );
};

export default mapProp(
  module_notifications,
  "notificationCount"
)(mapProp(module_chat)(mapProp(modle_user)(CRHeader)));
