import { ModuleStore, ModuleAction, KV, plusAction } from "module-reaction";
import _ from "lodash";
import { getCustomized } from "../api/Options";
import { getCanvas, editCanvas, updateCanvasStatus } from "../api/Canvas";
import { Storage } from "aws-amplify";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { IMediaItem } from "../components/component_media_item";
import { MediaType, CanvasType } from "../utils/enum";
import { IAward } from "../components/component_award_item";
import { ISocialMedia } from "../components/component_social_media_item";
import { AWS_URL } from "../utils/constants";
import { getMediaInfo } from "../utils/mediaHelper";
import { delInstInfo } from "../api/Insta";
import { htmlDecode, isHtmlTag } from "../utils/utils";
import store from "../utils/store";
export const MODULE_BUSINESS_CANVAS = "module_business_canvas";

const tempSocial = [
  {
    type: 0,
    label: "instagram",
  },
  {
    type: 1,
    label: "youtube",
  },
  {
    type: 1,
    label: "facebook",
  },
  {
    type: 0,
    label: "tiktok",
  },
];

export interface ModuleBusinessCanvas extends ModuleStore {
  id: number;
  type: string;
  slug: string;
  owner: number;
  businessCategory: string[];
  charityOptions: any[];
  programOptions: any[];
  awardList: IAward[];
  socialMediaList: ISocialMedia[];
  companyName: string;
  headline: string;
  showDesc: boolean;
  desc: string;
  whyJoin: string;
  defaultLocation: any;
  contactNumber: string;
  category: any[];
  additional: any;
  extraLocations: any[];
  brandList: any[];
  companyBenefitList: any[];
  paymentModeList: any[];
  softwareList: any[];
  specialityList: any[];
  logo: IMediaItem; // type, link
  featuredPhoto: IMediaItem;
  teamPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  extraVideo: IMediaItem[];
  emptyFields: string[];
}

export const module_business_canvas: ModuleBusinessCanvas = {
  module: MODULE_BUSINESS_CANVAS,
  id: 0,
  slug: "",
  owner: 0,
  type: "business",
  businessCategory: [],
  charityOptions: [],
  programOptions: [],
  awardList: [],
  socialMediaList: tempSocial,
  companyName: "",
  headline: "",
  desc: "",
  showDesc: false,
  whyJoin: "",
  defaultLocation: {
    uuid: uuidv4(),
    areaUnit: "ft",
  },
  contactNumber: "",
  category: [],
  additional: {
    retailBrands: [],
    softwares: [],
    backbarBrands: [],
    specialties: [],
    companyBenefits: [],
    paymentMode: {},
    charities: [],
  },
  extraLocations: [],
  brandList: [],
  companyBenefitList: [],
  paymentModeList: [],
  softwareList: [],
  specialityList: [],
  logo: {
    image: "",
    title: "Company Logo",
    dimensions: "100x60",
    fileSize: 1,
    url: "",
    type: MediaType.LOGO,
  },
  featuredPhoto: {
    image: "",
    title: "Featured Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.FEATURED_PHOTO,
  },
  teamPhoto: {
    image: "",
    title: "Team Photo",
    dimensions: "1240x800",
    fileSize: 5,
    url: "",
    type: MediaType.TEAM,
  },
  featuredVideo: {
    image: "",
    title: "Featured Video",
    dimensions: "1240x800",
    fileSize: 10,
    url: "",
    type: MediaType.FEATURED_VIDEO,
  },
  extraVideo: [] as IMediaItem[],
  emptyFields: [] as string[],
};

export const GetPageDataAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (slug: string, module: ModuleBusinessCanvas) => {
    if (!slug) {
      return {};
    }
    const res = await getCanvas(slug);
    if (res) {
      const mappedLocations = _.map(res.detail.locations, (item) => {
        return {
          uuid: item.uuid,
          location: item.address,
          area: item.area,
          areaUnit: item.areaUnit,
          chairs: item.chairAmount,
          city: item.city,
          coordinates: item.coordinates,
          country: item.country,
          countryShort: item.countryShort,
          employeeNumber: item.employeeAmount,
          state: item.state,
          stateShort: item.stateShort,
          members: _.map(item.members, (item) => {
            const newItem = { ...item };
            delete newItem.status;
            return newItem;
          }),
          isAccredited: item.isAccredited,
          nightStudents: item.nightStudents,
          supportedProgram: _.map(item.supportedProgram, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }),
          classType: item.classType,
          dayStudents: item.dayStudents,
        };
      });
      const defaultLoc = _.first(mappedLocations) || {
        uuid: uuidv4(),
        areaUnit: "ft",
      };
      const extraLoc =
        _.slice(mappedLocations, 1, mappedLocations.length) || [];

      let social = [...tempSocial];
      social = _.map(social, (socialItem) => {
        if (_.get(res.socialMedia, socialItem.label)) {
          return {
            ...socialItem,
            value: _.get(res.socialMedia, socialItem.label),
          };
        } else {
          return socialItem;
        }
      });

      let pageData = {
        id: res.id,
        slug: res.slug,
        type: res.type || "business",
        owner: res.owner || 0,
        awardList: res.achievements,
        additional: {
          backbarBrands: _.map(
            res.additionalInformation.backbarBrands,
            (item) => {
              return { id: item.optionId, name: item.name };
            }
          ),
          companyBenefits: _.map(
            res.additionalInformation.companyBenefits,
            (item) => {
              return { id: item.optionId, name: item.name };
            }
          ),
          paymentMode: res.additionalInformation.paymentMode,
          retailBrands: _.map(
            res.additionalInformation.retailBrands,
            (item) => {
              return { id: item.optionId, name: item.name };
            }
          ),
          softwares: _.map(res.additionalInformation.softwares, (item) => {
            return { id: item.optionId, name: item.name };
          }),
          specialties: _.map(res.additionalInformation.specialities, (item) => {
            return { id: item.optionId, name: item.name };
          }),
          charities: _.map(res.additionalInformation.charities, (item) => {
            return { id: item.optionId, name: item.name };
          }),
        },
        contactNumber: res.detail.primaryContactNumber || "",
        category: _.map(res.detail.categories, (item) => {
          return { id: item.optionId, name: item.name };
        }),
        defaultLocation: defaultLoc,
        extraLocations: extraLoc,
        companyName: res.introduction.name,
        headline: res.introduction.headline,
        desc: isHtmlTag(res.introduction.description)? res.introduction.description : htmlDecode(res.introduction.description),
        whyJoin: isHtmlTag(res.introduction.reason)? res.introduction.reason : htmlDecode(res.introduction.reason),
        showDesc: true,
        logo: (res.media && res.media.logo) || {
          image: "",
          title:
            res.type === CanvasType.BUSINESS ? "Company Logo" : "School Logo",
          dimensions: "100x60",
          fileSize: 1,
          url: "",
          type: MediaType.LOGO,
        },
        featuredPhoto: (res.media && res.media.featuredPhoto) || {
          image: "",
          title: "Featured Photo",
          dimensions: "1440x758",
          fileSize: 5,
          url: "",
          type: MediaType.FEATURED_PHOTO,
        },
        teamPhoto: (res.media && res.media.teamPhoto) || {
          image: "",
          title: "Team Photo",
          dimensions: "1240x800",
          fileSize: 5,
          url: "",
          type: MediaType.TEAM,
        },
        featuredVideo: (res.media && res.media.featuredVideo) || {
          image: "",
          title: "Featured Video",
          dimensions: "1240x800",
          fileSize: 10,
          url: "",
          type: MediaType.FEATURED_VIDEO,
        },
        extraVideo: (res.media && res.media.additionalVideo) || [],
        socialMediaList: social,
      };

      return pageData;
    }

    return {};
  },
};

export const GetSelectionsAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async () => {
    const res = await getCustomized(true, [
      "category",
      "brand",
      "software",
      "speciality",
      "paymentMode",
      "companyBenefit",
      "charity",
      "program",
    ]);
    if (res) {
      return {
        businessCategory: res.category.business,
        brandList: res.brand,
        companyBenefitList: res.companyBenefit,
        paymentModeList: res.paymentMode,
        softwareList: res.software,
        specialityList: res.speciality,
        charityOptions: res.charity,
        programOptions: res.program,
      };
    }
    return {};
  },
};

export const UpdatePageInfoAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (options: KV, module: ModuleBusinessCanvas) => {
    return { ...options };
  },
};

export const EditAwardAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (award: IAward, module: ModuleBusinessCanvas) => {
    const oldList = module.awardList;
    if (!award.uuid) {
      return { awardList: [...oldList, { ...award, uuid: uuidv4() }] };
    } else {
      const editedItem = _.find(oldList, { uuid: award.uuid });
      if (editedItem) {
        editedItem.issuer = award.issuer;
        editedItem.issueYear = award.issueYear;
        editedItem.title = award.title;
        return { awardList: [...oldList] };
      } else {
        return {};
      }
    }
  },
};

export const DeleteAwardAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (award: IAward, module: ModuleBusinessCanvas) => {
    const oldList = module.awardList;
    const newList = _.filter(oldList, (item) => {
      return item.uuid !== award.uuid;
    });
    return { awardList: newList };
  },
};

export const SocialMediaInputAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (media: any, module: ModuleBusinessCanvas) => {
    const oldList = module.socialMediaList;
    const item = _.find(oldList, { label: media.label });
    if (item) {
      item.value = media.value;
      return { socialMediaList: [...oldList] };
    }
    return {};
  },
};
export const DisconnectInstaAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (payload: any, module: ModuleBusinessCanvas) => {
    const res = await delInstInfo(module.id)
    if (res?.success) {
      const { socialMediaList } = module
      const instaMedia = _.find(socialMediaList, { label: "instagram" })
      delete instaMedia!.value

      plusAction(AutoSaveAction) // just save , not go to insta-auth
      return { socialMediaList }
    }
    return {}
  }
}
export const DeleteMediaAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (params: KV, module: ModuleBusinessCanvas) => {
    const { type, index } = params;
    switch (type) {
      case MediaType.LOGO:
        return {
          logo: {
            image: "",
            title: "Company Logo",
            dimensions: "100x60",
            fileSize: 1,
            url: "",
            type: MediaType.LOGO,
          },
        };
      case MediaType.FEATURED_PHOTO:
        return {
          featuredPhoto: {
            image: "",
            title: "Featured Photo",
            dimensions: "1440x758",
            fileSize: 5,
            url: "",
            type: MediaType.FEATURED_PHOTO,
          },
        };
      case MediaType.TEAM:
        return {
          teamPhoto: {
            image: "",
            title: "Team Photo",
            dimensions: "1240x800",
            fileSize: 5,
            url: "",
            type: MediaType.TEAM,
          },
        };
      case MediaType.FEATURED_VIDEO:
        return {
          featuredVideo: {
            image: "",
            title: "Featured Video",
            dimensions: "1240x800",
            fileSize: 10,
            url: "",
            type: MediaType.FEATURED_VIDEO,
          },
        };
      case MediaType.ADDITIONAL:
        const newAdd = _.filter(module.extraVideo, (item, idx) => {
          return index !== idx;
        });
        const mapped = _.map(newAdd, (item, idx) => {
          item.title = `Additional Video ${idx + 1}`;
          return item;
        });
        return { extraVideo: mapped };
    }
    return {};
  },
};

export const UploadFileAction: ModuleAction<KV, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (params: KV, module: ModuleBusinessCanvas) => {
    const { mediaInfo, type } = params;
    // let maxSize;
    // switch (type) {
    //   case MediaType.LOGO:
    //     maxSize = 1;
    //     break;
    //   case MediaType.FEATURED_PHOTO:
    //     maxSize = 5;
    //     break;
    //   case MediaType.TEAM:
    //     maxSize = 5;
    //     break;
    //   case MediaType.FEATURED_VIDEO:
    //     maxSize = 10;
    //     break;
    //   case MediaType.ADDITIONAL:
    //     maxSize = 10;
    //     break;
    //   default:
    //     maxSize = 10;
    // }
    if (mediaInfo.error) return {};
    //
    const remotePath = `canvas/${type}/`;
    const customPrefix = {
      public: remotePath,
    };
    let imageKey = "";
    let videoKey = "";
    let hasError = false;
    if (mediaInfo.image) {
      const result: any = await Storage.put(
        uuidv4() + ".png",
        mediaInfo.image,
        {
          customPrefix,
          progressCallback(progress: any) {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            store.dispatch({
              type: 'SET_UPLOAD_PROGRESS',
              payload: uploadProgress
            });
          }
        }
      ).catch((error) => {
        message.error(error.message);
        hasError = true;
      });
      if (result && result.key) {
        imageKey = result.key;
      }
    }
    if (mediaInfo.video) {
      const sf = _.last(mediaInfo.video.name.split(".")) || ".MP4";
      const result: any = await Storage.put(
        uuidv4() + `.${sf}`,
        mediaInfo.video,
        {
          customPrefix,
          progressCallback(progress: any) {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            store.dispatch({
              type: 'SET_UPLOAD_PROGRESS',
              payload: uploadProgress
            });
          }
        }
      ).catch((error) => {
        message.error(error.message);
        hasError = true;
      });
      if (result && result.key) {
        videoKey = result.key;
      }
    }
    if (hasError) {
      return {};
    }
    const imgUrl = imageKey ? AWS_URL + remotePath + imageKey : null;
    const videoUrl = videoKey ? AWS_URL + remotePath + videoKey : "";
    switch (type) {
      case MediaType.LOGO:
        return {
          logo: {
            ...module.logo,
            image: imgUrl,
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
          },
        };
      case MediaType.FEATURED_PHOTO:
        return {
          featuredPhoto: {
            ...module.featuredPhoto,
            image: imgUrl,
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            keepSize: mediaInfo.keepSize,
            reposition: mediaInfo.reposition,
          },
        };
      case MediaType.TEAM:
        return {
          teamPhoto: {
            ...module.teamPhoto,
            image: imgUrl,
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            keepSize: mediaInfo.keepSize,
            reposition: mediaInfo.reposition,
          },
        };
      case MediaType.FEATURED_VIDEO:
        if (videoUrl) {
          return {
            featuredVideo: {
              ...module.featuredVideo,
              image: imgUrl,
              video: videoUrl,
              dimensions: mediaInfo.dimensions,
              fileSize: mediaInfo.fileSize,
            },
          };
        } else {
          return {};
        }

      case MediaType.ADDITIONAL:
        if (videoUrl) {
          const oldAdds = module.extraVideo;
          const item = {
            image: imgUrl,
            video: videoUrl,
            title: `Additional Video ${oldAdds.length + 1}`,
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            url: "",
            type: MediaType.ADDITIONAL,
          };
          return {
            extraVideo: [...oldAdds, item],
          };
        } else {
          return {};
        }
    }

    return {};
  },
  maxProcessSeconds: 300,
};

export const SubmitToPreviewAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (params: any, module: ModuleBusinessCanvas) => {
    const { history, fromPost } = params;
    if (!module.id) {
      return {};
    }
    const request = convertModalToRequest(module);
    const res = await editCanvas(module.id, request);
    if (res) {
      history.push(
        `/${
          module.type === CanvasType.BUSINESS
            ? "business-canvas"
            : "school-canvas"
        }/preview/${module.slug || module.id}${
          fromPost ? "?fromPost=true" : ""
        }`
      );
    }
    return {};
  },
};

export const SaveAsDraftAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (history: any, module: ModuleBusinessCanvas) => {
    if (!module.id) {
      return {};
    }
    const request = convertModalToRequest(module);
    const res = await editCanvas(module.id, request);
    if (res) {
      history.push("/my-canvases");
    }
    return {};
  },
};

export const SubmitToPublishAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (params: any, module: ModuleBusinessCanvas) => {
    const { history, fromPost } = params;
    if (!module.id) {
      return {};
    }
    const emptyFields = [] as string[];
    if (!module.companyName) {
      emptyFields.push(
        module.type === CanvasType.BUSINESS ? "Company name" : "School name"
      );
    }
    // if (!module.contactNumber) {
    //   emptyFields.push("Primary Contact Number");
    // }
    let addressHasEmpty = false;
    _.forEach(module.extraLocations, (loc) => {
      if (!loc.location) {
        addressHasEmpty = true;
      }
    });
    if (!module.defaultLocation.location || addressHasEmpty) {
      emptyFields.push("Location");
    }
    if (!_.isEmpty(emptyFields)) {
      return { emptyFields: emptyFields };
    }
    const request = convertModalToRequest(module);
    const res = await editCanvas(module.id, request);
    if (res) {
      if (res.payorder && res.payorder.status === "succeeded") {
        const publish = await updateCanvasStatus(module.id);
        if (publish && publish.success) {
          history.push("/my-canvases");
        }
      } else {
        history.push(
          (module.type === CanvasType.BUSINESS
            ? "/business-payment/"
            : "/school-payment/") +
            module.slug +
            `${fromPost ? "?isPostOpportunity=true" : ""}`
        );
      }
    }
    return {};
  },
};

export const AutoSaveAction: ModuleAction<any, ModuleBusinessCanvas> = {
  module: MODULE_BUSINESS_CANVAS,
  process: async (history: any, module: ModuleBusinessCanvas) => {
    if (!module.id) {
      return {};
    }
    const request = convertModalToRequest(module);
    editCanvas(module.id, request);
    return {};
  },
};

const convertModalToRequest = (modal: ModuleBusinessCanvas) => {
  const requestLocation = _.map(
    [modal.defaultLocation, ...modal.extraLocations],
    (item) => {
      let location = {
        uuid: item.uuid,
        employeeAmount: item.employeeNumber || 0,
        chairAmount: item.chairs || 0,
        area: item.area || 0,
        areaUnit: item.areaUnit,
        address: item.location,
        members: item.members,
        classType: item.classType,
        nightStudents: item.nightStudents === "" ? null : item.nightStudents,
        dayStudents: item.dayStudents === "" ? null : item.dayStudents,
        isAccredited: item.isAccredited,
        supportedProgram: _.map(item.supportedProgram, (item) => {
          let newItem = { name: item.name } as any;
          if (item.id) {
            newItem = {
              ...newItem,
              optionId: item.id,
            };
          }
          return newItem;
        }),
      } as any;
      if (!_.isEmpty(item.country)) {
        location = {
          ...location,
          country: item.country,
        };
      }
      if (!_.isEmpty(item.countryShort)) {
        location = {
          ...location,
          countryShort: item.countryShort,
        };
      }
      if (!_.isEmpty(item.state)) {
        location = {
          ...location,
          state: item.state,
        };
      }
      if (!_.isEmpty(item.stateShort)) {
        location = {
          ...location,
          stateShort: item.stateShort,
        };
      }
      if (!_.isEmpty(item.city)) {
        location = {
          ...location,
          city: item.city,
        };
      }
      if (!_.isEmpty(item.coordinates)) {
        location = {
          ...location,
          coordinates: item.coordinates,
        };
      }
      return location;
    }
  );
  let mediaFiltered = {};
  const extraV = _.filter(modal.extraVideo, (video) => {
    return !_.isEmpty(video.video);
  });
  if (!_.isEmpty(extraV)) {
    mediaFiltered = {
      ...mediaFiltered,
      additionalVideo: extraV,
    };
  }
  if (modal.logo.image) {
    mediaFiltered = {
      ...mediaFiltered,
      logo: modal.logo,
    };
  }
  if (modal.featuredPhoto.image) {
    mediaFiltered = {
      ...mediaFiltered,
      featuredPhoto: modal.featuredPhoto,
    };
  }
  if (modal.teamPhoto.image) {
    mediaFiltered = {
      ...mediaFiltered,
      teamPhoto: modal.teamPhoto,
    };
  }
  if (modal.featuredVideo.video || modal.featuredVideo.url) {
    mediaFiltered = {
      ...mediaFiltered,
      featuredVideo: modal.featuredVideo,
    };
  }
  let request = {
    introduction: {
      name: modal.companyName,
      headline: modal.headline,
      description: modal.desc,
      reason: modal.whyJoin,
    },
    media: mediaFiltered,
    socialMedia: {
      instagram:
        _.find(modal.socialMediaList, { label: "instagram" })?.value || null,
      youtube:
        _.find(modal.socialMediaList, { label: "youtube" })?.value || null,
      facebook:
        _.find(modal.socialMediaList, { label: "facebook" })?.value || null,
      tiktok: _.find(modal.socialMediaList, { label: "tiktok" })?.value || null,
    },
    achievements: modal.awardList,
    detail: {
      primaryContactNumber: modal.contactNumber,
      categories: _.map(modal.category, (item) => {
        if (item.id) {
          return { optionId: item.id, name: item.name };
        } else {
          return item;
        }
      }),
      locations: requestLocation,
    },
    additionalInformation: {
      backbarBrands: _.map(modal.additional.backbarBrands, (item) => {
        return { optionId: item.id, name: item.name };
      }),
      companyBenefits: _.map(modal.additional.companyBenefits, (item) => {
        return { optionId: item.id, name: item.name };
      }),
      paymentModeId: modal.additional.paymentMode?.id || 0,
      retailBrands: _.map(modal.additional.retailBrands, (item) => {
        return { optionId: item.id, name: item.name };
      }),
      softwares: _.map(modal.additional.softwares, (item) => {
        return { optionId: item.id, name: item.name };
      }),
      specialities: _.map(modal.additional.specialties, (item) => {
        return { optionId: item.id, name: item.name };
      }),
      charities: _.map(modal.additional.charities, (item) => {
        return { optionId: item.id, name: item.name };
      }),
    },
  };

  return request;
};
