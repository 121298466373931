import { ModuleStore, ModuleAction, plusAction, KV } from "module-reaction";
import { getMyBookmarks, addBookmark } from "../api/Bookmark";
import _ from "lodash";

export const MODULE_BOOKMARKS = "MODULE_BOOKMARKS";

export interface ModelBookmarks extends ModuleStore {
  canvasList: any[];
  opportunityList: any[];
  canvasListByPage: any[];
  opportunityListByPage: any[];
  curPage: number;
  user: any;
}

export const module_bookmarks: ModelBookmarks = {
  module: MODULE_BOOKMARKS,
  canvasList: [] as any[],
  opportunityList: [] as any[],
  canvasListByPage: [] as any[],
  opportunityListByPage: [] as any[],
  curPage: 1,
  user: {},
};
export const GetCanvasAndOppBookmarks: ModuleAction<any, ModelBookmarks> = {
  module: MODULE_BOOKMARKS,
  process: async (x: any, module: ModelBookmarks) => {
    const result = await getMyBookmarks();
    if (result && !_.isEmpty(result.bookmarks)) {
      const canvases = _.filter(result.bookmarks, (item) => {
        return item.canvas;
      });
      const opportunities = _.filter(result.bookmarks, (item) => {
        return item.job;
      });
      plusAction(ChangePageAction, module.curPage);
      return {
        canvasList: canvases,
        opportunityList: opportunities,
      };
    }
    return {};
  },
};

export const EditBookmarkNoteAction: ModuleAction<KV, ModelBookmarks> = {
  module: MODULE_BOOKMARKS,
  process: async (params: KV) => {
    const { id, note } = params;
    const result = await addBookmark("canvas", id, "edit", note);
    if (result && result.success) {
      plusAction(GetCanvasAndOppBookmarks);
    }
    return {};
  },
};

export const RemoveBookmarkAction: ModuleAction<number, ModelBookmarks> = {
  module: MODULE_BOOKMARKS,
  process: async (id: number) => {
    const result = await addBookmark("canvas", id, "remove", "");
    if (result && result.success) {
      plusAction(GetCanvasAndOppBookmarks);
    }
    return {};
  },
};

export const ChangePageAction: ModuleAction<number, ModelBookmarks> = {
  module: MODULE_BOOKMARKS,
  process: async (page: number, module: ModelBookmarks) => {
    const curPage = page > 0 ? page : 1;
    const start = (curPage - 1) * 6;
    const pageCanvas = _.slice(module.canvasList, start, start + 6);
    const opportunityCanvas = _.slice(module.opportunityList, start, start + 6);
    return {
      canvasListByPage: pageCanvas,
      opportunityListByPage: opportunityCanvas,
      curPage: curPage,
    };
  },
};
