import React from "react";
import "./membership.less";
import { Breadcrumbs } from "@material-ui/core";
import { Button, message } from "antd";
import _ from "lodash";
import {
  getPaymentInfo,
  getPayKey,
  getClientSec,
  putPaymentInfo,
} from "../../../api/Pay";
import { getCustomized } from "../../../api/Options";
import { Link } from "react-router-dom";
import { router_membership } from "../../../utils/enum";
import CValidateForm, {
  ValidateForm,
} from "../../../components/component_validate_form";
import StripeModal from "../../../components/component_stripe_modal";
import { billingFormElement } from "../../../models/model_business_payment";

class BillingUpdate extends React.Component<any, any> {
  billingFormRef: React.RefObject<ValidateForm>;
  constructor(props: any) {
    super(props);
    this.billingFormRef = React.createRef();
  }

  state = {
    paymentInfo: null,
    stateOptions: [],
    isMobile: false,
    stripeVisible: false,
    stripeKey: "",
    clientSec: "",
    loading: false,
  };

  public componentDidMount() {
    this.getPaymentInformation();
    window.onresize = () => {
      this.setState({ isMobile: window.innerWidth < 1024 });
    };
  }

  public render() {
    let content;
    const {
      paymentInfo,
      stateOptions,
      stripeKey,
      stripeVisible,
      clientSec,
      isMobile,
      loading,
    } = this.state;

    if (!paymentInfo) {
      content = <div className="no-content-message">Loading...</div>;
    } else {
      content = (
        <section>
          <h1 className="common-title">{this.props.title}</h1>
          <CValidateForm
            wrappedComponentRef={this.billingFormRef}
            formElement={billingFormElement(isMobile, stateOptions)}
            defaultValue={paymentInfo || {}}
            disabledForm={loading}
          />
          <Button
            className="btn-primary"
            onClick={this.onFormSubmit}
            loading={loading}
            style={{ height: "51px" }}
          >
            Update Billing Information
          </Button>
          <StripeModal
            visible={stripeVisible}
            afterPay={this.updatePayment}
            onClose={() =>
              this.setState({ stripeVisible: false, loading: false })
            }
            billingDetail={paymentInfo || {}}
            stripeKey={stripeKey}
            clientSec={clientSec}
          />
        </section>
      );
    }

    return (
      <div className="membership-view">
        {this.breadcrumbs()}
        <div className="billing-update">{content}</div>
      </div>
    );
  }

  private breadcrumbs() {
    return (
      <Breadcrumbs>
        <Link to={router_membership}>Membership</Link>
        <span>{this.props.title}</span>
      </Breadcrumbs>
    );
  }

  onFormSubmit = () => {
    this.billingFormRef.current!.handleSubmit().then(async (res: any) => {
      const { stripeKey } = this.state;
      this.setState({
        loading: true,
        stripeVisible: true,
        stripeKey: stripeKey,
        paymentInfo: res,
      });
    });
  };

  updatePayment = (paymentMethod = "") => {
    this.setState({ stripeVisible: false });
    const params = {
      email: _.get(this.state, "paymentInfo.email"),
      payment_method: paymentMethod,
    };
    putPaymentInfo(params)
      .then(() => {
        this.setState({ loading: false });
        message.success("Billing Information updated successfully");
        this.props.history.push("/membership");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  private async getPaymentInformation() {
    const paymentDetail: any = (await getPaymentInfo()) || {};
    const options = (await getCustomized(true, ["state"])) || {};
    const getKey: any = (await getPayKey()) || { key: "" };
    let paymentInfo = {};
    if (paymentDetail.stripeId) {
      const { billing_details } = paymentDetail;
      paymentInfo = {
        name: _.get(billing_details, "name"),
        email: _.get(billing_details, "email"),
        address: _.get(billing_details, "address.line1"),
        city: _.get(billing_details, "address.city"),
        state: _.get(billing_details, "address.state"),
        country: _.get(billing_details, "address.country"),
        zip: _.get(billing_details, "address.postal_code"),
      };
      getClientSec().then((res: any) => {
        this.setState({
          clientSec: res.secret || "",
        });
      });
    }
    this.setState({
      stripeKey: getKey.key,
      paymentInfo,
      stateOptions: _.get(options, "state") || [],
    });
  }
}

export default BillingUpdate;
