import React from "react";
import { Modal, Icon } from "antd";
import "./component_common_loading_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  closable?: boolean;
}

const CommonLoadingModal: React.FC<Props> = (props) => {
  const { className, visible, closable = false } = props;

  return (
    <Modal
      footer={null}
      visible={visible}
      closable={closable}
      className={className}
      centered={true}
      wrapClassName="common-loading-modal-style"
    >
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Icon type="loading" className="icon" />
      </div>
    </Modal>
  );
};
export default CommonLoadingModal;
