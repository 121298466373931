import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { search } from "../../api/Options";
import { Checkbox, Input } from "antd";
import { ModuleIdName } from "../../models/model_get_started";
import "./get_start_multi_sel_view.less";
import IconRadioOff from "../../assets/images/ic_radio_off.svg";
import IconRadioOn from "../../assets/images/ic_radio_on.svg";

type Props = {
  onItemAdd: Function;
  onOtherAdd?: Function;
  onItemRemove: Function;
  data: ModuleIdName[];
  hideOther?:boolean;
  hideLicense?: boolean;
  limit?:{limit: number, now: number}

};

interface CategoryProps {
  id: number;
  name: string;
  onItemAdd: Function;
  onItemRemove: Function;
  hideLicense?: boolean;
  limit?:{limit: number, now: number}
}

interface OtherCategoryProps {
  name: string;
  setCheck: Function;
  onInput: Function;
  showBottom: boolean;
  setLicense: Function;
  limit?:{limit: number, now: number};
}

const CategoryItem: React.FC<CategoryProps> = (props) => {
  const { id, name, onItemAdd, onItemRemove, hideLicense, limit } = props;
  const [number, setNumber] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      onItemAdd({ id: id, name: name, license: number || "" });
    } else {
      onItemRemove({ name: name });
      setNumber("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, number]);

  return (
    <div
      className="box-item"
      onClick={() => {
        if(limit && limit.limit <= limit.now && !checked) {
          return
        }
        setChecked(!checked);
      }}
      key={name}
    >
      <div className="box-item-center">
        <span className={classnames("box-item-name", { isUp: checked })}>
          {name}
        </span>
        {checked && !hideLicense && (
          <Input
            className="box-item-input"
            placeholder="Input license number"
            onChange={(e) => setNumber(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
      <img className="ellipse" src={checked ? IconRadioOn : IconRadioOff}></img>
      <div className="divider" />
    </div>
  );
};

const OtherItem: React.FC<OtherCategoryProps> = (props) => {
  const { name, onInput, showBottom, setCheck, setLicense } = props;
  const [showInput, setShowInput] = useState(false);
  const [checked, setChecked] = useState(false);
  const [number, setNumber] = useState("");

  useEffect(() => {
    setLicense(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    setCheck(checked);
    setShowInput(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className="box-item-other" onClick={() => setChecked(!checked)}>
      <Checkbox
        className="box-item-check"
        checked={checked}

        // onChange={(e) => {
        //   if(limit && limit.limit >= limit.now && e.target.checked ) {
        //     return
        //   }
        //   setChecked(e.target.checked)
        // }}
      />
      <div className="box-item-other-right">
        <div className="top-sec">
          <div className="box-item-center">
            <span className={classnames("box-item-name", { isUp: showInput })}>
              Other
            </span>
            {showInput && (
              <Input
                className="box-item-input"
                placeholder="Enter category name"
                onChange={(e) => onInput(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                value={name}
              />
            )}
          </div>
        </div>
        <div className="divider-other" />
        {showBottom && (
          <div className="bottom-sec">
            <div className="box-item-center">
              <span className={classnames("box-item-name", { isUp: true })}>
                License number
              </span>
              <Input
                className="box-item-input"
                placeholder="Enter your license number"
                onChange={(e) => setNumber(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const GetStartMultiSelView: React.FC<Props> = (props) => {
  const { onItemAdd, onOtherAdd, onItemRemove, data, hideOther, hideLicense, limit } = props;
  const [otherText, setOtherText] = useState("");
  const [otherOptions, setOtherOptions] = useState([] as ModuleIdName[]);
  const [otherCheck, setOtherCheck] = useState(false);
  const [otherLicense, setOtherLicense] = useState("");
  const [showOtherBottom, setShowOtherBottom] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(otherText)) {
      searchCategories();
      setShowOtherBottom(true);
    } else {
      setOtherOptions([]);
      setShowOtherBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherText]);

  const searchCategories = async () => {
    const res = await search("category", false, "beautyPro", otherText);
    if (res && res.categories) {
      const has = _.find(res.categories, { name: otherText });
      if (!has) {
        setOtherOptions(res.categories);
      }
    }
  };

  useEffect(() => {
    if (otherCheck && onOtherAdd) {
      onOtherAdd({
        name: otherText || "Other",
        license: otherLicense,
        isOther: true,
      });
    } else {
      onItemRemove({ name: otherText || "Other" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherCheck, otherLicense, otherText]);

  return (
    <div className="category-root" onClick={() => setOtherOptions([])}>
      <div className="category">
        {_.map(data, (item, idx) => {
          return (
            <CategoryItem
              id={item.id}
              name={item.name}
              onItemAdd={onItemAdd}
              onItemRemove={onItemRemove}
              hideLicense={true}
              key={idx}
              limit={limit}
            />
          );
        })}
        {
          !hideOther &&
        <OtherItem
          onInput={(str: string) => {
            setOtherText(str);
          }}
          limit={limit}
          name={otherText}
          showBottom={showOtherBottom}
          setCheck={(checked: boolean) => {
            if (!checked) {
              setOtherText("");
              setOtherLicense("");
            }
            if(checked && limit && limit.limit <= limit.now) {
              return
            }
            setTimeout(() => {
              setOtherCheck(checked);
            });
          }}
          setLicense={(number: string) => setOtherLicense(number)}
        />
      }
      </div>
      {!_.isEmpty(otherOptions) && (
        <div className="other-options">
          {_.map(otherOptions, (item, index) => {
            return (
              <span
                className="options-item"
                key={`${item}-${index}`}
                onClick={() => {
                  setOtherText(item.name);
                  setOtherOptions([]);
                }}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GetStartMultiSelView;
