import { ModuleStore, ModuleAction, KV } from "module-reaction";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { getCanvas, updateCanvasStatus } from "../api/Canvas";
import { getUserInfo } from "../api/Auth";
import { IMediaItem } from "../components/component_media_item";
import { MediaType, CanvasType } from "../utils/enum";
import { IAward } from "../components/component_award_item";
import { ISocialMedia } from "../components/component_social_media_item";
import { IEmployment } from "../components/component_employment_item";
export const MODULE_STUDENT_CANVAS_PREVIEW = "module_student_canvas_preview";

const tempSocial = [
  {
    type: 0,
    label: "instagram",
  },
  {
    type: 1,
    label: "youtube",
  },
  {
    type: 1,
    label: "facebook",
  },
  {
    type: 0,
    label: "tiktok",
  },
];

export interface ModuleStudentCanvasPreview extends ModuleStore {
  id: number;
  slug: string;
  type: string;
  owner: number;
  //pagedata
  title: string;
  name: string;
  experience: number;
  location: any;
  willingToRelocate: boolean;
  opportunityStatus: false;
  relocateList: any[];
  aboutMe: string;
  why: string;
  logo: IMediaItem;
  featuredPhoto: IMediaItem;
  portraitPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  portfolioPhotos: IMediaItem[];
  extraVideo: IMediaItem[];
  awardList: IAward[];
  preferredBrands: any[];
  specialities: any[];
  softwares: any[];
  kpi: any;
  employment: IEmployment[];
  payment: any;
  socialMediaList: ISocialMedia[];
  educations: any[];
  categories: any[];
  emptyFields: string[];
  bookingUrl: string;
  pronouns: string;
}

export const module_student_canvas_preview: ModuleStudentCanvasPreview = {
  module: MODULE_STUDENT_CANVAS_PREVIEW,
  id: 0,
  type: CanvasType.STUDENT,
  slug: "",
  owner: 0,
  //pagedata
  title: "",
  name: "",
  pronouns: "",
  experience: -1,
  location: {
    uuid: uuidv4(),
  },
  willingToRelocate: false,
  bookingUrl: null,
  opportunityStatus: false,
  relocateList: [],
  aboutMe: "",
  why: "",
  logo: {
    image: "",
    title: "Logo",
    dimensions: "100x60",
    fileSize: 1,
    url: "",
    type: MediaType.LOGO,
  },
  featuredPhoto: {
    image: "",
    title: "Featured Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.FEATURED_PHOTO,
  },
  portraitPhoto: {
    image: "",
    title: "Bio Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.PORTRAIT_PHOTO,
  },
  featuredVideo: {
    image: "",
    title: "Featured Video",
    dimensions: "1240x800",
    fileSize: 10,
    url: "",
    type: MediaType.FEATURED_VIDEO,
  },
  portfolioPhotos: [],
  extraVideo: [],
  awardList: [],
  preferredBrands: [],
  specialities: [],
  softwares: [],
  kpi: {},
  employment: [],
  payment: {},
  socialMediaList: tempSocial,
  educations: [] as any[],
  categories: [] as any[],
  emptyFields: [] as string[],
};

export const UpdatePageInfoAction: ModuleAction<
  any,
  ModuleStudentCanvasPreview
> = {
  module: MODULE_STUDENT_CANVAS_PREVIEW,
  process: async (options: KV, module: ModuleStudentCanvasPreview) => {
    return { ...options };
  },
};

export const GetPageDataAction: ModuleAction<
  any,
  ModuleStudentCanvasPreview
> = {
  module: MODULE_STUDENT_CANVAS_PREVIEW,
  process: async (slug: string, module: ModuleStudentCanvasPreview) => {
    if (!slug) {
      return {};
    }
    const res = await getCanvas(slug);
    const auth = await getUserInfo();
    if (res) {
      let social = [...tempSocial];
      social = _.map(social, (socialItem) => {
        if (_.get(res.socialMedia, socialItem.label)) {
          return {
            ...socialItem,
            value: _.get(res.socialMedia, socialItem.label),
          };
        } else {
          return socialItem;
        }
      });
      const firstName =
        (auth &&
          auth.user &&
          auth.user.profile &&
          auth.user.profile.firstName) ||
        "";
      const lastName =
        (auth &&
          auth.user &&
          auth.user.profile &&
          auth.user.profile.lastName) ||
        "";

      const loca = res.detail.locations[0];
      let cates = [];
      if (res.type === CanvasType.STUDENT) {
        cates = _.map(res.detail.categories, (item) => {
          return {
            id: item.optionId,
            name: item.name,
          };
        });
      } else {
        cates = _.map(res.detail.categories, (item) => {
          let newItem = {
            id: item.optionId,
            name: item.name,
            licenseNumber: item.licenseNumber,
            stateId: (item.state && item.state.id) || 0,
            stateName: (item.state && item.state.name) || "",
            otherName: "",
          } as any;
          return newItem;
        });
      }
      const pageData = {
        id: res.id,
        type: res.type,
        slug: res.slug,
        owner: res.owner || 0,
        title: res.introduction.title,
        name: `${firstName} ${lastName}`,
        experience: res.introduction.yearOfExperience || -1,
        location: loca
          ? {
              uuid: loca.uuid,
              location: loca.address,
              city: loca.city,
              coordinates: loca.coordinates,
              country: loca.country,
              countryShort: loca.countryShort,
              state: loca.state,
              stateShort: loca.stateShort,
            }
          : {
              uuid: uuidv4(),
            },
        willingToRelocate: res.introduction.isRelocated || false,
        bookingUrl: res.bookingUrl,
        pronouns: res.pronouns,
        opportunityStatus: res.introduction.opportunityStatus || false,
        showOpportunityStatus: res.introduction.showOpportunityStatus || false,
        relocateList: res.introduction.relocateTo || [],
        aboutMe: res.introduction.description || "",
        why: res.introduction.reason || "",
        logo: (res.media && res.media.logo) || {
          image: "",
          title: "Logo",
          dimensions: "100x60",
          fileSize: 1,
          url: "",
          type: MediaType.LOGO,
        },
        featuredPhoto: (res.media && res.media.featuredPhoto) || {
          image: "",
          title: "Featured Photo",
          dimensions: "1440x758",
          fileSize: 5,
          url: "",
          type: MediaType.FEATURED_PHOTO,
        },
        portraitPhoto: (res.media && res.media.portraitPhoto) || {
          image: "",
          title: "Bio Photo",
          dimensions: "1440x758",
          fileSize: 5,
          url: "",
          type: MediaType.PORTRAIT_PHOTO,
        },
        featuredVideo: (res.media && res.media.featuredVideo) || {
          image: "",
          title: "Featured Video",
          dimensions: "1240x800",
          fileSize: 10,
          url: "",
          type: MediaType.FEATURED_VIDEO,
        },
        portfolioPhotos: (res.media && res.media.portfolioPhoto) || [],
        extraVideo: (res.media && res.media.additionalVideo) || [],
        awardList: res.achievements || [],
        preferredBrands:
          _.map(res.additionalInformation.preferredBrands, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }) || [],
        specialities:
          _.map(res.additionalInformation.specialities, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }) || [],
        softwares:
          _.map(res.additionalInformation.softwares, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }) || [],
        kpi: res.additionalInformation.KPI || {},
        employment:
          _.map(res.employments, (item) => {
            let position = [] as any[];
            if (item.position) {
              position = _.map(item.position.split(","), (item) => {
                return {
                  name: item,
                };
              });
            }
            let jobCategory = [] as any[];
            if (item.jobCategory) {
              jobCategory = _.map(item.jobCategory.split(","), (item) => {
                return {
                  name: item,
                };
              });
            }
            return {
              uuid: item.uuid,
              title: item.title || "",
              position: position,
              jobCategory: jobCategory,
              isCurrentWork: item.isCurrentWork,
              company: item.company,
              startDate: item.startDate,
              endDate: item.endDate,
              description: item.description || "",
            };
          }) || [],
        payment: res.additionalInformation.paymentMode || {},
        socialMediaList: social,
        educations: res.educations || [],
        categories: cates || [],
      } as any;
      return pageData;
    }
    return {};
  },
};

export const SubmitToPublishAction: ModuleAction<
  any,
  ModuleStudentCanvasPreview
> = {
  module: MODULE_STUDENT_CANVAS_PREVIEW,
  process: async (history: any, module: ModuleStudentCanvasPreview) => {
    if (!module.id) {
      return {};
    }
    const emptyFields = [] as string[];
    if (module.type === CanvasType.PROFESSIONAL) {
      if (!module.location.location) {
        emptyFields.push("Location");
      }
    }
    if (!_.isEmpty(emptyFields)) {
      return { emptyFields: emptyFields };
    } else {
      const publish = await updateCanvasStatus(module.id);
      if (publish && publish.success) {
        history.push(
          `/publish-success/${
            module.type === CanvasType.STUDENT ? "student" : "professional"
          }?id=${module.slug}`
        );
      }
    }
    return {};
  },
};
