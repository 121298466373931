import React from "react";
import { Button, Menu, Dropdown, Divider, message, Spin } from "antd";
import classnames from "classnames";
import _ from "lodash";
import DeleteCanvasModal from "../../../components/component_delete_canvas_modal";
import ConfirmModal from "../../../components/component_confirm_modal";
import IconMore from "../../../assets/images/ic_add_green.svg";
import IconView from "../../../assets/images/ic_view.svg";
import IconArrow from "../../../assets/images/ic_arrow_down.svg";
import IconEdit from "../../../assets/images/ic_edit.svg";
import IconDel from "../../../assets/images/ic_delete.svg";
import IconEmptyImg from "../../../assets/images/ic_empty_image.png";
import {
  GetUserInfoAction,
  DeleteCanvasAction,
  UpdateCanvasStateAction,
  module_my_canvas,
  ModelMyCanvases,
} from "../../../models/model_my_canvas";
import { getCanvas, revertCanvasToStudent } from "../../../api/Canvas";
import "./my_canvas.less";
import { doAction, mapProp, doFunction } from "module-reaction";
import moment from "moment";
import {
  CanvasType,
  router_upgrade_student_canvas,
  router_canvas_detail,
  router_my_dashboard,
  router_my_canvases,
  router_homepage_index,
} from "../../../utils/enum";
import { getRoleName } from "../../../components/component_employee_gridview";
import { RouteComponentProps } from "react-router";
import { MODULE_DASHBOARD_INDEX } from "../../../models/model_dashboard_index";

interface MyCanvasItemProps {
  canvas: any;
  onDelete?: Function;
  history?: any;
  onPublish?: Function;
}

const CanvasItem: React.FC<MyCanvasItemProps> = (props) => {
  const { canvas, onDelete, history, onPublish } = props;

  const cateMenu = (
    <Menu className="cate-menu">
      {_.map(canvas.categories, (item, index) => {
        return <Menu.Item key={index}>{item}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <div className="canvas-item-root">
      <div className="img-sec">
        <img
          alt=""
          className="feature-img"
          src={
            (canvas.media &&
              canvas.media.featuredPhoto &&
              canvas.media.featuredPhoto.image) ||
            IconEmptyImg
          }
        />
        <span
          className={classnames("label-active", "label-mobile", {
            active: canvas.status === "public",
          })}
        >
          {canvas.status === "private" ? "INACTIVE" : "ACTIVE"}
        </span>
      </div>

      <div className="info-sec">
        <div className="canvas-name">
          <i>{canvas.name}</i>
          <span
            className={classnames("label-active", {
              active: canvas.status === "public",
            })}
          >
            {canvas.status === "private" ? "INACTIVE" : "ACTIVE"}
          </span>
          <span className={classnames("label-canvas-type")}>{canvas.type}</span>
        </div>
        <span className="last-update">
          Last updated at {moment(canvas.updatedAt).format("HH:mm MM/DD/YYYY")}
        </span>
        <span className="space" />

        {canvas.type === "professional" && (
          <div>
            {!_.isEmpty(canvas.categories) && (
              <div className="category-sec">
                <span className="cate">CATEGORY</span>
                <Dropdown overlay={cateMenu}>
                  <span className="cate-name">
                    <span>{canvas.categories[0]}</span>
                    <img alt="" src={IconArrow} className="icon-arrow" />
                  </span>
                </Dropdown>
              </div>
            )}
            <Button
              className="btn-secondary btn-upgrade"
              onClick={(e) =>
                revertCanvasToStudent(canvas.id).then(() => {
                  history.push({ pathname: router_homepage_index });
                  history.push({ pathname: router_my_canvases });
                })
              }
            >
              <img alt="" src={IconMore} />
              Change to Student Canvas
            </Button>
          </div>
        )}

        {canvas.type === "student" && (
          <Button
            className="btn-secondary btn-upgrade"
            onClick={(e) =>
              history.push({
                pathname: router_upgrade_student_canvas,
                search: `?id=${canvas.id}`,
              })
            }
          >
            <img alt="" src={IconMore} />
            Update to Professional Canvas
          </Button>
        )}
      </div>
      <span className="space" />
      <div className="operate-sec">
        <span className="space" />
        <div className="bottom-sec">
          <span className="view-counts">{canvas.viewCount} views</span>
          <Button
            className={classnames(
              canvas.status === "private" ? "btn-primary" : "btn-secondary",
              "btn-publish"
            )}
            onClick={() =>
              onPublish && onPublish(canvas.status === "private", canvas)
            }
          >
            {canvas.status === "private" ? "Publish" : "Unpublish"}
          </Button>
        </div>
      </div>
      <div className="icon-sec">
        <img
          alt=""
          src={IconView}
          className="operate-icon"
          onClick={() => history.push(`/canvas/${canvas.slug || canvas.id}`)}
        />
        <img
          alt=""
          src={IconEdit}
          className="operate-icon"
          onClick={() =>
            history.push(`/${canvas.type}-canvas/${canvas.slug || canvas.id}`)
          }
        />
        <img
          alt=""
          src={IconDel}
          className="operate-icon"
          onClick={() => onDelete && onDelete(canvas.id)}
        />
      </div>
    </div>
  );
};
//membercanvas
const MemberCanvasItem = (props: {
  canvas: any;
  viewCanvas: Function;
  editCanvas: Function;
  viewDashboard: Function;
}) => {
  const { canvas, viewCanvas, viewDashboard, editCanvas } = props;
  return (
    <div className="canvas-item-root">
      <div className="img-sec">
        <img
          alt=""
          className="feature-img"
          src={
            (canvas.media &&
              canvas.media.featuredPhoto &&
              canvas.media.featuredPhoto.image) ||
            IconEmptyImg
          }
        />
        <span
          className={classnames("label-active", "label-mobile", {
            active: canvas.status === "public",
          })}
        >
          {canvas.status === "private" ? "INACTIVE" : "ACTIVE"}
        </span>
      </div>
      <div className="info-sec">
        <div className="canvas-name">
          <i>{canvas.name}</i>
          <span
            className={classnames("label-active", {
              active: canvas.status === "public",
            })}
          >
            {canvas.status === "private" ? "INACTIVE" : "ACTIVE"}
          </span>
        </div>
        <span className="last-update">
          Role:{getRoleName(canvas.memberRole)} - Can{" "}
          {canvas.memberRole > 1 ? "View" : "Edit"}
        </span>
        <span className="space" />
      </div>
      <div className="member-bottom-sec">
        <Button className="btn-secondary" onClick={() => viewCanvas(canvas)}>
          View {canvas.type} canvas
        </Button>
        <Button className="btn-secondary" onClick={() => editCanvas(canvas)}>
          Edit {canvas.type} canvas
        </Button>
        <Button className="btn-primary" onClick={() => viewDashboard(canvas)}>
          Go to dashboard
        </Button>
      </div>
    </div>
  );
};

@mapProp(module_my_canvas)
export class MyCanvas extends React.Component<
  ModelMyCanvases & RouteComponentProps,
  {}
> {
  state = {
    showDelete: false,
    deleteId: 0,
    unPublishId: 0,
    showUnpubConfirm: false,
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    doAction(GetUserInfoAction);
    doFunction(async () => {
      this.setState({ loading: false });
    });
  }

  onCanvasDelete = (id: number) => {
    this.setState({
      ...this.state,
      showDelete: true,
      deleteId: id,
    });
  };

  onPublish = async (isPublish: boolean, canvas: any) => {
    if (isPublish) {
      if (
        canvas.type === CanvasType.BUSINESS ||
        canvas.type === CanvasType.SCHOOL
      ) {
        const res = await getCanvas(canvas.id);
        if (res && res.payorder && res.payorder.status !== "succeeded") {
          this.props.history.push(
            (canvas.type === CanvasType.BUSINESS
              ? "/business-payment/"
              : "/school-payment/") + canvas.id
          );
          return;
        } else {
          const res = await getCanvas(canvas.id);
          let emptyFields = [];
          if (res && res.payorder && res.payorder.status !== "succeeded") {
            if (_.isEmpty(res.educations)) {
              emptyFields.push("Program or School");
            } else {
              _.forEach(res.educations, (item) => {
                if (!item.program || !item.program.name) {
                  emptyFields.push("Program");
                }
                if (!item.schoolName) {
                  emptyFields.push("School");
                }
              });
            }

            if (!_.isEmpty(emptyFields)) {
              message.error(emptyFields.join(",") + " is required");
              return false;
            }
          }
        }
      }
      doAction(UpdateCanvasStateAction, {
        isPublish: isPublish,
        id: canvas.id,
      });
    } else {
      this.setState({
        ...this.state,
        showUnpubConfirm: true,
        unPublishId: canvas.id,
      });
    }
  };

  render() {
    return this.state.loading ? (
      <Spin />
    ) : (
      <div className="my-canvases-root">
        <span className="title">My Portfolio</span>
        <div className="content">
          {_.map(this.props.canvasList, (item, index) => {
            return item.memberRole ? null : (
              <CanvasItem
                canvas={item}
                key={index}
                history={this.props.history}
                onDelete={this.onCanvasDelete}
                onPublish={this.onPublish}
              />
            );
          })}
        </div>
        <div>
          <Button
            className="btn-secondary btn-add"
            onClick={() => this.props.history.push("/add-canvas")}
          >
            <img alt="" src={IconMore} />
            Add new canvas
          </Button>
        </div>
        <DeleteCanvasModal
          visible={this.state.showDelete}
          onClose={() => this.setState({ ...this.state, showDelete: false })}
          onDelete={() => {
            this.setState({ ...this.state, showDelete: false });
            doAction(DeleteCanvasAction, this.state.deleteId);
          }}
        />
        <ConfirmModal
          visible={this.state.showUnpubConfirm}
          onClose={() =>
            this.setState({ ...this.state, showUnpubConfirm: false })
          }
          onConfirm={() => {
            this.setState({ ...this.state, showUnpubConfirm: false });
            doAction(UpdateCanvasStateAction, {
              isPublish: false,
              id: this.state.unPublishId,
            });
          }}
          confirmText="Hide Canvas"
          description="By clicking on Unpublish, you are switching this canvas to inactive mode and it will be hidden on all search functionality."
        />

        {/* my-orgnizations */}
        {this.props.canvasList.some((_) => _.memberRole) && (
          <>
            <Divider />
            <span className="title">My Organizations</span>
            <div className="content">
              {_.map(this.props.canvasList, (item, index) => {
                return item.memberRole ? (
                  <MemberCanvasItem
                    canvas={item}
                    key={index}
                    viewCanvas={this.onViewCanvas}
                    editCanvas={this.onEditCanvas}
                    viewDashboard={this.onViewMemberDashboard}
                  />
                ) : null;
              })}
            </div>
          </>
        )}
      </div>
    );
  }

  private onEditCanvas = (canvas: any) => {
    this.props.history.push(
      `/${canvas.type}-canvas/${canvas.slug || canvas.id}`
    );
  };

  private onViewCanvas = (canvas: any) => {
    this.props.history.push(
      router_canvas_detail.replace(":id", canvas.slug || canvas.id)
    );
  };
  private onViewMemberDashboard = (canvas: any) => {
    doAction(MODULE_DASHBOARD_INDEX, { curCanvas: canvas });
    doFunction(async () => this.props.history.push(router_my_dashboard));
  };
}
