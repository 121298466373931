import React, { FunctionComponent } from 'react'
import { InputBaseComponentProps } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const ContactNumberMask: FunctionComponent<InputBaseComponentProps> = props => {
    const { inputRef, ...other } = props;
    return <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  }


export default ContactNumberMask
