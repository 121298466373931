import React, { useState, useEffect } from "react";
import { Modal, Button, Icon, Checkbox } from "antd";
import Close from "../assets/images/ic_close_grey.svg";
import "./component_edit_photo_modal.less";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { getMediaInfo } from "../utils/mediaHelper";

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  onApply: Function;
  data?: any;
  showReposition?:boolean
}

const EditPhotoModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onApply, data, showReposition } = props;
  const [imageEle, setImageEle] = useState<HTMLImageElement>();
  const [cropper, setCropper] = useState<Cropper>();
  const [loading, setLoading] = useState(true);
  const [isReposition, setIsReposition] = useState(false);
  const [isCroping, setIsCroping] = useState(false);
  const [applyEnabled, setApplyEnabled] = useState(false);
  const [keepSize, setKeepSize] = useState(data.keepSize || false);


  useEffect(() => {
    if (imageEle) {
      const cropp = new Cropper(imageEle, {
        autoCropArea: 1,
        ready: () => {
          setLoading(false);
          setApplyEnabled(true)
          setFullCropBox()
        },
      });
      setCropper(cropp);
    }
  }, [imageEle]);

  useEffect(() => {
    checkHideCropperBox()
  }, [isReposition, isCroping])

  const checkHideCropperBox = () => {
    let cropperBox = document.querySelector('.cropper-crop-box')
    if (cropperBox) {
      let cropperModal = document.querySelector('.cropper-modal')
      if (isReposition || isCroping) {
        cropperBox.classList.remove('hidden')
        cropperModal!.classList.remove('hidden')
      } else {
        cropperBox.classList.add('hidden')
        cropperModal!.classList.add('hidden')
        cropper?.setDragMode('move')
      }
    } else {
      setTimeout(checkHideCropperBox, 100);
    }
  }

  const apply = (reposition: any = null) => {
    cropper!.getCroppedCanvas().toBlob((blob) => {
      const result = new File([blob!!], `cropped.png`, {
        type: "image/png",
        lastModified: Date.now(),
      });
      getMediaInfo(result).then(info => {
        onApply({ ...info, reposition, keepSize });
      })
    });

  };

  const saveReposition = () => {
    const { top, left, width, height } = cropper?.getCanvasData()!
    const { naturalWidth, naturalHeight } = cropper?.getImageData()!
    const cropBox = cropper?.getCropBoxData()!
    const reposition = {
      left: `-${((cropBox.left - left) / width * naturalWidth).toFixed(0)}px`,
      top: `-${((cropBox.top - top) / height * naturalHeight).toFixed(0)}px`,
      width: `${(cropBox.width / width * naturalWidth).toFixed(0)}px`,
      height: `${(cropBox.height / height * naturalHeight).toFixed(0)}px`,
    }
    setFullCropBox()
    apply(reposition)
  }

  const setFullCropBox = () => {
    if (cropper) {
      const {width, height} = cropper.getContainerData()
      const initialCanvasData = (cropper as any).initialCanvasData
      cropper.setCanvasData(initialCanvasData)
      cropper.setCropBoxData({width, height, left: 0, top: 0})
    }
  }
  const setHalfCropBox = () => {
    if (cropper) {
      const initialCanvasData = (cropper as any).initialCanvasData
      cropper.setCanvasData(initialCanvasData)
      cropper.setCropBoxData({width: initialCanvasData.width * 0.5, height: initialCanvasData.height * 0.5, left: initialCanvasData.width * 0.25, top: initialCanvasData.height * 0.25})
    }
  }

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      wrapClassName="modal-wrap"
      centered={true}
      maskClosable={false}
    >
      <div className="edit-photo-root">
        <div className="title-sec">
          <span className="title">Edit Photo</span>
          <img className="close" src={Close} alt="" onClick={() => onClose()} />
        </div>

        <div className="center-sec">
          <div className="image-wrap">
            <img
              alt=""
              id="image"
              src={data.image}
              onLoad={() => {
                const image = document.getElementById(
                  "image"
                ) as HTMLImageElement;
                setImageEle(image);
              }}
            />
          </div>
          {loading && <Icon type="loading" className="loading" />}
          <div className="keep-size">
            <Checkbox
              disabled={isCroping || isReposition}
              defaultChecked={keepSize}
              onChange={e => {
                setKeepSize(e.target.checked)
                // remove rotate and flip
                setFullCropBox()
                
              }}
            >Original size with padding</Checkbox>
          </div>
        </div>
        <div className="bottom-sec" hidden={isReposition || isCroping}>

          <Button
            type="link" className={`btn ${keepSize && 'disabled'}`}
            onClick={() => { 
              setHalfCropBox()
              setIsReposition(true)
             }}
            disabled={keepSize || !applyEnabled}
            hidden={!showReposition}
          >
            Reposition
            </Button>
          <Button
            type="link" className={`btn ${keepSize && 'disabled'}`}
            onClick={() => {
              setHalfCropBox()
              setIsCroping(true)
            }}
            disabled={keepSize || !applyEnabled}
          >
            Crop
            </Button>
          <Button
            type="link" className={`btn ${keepSize && 'disabled'}`}
            onClick={() => { 
              // setFullCropBox()
              cropper && cropper.rotate(90)
              setApplyEnabled(true)
             }}
            disabled={keepSize || !applyEnabled}
          >
            Rotate
            </Button>
          <Button
            type="link" className={`btn ${keepSize && 'disabled'}`}
            onClick={() => {
              if (cropper) {
                // setFullCropBox()
                const { scaleX, scaleY } = cropper.getImageData();
                if (scaleX === -1 && scaleY === -1) {
                  cropper.scale(1);
                } else {
                  cropper.scale(-1);
                }
              }
              setApplyEnabled(true)
            }}
            disabled={keepSize || !applyEnabled}
          >
            Flip
            </Button>
          <Button className="btn-apply btn-primary" disabled={!applyEnabled} onClick={e => apply()}>
            Apply
          </Button>
        </div>
        <div className="bottom-sec" hidden={!isReposition}>
          <div className="tip">Drag to reposition photo</div>
          <Button
            type="link" className="btn"
            onClick={() => { 
              setIsReposition(false)
              setFullCropBox()
             }}
          >
            Back
            </Button>
          <Button
            type="link" className="btn-primary btn-save-pos"
            onClick={saveReposition}
          >
            Save position
            </Button>
        </div>
        <div className="bottom-sec" hidden={!isCroping}>
          <div className="tip">Drag to crop photo</div>
          <Button
            type="link" className="btn"
            onClick={() => { 
              setIsCroping(false)
              setFullCropBox()
             }}
          >
            Back
            </Button>
          <Button
            type="link" className="btn-primary btn-save-pos"
            onClick={e => apply()}
          >
            Save
            </Button>
        </div>
      </div>
    </Modal>
  );
};
export default EditPhotoModal;
