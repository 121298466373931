//created by pegg on 2020-08-28
import React, { useEffect, useState } from "react";
import { Radio, RadioGroup, FormControlLabel, Button } from "@material-ui/core";

import CRTextArea from "../../components/component_cr_textarea";

import "./homepage_canvas_rights_data.less";
import { mapProp, doAction } from "module-reaction";

import {
  module_rights_data,
  ModuleRightsData,
  SendToEmail,
} from "../../models/model_canvas_rights";
import { Checkbox, message } from "antd";

import IconCheck from "../../assets/images/ic_rights_check.svg";
import ContactNumberMask from "../../components/component_contact_number_mask";

import Recaptcha from "react-recaptcha";

const HomepageCanvasRightsData: React.FC<Props> = (props: Props) => {
  const {
    option,
    first_name,
    last_name,
    address,
    email,
    phone_number,
    canvas_url,
    state_residence,
    success = false,
  } = props;

  const [checked, setChecked] = useState(true);
  const [code, setCode] = useState("");

  const handleSubmit = (row: any) => {
    let _data = {
      option,
      first_name,
      last_name,
      address,
      email,
      phone_number,
      canvas_url,
      state_residence,
      code,
    };

    let regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (!!!regEmail.test(email)) {
      message.error("please enter a valid email");
      return false;
    }
    doAction(SendToEmail, _data);
  };

  const handleRecaptcha = (e: any) => {
    setCode(e);
  };

  useEffect(() => {}, []);

  return (
    <div className="homepage-canvas-rights-data-container">
      <div className="terms-banner padding-layout">
        <div className="homepage-title-wrap">
          <div className="title">Canvas Data Rights Request Form</div>
        </div>
      </div>
      <div className="terms-content">
        {success ? (
          <div className="padding-layout success-wrapper">
            <img alt="" src={IconCheck} />
            <h3>Your request is submitted</h3>
            <p>Thank you for contactting us. We will get back to you soon.</p>
          </div>
        ) : (
          <div className="padding-layout">
            <p>
              Under the California Consumer Privacy Act of 2018 (“CCPA”), if you
              are a California resident, you have certain rights concerning your
              personal information, as outlined in our California Privacy Policy
              (“California Policy”).
            </p>

            <p>
              To make a rights request, please complete the form below. The
              information provided here will only be used to respond to your
              request.
            </p>
            <h3>Nature of Your Request </h3>
            <p>Please check one of these below options</p>
            <p className="radio-wrapper">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={option}
                onChange={(e) => {
                  doAction(module_rights_data, {
                    option: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="access_my_personal_info"
                  control={<Radio />}
                  label="Access my personal information."
                />
                <FormControlLabel
                  value="request_how_to_use"
                  control={<Radio />}
                  label="Request more information about how my personal information is collected, used and disclosed."
                />
                <FormControlLabel
                  value="delete_my_personal_info"
                  control={<Radio />}
                  label="Delete my personal information."
                />
                <FormControlLabel
                  value="out_of_sale"
                  control={<Radio />}
                  label="Opt out of the sale of my personal information."
                />
              </RadioGroup>
            </p>

            <h3>Your Contact Information</h3>
            <p style={{ marginBottom: 0 }}>
              <CRTextArea
                key="First Name"
                label="First Name"
                type="text"
                value={first_name}
                onInput={(e: string) => {
                  doAction(module_rights_data, {
                    first_name: e,
                  });
                }}
              />
              <CRTextArea
                key="LastName"
                label="Last Name"
                type="text"
                value={last_name}
                onInput={(e: string) => {
                  doAction(module_rights_data, {
                    last_name: e,
                  });
                }}
              />
              <CRTextArea
                key="Address"
                label="Address"
                type="text"
                value={address}
                onInput={(e: string) => {
                  doAction(module_rights_data, {
                    address: e,
                  });
                }}
              />

              <CRTextArea
                type="text"
                key="email"
                label="Email"
                value={email}
                onInput={(e: number) => {
                  doAction(module_rights_data, {
                    email: e,
                  });
                }}
              />
              <CRTextArea
                key="PhoneNumber"
                label="Phone Number"
                type="text"
                value={phone_number}
                onInput={(e: string) => {
                  doAction(module_rights_data, {
                    phone_number: e,
                  });
                }}
                InputProps={{ inputComponent: ContactNumberMask }}
              />
              <CRTextArea
                key="CanvasURL"
                label="Canvas URL"
                type="text"
                value={canvas_url}
                onInput={(e: string) => {
                  doAction(module_rights_data, {
                    canvas_url: e,
                  });
                }}
              />
              <CRTextArea
                key="StateOfResidence"
                label="State of residence"
                type="text"
                value={state_residence}
                onInput={(e: string) => {
                  doAction(module_rights_data, {
                    state_residence: e,
                  });
                }}
              />
            </p>

            <Recaptcha
              sitekey="6LdbAscZAAAAADyh2PO7bIdCcze4BjnGQzhVhAIX"
              verifyCallback={handleRecaptcha}
            />

            <p className="checkbox-wrapper">
              <Checkbox
                className="check-box"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <span className="check-text">
                I hereby certify under penalty of perjury I am the individual
                who is the subject of this request (or that I have authority to
                submit this request on behalf of such individual) and the
                information provided by me is accurate.
              </span>
            </p>

            <p className="btn-wrapper">
              <Button
                disabled={
                  !(
                    first_name &&
                    last_name &&
                    address &&
                    email &&
                    phone_number &&
                    canvas_url &&
                    state_residence &&
                    checked
                  )
                }
                onClick={handleSubmit}
                variant="outlined"
              >
                Submit
              </Button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

interface Props extends ModuleRightsData {}

export default mapProp(module_rights_data)(HomepageCanvasRightsData);
