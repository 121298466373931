import React, { useContext } from "react";
import { LoginWithTokenAction, modle_user } from "../../../models/model_user";
import { doAction, mapProp, doFunction } from "module-reaction";
import {
  Chat,
  Channel,
  Thread,
  ChannelList,
  Window,
  ChannelStateContext,
  ChatContext,
  LoadingChannels,
} from "stream-chat-react";
import { message, Tooltip } from "antd";
import moment from "moment";
import { chatClient, initChatClient } from "../../../utils/chatHelper";
import { checkChatToken, msgNotEmail } from "../../../api/Auth";
import { MessageList, MessageInput } from "stream-chat-react";
import ManageMemberModal from "./manage_member_modal";
import AddMemberModal from "./add_member_modal";
import ChannelNameModal from "../../../components/component_channel_name_modal";
import IconMember from "../../../assets/images/ic_member.svg";
import IconEdit from "../../../assets/images/ic_edit.svg";
import IconBack from "../../../assets/images/ic_back.svg";
import IconDel from "../../../assets/images/ic_delete.svg";
import classnames from "classnames";
import _ from "lodash";
import "stream-chat-react/dist/css/index.css";
import "./message.less";

const CRChannelList: React.FC<any> = (props) => {
  const { loading } = props;
  return (
    <div className="channel-list-wrap">
      <div className="mobile-header">Message</div>
      {loading ? <LoadingChannels /> : props.children}
    </div>
  );
};

const CRChannelHeader: React.FC<any> = (props) => {
  const { curUser, onMemberClick, onEditClick, initChannel } = props;
  const channelContext = useContext(ChannelStateContext);
  const channel = channelContext?.channel;
  const chatContext = useContext(ChatContext);
  const openMobileNav = chatContext?.openMobileNav;
  let name = "";
  let isChannel = true;
  let canEdit = false;
  if (channel) {
    const members = _.map(channel.state.members, (item) => {
      return item.user;
    });
    name = channel.data?.name || "";
    if (members.length === 2 && !name) {
      const friend = _.filter(members as any[], (item) => {
        return item.id !== `${curUser.id}`;
      })[0];
      name = friend && friend.name;
      isChannel = false;
    }
    if (channel.data && channel.data.created_by) {
      if (`${curUser.id}` === channel.data.created_by_id) {
        canEdit = true;
      }
    }

    initChannel(channel)
  }

  const hideChannel = async () => {
    if (channel) {
      await channel.hide();
      await channel.stopWatching();
      openMobileNav && openMobileNav();
    }
  };

  return (
    <div className="channel-header-wrap">
      <img
        className="icon-back"
        src={IconBack}
        alt=""
        onClick={openMobileNav}
      />
      <Tooltip title={name || ""} placement="bottom">
        {/* TODO: Add links here to go to messengers canvases */}
        <span className="title">{name}</span>
      </Tooltip>
      <div className="icon-sec">
        <img
          src={IconEdit}
          alt=""
          hidden={!isChannel || !canEdit}
          onClick={() => onEditClick && onEditClick(channel)}
        />
        <img
          src={IconMember}
          alt=""
          hidden={!isChannel}
          onClick={() => onMemberClick && onMemberClick(channel)}
        />
        <img src={IconDel} alt="" onClick={() => hideChannel()} />
      </div>
    </div>
  );
};

const CRChannelItem: React.FC<any> = (props) => {
  const {
    setActiveChannel,
    channel,
    latestMessage,
    activeChannel,
    watchers,
  } = props;
  const unreadCount = channel.countUnread();
  let avatar = props.displayImage || "";
  let lastUpdate = "";
  let name = props.displayTitle || "";
  let isActive = false;
  if (channel) {
    if (channel.data && channel.data.last_message_at) {
      lastUpdate = moment(channel.data.last_message_at).format("h:mm A");
    }
    if (activeChannel) {
      if (channel.cid === activeChannel.cid) {
        isActive = true;
      }
    }
  }

  return (
    <div
      className={classnames("channel-item", { isActive: isActive })}
      onClick={(e) => {
        setActiveChannel(channel, watchers);
      }}
    >
      <img alt="" src={avatar} />
      <div className="info-sec">
        <div className="name-wrap">
          <span className={classnames("name", { highLight: unreadCount > 0 })}>
            {name}
          </span>
          <span className="point" hidden={unreadCount === 0} />
        </div>
        <span
          className={classnames("last-msg", { highLight: unreadCount > 0 })}
        >
          {latestMessage || ""}
        </span>
      </div>
      <span className={classnames("time", { highLight: unreadCount > 0 })}>
        {lastUpdate}
      </span>
      <div className="divider" />
    </div>
  );
};

interface MessageProps {
  curUser: any;
  location: any;
}

@mapProp(modle_user)
export class Message extends React.Component<MessageProps, {}> {
  state = {
    curOption: 0,
    refresh: 0,
    curChannel: {} as any,
    showMember: false,
    showAdd: false,
    defaultChannel: "",
    showChannelModal: false,
    isMobile: window.innerWidth < 1024,
  };

  private filters = null as any;
  private sort: any = { last_updated: -1 };

  componentDidMount() {
    if (!this.props.curUser || !this.props.curUser.profile) {
      doAction(LoginWithTokenAction);
    }
    doFunction(async () => {
      if (!chatClient) {
        await initChatClient(this.props.curUser);
      }
    });
    doFunction(async () => {
      if (!this.props.curUser.chatToken) return;
      this.filters = {
        type: "messaging",
        members: { $in: [this.props.curUser.id + ""] },
      };
      this.setState({ refresh: this.state.refresh + 1 });
    });
    const routerState: any = this.props.location.state;
    if (routerState && routerState.channelId) {
      this.setState({ defaultChannel: routerState.channelId });
    }
  }

  private addMembersToCurChannel = async (members: any[]) => {
    if (this.state.curChannel) {
      const idsNumber: number[] = _.map(members, (item) => {
        return item.userId;
      });
      const checkRes = await checkChatToken(idsNumber);
      if (checkRes && checkRes.success) {
        const ids = _.map(members, (item) => {
          return `${item.userId}`;
        });
        const res = await this.state.curChannel.addMembers(ids);
        if (res && res.channel) {
          message.success("Add Members Success");
        }
      }
    }
  };

  private changeCurChannelName = async (name: string) => {
    if (name && this.state.curChannel) {
      await this.state.curChannel.update({
        name: name,
        image: `https://getstream.io/random_png/?name=${name.replace(
          " ",
          "%20"
        )}`,
      });
    }
  };

  render() {
    if (!chatClient || !this.filters) {
      return <div />;
    }
    return (
      <div className="message-root">
        <Chat client={chatClient} theme={"messaging light"}>
          <ChannelList
            filters={this.filters}
            sort={this.sort}
            List={CRChannelList}
            Preview={CRChannelItem}
            customActiveChannel={this.state.defaultChannel || ""}
            setActiveChannelOnMount={!this.state.isMobile}
          />
          <Channel>
            <Window>
              <CRChannelHeader
                initChannel={(e: any) => {
                  if (!this.state.curChannel.name) {
                    this.state.curChannel = e;
                  }
                }}
                curUser={this.props.curUser}
                onMemberClick={(channel: any) => {
                  this.setState({ showMember: true, curChannel: channel });
                }}
                onEditClick={(channel: any) => {
                  this.setState({
                    showChannelModal: true,
                    curChannel: channel,
                  });
                }}
              />
              <MessageList />
              <MessageInput
                overrideSubmitHandler={
                  (message, channelCid) => {
                    this.state.curChannel.sendMessage(message)
                    // call api to send notification email
                    this.state.curChannel.queryMembers({banned: false}).then(({members} : {members: any[]}) => {
                      msgNotEmail(members.map(m => m.user.id).filter(_ => _ != this.props.curUser.id))
                    })
                  }
                }
              />
            </Window>
            <Thread />
          </Channel>
        </Chat>
        {this.state.showMember && (
          <ManageMemberModal
            visible
            onClose={() => this.setState({ showMember: false })}
            onAdd={() => this.setState({ showMember: false, showAdd: true })}
            channel={this.state.curChannel}
            curUser={this.props.curUser}
          />
        )}
        {this.state.showAdd && (
          <AddMemberModal
            visible
            channel={this.state.curChannel}
            title={
              this.state.curChannel.data && this.state.curChannel.data.name
            }
            onClose={() => this.setState({ showAdd: false })}
            onSend={(members: any[]) => {
              this.setState({ showAdd: false });
              if (!_.isEmpty(members)) {
                this.addMembersToCurChannel(members);
              }
            }}
          />
        )}
        {this.state.showChannelModal && (
          <ChannelNameModal
            visible
            isEdit={true}
            onClose={() => this.setState({ showChannelModal: false })}
            onConfirm={(value: string) => {
              this.setState({ showChannelModal: false });
              this.changeCurChannelName(value);
            }}
          />
        )}
      </div>
    );
  }
}
