import React from "react";
import classnames from "classnames";
import IconEmploy from "../assets/images/ic_empty_employ.svg";
import IconDelete from "../assets/images/ic_delete.svg";
import IconEdit from "../assets/images/ic_edit.svg";
import "./component_employment_item.less";
import moment from "moment";

export interface IEmployment {
  uuid: string;
  title?: string;
  position?: any[];
  jobCategory?: any[];
  company?: string;
  isCurrentWork?: boolean;
  startDate?: string;
  endDate?: string;
  description?: string;
}

interface Props {
  className?: string;
  data: IEmployment;
  onEdit: Function;
  onDelete: Function;
}

const EmploymentItem: React.FC<Props> = (props) => {
  const { className = "", data, onEdit, onDelete } = props;

  const getIssueText = () => {
    const start = moment(data.startDate).format('MM/DD/YYYY');
    const end = moment(data.endDate).format("MM/DD/YYYY");
    let time = "";
    if (start === end || data.isCurrentWork) {
      time = `(${start})`;
    } else if (start && end) {
      time = `(${start} - ${end})`;
    } else {
      time = "";
    }
    // return `${data.company || ""} ${time}`;
    return time
  };

  return (
    <div className={classnames(className, "award-item")}>
      <img alt="" src={IconEmploy} className="icon-award" />
      <div className="info-sec">
        <span className="award-title ">{data.title || ""}</span>
        <span className="issue">{data.company||''}</span>
        <span className="issue">{getIssueText()}</span>
      </div>
      <img
        alt=""
        className="icon-edit"
        src={IconEdit}
        onClick={() => onEdit(data)}
      />
      <img
        alt=""
        className="icon-delete"
        src={IconDelete}
        onClick={() => onDelete(data)}
      />
    </div>
  );
};

export default EmploymentItem;
