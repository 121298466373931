import Http from "./Http";
import { Net } from "../utils/utils";

export const searchSchoolStudent = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/school/students", params)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((err) => console.error(err.message));
  });
};
export const exportStudentCsv = (params: any) => {
  const apiHost = process.env.REACT_APP_BASE_URL;
  window.open(
    Net.encodeQuery(
      `${
        apiHost?.endsWith("/") ? apiHost : apiHost + "/"
      }canvas/school/exportcsv`,
      params
    )
  );
};
export const searchSchoolEmployee = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/school/employees", params)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((err) => console.error(err.message));
  });
};

export const getCandidateStudents = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/school/candidate-students", params)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};

export const inviteCandidateStudents = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/school/invite-students", params)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};

export const getInviteSchool = (code: string) => {
  return Http.get("/canvas/school/invited", {
    params: { code: code },
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const inviteStudentsByEmail = (
  canvas_id: number,
  location: number,
  emails: any[]
) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/school/email-invite-students", {
      canvas_id: canvas_id,
      location: location,
      emails: emails,
    })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};

export const schoolRecentJobs = (data: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/school/recent-jobs", data)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};

export const employeeChangeHide = (
  schoolId: number,
  memberId: number,
  hided = true,
  business = false
) => {
  return new Promise((resolve, reject) => {
    Http.post("/canvas/school/employee-change-hide", {
      schoolId: schoolId,
      memberId: memberId,
      hided: hided,
      business: business,
    })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};
