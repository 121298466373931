import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Modal } from "antd";
import { TextField, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {
  ListTable,
  IHeadItem,
  IBodyItem,
} from "../../../components/component_list_table";

import { GoogleUtil } from "../../../utils/utils";

//getCustomized
import { getCustomized } from "../../../api/Options";
import {
  schoolList,
  getDataSchool,
  getAccessAccount,
} from "../../../api/admin";

import { router_canvas_detail } from "../../../utils/enum";

import SearchComponent from "../../homepage/SearchComponent";
import IconMessageBlack from "../../../assets/images/ic_message_black.svg";
import IconPrint from "../../../assets/images/ic_link.svg";
import IconCalendar from "../../../assets/images/ic_calendar.svg";
import ic_filter from "../../../assets/images/ic_filter.svg";
import ic_export from "../../../assets/images/ic_export.svg";
import ic_close from "../../../assets/images/ic_close_black.svg";

import "./school.less";

import { mapProp, doAction } from "module-reaction";
import { modle_user, LoginWithTokenAction } from "../../../models/model_user";
import { setUserLoginInfoAction } from "../../../models/model_home";

const radiusList: any[] = [
  {
    value: 10,
    label: "10 miles",
  },
  {
    value: 25,
    label: "25 miles",
  },
  {
    value: 30,
    label: "30 miles",
  },
  {
    value: 50,
    label: "50 miles",
  },
  {
    value: 100,
    label: "100 miles",
  },
  {
    value: 250,
    label: "250 miles",
  },
];

const accreditedList: any[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const classList: any[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "dayAndNight",
    label: "Both",
  },
  {
    value: "day",
    label: "Day",
  },
  {
    value: "night",
    label: "Night",
  },
];

const statusList: any[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "public",
    label: "Active",
  },
  {
    value: "private",
    label: "Inactive",
  },
];

const School: React.FC<Props> = (props: Props) => {
  const { history } = props;
  const tHeadRender: IHeadItem[] = [
    { name: "Name", isSort: true, key: "name" },
    { name: "Email" },
    { name: "Program" },
    { name: "Created", isSort: true, key: "createdAt" },
    { name: "No of Students" },
    { name: "No of Allumni" },
    { name: "Student Canvas View" },
    { name: "Hired Students" },
    { name: "Time from Grad to Hired" },
    { name: "Status" },
    { name: "Actions" },
  ];

  const tBodyRender: IBodyItem[] = [
    {
      key: "name",
    },
    { name: "Email", key: "email" },
    { name: "Program", key: "program" },
    { name: "Created", key: "createdAt", type: "date" },
    { name: "No of Students", key: "unGraduationCount" },
    { name: "No of Allumni", key: "graduationCount" },
    { name: "Student Canvas View", key: "studetCanvasView" },
    { name: "Hired Students", key: "hiredStudents" },
    { name: "Time from Grad to Hired", key: "timeFromGradHiredStudents" },
    {
      name: "Status",
      key: "status",
      className: "status-cell",
      component: (row: any) => {
        if (row?.status === "public") {
          return <span>active</span>;
        } else {
          return <span className="active">inactive</span>;
        }
      },
    },
    {
      type: "dropdown-actions",
      dropdownList: [
        {
          icon: IconPrint,
          name: "Access school",
          onClick: (item: any, index: number) => changeAuthority(item),
        },
        {
          icon: IconMessageBlack,
          name: "View school canvas",
          onClick: (item: any, index: number) =>
            history.push(router_canvas_detail.replace(":id", item.slug)),
        },
      ],
    },
  ];

  const size = 8;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [dataList, setDatalist] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectList, setSelectList] = useState([] as any);
  const [orderBy, setOrderBy] = useState("");
  const [options, setOptions] = useState();
  const [dataItem, setDataItem] = useState({
    numberOfAllumni: 0,
    numberOfStudents: 0,
    schoolTotal: 0,
    timeFromGradToHired: 0,
    totalHired: 0,
  });
  const initSearch = {
    keywords: null,
    radius: 30,
    supportedProgram: 0,
    software: 0,
    isAccredited: "all",
    classType: "all",
    benefits: 0,
    charity: 0,
    specialty: 0,
    backBar: 0,
    retail: 0,
    startDate: null,
    endDate: null,
    status: "all",
  }
  const [search, setSearch] = useState<any>({ ...initSearch });
  const list = (page = 1) => {
    const {
      keywords,
      addressInfo,
      radius,
      location,
      isAccredited,
      classType,
      status,
      ...others
    } = search;
    let _data = {
      keyword: keywords,
      location: addressInfo
        ? {
          radius,
          coordinates: addressInfo?.coordinates || null,
        }
        : null,
      page,
      size,
      classType: classType === "all" ? null : classType,
      isAccredited: isAccredited === "all" ? null : isAccredited,
      sortBy: orderBy || null,
      status: status === "all" ? null : status,
      ...others,
    };
    setLoading(true);
    schoolList(_data).then((data) => {
      setDatalist(data.rows);
      setTotal(data.total);
      setLoading(false);
    });
    getData(_data);
  };

  const getData = (_data: any) => {
    getDataSchool("all", _data).then((data) => {
      setDataItem(data);
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(1);
    list(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    list(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy]);

  useEffect(() => {
    let _data = {
      kind: "official",
      types: [
        "program",
        "brand",
        "software",
        "speciality",
        "companyBenefit",
        "charity",
      ],
    };
    getCustomized(true, _data.types).then((data) => {
      setOptions(data);
    });
  }, []);

  //link normal account - access account
  const changeAuthority = (item: any) => {
    let _data = {
      id: item.userId,
    };
    getAccessAccount(_data).then((data: any) => {
      sessionStorage.setItem('canvas:local:adminpath', props.location.pathname)

      doAction(LoginWithTokenAction);
      doAction(setUserLoginInfoAction, { res: data, history: props.history });
    });
  };

  //dialog-search
  const [dialog, setDialog] = useState(false);
  const handleShow = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };

  const resetData = () => {
    setSearch({ ...initSearch })
  }

  //export
  const handleExport = () => {
    const {
      keywords,
      addressInfo,
      radius,
      location,
      isAccredited,
      classType,
      ...others
    } = search;
    let _data = {
      keyword: keywords,
      location: addressInfo
        ? {
          radius,
          coordinates: addressInfo?.coordinates || null,
        }
        : null,
      page,
      size,
      classType: classType === "all" ? null : classType,
      isAccredited: isAccredited === "all" ? null : isAccredited,
      sortBy: orderBy || null,
      ...others,
    };

    let str = Object.keys(_data)
      .filter((item: any) => _data[item])
      .map(
        (prev: any) =>
          prev +
          "=" +
          encodeURIComponent(
            (typeof _data[prev] === "object" && JSON.stringify(_data[prev])) ||
            _data[prev]
          )
      )
      .join("&");
    window.open(
      decodeURIComponent(
        process.env.REACT_APP_BASE_URL + "/admin/school/export-to-csv?" + str
      )
    );
  };

  return (
    <div className="admin-school-container">
      <div className="title">
        School Dashboard
        <span className="title-btn"></span>
        <Button className="item filter-btn" onClick={handleExport}>
          <img src={ic_export} alt="filter" />
              Export csv
        </Button>
      </div>
      <div className="list-wrapper">
        <div className="search-item">
          <SearchComponent
            placeholder="Search by Name"
            value={{
              location: search.location,
              keywords: search.keywords,
            }}
            autoCompleteService={GoogleUtil.googleAutoCompleteService}
            onChange={(val: any) => {
              setSearch({
                ...search,
                ...val,
              });
            }}
          />
          <TextField
            label="Status"
            variant="filled"
            select
            className="material-input input ml-24 redius-wrapper"
            value={search.status}
            onChange={(e) =>
              setSearch({
                ...search,
                status: e.target.value,
              })
            }
          >
            {statusList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Radius"
            variant="filled"
            select
            className="material-input input ml-24 redius-wrapper"
            value={search.radius}
            onChange={(e) =>
              setSearch({
                ...search,
                radius: e.target.value,
              })
            }
          >
            {radiusList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="search-item mt-16">
          <div className="date-sec">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/YY"
                label="Start Date"
                className="material-date-picker date-input"
                views={["year", "month", "date"]}
                value={search.startDate}
                onChange={(value) => {
                  setSearch({
                    ...search,
                    startDate: value?.format("MM/DD/YY"),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
            <img alt="" src={IconCalendar} />
          </div>
          <div className="date-sec ml-24">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="MM/DD/YY"
                label="End Date"
                className="material-date-picker date-input"
                views={["year", "month", "date"]}
                value={search.endDate}
                onChange={(value) => {
                  setSearch({
                    ...search,
                    endDate: value?.format("MM/DD/YY"),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
            <img alt="" src={IconCalendar} />
          </div>
          <div className="btn-item ml-24">
            <Button className="item filter-btn" onClick={handleShow}>
              <img src={ic_filter} alt="filter" />
              More Filters
            </Button>

            <Button
              className="item filter-btn clear-btn"
              onClick={resetData}
            >
              <img src={ic_close} alt="filter" />
                CLEAR SEARCH
              </Button>
          </div>
        </div>
        <div className="data-item">
          <span>
            <i>Number of schools</i>
            {dataItem.schoolTotal}
          </span>
          <span>
            <i>Number of students</i>
            {dataItem.numberOfStudents}
          </span>
          <span>
            <i>Number of Allumni</i>
            {dataItem.numberOfAllumni}
          </span>
          <span>
            <i>Total Hired</i>
            {dataItem.totalHired}
          </span>
          <span>
            <i>Time from grad to hired</i>
            {dataItem.timeFromGradToHired}
          </span>
        </div>
        <div className="list-item">
          <ListTable
            tHeadRender={tHeadRender}
            tBodyRender={tBodyRender}
            loading={loading}
            hasSelect={false}
            idKey="id"
            dataList={dataList}
            selectList={selectList}
            selectOnChange={setSelectList}
            total={total}
            size={8}
            page={page}
            pageOnChange={handleChangePage}
            orderBy={orderBy}
            orderByOnChange={(value: string) => setOrderBy(value)}
          />
        </div>
      </div>
      <FilterModal
        classes="common-filter"
        visible={dialog}
        onClose={handleDialogClose}
        options={options}
        searchValue={search}
        onChange={(e: any) => {
          setSearch({
            ...search,
            ...e,
          });
        }}
      ></FilterModal>
    </div>
  );
};

interface Props extends RouteComponentProps {
  history: any;
  curUser: any;
}

const FilterModal: React.FC<ModalProps> = (props: ModalProps) => {
  const { visible, classes, onClose, options, onChange, searchValue } = props;

  const [filter, setFilter] = useState<any>({
    supportedProgram: 0,
    software: 0,
    isAccredited: "all",
    classType: "dayAndNight",
    benefits: 0,
    charity: 0,
    specialty: 0,
    backBar: 0,
    retail: 0,
  });

  useEffect(() => {
    if (visible) {
      const {
        supportedProgram,
        software,
        isAccredited,
        classType,
        benefits,
        charity,
        specialty,
        backBar,
        retail,
      } = searchValue;
      setFilter({
        ...filter,
        supportedProgram,
        software,
        isAccredited,
        classType,
        benefits,
        charity,
        specialty,
        backBar,
        retail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={classes}
      centered={true}
      width={760}
    >
      <div className="modal-content">
        <p>More filter</p>
        <div className="filter-field">
          <TextField
            label="Supported Program"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.supportedProgram}
            onChange={(e) =>
              setFilter({
                ...filter,
                supportedProgram: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.program.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Accredited"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.isAccredited}
            onChange={(e) =>
              setFilter({
                ...filter,
                isAccredited: e.target.value,
              })
            }
          >
            {accreditedList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Day/Night class"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.classType}
            onChange={(e) =>
              setFilter({
                ...filter,
                classType: e.target.value,
              })
            }
          >
            {classList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="filter-field">
          <TextField
            label="Retail Brand"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.retail}
            onChange={(e) =>
              setFilter({
                ...filter,
                retail: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.brand.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="backbar Brand"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.backBar}
            onChange={(e) =>
              setFilter({
                ...filter,
                backBar: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.brand.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Software"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.software}
            onChange={(e) =>
              setFilter({
                ...filter,
                software: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.software.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="filter-field">
          <TextField
            label="Specialty"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.specialty}
            onChange={(e) =>
              setFilter({
                ...filter,
                specialty: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.speciality.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Benefits"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.benefits}
            onChange={(e) =>
              setFilter({
                ...filter,
                benefits: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.companyBenefit?.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Charity"
            variant="filled"
            select
            className="material-input input ml-24"
            value={filter.charity}
            onChange={(e) =>
              setFilter({
                ...filter,
                charity: e.target.value,
              })
            }
          >
            <MenuItem value={0}>All</MenuItem>
            {options?.charity.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Button
          className="btn-primary btn-add"
          onClick={() => {
            onChange(filter);
            onClose();
          }}
        >
          Apply filter
        </Button>
      </div>
    </Modal>
  );
};
interface ModalProps {
  visible: boolean;
  options: any;
  searchValue: any;
  classes?: string;
  onClose: any;
  onChange: any;
}

export default mapProp(modle_user)(School);
