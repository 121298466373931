import { ModuleAction, KV } from "module-reaction";
import {
  ModuleEducationInfo,
  module_education_info,
} from "./model_education_info";
import { createCanvas, getCanvas, editCanvas } from "../api/Canvas";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";
import { getCustomized } from "../api/Options";
import { CanvasType } from "../utils/enum";
export const MODULE_PROFESSIONCAL_INFO = "module_professional_info";

export interface ModuleProfessionalInfo extends ModuleEducationInfo {
  professionalList: IProfessioncalItem[];
  categoryList: any[];
  stateIssueList: any[];
}

export interface IProfessioncalItem {
  id: number;
  name?: string;
  licenseNumber?: string;
  stateId?: number;
  otherName?: string;
}

export const professionalItem: IProfessioncalItem = {
  id: -1,
  name: "",
  licenseNumber: "",
  stateId: 0,
  otherName: "",
};

export const module_professional_info: ModuleProfessionalInfo = {
  ...module_education_info,
  module: MODULE_PROFESSIONCAL_INFO,
  professionalList: [professionalItem],
  categoryList: [],
  stateIssueList: [],
};

export const UpdateBaseInfoAction: ModuleAction<KV, ModuleProfessionalInfo> = {
  module: MODULE_PROFESSIONCAL_INFO,
  process: async (info: KV) => {
    return { ...info };
  },
};

export const InitDataAction: ModuleAction<any, ModuleProfessionalInfo> = {
  module: MODULE_PROFESSIONCAL_INFO,
  process: async (slug: string) => {
    const res = await getCustomized(true, ["category", "state", "program"]);
    if (slug && res) {
      const canvasInfo = await getCanvas(slug);
      if (canvasInfo) {
        let pageData = {
          categoryList: res.category.professional.concat({
            id: 0,
            name: "Other",
          }),
          programOptions: res.program,
          stateIssueList: res.state,
          id: canvasInfo.id,
          slug: slug,
          educationList: _.map(canvasInfo.educations, (item) => {
            let newItem = {
              workPermit: item.workPermitHour || "",
              attendance: item.attendance || "",
              gpa: item.gpa || "",
              graduationDate: item.graduationDate,
            } as any;
            if (item.program && item.program.name && item.program.optionId) {
              newItem = {
                ...newItem,
                program: {
                  id: item.program.optionId,
                  name: item.program.name,
                },
              };
            }
            if ((item.schoolId || item.schoolOptionId) && item.schoolName) {
              newItem = {
                ...newItem,
                school: {
                  id: item.schoolId || item.schoolOptionId,
                  name: item.schoolName,
                },
              };
            }
            return newItem;
          }),
          professionalList: _.map(canvasInfo.detail.categories, (item) => {
            let newItem = {
              id: item.optionId,
              name: item.name,
              licenseNumber: item.licenseNumber,
              stateId: (item.state && item.state.id) || 0,
              otherName: "",
            } as any;
            if (!_.isEmpty(res.category.professional)) {
              const curCate = _.find(res.category.professional, {
                id: item.optionId,
              });
              if (!curCate) {
                newItem = {
                  id: 0,
                  name: "Other",
                  licenseNumber: item.licenseNumber,
                  stateId: (item.state && item.state.id) || 0,
                  otherName: item.name,
                };
              }
            }
            return newItem;
          }),
          oldAwards: canvasInfo.achievements || [],
        } as any;
        return pageData;
      }
      return {
        categoryList: res.category.professional.concat({
          id: 0,
          name: "Other",
        }),
        programOptions: res.program,
        stateIssueList: res.state,
        slug: slug,
      };
    }
    return {
      categoryList: res.category.professional.concat({
        id: 0,
        name: "Other",
      }),
      programOptions: res.program,
      stateIssueList: res.state,
    };
  },
};

export const GetSelectionsAction: ModuleAction<any, ModuleProfessionalInfo> = {
  module: MODULE_PROFESSIONCAL_INFO,
  process: async () => {
    const res = await getCustomized(true, ["category", "state", "program"]);
    if (res) {
      return {
        categoryList: res.category.professional.concat({
          id: 0,
          name: "Other",
        }),
        programOptions: res.program,
        stateIssueList: res.state,
      };
    }
    return {};
  },
};
export const SubmitAction: ModuleAction<any, ModuleProfessionalInfo> = {
  module: MODULE_PROFESSIONCAL_INFO,
  process: async (history: any, module: ModuleProfessionalInfo) => {
    const request = convertToRequestData(module);
    let createRes;
    if (module.slug) {
      createRes = await editCanvas(module.id, request);
    } else {
      createRes = await createCanvas(CanvasType.PROFESSIONAL, request);
    }
    if (createRes && createRes.slug) {
      history.push(`/professional-canvas/${createRes.slug}`);
    }
    return {};
  },
};

const convertToRequestData = (module: ModuleProfessionalInfo) => {
  const educations = _.map(module.educationList, (item) => {
    let newProgram = {
      name: item.program.name,
    } as any;
    if (item.program.id) {
      newProgram = {
        ...newProgram,
        optionId: item.program.id,
      };
    }
    let newEdu = {
      uuid: uuidv4(),
      schoolName: item.school.name || "",
      program: newProgram,
      graduationDate: item.graduationDate
        ? moment(item.graduationDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    } as any;
    if (item.gpa) {
      newEdu = {
        ...newEdu,
        gpa: Number(item.gpa),
      };
    }
    if (item.attendance) {
      newEdu = {
        ...newEdu,
        attendance: Number(item.attendance),
      };
    }
    if (item.workPermit) {
      newEdu = {
        ...newEdu,
        workPermitHour: Number(item.workPermit),
      };
    }

    if (item.school.id) {
      newEdu = {
        ...newEdu,
        schoolId: item.school.id,
      };
    }
    return newEdu;
  });

  const awards = [
    ...module.deanList,
    ...module.honoredAndAwards,
    ...module.caper,
    ...module.beacon,
    ...module.nahaSubmission,
    ...module.scholarshipRecipient,
    ...module.club,
  ];
  const mappedAwards = _.map(awards, (item) => {
    return {
      uuid: uuidv4(),
      title: item.title,
      issuer: item.issuer,
      issueYear: moment(item.year).get("year").toString(),
    };
  });
  const categories = _.filter(module.professionalList, (item) => {
    return item.id !== -1;
  });
  const request = {
    educations: educations,
    detail: {
      categories: _.map(categories, (item) => {
        let newItem = {
          name: item.name === "Other" ? item.otherName : item.name,
        } as any;
        if (item.id > 0) {
          newItem = {
            ...newItem,
            optionId: item.id,
          };
        }
        if (item.licenseNumber) {
          newItem = {
            ...newItem,
            licenseNumber: item.licenseNumber,
          };
        }
        if (item.stateId) {
          newItem = {
            ...newItem,
            stateId: item.stateId,
          };
        }
        return newItem;
      }),
    },
    achievements: _.isEmpty(mappedAwards) ? module.oldAwards : mappedAwards,
  };

  return request;
};
