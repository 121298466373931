import React, { useEffect } from "react";

interface Props {
  vidSrc: string;
}

const VimeoPlayer: React.FC<Props> = (props) => {
  const vidSrc = props.vidSrc;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <iframe
      src={vidSrc}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

export default VimeoPlayer;
