import React from "react";
import { Modal, Button } from "antd";
import _ from "lodash";
import "./component_field_require_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  onEdit?: Function;
  data?: any;
}

const FieldRequireModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onEdit, data } = props;

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
    >
      <div className="field-require-root">
        <span className="title">The following fields are required:</span>
        {_.map(data, (field, index) => {
          return (
            <span key={index} className="field-item">
              {field}
            </span>
          );
        })}
        <div className="btn-sec">
          <Button className="btn-secondary btn-close" onClick={() => onClose()}>
            Got it
          </Button>
          {onEdit && (
            <Button className="btn-primary btn-edit" onClick={() => onEdit()}>
              Back to edit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default FieldRequireModal;
