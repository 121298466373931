import React, { useState } from "react";
import { Modal, Button } from "antd";
import { TextField } from "@material-ui/core";
import Close from "../assets/images/ic_close.svg";
import "./component_channel_name_modal.less";

interface Props {
  visible?: boolean;
  onClose: Function;
  onConfirm: Function;
  count?: Number;
  isEdit?: boolean;
}

const ChannelNameModal: React.FC<Props> = (props) => {
  const { visible, onClose, onConfirm, count, isEdit = false } = props;
  const [value, setValue] = useState("");

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      centered={true}
      wrapClassName="channel-name-modal-wrap"
    >
      <div className="channel-name-modal-root">
        <div className="title-sec">
          <span className="title">
            {isEdit ? "Group name" : "Creating a group"}
          </span>
          <img className="close" src={Close} alt="" onClick={() => onClose()} />
        </div>
        <div className="content">
          <span className="desc" hidden={isEdit}>
            You are creating a group of {count || ""} members. Please assign a
            name to this group and continue. You can always add and remove
            member later in the Message section.
          </span>
          <TextField
            label="Group Name"
            variant="filled"
            value={value}
            className="material-input input"
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <div className="btn-sec">
            <Button
              className="btn-primary btn-confirm"
              onClick={() => onConfirm(value)}
              disabled={!value}
            >
              {isEdit ? "Save" : "Create group"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ChannelNameModal;
