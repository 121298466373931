import React from "react";
import { TextField } from "@material-ui/core";
import { isPasswordValid } from "../../utils/validator";
import { changePassword } from "../../api/Auth";
import Logo from "../../assets/images/logo.svg";
import classnames from "classnames";
import { Button } from "antd";
import { Layout } from "antd";
import "./change_password.less";
import Joi from "@hapi/joi";

const { Content, Header } = Layout;

export default class ChangePassword extends React.Component<{ history: any }, {}> {
  state = {
    newPassword: "",
    confirm: "",
  };
  private checkNewPw = () => {
    const { newPassword, confirm } = this.state;
    let isPwdValid: boolean, isConfirmValid: boolean;
    if (newPassword) {
      isPwdValid = isPasswordValid(newPassword);
    } else {
      isPwdValid = true;
    }
    if (confirm) {
      isConfirmValid = confirm === newPassword;
    } else {
      isConfirmValid = true;
    }
    return { isPwdValid, isConfirmValid };
  };

  private resetPassord = async () => {
    const result = await changePassword(this.state.newPassword);
    if (result && result.success) {
      this.props.history.push("/settings");
    }
  };

  passwordSchema = Joi.object({
    oneLower: Joi.string().pattern(/[a-z]/),
    oneUpper: Joi.string().pattern(/[A-Z]/),
    oneNumber: Joi.string().pattern(/[0-9]/),
    oneSpecial: Joi.string().pattern(/[!-\/:-@[-`{-~]/),
    minChar: Joi.string().min(8),
  });

  isPasswordValid = (password: string) => {
      const result = this.passwordSchema.validate({ [password]: this.state.newPassword });
      return result.error  ? false : true;
    };

  render() {
    return (
      <Layout className="change-pw-root">
        <Header className="change-pw-header">
          <img src={Logo} alt="" />
        </Header>
        <Content className="change-pw-wrap">
          <div className="change-pw">
            <div className="title">Change password</div>
            <div className="tips">
              Enter your new password and confirm one more time to complete the
              reset.{" "}
            </div>

            <TextField
              key="newPassword"
              label="New password"
              variant="filled"
              className={classnames("material-input input", {
                showError: !this.checkNewPw().isPwdValid,
              })}
              placeholder="Enter new password"
              error={!this.checkNewPw().isPwdValid}
              // helperText={
              //   this.checkNewPw().isPwdValid
              //     ? ""
              //     : "Password must be more than 8 characters long, should contain at leat 1 uppercase, 1 lowercase, 1 numeric and 1 special character."
              // }
              onChange={(e) => this.setState({ newPassword: e.target.value })}
              type="password"
            />
            {this.state.newPassword !== '' && (<>
              <div className="pw-criteria-shell">
                <div className="pw-criteria">
                    <span
                    className={classnames(
                        "pw-criteria-item",
                        this.isPasswordValid("oneLower") ? "match" : "no-match"
                    )}
                    >
                    {this.isPasswordValid("oneLower") ? <>✅</> : <>❌</>}one
                    lowercase character
                    </span>
                    <span
                    className={classnames(
                        "pw-criteria-item",
                        this.isPasswordValid("oneUpper") ? "match" : "no-match"
                    )}
                    >
                    {this.isPasswordValid("oneUpper") ? <>✅</> : <>❌</>}one
                    uppercase character
                    </span>
                    <span
                    className={classnames(
                        "pw-criteria-item",
                        this.isPasswordValid("oneNumber") ? "match" : "no-match"
                    )}
                    >
                    {this.isPasswordValid("oneNumber") ? <>✅</> : <>❌</>}one number
                    </span>
                </div>
                <div className="pw-criteria">
                <span
                    className={classnames(
                        "pw-criteria-item",
                        this.isPasswordValid("oneSpecial") ? "match" : "no-match"
                    )}
                    >
                    {this.isPasswordValid("oneSpecial") ? <>✅</> : <>❌</>}one
                    special character
                    </span>
                    <span
                    className={classnames(
                        "pw-criteria-item",
                        this.isPasswordValid("minChar") ? "match" : "no-match"
                    )}
                    >
                    {this.isPasswordValid("minChar") ? <>✅</> : <>❌</>}8 character
                    minimum
                    </span>
                </div>
              </div>
            </>)}
            <TextField
              key="confirm"
              label="Confirm new password"
              variant="filled"
              className={classnames("material-input input", {
                showError: !this.checkNewPw().isConfirmValid,
              })}
              placeholder="Enter confirm password"
              error={!this.checkNewPw().isConfirmValid}
              // helperText={
              //   this.checkNewPw().isConfirmValid || !this.state.confirm
              //     ? ""
              //     : "Different from the new password"
              // }
              onChange={(e) => this.setState({ confirm: e.target.value })}
              type="password"
            />
            {this.state.confirm !== '' && (<>
              <div className="pw-criteria">
                <span
                className={classnames(
                    "pw-criteria-item",
                    this.checkNewPw().isConfirmValid ? "match" : "no-match"
                )}
                >
                {this.checkNewPw().isConfirmValid ? <>✅Passwords Match</> : <>❌Passwords Do Not Match</>}
                </span>
              </div>
            </>)}
            <Button
              className="btn-primary btn-continue btn-sign-up"
              disabled={
                !this.checkNewPw().isPwdValid ||
                !this.state.newPassword ||
                !this.checkNewPw().isConfirmValid ||
                !this.state.confirm
              }
              onClick={() => this.resetPassord()}
            >
              Change Password
            </Button>
          </div>
        </Content>
      </Layout>
    );
  }
}
