import React from "react";
import { Input, Button, Icon } from "antd";
import _ from "lodash";
import classnames from "classnames";
import { MediaType } from "../utils/enum";
import queryString from "query-string";
import FileUploader from "./component_file_uploader";
import IconUpload from "../assets/images/ic_upload.svg";
import IconUploadGreen from "../assets/images/ic_upload_green.svg";
import IconEdit from "../assets/images/ic_edit_photo.svg";
import IconDelete from "../assets/images/ic_delete.svg";
import IconImagePlaceHolder from "../assets/images/ic_no_img.png";
import IconVideoPlaceHolder from "../assets/images/ic_no_video.png";
import "./component_media_item.less";
import { getMediaInfo } from "../utils/mediaHelper";

export interface IMediaItem {
  image: string;
  video?: string;
  title: string;
  dimensions: string;
  fileSize: number;
  url?: string;
  type: MediaType;
  keepSize?: boolean;
  reposition?: object;
  order?: number;
}

interface Props {
  className?: string;
  data: IMediaItem;
  isShowUploadBtn?: boolean;
  onUpload?: Function;
  onUrlInput?: Function;
  onDelete: Function;
  onEdit?: Function;
  showLoading?: boolean;
  showYoutubeLink?: boolean;
  char?: string;
}

const MediaItem: React.FC<Props> = (props) => {
  const {
    className = "",
    data,
    isShowUploadBtn = false,
    onUpload,
    onUrlInput,
    onDelete,
    onEdit,
    showLoading = false,
    showYoutubeLink = true,
    char = "",
  } = props;

  const isVideo =
    data.type === MediaType.FEATURED_VIDEO ||
    data.type === MediaType.ADDITIONAL;

  const getFormatText = () => {
    if (data.video) {
      const format = data.video.split(".");
      return _.last(format)?.toUpperCase();
    } else if (data.image) {
      const format = data.image.split(".");
      return _.last(format)?.toUpperCase();
    }
    return isVideo ? "MP4,MOV" : "JPG,PNG";
  };

  const getImageSource = () => {
    if (data.url) {
      const query = data.url.split("?");
      let queryStr = "";
      if (query.length > 0) {
        queryStr = query[1];
      }
      const { v } = queryString.parse(queryStr);
      if (v) {
        return `https://img.youtube.com/vi/${v}/0.jpg`;
      } else {
        return IconVideoPlaceHolder;
      }
    } else {
      return (
        data.image || (isVideo ? IconVideoPlaceHolder : IconImagePlaceHolder)
      );
    }
  };

  return (
    <div className={classnames(className, "media-item")}>
      <div className="image-sec">
        <img
          alt=""
          src={getImageSource()}
          className="icon-image"
          hidden={isVideo && !data.image && !_.isEmpty(data.video)}
        />
        <span
          className="video-default-thumb"
          hidden={!isVideo || !_.isEmpty(data.image) || _.isEmpty(data.video)}
        >
          {char}
        </span>
        {showLoading && (
          <Icon
            type="loading"
            className="loading"
            style={{ color: "#2D2E3D" }}
          />
        )}
      </div>

      <div className="info-sec">
        <span className="media-title ">{data.title}</span>
        {!data.url && (
          <>
            <span className="text">Format: {getFormatText()}</span>
            <span className="text">
              {data.image
                ? `Dimensions:${data.dimensions}`
                : `Dimensions:Min ${data.dimensions}`}
            </span>
            {/* <span className="text">
              File size: {data.image ? "" : "Max"} {data.fileSize}mb
            </span> */}
          </>
        )}
        {data.type === MediaType.FEATURED_VIDEO && showYoutubeLink && (
          <Input
            className="input"
            placeholder="Or insert Youtube link here"
            value={data.url || ""}
            onChange={(e) => {
              onUrlInput && onUrlInput(e.target.value);
            }}
          />
        )}
      </div>
      <div className="space" />
      {!isVideo && data.image && (
        <img
          alt=""
          src={IconEdit}
          className="icon-edit"
          onClick={() => onEdit && onEdit(data)}
        />
      )}

      {data.image || data.video || data.url ? (
        <img
          alt=""
          className="icon-upload"
          src={IconDelete}
          onClick={() => onDelete()}
        />
      ) : (
        <FileUploader
          isVideo={isVideo}
          onPost={(file: any) => {
            if (onUpload) {
              data.video || data.type.match(/video/ig)? onUpload(file) : getMediaInfo(file).then(info => onUpload(info))
            }
          }}
        >
          {isShowUploadBtn ? (
            <div className="btn-upload-wrap">
              <Button className="btn-secondary btn-upload">
                <img alt="" src={IconUploadGreen} />
                Upload
              </Button>
              <img alt="" src={IconUploadGreen} className="icon-upload" />
            </div>
          ) : (
            <img alt="" className="icon-upload" src={IconUpload} />
          )}
        </FileUploader>
      )}
    </div>
  );
};

export default MediaItem;
