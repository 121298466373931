import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import {
  Button,
  Input,
  Avatar,
  Modal,
  message,
  Menu,
  Dropdown,
  Icon,
} from "antd";
import classnames from "classnames";
import _, { capitalize } from "lodash";
import {
  ListTable,
  IHeadItem,
  IBodyItem,
} from "../../../components/component_list_table";
import ConfirmModal from "../../../components/component_confirm_modal";
import ChannelNameModal from "../../../components/component_channel_name_modal";
import CommonLoadingModal from "../../../components/component_common_loading_modal";
import { getCanvas } from "../../../api/Canvas";
import { searchSchoolStudent, exportStudentCsv } from "../../../api/School";
import { sendMsgEmail } from "../../../api/Common";
import IconAdd from "../../../assets/images/ic_add_green.svg";
import IconExport from "../../../assets/images/ic_export.svg";
import IconDel from "../../../assets/images/ic_delete_orange.svg";
import IconMessage from "../../../assets/images/ic_message_green.svg";
import IconNoStudent from "../../../assets/images/ic_no_student.svg";
import IconMessageBlack from "../../../assets/images/ic_message_black.svg";
import IconPrint from "../../../assets/images/ic_print.svg";
import IconLink from "../../../assets/images/ic_link.svg";
import IconSuccess from "../../../assets/images/ic_check.svg";
import IconView from "../../../assets/images/ic_view.svg";
import IconNotify from "../../../assets/images/ic_notify_black.svg";
import IconGroupChat from "../../../assets/images/ic_group_msg.svg";
import IconBlastChat from "../../../assets/images/ic_blast_msg.svg";
import { copyToClipboard } from "../../../utils/utils";
import { router_message } from "../../../utils/enum";
import {
  createChat,
  chatClient,
  createChannel,
  createBlastChat,
} from "../../../utils/chatHelper";
import { removeStudentFromSchool } from "../../../api/Canvas";
import "./school_dashboard_students.less";
import { getCustomized } from "../../../api/Options";
import moment from "moment";
const { Search } = Input;

interface StudensProps {
  school: any;
  changeTab: any;
  history: any;
  curUser: any;
}

const msgTypeList: any = {
  msg: "msg_to_student",
  notify: "notify_update_canvas",
};

const msgText: any = {
  msg: {
    title: "Send Message",
    intro1: "You are sending your student(s) a message via email. ",
    intro2: (
      <>
        Clicking on <strong>Send My Message</strong> will initiate a email that
        goes out to all selected students.
      </>
    ),
  },
  notify: {
    title: "Gentle reminder to update your canvas",
    intro1: "You are sending this notification to <username> via email. ",
    intro2: (
      <>
        Clicking on <strong>Send My Message</strong> will initiate an email to
        this user.
      </>
    ),
  },
};
enum StudentStatusEnum {
  HIRED = 'HIRED',
  AVAILABLE = 'AVAILABLE',
  NONE = 'NONE',
  // NONINDUSTRY = 'NONINDUSTRY'
}

export const SchoolDashboardStudents: React.FC<StudensProps> = (props) => {
  const { school, changeTab = _.noop, history = _.noop, curUser } = props;
  const isMobile = window.innerWidth < 1024;

  const size = 10;
  const [graduated, setGraduated] = useState(false);
  const [location, setLocation] = useState(0);
  const [program, setProgram] = useState(0);
  const [status, setStatus] = useState<string>('all');
  const [programList, setProgramList] = useState([
    { name: "All", optionId: 0 },
  ]);
  const [keyword, setKeyword] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selectList, setSelectList] = useState([] as any);
  const [curPage, setCurPage] = useState(1);
  const [list, setList] = useState([] as any[]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visibleMsgModal, setVisibleMsgModal] = useState(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [msgType, setMsgType] = useState("msg");
  const [msgList, setMsgList] = useState([]);
  const [msgContent, setMsgContent] = useState("");
  const [showDelConfirm, setShowDelConfirm] = useState(false);
  const [curDelStu, setCurDelStu] = useState({} as any);
  const [showChannelModal, setShowChannelModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  
  const statusList = [{
    name: 'All',
    key: 'all'
  }].concat(Object.values(StudentStatusEnum).map(_ => ({name: capitalize(_), key: _})))

  useEffect(() => {
    if (school && school.id) {
      getSchoolPrograms(school.id);
    }
    setSelectList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school]);

  useEffect(() => {
    setSelectList([]);
  }, [graduated]);

  const getSchoolPrograms = async (id: number) => {
    const res = await getCanvas(id + "");
    if (res) {
      const locs = _.map(res.detail.locations, (loc) => {
        let newItem = { ...loc } as any;
        if (!_.isEmpty(school.locations)) {
          const temp = _.find(school.locations, { uuid: loc.uuid });
          if (temp && temp.id) {
            newItem = { ...newItem, id: temp.id };
          }
        }
        return newItem;
      });
      if (programList.length < 2) {
        let programs = [{ name: "All", optionId: 0 }] as any[];

        const options = await getCustomized(true, ["program"]);
        programs = programs.concat(
          options.program.map((_: any) => ({ optionId: _.id, name: _.name }))
        );
        setProgramList(programs);
      }
      setLocation(locs[0].id);
      setProgram(0);
    }
  };

  const getList = (page = 1, append?: boolean) => {
    if (!school.id || loading) return;
    setLoading(true);
    const params: any = {
      canvas_id: school.id,
      page,
      size,
    };
    const filter = {
      graduated,
      keyword,
      location: +location,
      program: +program,
      sortBy: orderBy,
      hiredStatus: status !== 'all' ? status : null
    };
    _.map(filter, (value: any, key) => {
      if (value !== "" && value !== 0) params[key] = value;
    });

    searchSchoolStudent(params)
      .then((res: any) => {
        if (res.list) {

          setCurPage(page);
          setList(append ? list.concat(res.list) : res.list);
          setTotal(res.count || 0);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const scrollLoadMore = () => {
    getList(curPage + 1, true)
  }

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graduated, location, program, keyword, orderBy, school.id, status]);

  const handleChangePage = (event: unknown, newPage: number) => {
    getList(newPage);
  };

  const sendMessage = (userId?: any) => {
    setMsgList(userId ? [userId] : selectList);
    setVisibleMsgModal(true);
  };

  const sendMsg = () => {
    if (!school.id) return;
    if (!msgContent) {
      message.info("please input your message");
      return;
    }
    const params = {
      msg: msgContent,
      template: msgTypeList[msgType],
      users: msgList,
      extra: {
        canvas_id: school.id,
        // address: string,
      },
    };
    sendMsgEmail(params).then(() => {
      onMsgClose();
      setVisibleSuccessModal(true);
    });
  };

  const startChat = async (userId: any) => {
    if (!userId || !curUser || !curUser.id || !chatClient) return;
    setShowLoading(true);
    const channelId = await createChat([curUser.id, userId]);
    setShowLoading(false);
    history.push(router_message, { channelId: channelId });
  };

  const onMsgClose = () => {
    setVisibleMsgModal(false);
    setMsgContent("");
    setMsgList([]);
  };

  const removeStu = async () => {
    if (!school || !school.id || !curDelStu.userId) return;
    const res = await removeStudentFromSchool(school.id, curDelStu.userId);
    if (res) {
      setCurDelStu({});
      getList();
    }
  };

  const getMsgIntro = () => {
    if (msgType === "notify") {
      const currentUser = _.find(list, { userId: msgList[0] });
      const username =
        _.get(currentUser, "firstName") +
        " " +
        _.get(currentUser, "lastName") || "";
      return msgText[msgType].intro1.replace("<username>", username);
    } else {
      return msgText[msgType].intro1;
    }
  };

  const getCompletionColor = (row: any) => {
    if (row && row.completion) {
      if (row.completion <= 50) {
        return "#ff6b00";
      } else if (row.completion <= 80) {
        return "#B8A605";
      } else {
        return "#5A8E09";
      }
    }
    return "#ff6b00";
  };

  const exportCsv = () => {
    const filter = {
      canvas_id: school.id,
      graduated,
      keyword,
      location: +location,
      program: +program,
      sortBy: orderBy,
    };
    const params: any = {};
    _.map(filter, (value: any, key) => {
      if (value !== "" && value !== 0) params[key] = value;
    });
    exportStudentCsv(params);
  };

  const tHeadRender: IHeadItem[] = isMobile
    ? [{ name: "Student name", isSort: true, key: "name", col: 2 }]
    : [
      { name: "Student name", isSort: true, key: "name", col: 2 },
      { name: "Phone No." },
      { name: "Location", isSort: true, key: "location" },
      { name: "Graduation", isSort: true, key: "graduate" },
      { name: "Hired", key: "hired" },
      { name: "Time from\ngrad to hired", col: 2 },
      { name: "Program" },
      { name: "Views" },
      { name: "Portfolio\ncompletion", key: "completion" },
      { name: "Status", key: "status", col: 1.5 },
      { name: "Bookmarks", key: "bookmarksCount"},
      { name: "Opportunities Applied", key: "opportunityApplyCount"},
      { name: "Last Login" },
      { name: "Last Updated" },
      { name: "Actions" },
    ];

  const tBodyRender: IBodyItem[] = [
    {
      type: "avatar",
      component: (row: any) => (
        <>
          <Avatar
            icon="user"
            src={row && row.avatar}
            className="avatar"
            style={{ marginRight: "8px" }}
          />
          {`${row.firstName} ${row.lastName}`}
        </>
      ),
      className: "avatar-name",
      col: 2
    },
    { name: "Phone No.", key: "phoneNumber" },
    { name: "Location", key: "location.city" },
    { name: "Graduation", key: "graduationDate", type: "date",
      component: (row: any) => (
        <span
          className="school-dashboard-date"
        >{`${row && row.graduationDate ? `${moment(row.graduationDate).format('MM/DD/YY')}` : ""}`}</span>
      )
    },
    { name: "Hired", key: "hired", type: "date",
      component: (row: any) => (
        <span
          className="school-dashboard-date"
        >{`${row && row.hired ? `${moment(row.hired).format('MM/DD/YY')}` : ""}`}</span>
      )
    },
    { name: "Time from\ngrad to hired", key: "timeToHired", col: 2 },
    { name: "Program", key: "programName" },
    { name: "Views", key: "viewCount" },
    {
      name: "Portfolio\ncompletion",
      type: "completion",
      component: (row: any) => (
        <span
          className="completion-value"
          style={{ color: getCompletionColor(row) }}
        >{`${row && row.completion ? `${row.completion}%` : "0%"}`}</span>
      ),
      className: "completion",
    },
    {
      name: "Status",
      col: 1.5,
      type: "stats",
      component: (row: any) => (
        <span
          className={classnames("status-value", {
            isHired: row && row.status === "HIRED",
            isAva: row && row.status === "AVAILABLE",
          })}
        >
          {(row && row.status) || "NONE"}
        </span>
      ),
      className: "status",
    },
    { name: "Bookmarks", key: "bookmarksCount"},
    { name: "Opportunities Applied", key: "opportunityApplyCount"},
    { name: "Last Login", key: "lastLogin", type: "date",
      component: (row: any) => (
        <span
          className="school-dashboard-date"
        >{`${row && row.lastLogin ? `${moment(row.lastLogin).format('MM/DD/YY')}` : ""}`}</span>
      )
    },
    { name: "Last Updated", key: "lastUpdated", type: "date",
      component: (row: any) => (
        <span
          className="school-dashboard-date"
        >{`${row && row.lastUpdated ? `${moment(row.lastUpdated).format('MM/DD/YY')}` : ""}`}</span>
      )  
    },
    {
      type: "dropdown-actions",
      dropdownList: [
        {
          icon: IconMessageBlack,
          name: "Message",
          onClick: (item: any, index: number) => {
            startChat(item.userId);
          },
        },
        {
          icon: IconPrint,
          name: "Print",
          onClick: (item: any, index: number) => {
            const apiHost = process.env.REACT_APP_BASE_URL;
            window.open(
              `${apiHost?.endsWith("/") ? apiHost : apiHost + "/"
              }canvas/canvas-pdf/${item.canvasId}`
            );
          },
        },
        {
          icon: IconLink,
          name: "Copy link",
          onClick: (item: any, index: number) => {
            const link =
              window.location.origin +
              `/canvas/${item.canvasSlug || item.canvasId}`;
            setTimeout(() => {
              copyToClipboard(link);
            }, 500);
          },
        },
        {
          icon: IconView,
          name: "View",
          onClick: (item: any, index: number) => {
            let path = `/canvas/${item.canvasSlug || item.canvasId}?tabType=students`;
            if (school && school.id) {
              path = `${path}&viewCanvas=${school.id}`;
            }
            history.push(path);
          },
        },
        {
          icon: IconDel,
          name: "Remove",
          orangeText: true,
          onClick: (item: any, index: number) => {
            setShowDelConfirm(true);
            setCurDelStu(item);
          },
        },
        {
          icon: IconNotify,
          name: "Notify user to update canvas",
          onClick: (item: any, index: number) => {
            setMsgType("notify");
            sendMessage(item.userId);
          },
        },
      ],
    },
  ];
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <span
          className="msg-menu-item"
          onClick={() => setShowChannelModal(true)}
        >
          <img alt="" src={IconGroupChat} />
          Group message
        </span>
      </Menu.Item>
      <Menu.Item key="1">
        <span className="msg-menu-item" onClick={() => createBlastMsg()}>
          <img alt="" src={IconBlastChat} />
          Blast message
        </span>
      </Menu.Item>
    </Menu>
  );

  const createBlastMsg = async () => {
    if (_.isEmpty(selectList) || !curUser || !curUser.id) return;
    setShowLoading(true);
    const memberList = [...selectList];
    await createBlastChat(memberList, curUser.id);
    setShowLoading(false);
    history.push("/message");
  };

  const createGroupChat = async (channelName: string) => {
    if (
      _.isEmpty(selectList) ||
      !curUser ||
      !curUser.id ||
      !channelName ||
      !school.id
    )
      return;
    const memberList = [...selectList, curUser.id];
    setShowLoading(true);
    const channelId = await createChannel({
      members: memberList,
      channelName: channelName,
      canvasId: school.id,
    });
    setShowLoading(false);
    history.push(router_message, { channelId: channelId });
  };

  return (
    <div className="school-dashboard-students">
      <div className="title-wrap">
        <div className="common-title bold-title">My Students</div>
        <div>
          <span
            className={classnames("tab-btn", {
              active: graduated,
            })}
            onClick={() => {
              if (loading) {
                message.warn("loading..");
                return;
              }
              setGraduated(true);
            }}
          >
            Alumni
          </span>
          <span
            className={classnames("tab-btn", {
              active: !graduated,
            })}
            onClick={() => {
              if (loading) {
                message.warn("loading..");
                return;
              }
              setGraduated(false);
            }}
          >
            Student
          </span>
        </div>
        <div className="space" />
        <Button
          className="btn-secondary btn-export"
          onClick={exportCsv}
          disabled={total <= 0}
        >
          <img src={IconExport} alt="" />
          Export csv
        </Button>
        <Button
          className="btn-secondary"
          onClick={() => changeTab("Add Students")}
        >
          <img src={IconAdd} alt="" />
          Add student
        </Button>
      </div>
      <div className="filter-wrap">
        {/* <TextField
          label="location"
          variant="filled"
          select
          className="material-input input"
          value={"" + location}
          onChange={(e) => setLocation(+e.target.value)}
        >
          {locationList.map((item: any, index: number) => (
            <MenuItem key={index} value={"" + item.id}>
              {item.address}
            </MenuItem>
          ))}
        </TextField> */}
        <TextField
          label="Program"
          variant="filled"
          select
          className="material-input input"
          value={"" + program}
          onChange={(e) => setProgram(+e.target.value)}
        >
          {programList.map((item: any, index: number) => (
            <MenuItem key={index} value={"" + item.optionId}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Status"
          variant="filled"
          select
          className="material-input input"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          {statusList.map((item: any, index: number) => (
            <MenuItem key={index} value={item.key}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <Search
          className="keyword-input"
          placeholder="Search student by name..."
          onSearch={(value) => setKeyword(value)}
        />
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayClassName="chat-pop"
          disabled={!selectList.length}
        >
          <Button
            className="btn-secondary"
            disabled={!selectList.length}
            onClick={() => { }}
          >
            <img src={IconMessage} alt="" />
            Message
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
      {list.length || loading ? (
        <ListTable
          tHeadRender={tHeadRender}
          tBodyRender={tBodyRender}
          loading={loading}
          hasSelect={true}
          idKey="userId"
          dataList={list}
          selectList={selectList}
          selectOnChange={setSelectList}
          total={total}
          size={size}
          page={curPage}
          // pageOnChange={handleChangePage}
          scrollLoadMore={scrollLoadMore}
          orderBy={orderBy}
          orderByOnChange={(value: string) => setOrderBy(value)}
          isMobile={isMobile}
        />
      ) : (
          <div className="table-empty-wrap">
            <img src={IconNoStudent} alt="" />
            {/* <div className="common-title">There’s no students added</div> */}
            <p>Starting adding students to your school dashboard!</p>
            <Button
              className="btn-secondary"
              onClick={() => changeTab("Add Students")}
            >
              <img src={IconAdd} alt="" />
            Add student
          </Button>
          </div>
        )}
      <Modal
        footer={null}
        closable={false}
        onCancel={onMsgClose}
        visible={visibleMsgModal}
        centered={true}
        width="80%"
      >
        <div className="send-msg-modal">
          <div className="common-title">{msgText[msgType].title}</div>
          <div className="modal-text">{getMsgIntro()}</div>
          <TextField
            label="Message goes here"
            placeholder="Message goes here"
            multiline
            rows={4}
            variant="filled"
            className="material-input"
            value={msgContent}
            onChange={(event) => setMsgContent(event.target.value)}
          />
          <div className="modal-text">{msgText[msgType].intro2}</div>
          <div className="btn-wrap">
            <Button type="primary" onClick={sendMsg}>
              Send Message
            </Button>
            <Button onClick={onMsgClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setVisibleSuccessModal(false)}
        visible={visibleSuccessModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Message sent!</div>
          <div className="modal-text">
            Woohoo! Your message was sent successfully.
          </div>
          <img src={IconSuccess} alt="success" />
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={() => setVisibleSuccessModal(false)}
            >
              Back to My Students
            </Button>
          </div>
        </div>
      </Modal>
      {showDelConfirm && (
        <ConfirmModal
          visible
          title="Confirmation"
          confirmText="Remove"
          description={`You are removing student(s) from your school dashboard.  By clicking on the Remove button, all data related to this student(s) will be deleted from your school’s statistics. This action can’t be undone.`}
          onClose={() => {
            setShowDelConfirm(false);
            setCurDelStu({});
          }}
          onConfirm={() => {
            removeStu();
            setShowDelConfirm(false);
          }}
        />
      )}
      {showChannelModal && (
        <ChannelNameModal
          visible
          count={selectList.length + 1}
          onClose={() => setShowChannelModal(false)}
          onConfirm={(value: string) => {
            setShowChannelModal(false);
            createGroupChat(value);
          }}
        />
      )}
      <CommonLoadingModal visible={showLoading} />
    </div>
  );
};
