import Http from "./Http";

export const setupExternalPayment = (data: any) => {
  return Http.post("/subscription/external-payment", data)
  .then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  })
  .catch((err) => {
    return Promise.reject(err);
  });
}

export const retrieveCanvasInfo = (idOrSlug: any) => {
  return Http.get(`/subscription/canvas-info/${idOrSlug}`)
  .then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  })
  .catch((err) => {
    return Promise.reject(err);
  });
}
