import React, { useState } from "react";
import AdminSubscriptionForm from "../../../components/component_admin_subscription_form";
import { retrieveCanvasInfo } from "../../../api/Subscription";
import "./external.less";
import { Spin, notification } from "antd";

const AdminExternalPayment = () => {
  const [inputValue, setInputValue] = useState(null);
  const [canvasId, setCanvasId] = useState(null);
  const [canvasName, setCanvasName] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      const canvasInfo = await retrieveCanvasInfo(inputValue);
      if (!canvasInfo) {
        notification.error({
          message: "Error",
          description: "Canvas not found",
        });
      } else {
        setCanvasId(canvasInfo.id);
        setCanvasName(canvasInfo.name);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while retrieving the canvas information",
      });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="view-admin-external-payment">
      <h1>External Payment</h1>
      {loading ? (
        <Spin />
      ) : (
        <>
          {!canvasId && (
            <>
              <p>Enter the Canvas ID or Slug to create a payment</p>
              <input
                type="text"
                placeholder="Enter Canvas ID or Slug"
                value={canvasId}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button type="button" onClick={handleButtonClick}>Add External Payment</button>
            </>
          )}
        </>
      )}
      {canvasId && (
        <>
          <AdminSubscriptionForm canvasId={canvasId} canvasName={canvasName} />
          <button type="button" onClick={() => setCanvasId(null)}>
            Back
          </button>
        </>
      )}
    </div>
  );
};

export default AdminExternalPayment;
