import React from "react";
import { getStaticAssetPath } from "../../../utils/utils";

interface Props {
  headline: string;
  subtitle: string;
}

const StudentProHeadline: React.FC<Props> = (props) => {
  const { headline, subtitle } = props;
  return (
    <div className="cm-sp-headline">
      <img src={getStaticAssetPath('studentpro-landing/canvas-symbol.png')} alt="Canvas symbol" />
      <div>
        <h1>{ headline }</h1>
        <p>{ subtitle }</p>
      </div>
    </div>
  )
};

export default StudentProHeadline;
