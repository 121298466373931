import React, { useState } from 'react'
import SalonCentricHero from './sc_hero';
import StudentProBrands from '../studentpro/studentpro_brands';
import StudentProOpportunities from '../studentpro/studentpro_opportunities';
import VideoModal from '../../../components/component_video_modal';
import _ from "lodash";
import StudentProWatch from '../studentpro/studentpro_watch';
import StudentProFeatured from '../studentpro/studentpro_featured';
import { getStaticAssetPath } from '../../../utils/utils';
import FeaturedOpportunities from '../studentpro/featured_opps';
import StudentProThrive from '../studentpro/studentpro_thrive';
import StudentProHeadline from '../studentpro/studentpro_headline';
import { HomeFaq } from '../home/home_faq';
import { publicSourceUrl } from '../../../utils/constants';

const SalonCentricHome: React.FC = () => {
  const [curPlayUrl, setCurPlayUrl] = useState("");

  return (
    <div className="cm-sc-landing">
      <SalonCentricHero />
      <StudentProBrands headline="IN PARTNERSHIP WITH SALONCENTRIC" isSalonCentric={true} />
      <StudentProOpportunities source='saloncentric' ctaLabel='Create Your Free Account' setCurPlayUrl={setCurPlayUrl} />
      <StudentProWatch ctaLabel='Create Your Free Account' setCurPlayUrl={setCurPlayUrl} source="saloncentric" />
      <StudentProFeatured ctaLabel='Create Your Free Account' source="saloncentric" />
      <StudentProWatch
        setCurPlayUrl={setCurPlayUrl}
        watchLink='https://sites.canvasme.com/pros-and-students/'
        watchImagePath={getStaticAssetPath('studentpro-landing/empowering-watch-preview.png')}
        source="saloncentric"
        headline='Empowering Beauty Businesses With a Proven Hiring Process.'
        content='Our mission at Beautista is to empower you to find the RIGHT cultural fit. Beautista not only humanizes your salon but it provides a hiring process that meets this new professional where they are. Goodbye outdated career forms. Hello new age, one click process.'
        contentPosition='left'
        vidPath={publicSourceUrl + 'Canvas_Business_Owner_v2.mp4'}
        icon={getStaticAssetPath('studentpro-landing/icon-guarantee.png')}
      />
      <FeaturedOpportunities ctaLabel='Create Your Free Account' source="saloncentric" />
      <StudentProThrive ctaLabel='Create Your Free Account' source="saloncentric" />
      <div className="cm-sp-faq">
        <StudentProHeadline headline="FAQ's" subtitle="Check out some of our frequently asked questions below." />
        <HomeFaq showSelector={true} includeSchoolFaqs={false} showHeader={false} customBackground="#f2f3f3" />
      </div>
      {!_.isEmpty(curPlayUrl) && (
        <VideoModal
          visible
          onClose={() => setCurPlayUrl("")}
          url={curPlayUrl}
        />
      )}
    </div>
  )
};

export default SalonCentricHome;
