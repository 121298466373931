import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { MyOpportunityList } from "../../../components/component_my_opp_list";
import OpportunityApplyModal from "../../opportunity/opportunity_apply_modal/opportunity_apply_modal";
import {
  getMyAskedOppList,
  opportunityWithdraw,
} from "../../../api/Opportunity";
import "./my_opportunities.less";
import { SchoolRole } from "../../../utils/enum";

interface MyOpportunitiesProps {
  canvas?: any
}

export const MyOpportunities: React.FC<MyOpportunitiesProps> = (props) => {
  const {canvas} = props
  const [opportunityList, setOpportunityList] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState({ id: 0 });
  const [loading, setLoading] = useState(false);
  const [modalParameter, setModalParameter] = useState({
    visible: false,
    title: "",
    desc: "",
    tips: "",
    applyBtnText: "",
    cancelText: "",
  });
  useEffect(() => {
    getMyAskedOppData();
  }, []);

  const getMyAskedOppData = async () => {
    setLoading(true);
    const res = await getMyAskedOppList(canvas?.id);
    if (res) {
      setOpportunityList(res);
    }
    setLoading(false);
  };

  const modalWithdraw = async (msg: string) => {
    closeModal();
    const res = await opportunityWithdraw({
      id: selectedOpp.id,
      msg,
    });
    if (res && res.success) {
      getMyAskedOppData();
    }
  };

  const closeModal = () => {
    setModalParameter({
      visible: false,
      title: "",
      desc: "",
      tips: "",
      applyBtnText: "",
      cancelText: "",
    });
  };

  const withdrawApply = (item: any) => {
    setSelectedOpp(item);
    setModalParameter({
      ...modalParameter,
      visible: true,
      title: "Withdraw from this opportunity",
      desc: "Send the poster a message to withdraw from this opportunity. ",
      tips: `Clicking on <span style="font-weight: 800;">Withdraw</span> will automatically send the opportunity the above message.`,
      applyBtnText: "Withdraw",
    });
  };
  const {
    visible,
    title,
    desc,
    tips,
    applyBtnText,
    cancelText,
  } = modalParameter;
  return (
    <div className="my-opportunities-root">
      <div className="title-wrap">
        <div className="common-title bold-title">My Opportunities</div>
      </div>
      {loading ? (
        <Spin className="loading" />
      ) : (
        <div className="opportunity-list-wrap">
          {opportunityList.length ? (
            <MyOpportunityList
              dataList={opportunityList}
              forbidEdit={canvas &&
                canvas.memberRole &&
                canvas.memberRole !== SchoolRole.ADMIN}
              clickWithdraw={withdrawApply}
            ></MyOpportunityList>
          ) : (
            <div className="table-empty-wrap">
              <div className="common-title">
                You don't have any opportunities yet
              </div>
            </div>
          )}
        </div>
      )}

      <OpportunityApplyModal
        visible={visible}
        title={title}
        desc={desc}
        tips={tips}
        applyBtnText={applyBtnText}
        cancelText={cancelText}
        onApply={(msg: string) => modalWithdraw && modalWithdraw(msg)}
        onClose={() => closeModal && closeModal()}
        clickResultBtn={modalWithdraw}
        closeResultModal={() => {}}
        applyResultParam={{}}
      />
    </div>
  );
};
