import { StreamChat } from "stream-chat";
import _ from "lodash";
import { UpdateChatInfo } from "../models/model_chat";
import { checkChatToken } from "../api/Auth";
import { v4 as uuidv4 } from "uuid";
import { doAction } from "module-reaction";

export let chatClient: StreamChat;
export let unreadCount = 0;

export const initChatClient = async (user: any) => {
  if(process.env.NODE_ENV === 'development') return;
  if (!chatClient || !chatClient.userID) {
    const key = process.env.REACT_APP_STREAM_KEY || "";
    chatClient = new StreamChat(key, { timeout: 10000 });
    chatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        unreadCount = event.total_unread_count;
        doAction(UpdateChatInfo, { unreadCount: unreadCount });
      }
    });
    if (user && user.id) {
      try {
        const res = await chatClient.connectUser(
          {
            id: user.id + "",
            name: `${user.profile.firstName} ${user.profile.lastName}`,
            image:
              user.profile.avatar ||
              `https://getstream.io/random_png/?id=soft-sea-7&name=${user.profile.firstName}+${user.profile.lastName}`,
          },
          user.chatToken
        );
        console.log(res)
        if (res && res.me && res.me.total_unread_count) {
          doAction(UpdateChatInfo, { unreadCount: res.me.total_unread_count });
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
};

export const changeUser = async (user: any) => {
  if (user && user.id && chatClient) {
    doAction(UpdateChatInfo, { unreadCount: 0 });
    await chatClient.disconnectUser();
    const res = await chatClient.connectUser(
      {
        id: user.id + "",
        name: `${user.profile.firstName} ${user.profile.lastName}`,
        image:
          user.profile.avatar ||
          `https://getstream.io/random_png/?id=soft-sea-7&name=${user.profile.firstName}+${user.profile.lastName}`,
      },
      user.chatToken
    );
    if (res && res.me && res.me.total_unread_count) {
      doAction(UpdateChatInfo, { unreadCount: res.me.total_unread_count });
    }
  }
};

export const createChat = async (members: any[]) => {
  if (!_.isEmpty(members)) {
    await checkChatToken(members);
  }
  const conversation = chatClient.channel("messaging", {
    members: _.map(members, (item) => {
      return `${item}`;
    }),
  });
  const res = await conversation.create();
  if (res && res.channel && res.channel.id) {
    if (res.hidden) {
      await conversation.watch();
      await conversation.show();
    }
    return res.channel.id;
  } else {
    return "";
  }
};

export const createChannel = async (data: any) => {
  const { members, channelName, canvasId } = data;
  if (chatClient) {
    if (!_.isEmpty(members)) {
      await checkChatToken(members);
    }
    const conversation = chatClient.channel("messaging", uuidv4(), {
      name: channelName,
      image: `https://getstream.io/random_png/?name=${channelName.replace(
        " ",
        "%20"
      )}`,
      members: _.map(members, (item) => {
        return `${item}`;
      }),
      userList: members,
      canvasId: canvasId,
    });
    const res = await conversation.create();
    if (res && res.channel && res.channel.id) {
      return res.channel.id;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const createBlastChat = async (ids: number[], curUserId: number) => {
  if (!_.isEmpty(ids)) {
    await checkChatToken(ids);
  }
  _.forEach(ids, async (id) => {
    const conversation = chatClient.channel("messaging", {
      members: _.map([`${id}`, `${curUserId}`], (item) => {
        return `${item}`;
      }),
    });
    const res = await conversation.create();
    if (res && res.channel && res.channel.id) {
      if (res.hidden) {
        await conversation.watch();
        await conversation.show();
      }
    }
  });
};
