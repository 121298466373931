import React, { useRef, useState, useEffect } from "react";
import classnames from "classnames";
import IconArrowLeft from "../assets/images/ic_arrow_left.svg";
import IconArrowRight from "../assets/images/ic_arrow_right.svg";
import "./component_horizontal_scroll_view.less";

interface Props {
  children: any;
  className?: string;
  hideNavi?: boolean // whether hide the left/right navi btn
}

const HorizontalScrollView = (props: Props) => {
  const { children, className, hideNavi } = props;
  console.log('hideNavi', hideNavi)
  const [curX, setCurX] = useState(0);
  const [showArrow, setShowArrow] = useState(false);
  const scroll = useRef<any>();
  const content = useRef<any>();

  useEffect(() => {
    if (scroll && scroll.current) {
      setShowArrow(scroll.current.scrollWidth > scroll.current.clientWidth);
    }
  }, [scroll]);

  return (
    <div className={classnames("horizontal-scroll-root", className)}>
      <div className="scroll-sec" ref={scroll}>
        <div
          className={classnames("list-wrap", { showArrow: showArrow })}
          ref={content}
        >
          {children}
        </div>
      </div>
      <div className="left button" hidden={hideNavi || !showArrow}>
        <img
          alt=""
          src={IconArrowLeft}
          className="arrow"
          onClick={() => {
            if (scroll && scroll.current) {
              let nextX = curX - 200;
              if (nextX < 0) {
                nextX = 0;
              }
              scroll.current!!.scrollTo({ left: nextX, behavior: "smooth" });
              setCurX(nextX);
            }
          }}
        />
      </div>
      <div className="right button" hidden={hideNavi || !showArrow}>
        <img
          alt=""
          src={IconArrowRight}
          className="arrow"
          onClick={() => {
            console.log("scrollWidth", scroll.current.scrollWidth);
            console.log("clientWidth", content.current.clientWidth);
            if (scroll && scroll.current && content && content.current) {
              let nextX = curX + 200;
              if (nextX >= content.current.clientWidth) {
                nextX = content.current.clientWidth;
              }
              scroll.current!!.scrollTo({ left: nextX, behavior: "smooth" });
              setCurX(nextX);
            }
          }}
        />
      </div>
    </div>
  );
};

export default HorizontalScrollView;
