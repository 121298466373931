import React from "react";
import { mapProp } from "module-reaction";
import { modle_user } from "../../models/model_user";
import { ModuleSchoolInfo, MODULE_SCHOOL_INFO, module_school_info } from "../../models/model_school_info";
import SchoolInfo from "./school_info";

@mapProp(module_school_info)
@mapProp(modle_user)
export default class SchoolInfoWrapper extends React.Component<ModuleSchoolInfo, {}> {
  render() {
    return <SchoolInfo
      id={this.props.id}
      payOrderStatus={this.props.payOrderStatus}
      isInline={false}
      module={MODULE_SCHOOL_INFO}
      programOptions={this.props.programOptions}
      slug={this.props.slug}
      school={this.props.school}
      title={this.props.title}
      history={this.props.history}
      contactNumber={this.props.contactNumber}
      scSalonId={this.props.scSalonId}
      defaultLocation={this.props.defaultLocation}
      extraLocation={this.props.extraLocation}></SchoolInfo>
  }
}
