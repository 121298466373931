import { ModuleStore } from "module-reaction";
import { ISelectOptionItem } from "../components/component_validate_form";
export const MODULE_POST_JOB = "module_post_job";

const categoryList: ISelectOptionItem[] = [
  {
    name: "category 1",
    value: 1
  },
  {
    name: "category 2",
    value: 2
  },
  {
    name: "category 3",
    value: 3
  }
];

const typeCategoryList: ISelectOptionItem[] = [
  {
    name: "type category 1",
    value: 1
  },
  {
    name: "type category 2",
    value: 2
  },
  {
    name: "type category 3",
    value: 3
  }
];

const structureList: ISelectOptionItem[] = [
  {
    name: "structure 1",
    value: 1
  },
  {
    name: "structure 2",
    value: 2
  },
  {
    name: "structure 3",
    value: 3
  }
];

export interface IPostJob {
  title: string;
  category: any;
  typeCategory: any;
  structure: any;
  text: string;
}

const postJobValue: IPostJob = {
  title: "",
  category: "",
  typeCategory: "",
  structure: "",
  text: "",
};

const postJobFormElement = (isMobile: boolean) => [
  {
    name: "title",
    label: "Job title",
    required: true,
  },
  {
    type: "select",
    name: "category",
    label: "Job category",
    required: true,
    options: categoryList,
  },
  {
    type: "select",
    name: "typeCategory",
    label: "Job type category",
    colSpan: isMobile ? 24 : 12,
    required: true,
    options: typeCategoryList
  },
  {
    type: "select",
    name: "structure",
    label: "Pay structure",
    colSpan: isMobile ? 24 : 12,
    required: true,
    options: structureList
  },
  {
    type: "textarea",
    name: "text",
    label: "Text",
    required: true,
  },
];

export interface IPostJobOpportunity extends ModuleStore {
  postJobFormElement: any;
  postJobValue: IPostJob;
}

export const module_post_job: IPostJobOpportunity = {
  module: MODULE_POST_JOB,
  postJobFormElement,
  postJobValue,
};
