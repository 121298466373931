import React from "react";
import moment from "moment";
import ic_video_tag from "../assets/images/ic_video_tag.svg";
import { Tag } from "antd";
import "./component_canvas_search_item.styl";
import { doAction } from "module-reaction";
import {
  MODULE_HOMEPAGE_OPPORTUNITY,
  module_homepage_opportunity,
} from "../models/model_homepage_opportunity";
import { router_opportunity_detail, router_canvas_detail } from "../utils/enum";
import {
  MODULE_HOMEPAGE_CANVASES,
  module_homepage_canvases,
} from "../models/model_homepage_canvases";
import { getOptimizedPath } from "../utils/utils";
export interface CanvasSearchItemProp {
  className?: string; // classname suffix
  media: string; // url of img or video
  videoCover?: string; // url of cover or video
  is_video?: boolean; // media link is video
  tags: { label: string; style: React.CSSProperties }[];
  timestamp: string; // date
  title: string;
  link: string; // on click, where go
  history: any; // router history obj
  addtions: { ico?: React.ReactNode; txt: string }[]; // for business_ico business_name, location_ico location_str...
}

export class CanvasSearchItem extends React.Component<
  CanvasSearchItemProp,
  {}
> {
  private videoEle: HTMLVideoElement = undefined as any;
  render() {
    const props = this.props;
    const isMobile =
      document.documentElement.getBoundingClientRect().width < 1024;
    return (
      <div
        className={`canvas-search-item ${props.className || ""}`}
        onClick={(e) => {
          if (props.link) {
            props.history.push(props.link);
            // reset model when route to detail page
            if (props.link.includes(router_opportunity_detail.split(":")[0])) {
              doAction(
                MODULE_HOMEPAGE_OPPORTUNITY,
                module_homepage_opportunity
              );
            } else if (
              props.link.includes(router_canvas_detail.split(":")[0])
            ) {
              doAction(MODULE_HOMEPAGE_CANVASES, module_homepage_canvases);
            }
          }
        }}
        onMouseEnter={this.waitPlay}
        onMouseLeave={this.cancelPlay}
      >
        <div className="media-con">
          {props.is_video ? (
            isMobile ? (
              props.videoCover ? (
                <img className="media-img" src={props.videoCover} alt='media-img' />
              ) : (
                <span className="name-ico">{props.title?.charAt(0) || ""}</span>
              )
            ) : (
              <video
                className="media-video"
                // onLoadedData={gotoFirstFrame}
                preload="auto"
                src={props.media}
                poster={props.videoCover}
                ref={(e) => (this.videoEle = e!)}
              />
            )
          ) : props.media ? (
            <img className="media-img" src={getOptimizedPath(props.media, 768)} alt="" />
          ) : (
            <span className="name-ico">{props.title?.charAt(0) || ""}</span>
          )}
          {props.is_video && (
            <img src={ic_video_tag} alt="" className="video-tag" />
          )}
        </div>
        <div className="info-con">
          <div className="tag-con">
            {props.tags.map((t, idx) => {
              return (
                <Tag key={`tag-con-idx-${idx}`} style={t.style} title={t.label}>
                  {t.label}
                </Tag>
              );
            })}
            {
              <span className="update-tag">
                Updated {formatDuration(props.timestamp)} ago
              </span>
            }
          </div>
          <p className="card-title">{props.title}</p>
          <div className="additions">
            {props.addtions.map((a, idx) => {
              return (
                <div className="addition-line" key={`c-s-i-a-${idx}`}>
                  {a.ico && <span className="ico">{a.ico}</span>}
                  {<span className="txt">{a.txt}</span>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  private waitPlay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.videoEle) {
      this.videoEle.play();
    }
  };

  private cancelPlay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.videoEle) {
      this.videoEle.pause();
    }
  };
}

// video's preload is enough
// function gotoFirstFrame(e: React.SyntheticEvent<HTMLVideoElement>) {
//     let scale = 0.8, video = e.target as HTMLVideoElement, canvas = document.createElement("canvas");
//     canvas.width = video.videoWidth * scale;
//     canvas.height = video.videoHeight * scale;
//     canvas.getContext('2d')!.drawImage(video, 0, 0, canvas.width, canvas.height);
//     try {
//         video.setAttribute("poster", canvas.toDataURL("image/png"));
//     }
//     catch (err) {
//         console.log("Error: " + err);
//     }
// }
function formatDuration(timestamp: string) {
  const date = moment(timestamp);
  const now = moment();
  const duration = moment.duration(now.diff(date));
  const months = duration.months();
  if (months >= 1) {
    return `${months} month${months >= 2 ? "s" : ""}`;
  } else {
    const weeks = duration.weeks();
    if (weeks >= 1) {
      return `${weeks} week${weeks >= 2 ? "s" : ""}`;
    } else {
      const days = duration.days();
      if (days >= 1) {
        return `${days} day${days >= 2 ? "s" : ""}`;
      } else {
        const hours = duration.hours();
        if (hours >= 1) {
          return `${hours} hour${hours >= 2 ? "s" : ""}`;
        } else {
          const mins = duration.minutes();
          if (mins >= 1) {
            return `${mins} minute${mins >= 2 ? "s" : ""}`;
          } else {
            return "1 minute";
          }
        }
      }
    }
  }
}
