import { ModuleStore, ModuleAction, plusAction, KV } from "module-reaction";
import {
  searchMyCanvases,
  deleteCanvas,
  updateCanvasStatus,
} from "../api/Canvas";
import { getUserInfo } from "../api/Auth";

export const MODULE_MY_CANVAS = "MODULE_MY_CANVAS";

export interface ModelMyCanvases extends ModuleStore {
  canvasList: any[];
  user: any;
}

export const module_my_canvas: ModelMyCanvases = {
  module: MODULE_MY_CANVAS,
  canvasList: [] as any[],
  user: {},
};

export const GetUserInfoAction: ModuleAction<string, ModelMyCanvases> = {
  module: MODULE_MY_CANVAS,
  process: async () => {
    const result = await getUserInfo();
    if (result && result.user) {
      plusAction(GetMyCanvasesAction);
      return {
        user: result.user,
      };
    }
    return {};
  },
};

export const GetMyCanvasesAction: ModuleAction<string, ModelMyCanvases> = {
  module: MODULE_MY_CANVAS,
  process: async () => {
    const result = await searchMyCanvases("", "all");
    if (result && result.list) {
      return {
        canvasList: result.list,
      };
    }
    return {};
  },
};

export const DeleteCanvasAction: ModuleAction<number, ModelMyCanvases> = {
  module: MODULE_MY_CANVAS,
  process: async (id: number, module: ModelMyCanvases) => {
    const result = await deleteCanvas(id);
    if (result && result.success) {
      plusAction(GetMyCanvasesAction);
    }
    return {};
  },
};

export const UpdateCanvasStateAction: ModuleAction<KV, ModelMyCanvases> = {
  module: MODULE_MY_CANVAS,
  process: async (params: KV, module: ModelMyCanvases) => {
    const { isPublish, id } = params;
    const result = await updateCanvasStatus(
      id,
      isPublish ? "public" : "private"
    );
    if (result) {
      plusAction(GetMyCanvasesAction);
    }
    return {};
  },
  maxProcessSeconds: 10,
};
