import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import classnames from "classnames";
import IconFb from "../assets/images/home/icons/icons_facebookwhite.svg";
import IconIns from "../assets/images/home/icons/icons_instagramwhite.svg";
import IconYt from "../assets/images/home/icons/icons_youtubewhite.svg";
import "./component_cr_footer.less";
import {
  router_homepage_about,
  router_homepage_terms,
  router_homepage_privacy_policy,
  router_homepage_contact,
} from "../utils/enum";
const { Footer } = Layout;

interface Props {
  history: any;
  isHome?: boolean;
}

const CRFooter: React.FC<Props> = (props) => {
  const { isHome = false } = props;
  const year = new Date().getFullYear();

  return (
    <Footer className={classnames("footer-root", { isHome: isHome })}>
      <div className={classnames("footer-wrap", { isHome: isHome })}>
        <div className="left-sec">
          {isHome ? (
            <span className="copyright">Copyright © {year} Beautista. All rights reserved.</span>
          ) : (
            <>
              <Link
                to={router_homepage_about}
                className={classnames("text", { isHome: isHome })}
              >
                about
              </Link>
              <span className="point" />
              <Link
                to={router_homepage_contact}
                className={classnames("text", { isHome: isHome })}
              >
                contact
              </Link>
              <span className="point" />
              <span className={classnames("text", { isHome: isHome })}>
                who we work with
              </span>
            </>
          )}
        </div>
        <div className="right-sec">
          {isHome && (
            <>
              <Link
                to={router_homepage_about}
                className={classnames("text", { isHome: isHome })}
              >
                about us
              </Link>
              <span className="point" />
              <Link
                to={router_homepage_contact}
                className={classnames("text", { isHome: isHome })}
              >
                contact
              </Link>
              <span className="point" />
            </>
          )}
          <Link
            to={router_homepage_terms}
            className={classnames("text", { isHome: isHome })}
          >
            terms and conditions
          </Link>
          <span className="point" />
          {isHome ? (
            <Link
              to={router_homepage_privacy_policy}
              className={classnames("text", { isHome: isHome })}
            >
              privacy policy
            </Link>
          ) : (
            <span className={classnames("text", { isHome: isHome })}>
              who we work with
            </span>
          )}
          <div className="icon-wrap">
          <a href="https://www.instagram.com/beautista.connect" target="_blank" rel="noreferrer noopener">
              <img alt="Instagram" src={IconIns} className="ic-third-party" />
            </a>
            <a href="https://www.facebook.com/beautista.connect" target="_blank" rel="noreferrer noopener">
              <img alt="Facebook" src={IconFb} className="ic-third-party" />
            </a>
            <a href="https://www.youtube.com/@beautista.connect" target="_blank" rel="noreferrer noopener">
              <img alt="YouTube" src={IconYt} className="ic-third-party" />
            </a>
          </div>
        </div>
      </div>
      <div className={classnames("copyright-bottom", { isHome: isHome })}>
        Copyright © {year} Beautista. All rights reserved.
      </div>
    </Footer>
  );
};

export default CRFooter;
