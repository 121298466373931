import React from "react";
import { Tooltip } from "antd";
import _ from "lodash";
import IconEmptyImg from "../../../assets/images/ic_empty_image.png";
import IconView from "../../../assets/images/ic_view.svg";
import IconDel from "../../../assets/images/ic_delete.svg";
import IconNote from "../../../assets/images/ic_note.svg";
import "./canvas_bookmark_item.less";

interface CanvasBookmarkItemProps {
  canvas: any;
  note: string;
  onDelete?: Function;
  history?: any;
  onNote?: Function;
}

const CanvasBookmarkItem: React.FC<CanvasBookmarkItemProps> = (props) => {
  const { canvas, note, onNote, onDelete, history } = props;

  return (
    <div className="canvas-bookmark-item-root">
      <img
        alt=""
        className="feature-img"
        src={
          (canvas.media &&
            canvas.media.featuredPhoto &&
            canvas.media.featuredPhoto.image) ||
          IconEmptyImg
        }
      />

      <div className="info-sec">
        <span className="canvas-name">{canvas.name}</span>
        <div className="info-item">
          Location:{" "}
          <span>
            {(!_.isEmpty(canvas.locations) && canvas.locations[0].address) ||
              ""}
          </span>
        </div>
        <div className="info-item">
          Type:{" "}
          <span>
            {(!_.isEmpty(canvas.type) &&
              _.map(canvas.type, (item) => {
                return item.name;
              }).toString()) ||
              ""}
          </span>
        </div>
        <div className="info-item">
          Notes:{" "}
          <Tooltip title={note || ""}>
            <span className="note">{note || ""}</span>
          </Tooltip>
        </div>
      </div>
      <span className="space" />
      <div className="icon-sec">
        <img
          alt=""
          src={IconNote}
          className="operate-icon"
          onClick={() => onNote && onNote(canvas.id)}
        />
        <img
          alt=""
          src={IconView}
          className="operate-icon"
          onClick={() => history.push(`/canvas/${canvas.slug || canvas.id}`)}
        />

        <img
          alt=""
          src={IconDel}
          className="operate-icon"
          onClick={() => onDelete && onDelete(canvas.id)}
        />
      </div>
    </div>
  );
};

export default CanvasBookmarkItem;
