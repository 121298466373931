import Http from "./Http";
import { Net } from "../utils/utils";
import { router_insta_auth } from "../utils/enum";

export const getInstaAppInfo = () => {
  return new Promise((resolve, reject) => {
    Http.get("/instagram/app/")
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const getInstaUser = async (code: string) => {
  const redirect_uri = window.location.origin + router_insta_auth;
  const info = await getAccessToken(code, redirect_uri);
  return info;
};

export async function putInstInfo(info: any) {
  return await Http.put("/instagram/canvas", info).then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  })
    .catch((err) => console.error(err.message));
}
export async function delInstInfo(canvas_id: number) {
  return await Http.delete(`/instagram/canvas/${canvas_id}`).then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  })
    .catch((err) => console.error(err.message));
}
export const getMedias = (id: string | number, size: number) => {
  return Http.get(`/instagram/medias`, {
    params: {
      id: id,
      count: size,
    },
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const getCanvasMedias = (size: number) => {
  return Http.get(`/instagram/home-medias`, {
    params: {
      count: size,
    },
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
};

export const getAccessToken = (code: string, redirect_uri: string) => {
  return Http.get(`/instagram/access-token`, {
    params: {
      code,
      redirect_uri
    },
  })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch((err) => console.error(err.message));
}
