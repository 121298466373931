import React, { useState, useEffect } from "react";
import { Modal, Button, Select, message } from "antd";
import classnames from "classnames";
import _ from "lodash";
import { isEmailValid } from "../../utils/validator";
import { searchUser } from "../../api/Auth";
import Close from "../../assets/images/ic_close.svg";
import "./add_employee_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  onSend: Function;
}

const AddEmployeeModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onSend } = props;
  const [inputVisible, setInputVisible] = useState(false);
  const [searchUserList, setSearchUserList] = useState([] as any[]);
  const [selectedUsers, setSelectedUsers] = useState([] as any[]);
  const [inputFocused, setInputFocused] = useState(false);
  const [tags, setTags] = useState([] as string[]);
  const [keyword, setKeywords] = useState("");

  useEffect(() => {
    const newTags = _.map(selectedUsers, (item) => {
      return item.firstName || item.lastName
        ? `${item.firstName} ${item.lastName}`
        : item.email || "";
    });
    setTags(newTags);
  }, [selectedUsers]);

  useEffect(() => {
    if (!keyword) return;
    searchUserByWords(keyword);
  }, [keyword]);

  const searchUserByWords = async (keyword: string) => {
    const result = await searchUser(keyword);
    setSearchUserList(result);
  };

  const onEmployClick = (employ: any) => {
    const has = _.find(selectedUsers, { id: employ.id });
    if (has) {
      const newList: any[] = _.filter(selectedUsers, (item: any) => {
        return item.id !== employ.id;
      });
      setSelectedUsers(newList);
    } else {
      setSelectedUsers([...selectedUsers, employ]);
    }
    setKeywords("");
  };

  const dropDownRender = () => {
    return !_.isEmpty(searchUserList) || keyword ? (
      <div className="drop-down">
        {_.isEmpty(searchUserList) ? (
          <div
            className="no-result-item"
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              if (isEmailValid(keyword)) {
                onEmployClick({ email: keyword });
              } else {
                message.error("Invalid Email Address");
              }
            }}
          >
            <span className="avatar">{keyword[0]}</span>
            <div className="no-result-center">
              <span className="no-result-inputed">{keyword}</span>
              <span className="email-address">Email address not confirmed</span>
            </div>
          </div>
        ) : (
          _.map(searchUserList, (item, index) => {
            return (
              <div
                className="result-item"
                key={index}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => onEmployClick(item)}
              >
                <span className="avatar">
                  {!_.isEmpty(item.firstName) && item.firstName[0]}
                </span>
                {item.firstName || item.lastName
                  ? `${item.firstName} ${item.lastName}`
                  : item.email || ""}
              </div>
            );
          })
        )}
      </div>
    ) : (
      <div></div>
    );
  };

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="add-employee-modal-style"
    >
      <div className="add-employee-modal">
        <img className="close" src={Close} alt="" onClick={() => onClose()} />
        <span className="title">Add to company</span>
        <div className={classnames("select", { onFocus: inputFocused })}>
          <div className="select-center-warp">
            <span
              className={classnames("label", { inputVisible: inputVisible })}
              onClick={() => {
                setInputVisible(true);
              }}
            >
              Email address or name
            </span>
            {inputVisible && (
              <Select
                autoFocus={true}
                className={classnames("select-input", {
                  hasTag: !_.isEmpty(tags),
                })}
                mode="tags"
                dropdownRender={dropDownRender}
                value={tags}
                onDeselect={(value: any) => {
                  setSelectedUsers(
                    _.filter(selectedUsers, (item) => {
                      return value.includes('@')? item.email !== value : `${item.firstName} ${item.lastName}` !== value;
                    })
                  );
                }}
                onBlur={() => {
                  _.isEmpty(selectedUsers) && setInputVisible(false);
                  setInputFocused(false);
                  setKeywords("");
                }}
                onFocus={() => setInputFocused(true)}
                onSearch={(value: string) => setKeywords(value)}
              ></Select>
            )}
          </div>
        </div>
        <Button
          className="btn-primary btn-continue"
          onClick={() => onSend(selectedUsers)}
        >
          Send Invite
        </Button>
      </div>
    </Modal>
  );
};
export default AddEmployeeModal;
