import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { Input } from "antd";
import { search } from "../../api/Options";
import { ModuleIdName } from "../../models/model_get_started";
import "./get_start_category_view.less";

type Props = {
  showOther: boolean;
  onItemSelected: Function;
  data: ModuleIdName[];
  showNext?: boolean;
  tips?: string;
};

interface IPCategoryProps {
  name: string;
  desc: string;
  onClick: Function;
  showDivider: boolean;
}

const IpCategoryItem: React.FC<IPCategoryProps> = (props) => {
  const { name, desc, onClick, showDivider } = props;
  return (
    <div className="box-item" onClick={() => onClick()} key={name}>
      <div className="box-item-center">
        <span className="box-item-name">{name}</span>
        {!_.isEmpty(desc) && <span className="box-item-desc">{desc}</span>}
      </div>
      {showDivider && <div className="divider" />}
    </div>
  );
};

const IpOtherItem: React.FC<{ onInput: Function }> = (props) => {
  const { onInput } = props;
  const [showInput, setShowInput] = useState(false);

  return (
    <div className="box-item" onClick={() => setShowInput(true)}>
      <div className="box-item-center">
        <span className={classnames("box-item-name", { isUp: showInput })}>
          Other
        </span>
        {showInput && (
          <Input
            className="box-item-input"
            placeholder="Enter category name"
            onChange={(e) => onInput(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

const GetStartCategoryView: React.FC<Props> = (props) => {
  const { showOther, onItemSelected, data } = props;
  const [otherText, setOtherText] = useState("");
  const [otherOptions, setOtherOptions] = useState([] as ModuleIdName[]);

  const searchCategories = async () => {
    const res = await search(
      "category",
      false,
      "industryProfessional",
      otherText
    );
    if (res && res.categories) {
      setOtherOptions(res.categories);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(otherText)) {
      searchCategories();
    } else {
      setOtherOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherText]);

  return (
    <div className="category-root" onClick={() => setOtherOptions([])}>
      <div className="category">
        {_.map(data, (item, idx) => {
          return (
            <IpCategoryItem
              name={item.name}
              desc={item.subtitle}
              onClick={() => onItemSelected(item)}
              showDivider={true}
              key={idx}
            />
          );
        })}
        {showOther && (
          <IpOtherItem
            onInput={(str: string) => {
              setOtherText(str);
              onItemSelected({ name: str }, true);
            }}
          />
        )}
      </div>
      {!_.isEmpty(otherOptions) && (
        <div className="other-options">
          {_.map(otherOptions, (item, index) => {
            return (
              <span
                className="options-item"
                key={`${item}-${index}`}
                onClick={() => onItemSelected(item)}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GetStartCategoryView;
